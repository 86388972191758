import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import authService from '../../services/authService';
import withGlobalInitialization from '../hoc/withGlobalInitialization';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const ProtectedRoute = ({ component: Component, render, ...rest }: RouteProps) => {
    const ssoConfigClient = useSelector((state: RootState) => state.configurations.data.ssoConfigClient);
    const accessToken = authService.getAccessTokenData();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (
                    accessToken?.groups.length === 1 &&
                    parseInt(accessToken?.groups[0]) === ssoConfigClient.default_group
                ) {
                    return <Redirect to={{ pathname: '/default' }} />;
                } else if (!!authService.getAccessToken()) {
                    return Component ? <Component {...props} /> : render(props);
                } else {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                }
            }}
        />
    );
};

export default withGlobalInitialization(ProtectedRoute);
