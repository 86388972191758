import { SearchByTextRequestData, SearchSimilarImageData } from '../../services/searchService';
import { ServerSideFilters } from '../../services/apiService';
import { SimilarImageSearchTypes } from '../../components/advancedSearch/similarImageForm';
import { AdvancedSearchTypes } from '../../components/advancedSearch/advancedSearch';
import { toast } from 'react-toastify';
import i18n from 'i18next';

export interface AdvSearchRequestParams {
    searchType: AdvancedSearchTypes;
    query?: SearchByTextRequestData;
    imageBase64?: string;
    similarImageSearchType?: SimilarImageSearchTypes;
    groupId?: string;
}

export const buildImage2TextQuery = (
    objectFilters: string[],
    serverFilters: ServerSideFilters,
    data: SearchByTextRequestData
) => {
    return { expressions: data.imageDescriptions, operators: data.operators };
};

export const buildAdvSearchRequestData = (
    params: AdvSearchRequestParams
): SearchByTextRequestData | SearchSimilarImageData => {
    switch (params.searchType) {
        case AdvancedSearchTypes.imageDescription:
            return params.query;
        case AdvancedSearchTypes.similarImage:
            return {
                type: params.similarImageSearchType,
                imageBase64: params.imageBase64 || '',
                groupId: params.groupId,
                pageSize: 20
            };
        default:
            return null;
    }
};

export const getSimilarImageNameAndType = (query: string, separator: string, isName: boolean) => {
    if (query) {
        const splitedQuery = query.split(separator);
        return isName ? splitedQuery[0].trim() : splitedQuery[1].trim();
    } else return null;
};

export const getBase64FromImage = (file: Blob, onLoad: (string: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const res = reader.result as string;
        onLoad(res);
    };
    reader.onerror = (error) => {
        console.log('Error: ', error);
        toast.error(i18n.t('general_err_msg', { ns: 'errors' }));
    };
};
