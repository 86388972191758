import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RefundCounterProps {
    refundFalconCounter: (refundFalconAmount: number) => void;
    refundVisionCounter: (refundVisionAmount: number) => void;
}

const RefundCounter: React.FC<RefundCounterProps> = (props) => {
    const { t } = useTranslation('usersManagement');
    const [refundFalconAmount, setRefundFalconAmount] = useState(0);
    const [refundVisionAmount, setRefundVisionAmount] = useState(0);

    return (
        <div className='counter-action-container'>
            <strong style={{ marginBottom: '4px' }}> Refund Counters: </strong>
            <div style={{ marginBottom: '10px' }} className='flex-align-center'>
                <TextField
                    style={{ marginRight: '10px' }}
                    value={refundFalconAmount || ''}
                    onChange={(e) => setRefundFalconAmount(+e.target.value)}
                    type='number'
                    label={t('refund_counter.refund_amount')}
                    size='small'
                />

                <Button
                    disabled={refundFalconAmount <= 0}
                    onClick={() => props.refundFalconCounter(refundFalconAmount)}
                    size='small'
                    variant='contained'
                    color='primary'>
                    {t('refund_counter.refund_falcon_yearly_counter')}
                </Button>
            </div>
            <div className='flex-align-center'>
                <TextField
                    style={{ marginRight: '10px' }}
                    value={refundVisionAmount || ''}
                    onChange={(e) => setRefundVisionAmount(+e.target.value)}
                    type='number'
                    label={t('refund_counter.refund_amount')}
                    size='small'
                />

                <Button
                    disabled={refundVisionAmount <= 0}
                    onClick={() => props.refundVisionCounter(refundVisionAmount)}
                    size='small'
                    variant='contained'
                    color='primary'>
                    {t('refund_counter.refund_vision_yearly_counter')}
                </Button>
            </div>
        </div>
    );
};

export default RefundCounter;
