import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import React, { PureComponent } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import ApiService from '../../services/apiService';
import AuditService, {
    AuditCategory,
    AuditGetResponse,
    AuditImageEvents,
    AuditPoiEvents,
    AuditTopologyEvents,
    AuditUserSecurityEvents
} from '../../services/auditService';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import WhiteSelect from '../common/whiteSelect';
import AuditCell from './auditCell';
import AuditExpandedRow from './auditExpandedRow';
import './auditViewContainer.less';
import { WithTranslation, withTranslation } from 'react-i18next';

interface AuditViewContainerState {
    auditRawData: AuditGetResponse<AuditUserSecurityEvents | AuditTopologyEvents | AuditImageEvents | AuditPoiEvents>;
    auditProcessedData:
        | (ProcessedAuditEvents & AuditUserSecurityEvents)[]
        | (ProcessedAuditEvents & AuditTopologyEvents)[]
        | (ProcessedAuditEvents & AuditImageEvents)[]
        | (ProcessedAuditEvents & AuditPoiEvents)[];
    sort: string;
    page: number;
    pageSize: number;
    order: 'asc' | 'desc';
    isLoading;
    showAllUserGroups: boolean;
    auditColumns: DataTableColumn[];
    selectedAuditCategory: AuditCategory;
    title: string;
    userIdToUserName: Record<number, string>;
    isDataHidden: boolean;
}

interface ProcessedAuditEvents {
    eventDateDisplayString: string;
    userName: string;
}

interface DataTableColumn {
    cell?: any;
    name: string;
    selector: string;
    sortable: boolean;
    maxWidth?: string;
    minWidth?: string;
}

const NoRecords = styled.h1`
    font-size: 18px;
`;

class AuditViewContainer extends PureComponent<WithTranslation, AuditViewContainerState> {
    constructor(props) {
        super(props);

        this.state = {
            auditRawData: null,
            auditProcessedData: [],
            order: 'desc',
            page: 0,
            pageSize: 20,
            sort: 'eventDate',
            isLoading: true,
            showAllUserGroups: false,
            selectedAuditCategory: AuditCategory.userSecurity,
            auditColumns: [],
            title: AuditCategory.userSecurity,
            userIdToUserName: {},
            isDataHidden: authService.hasPermissions(Permissions.hide_cases_tab)
        };
    }

    async componentDidMount() {
        await this.updateAuditData();
        this.fetchUsers();
    }

    fetchUsers = async () => {
        const response = await ApiService.getUsersForAudit();
        const userIdToUserName = response.data.reduce((userIdToUserName, user) => {
            userIdToUserName[user.id] = user.userName;
            return userIdToUserName;
        }, {});

        const processedAuditEvents = this.state.auditRawData.content.map((event: any) => {
            return {
                ...event,
                userName: userIdToUserName[event.userId],
                eventDateDisplayString: moment(event.eventDate).toString()
            };
        });

        this.setState({ userIdToUserName, auditProcessedData: processedAuditEvents });
    };

    async updateAuditData() {
        this.setState({ isLoading: true });

        let auditEvents = null;

        switch (this.state.selectedAuditCategory) {
            case AuditCategory.topologyActions:
                auditEvents = await AuditService.fetchTopologyActionsEvents(
                    this.state.sort,
                    this.state.order,
                    this.state.page,
                    this.state.pageSize,
                    this.state.showAllUserGroups,
                    this.state.isDataHidden ? ['topologyName', 'metadata'] : null
                );
                break;
            case AuditCategory.userSecurity:
                auditEvents = await AuditService.fetchUserSecurityEvents(
                    this.state.sort,
                    this.state.order,
                    this.state.page,
                    this.state.pageSize,
                    this.state.showAllUserGroups,
                    null
                );
                break;
            case AuditCategory.imageActions:
                auditEvents = await AuditService.fetchImageActionsEvents(
                    this.state.sort,
                    this.state.order,
                    this.state.page,
                    this.state.pageSize,
                    this.state.showAllUserGroups,
                    this.state.isDataHidden ? ['imageName', 'queryName'] : null
                );
                break;
            case AuditCategory.poiActions:
                auditEvents = await AuditService.fetchPoiActionsEvents(
                    this.state.sort,
                    this.state.order,
                    this.state.page,
                    this.state.pageSize,
                    this.state.showAllUserGroups,
                    null
                );
                break;
        }

        const processedAuditEvents = auditEvents.data.content.map((event) => {
            return {
                ...event,
                userName: this.state.userIdToUserName[event.userId],
                eventDateDisplayString: moment(event.eventDate).toString()
            };
        });
        this.setState({
            auditRawData: auditEvents.data,
            auditProcessedData: processedAuditEvents,
            isLoading: false
        });
    }

    buildColumns = () => {
        const WrapContent = (row) => (
            <span>
                {row.poiLabels?.map((label, index) => (
                    <span key={index + ' ' + label} className='with-before'>
                        {label}
                    </span>
                ))}
            </span>
        );
        const defaultColumns: Record<AuditCategory, DataTableColumn[]> = {
            [AuditCategory.topologyActions]: [
                {
                    name: this.props.t('table.event_type'),
                    selector: 'eventType',
                    maxWidth: '152px',
                    minWidth: '152px',
                    sortable: true,
                    cell: AuditCell('eventType')
                },
                {
                    name: this.props.t('table.event_date'),
                    selector: 'eventDateDisplayString',
                    sortable: true,
                    minWidth: '260px'
                },
                {
                    name: this.props.t('table.query_name'),
                    selector: 'topologyName',
                    sortable: true,
                    cell: AuditCell('topologyName')
                },
                {
                    name: this.props.t('table.user_name'),
                    selector: 'userName',
                    sortable: true,
                    maxWidth: '50px'
                },
                {
                    name: this.props.t('table.query_id'),
                    minWidth: '268px',
                    selector: 'queryId',
                    sortable: true
                }
            ],
            [AuditCategory.userSecurity]: [
                {
                    name: this.props.t('table.event_type'),
                    selector: 'eventType',
                    minWidth: '152px',
                    sortable: true
                },
                {
                    name: this.props.t('table.event_date'),
                    selector: 'eventDateDisplayString',
                    sortable: true,
                    minWidth: '260px'
                },
                {
                    name: this.props.t('table.user_name'),
                    selector: 'userName',
                    sortable: true
                }
            ],
            [AuditCategory.imageActions]: [
                {
                    name: this.props.t('table.event_type'),
                    selector: 'eventType',
                    maxWidth: '152px',
                    minWidth: '152px',
                    sortable: true
                },
                {
                    name: this.props.t('table.event_date'),
                    selector: 'eventDateDisplayString',
                    sortable: true,
                    minWidth: '260px'
                },
                {
                    name: this.props.t('table.query_name'),
                    selector: 'queryName',
                    sortable: true
                },
                {
                    name: this.props.t('table.user_name'),
                    selector: 'userName',
                    sortable: true,
                    maxWidth: '50px'
                },
                {
                    name: this.props.t('table.query_id'),
                    selector: 'queryId',
                    sortable: true
                },
                {
                    name: this.props.t('table.image_name'),
                    minWidth: '268px',
                    selector: 'imageName',
                    sortable: true
                }
            ],
            [AuditCategory.poiActions]: [
                {
                    name: this.props.t('table.event_type'),
                    selector: 'eventType',
                    maxWidth: '152px',
                    minWidth: '152px',
                    sortable: true
                },
                {
                    name: this.props.t('table.event_date'),
                    selector: 'eventDateDisplayString',
                    sortable: true,
                    minWidth: '260px'
                },
                {
                    name: this.props.t('table.poi_label'),
                    selector: 'poiLabels',
                    sortable: true,
                    cell: (row) => <WrapContent {...row} />,
                    minWidth: '150px'
                },
                {
                    name: this.props.t('table.poi_id'),
                    selector: 'poiName',
                    sortable: true,
                    minWidth: '280px'
                },
                {
                    name: this.props.t('table.query_name'),
                    selector: 'queryName',
                    sortable: true,
                    minWidth: '150px'
                },
                {
                    name: this.props.t('table.query_id'),
                    selector: 'queryId',
                    sortable: true,
                    minWidth: '260px'
                },
                {
                    name: this.props.t('table.user_name'),
                    selector: 'userName',
                    sortable: true,
                    minWidth: '150px'
                }
            ]
        };

        if (authService.hasPermissions(Permissions.audit_admin)) {
            defaultColumns[AuditCategory.topologyActions].push({
                name: this.props.t('table.flow_id'),
                selector: 'flowId',
                minWidth: '284px',
                sortable: true
            });
            defaultColumns[AuditCategory.imageActions].push({
                name: this.props.t('table.flow_id'),
                selector: 'flowId',
                minWidth: '284px',
                sortable: true
            });
        }

        if (this.state.isDataHidden) {
            defaultColumns[AuditCategory.topologyActions].splice(2, 1);
            defaultColumns[AuditCategory.imageActions].splice(2, 1);
            defaultColumns[AuditCategory.imageActions].splice(4, 1);
            defaultColumns[AuditCategory.topologyActions][0].maxWidth = 'unset';
            defaultColumns[AuditCategory.topologyActions][2].maxWidth = 'unset';
            defaultColumns[AuditCategory.imageActions][0].maxWidth = 'unset';
            defaultColumns[AuditCategory.imageActions][2].maxWidth = 'unset';
        }
        return defaultColumns[this.state.selectedAuditCategory];
    };

    handleSort = (column: IDataTableColumn<any>, sortDirection: 'asc' | 'desc') => {
        let sortColumn = column.selector;

        if (sortColumn === 'eventDateDisplayString') {
            sortColumn = 'eventDate';
        }

        this.setState(
            {
                sort: sortColumn.toString(),
                order: sortDirection
            },
            () => this.updateAuditData()
        );
    };

    handlePageChange = async (page: number) => {
        this.setState({ page: --page }, () => this.updateAuditData());
    };

    handlePerRowsChange = async (currentRowsPerPage: number, page: number) => {
        this.setState({ page: --page, pageSize: currentRowsPerPage }, () => this.updateAuditData());
    };

    getTableColumns = () => {
        const newColumns = [...this.buildColumns()];
        newColumns.push({
            name: this.props.t('table.group_id'),
            selector: 'userGroupId',
            sortable: true
        });
        return newColumns;
    };

    handleAllGroupsChange = async (showAllUserGroups: boolean) => {
        this.setState(
            {
                showAllUserGroups
            },
            () => this.updateAuditData()
        );
    };

    handleEventCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedAuditCategory: AuditCategory = event.target.value as AuditCategory;
        this.setState(
            {
                selectedAuditCategory: selectedAuditCategory,
                title: event.target.value,
                sort: 'eventDate'
            },
            this.updateAuditData
        );
    };

    shouldDisableMetadataRow = (
        data:
            | (ProcessedAuditEvents & AuditUserSecurityEvents)
            | (ProcessedAuditEvents & AuditTopologyEvents)
            | (ProcessedAuditEvents & AuditImageEvents)
            | (ProcessedAuditEvents & AuditPoiEvents)
    ) => {
        if (!data.metadata) {
            return true;
        }

        if (
            Object.keys(data.metadata).length === 1 &&
            Object.keys(data.metadata)[0] === 'message' &&
            !authService.hasPermissions(Permissions.audit_admin)
        ) {
            return true;
        }

        return false;
    };

    render() {
        return (
            <Grid className='scrollable-container' item style={{ flex: 1 }}>
                <div className={'audit-container'}>
                    <h4 className='flex-align-center'>{this.props.t('title')}</h4>
                    {authService.hasPermissions(Permissions.audit_admin) && (
                        <FormControlLabel
                            className={'show-all-groups'}
                            control={
                                <Checkbox
                                    checked={this.state.showAllUserGroups}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleAllGroupsChange(e.target.checked);
                                    }}
                                    color='primary'
                                />
                            }
                            label={this.props.t('show_events_from_all_user_groups')}
                        />
                    )}
                    <div className={'category-selector'}>
                        <span>{this.props.t('event_category')}:</span>
                        <WhiteSelect
                            onChange={this.handleEventCategoryChange}
                            options={Object.values(AuditCategory).map((category) => {
                                return { name: this.props.t(`audit_category_options.${category}`), id: category };
                            })}
                            value={this.state.selectedAuditCategory}
                        />
                    </div>
                    <DataTable<
                        | (ProcessedAuditEvents & AuditUserSecurityEvents)
                        | (ProcessedAuditEvents & AuditTopologyEvents)
                        | (ProcessedAuditEvents & AuditImageEvents)
                        | (ProcessedAuditEvents & AuditPoiEvents)
                    >
                        title={this.props.t(`audit_category_options.${this.state.title}`)}
                        data={this.state.auditProcessedData}
                        columns={this.state.showAllUserGroups ? this.getTableColumns() : this.buildColumns()}
                        theme={'dark'}
                        onSort={this.handleSort}
                        sortServer
                        keyField='eventDate'
                        progressPending={this.state.isLoading}
                        pagination
                        paginationServer
                        paginationPerPage={this.state.pageSize}
                        paginationTotalRows={this.state.auditRawData?.totalElements}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        expandableRows
                        expandableRowDisabled={this.shouldDisableMetadataRow}
                        expandableRowsComponent={<AuditExpandedRow />}
                        paginationComponentOptions={{ rowsPerPageText: this.props.t('table.rows_per_page') }}
                        noDataComponent={<NoRecords>{this.props.t('no_records')}</NoRecords>}
                    />
                </div>
            </Grid>
        );
    }
}

export default withTranslation('audit')(AuditViewContainer);
