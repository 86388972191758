import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ServerFilters } from '../../store/slices/filtersSlice';
import DateRangeSelect, { isDateOptionsDisabled } from '../common/dateRangeSelect';
import { useTranslation } from 'react-i18next';

interface UploadedMediaFiltersProps {
    filters: ServerFilters;
    setFilters: (filters: ServerFilters) => void;
    uploadedMediaPhotosCount: number;
    uploadedMediaFilteredPhotosCount: number;
    disabled: boolean;
}

const UploadedMediaFilters: React.FC<UploadedMediaFiltersProps> = (props) => {
    const { t } = useTranslation('filters');
    const currentTopologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const isOptionDisabled = useMemo(
        () =>
            isDateOptionsDisabled(
                currentTopologyPhotos.map((photo) => photo.creationDate),
                false
            ),
        [currentTopologyPhotos]
    );

    const photosWithCreationDateCount = currentTopologyPhotos.filter((photo) => !!photo.creationDate).length;

    return (
        <>
            <Typography variant='subtitle2' color='textPrimary' style={{ marginTop: '50px' }}>
                {t('upload_media_filters.uploaded_media', {
                    uploadedMediaFilteredPhotosCount: props.uploadedMediaFilteredPhotosCount,
                    uploadedMediaPhotosCount: props.uploadedMediaPhotosCount
                })}
            </Typography>

            <DateRangeSelect
                isOptionDisabled={isOptionDisabled}
                disabled={props.disabled || !props.uploadedMediaPhotosCount || !photosWithCreationDateCount}
                value={props.filters.creationDate}
                onChange={(date) => props.setFilters({ ...props.filters, creationDate: date })}
                label={t('upload_media_filters.creation_date')}
            />
        </>
    );
};

export default UploadedMediaFilters;
