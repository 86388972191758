import { Image, ShapeType, GalleryThumbnail } from '../components/popupImage/popupImageSectionContainer';
import {
    PhotoAppearance,
    PersonDescriptor,
    PhotoSourceNetwork,
    PhotoNodeType,
    SynapsePhoto,
    Thumbnail
} from '../components/topologyview/VisionSynapse';
import { VirtualScrollGalleryImage } from '../components/virtualScrollGallery/virtualScrollGallery';
import { TopologyMetaData } from '../store/slices/topologySlice';
import CommonService from './commonService';
import i18n from 'i18next';

const GalleryService = {
    getShapeType: (seedIds: string[], selectedConnectionId: string, personId: string): ShapeType => {
        // TODO tnt connected people to selected person should be seed type
        return seedIds.includes(personId)
            ? ShapeType.Seed
            : selectedConnectionId === personId
            ? ShapeType.Selected
            : ShapeType.Other;
    },

    convertToPopupGalleryPhotos: (
        photos: SynapsePhoto[],
        topologyMetadata: TopologyMetaData,
        seedIds: string[],
        selectedConnectionId?: string
    ): Image[] =>
        photos.map((photo) => ({
            id: photo.photoUID,
            url: GalleryService.getPhotoUrl(photo.jFileId),
            name: photo.customFileName || photo.fileName,
            imageSourceUrl: GalleryService.getImageSourceUrl(photo),
            shapes: GalleryService.convertAppearancesToShapes(
                topologyMetadata.photoUIDtoPhotoAppearances[photo.photoUID],
                topologyMetadata.personUIDtoPerson,
                seedIds,
                selectedConnectionId
            ),
            extraText: GalleryService.getPhotoDateText(photo.creationDate, photo.timestamp),
            metadata: GalleryService.getPhotoMetaData(photo),
            source: photo.photoType,
            flagged: photo.flagged,
            thumbnail: photo.thumbnails?.[0] ? GalleryService.convertThumbnail(photo.thumbnails[0]) : null,
            faceRecognitionEnabled: photo.faceRecognitionEnabled,
            image2TextEnabled: photo.image2TextEnabled,
            objectDetectionEnabled: photo.objectDetectionEnabled,
            ocrEnabled: photo.ocrEnabled,
            explicitContentEnabled: photo.explicitContentEnabled,
            landmarksEnabled: photo.landmarksEnabled,
            similarImagesEnabled: photo.similarImagesEnabled,
            detectedLandmarks: photo.detectedLandmarks
        })),

    convertToVirtualScrollGalleryPhotos: (photos: SynapsePhoto[]): VirtualScrollGalleryImage[] =>
        photos.map((photo) => {
            const thumbnail = photo.thumbnails?.[0];
            return {
                imageId: photo.photoUID,
                imageUrl: GalleryService.getPhotoUrl(thumbnail?.jfileId || photo.jFileId),
                flagged: photo.flagged,
                height: thumbnail?.height,
                width: thumbnail?.width
            };
        }),

    convertThumbnail: (thumbnail: Thumbnail): GalleryThumbnail => ({
        height: thumbnail.height,
        width: thumbnail.width,
        url: GalleryService.getPhotoUrl(thumbnail.jfileId)
    }),

    getHeightWhileKeepingAspectRatio: (imageWidth: number, imageHeight: number, targetWidth: number) => {
        const widthDifference = targetWidth / imageWidth;
        return imageHeight * widthDifference;
    },

    convertAppearancesToShapes: (
        photoAppearances: Pick<PhotoAppearance, 'personUID' | 'coordinates'>[],
        personUIDtoPerson: Record<string, PersonDescriptor>,
        seedIds: string[],
        selectedConnectionId: string
    ) => {
        return photoAppearances?.map((appearance) => ({
            coordinates: appearance.coordinates,
            type: GalleryService.getShapeType(seedIds, selectedConnectionId, appearance.personUID),
            label: CommonService.getHighestPriority(personUIDtoPerson[appearance.personUID]?.labels),
            id: appearance.personUID
        }));
    },

    getPhotoDateText: (creationTime: number, uploadTime: number) => {
        if (!creationTime && !uploadTime) {
            return '';
        }
        return uploadTime
            ? i18n.t('gallery_service.upload_time', { ns: 'common', date: new Date(uploadTime * 1000) })
            : i18n.t('gallery_service.creation_time', { ns: 'common', date: new Date(creationTime) });
    },
    getPhotoMetaData: (photo: SynapsePhoto) => {
        const returnObject = {};

        if (photo.vendor) {
            returnObject[i18n.t('photoMetadata.mobile_vendor', { ns: 'common' })] = photo.vendor;
        }

        if (photo.mobileType) {
            returnObject[i18n.t('photoMetadata.mobile_type', { ns: 'common' })] = photo.mobileType;
        }

        if (photo.location != null && photo.location.trim() !== '') {
            returnObject[i18n.t('photoMetadata.location', { ns: 'common' })] = photo.location;
        }

        if (photo.ocr) {
            returnObject[i18n.t('photoMetadata.ocr', { ns: 'common' })] = photo.ocr;
        }

        return returnObject;
    },

    getImageSourceUrl: (photo: SynapsePhoto) => {
        return GalleryService.shouldShowNetwork(photo.fileName, photo.photoType)
            ? photo.originalSourceLink || photo.fileName
            : null;
    },

    shouldShowNetwork: (imageName: string, imageSource: PhotoNodeType) => {
        return (
            !imageName.includes('profile photo') &&
            !imageName.includes('profile image') &&
            imageSource in PhotoSourceNetwork
        );
    },

    getPhotoUrl: (jFileId: string) => `/api/photo/${jFileId}`
};

export default GalleryService;
