import { createSelector } from '@reduxjs/toolkit';
import { createMatchSelector, getSearch, push, replace, RouterRootState } from 'connected-react-router';
import store from '..';
import SynapseHistoryService from '../../components/topologyview/synapseHistoryService';
import CommonService from '../../services/commonService';
import { AdvancedSearchTypes } from '../../components/advancedSearch/advancedSearch';

enum QueryKeys {
    seedId = 'sid',
    watchlist = 'wid',
    crossTopology = 'cid',
    isTnt = 'isTnt',
    entity = 'entity',
    imageDescription = 'imageDescription[]',
    ocr = 'ocr',
    similarImage = 'similarImage',
    operators = 'operators[]'
}

export type CurrentTab = 'gallery' | 'relationships';

export interface QueryParams {
    seedIds: string[];
    watchlistIds: string[];
    crossTopologyIds: string[];
    isTnt: boolean;
    entity: string;
    imageDescription: string[];
    operators: string[];
    ocr: string;
    similarImage: string;
}

export interface UrlParams {
    batchId: string;
    tab: CurrentTab;
}

export interface QueryParamArgs {
    key: string;
    items: string[];
}

export const getUrl = (urlParams: UrlParams | null, queryParams?: Partial<QueryParams>) => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (queryParams?.seedIds) {
        urlSearchParams.delete(QueryKeys.seedId);
        queryParams?.seedIds.forEach((seedId) => urlSearchParams.append(QueryKeys.seedId, seedId));
    }

    if (queryParams?.watchlistIds) {
        urlSearchParams.delete(QueryKeys.watchlist);
        queryParams?.watchlistIds.forEach((watchlist) => urlSearchParams.append(QueryKeys.watchlist, watchlist));
    }

    if (queryParams?.crossTopologyIds) {
        urlSearchParams.delete(QueryKeys.crossTopology);
        queryParams?.crossTopologyIds.forEach((topology) => urlSearchParams.append(QueryKeys.crossTopology, topology));
    }

    if (queryParams?.isTnt) {
        urlSearchParams.set(QueryKeys.isTnt, 'true');
    } else {
        urlSearchParams.delete(QueryKeys.isTnt);
    }

    if (queryParams?.entity) {
        urlSearchParams.set(QueryKeys.entity, queryParams.entity);
    }

    if (queryParams?.imageDescription) {
        urlSearchParams.delete(QueryKeys.imageDescription);
        queryParams?.imageDescription.forEach((description) => {
            urlSearchParams.append(QueryKeys.imageDescription, description);
        });
    }

    if (queryParams?.operators) {
        urlSearchParams.delete(QueryKeys.operators);
        queryParams?.operators.forEach((operator) => {
            urlSearchParams.append(QueryKeys.operators, operator);
        });
    }

    if (queryParams?.ocr) {
        urlSearchParams.set(QueryKeys.ocr, queryParams.ocr);
    }

    if (queryParams?.similarImage) {
        urlSearchParams.set(QueryKeys.similarImage, queryParams.similarImage);
    }

    const path = urlParams ? `/case/${urlParams.batchId}/${urlParams.tab}` : window.location.pathname;
    const fullPath = `${path}?${urlSearchParams.toString()}`;

    return fullPath;
};

export const navigateSynapse = (
    urlParams: UrlParams | null,
    queryParams?: Partial<QueryParams>,
    saveRoute?: boolean
) => {
    const fullPath = getUrl(urlParams, queryParams);

    if (saveRoute) {
        SynapseHistoryService.saveCurrentRoute(getPathForRelationshipMapBack());
    }
    return push(fullPath);
};

const getPathForRelationshipMapBack = () => {
    const state = store.getState();
    const batchId = TopologyRouterSelectors.getBatchId(state);
    const seedIds = TopologyRouterSelectors.getSeedIds(state);
    const crossTopologyIds = TopologyRouterSelectors.getCommonTopologies(state);
    const watchlistIds = TopologyRouterSelectors.getWatchlistIds(state);
    const isTnt = TopologyRouterSelectors.isTnt(state);

    return getUrl({ batchId, tab: 'relationships' }, { seedIds, watchlistIds, crossTopologyIds, isTnt });
};

export const doNavigateTopologyRoot = (batchId: string) => push(`/case/${batchId}`);

export const changeSeed = (...seedIds: string[]) => changeQueryParams({ key: QueryKeys.seedId, items: seedIds });
export const replaceSeed = (...seedIds: string[]) => replaceQueryParams({ key: QueryKeys.seedId, items: seedIds });

export const changeWatchlists = (watchlistIds: string[]) =>
    changeQueryParams({ key: QueryKeys.watchlist, items: watchlistIds });

export const changeTnt = (isTnt: boolean) => changeQueryParams({ key: QueryKeys.isTnt, items: [isTnt ? 'true' : ''] });

export const changeSearchQueryImageDescription = (searchQuery: string) =>
    changeQueryParams({ key: QueryKeys.imageDescription, items: [searchQuery] });

export const changeSearchQueryOperators = (searchQuery: string) =>
    changeQueryParams({ key: QueryKeys.operators, items: [searchQuery] });

export const changeEntityQuery = (searchQuery: string) =>
    changeQueryParams({ key: QueryKeys.entity, items: [searchQuery] });

export const changeOcrQuery = (searchQuery: string) => changeQueryParams({ key: QueryKeys.ocr, items: [searchQuery] });

export const changeSimImageQuery = (searchQuery: string) =>
    changeQueryParams({ key: QueryKeys.similarImage, items: [searchQuery] });

export const changeSearchQueryCommon = (searchQuery: string, searchType: AdvancedSearchTypes) =>
    changeQueryParams({ key: QueryKeys[searchType], items: [searchQuery] });

export const changeQueryParams = (...queryParams: QueryParamArgs[]) => {
    const path = getPathWithNewQueryParams(queryParams);

    SynapseHistoryService.saveCurrentRoute(getPathForRelationshipMapBack());
    return push(path);
};

const replaceQueryParams = (...queryParams: QueryParamArgs[]) => {
    const path = getPathWithNewQueryParams(queryParams);

    return replace(path);
};

const getPathWithNewQueryParams = (queryParams: QueryParamArgs[]) => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    queryParams.forEach((queryParam) => {
        currentUrlParams.delete(queryParam.key);

        queryParam.items.filter((item) => !!item).forEach((item) => currentUrlParams.append(queryParam.key, item));
    });
    return `${window.location.pathname}?${currentUrlParams.toString()}`;
};

export const TopologyRouterSelectors = {
    getWatchlistIds: createSelector([getSearch], (search) =>
        CommonService.extractQueryParamList(search, QueryKeys.watchlist)
    ),

    getSeedIds: createSelector([getSearch], (search) => CommonService.extractQueryParamList(search, QueryKeys.seedId)),

    getCommonTopologies: createSelector([getSearch], (search) =>
        CommonService.extractQueryParamList(search, QueryKeys.crossTopology)
    ),

    getCurrentTab: <T extends RouterRootState>(state: T) => {
        return createMatchSelector<T, { tab?: CurrentTab }>('/case/:batchId/:tab')(state)?.params.tab;
    },

    isTnt: createSelector(
        [getSearch],
        (search) => CommonService.extractQueryParamList(search, QueryKeys.isTnt).length === 1
    ),

    getBatchId: <T extends RouterRootState>(state: T) => {
        const topologyId = createMatchSelector<T, { batchId?: string }>('/case/:batchId/:tab')(state)?.params.batchId;
        const watchlistId = createMatchSelector<T, { batchId?: string }>('/watchlist/:batchId')(state)?.params.batchId;
        return topologyId || watchlistId;
    },

    getAllQueryIds: <T extends RouterRootState>(state: T) => {
        return [
            TopologyRouterSelectors.getBatchId(state),
            ...(TopologyRouterSelectors.getCommonTopologies(state) || [])
        ];
    },

    getEntityQuery: createSelector([getSearch], (search) => CommonService.extractQueryParam(search, QueryKeys.entity)),

    getSearchQuery: createSelector([getSearch], (search) =>
        CommonService.extractQueryParam(search, QueryKeys.imageDescription)
    ),

    getImage2TextQuery: createSelector([getSearch], (search) => {
        const imageDescription = CommonService.extractAllQueryParams(search, QueryKeys.imageDescription);
        const operators = CommonService.extractAllQueryParams(search, QueryKeys.operators);

        return { imageDescriptions: imageDescription, operators: operators };
    }),

    getOcrQuery: createSelector([getSearch], (search) => CommonService.extractQueryParam(search, QueryKeys.ocr)),

    getSimilarImageQuery: createSelector([getSearch], (search) =>
        CommonService.extractQueryParam(search, QueryKeys.similarImage)
    )
};
