import React, { useState } from 'react';
import { UserManagementData } from './usersManagmentContainer';
import ApiService from '../../services/apiService';
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select
} from '@material-ui/core';
import Loader from 'react-spinners/PulseLoader';
import { Colors } from '../colors';
import { toast } from 'react-toastify';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import TextFormField from '../common/formFields/TextFormField';
import * as yup from 'yup';
import SidePanelMultiSelect from '../topologyview/sidePanelMultiSelect';
import { Permissions } from '../../shared/model/Permissions';
import CustomTooltip from '../common/misc/CustomTooltip';
import { useTranslation } from 'react-i18next';

interface RolesManagementProps {
    managementData: UserManagementData;
    refreshManagementData: () => Promise<void>;
}

interface AddRoleForm {
    id?: string;
    name: string;
    permissions: Permissions[];
}

const permanentPermissions = [
    Permissions.case_show_falcon,
    Permissions.watchlist_show_falcon,
    Permissions.minimum_number_of_people,
    Permissions.show_video_advanced_options,
    Permissions.export_zip,
    Permissions.delete_individual,
    Permissions.merge_individual,
    Permissions.export_person_to_zip,
    Permissions.watchlist_sort,
    Permissions.fc_customization,
    Permissions.show_skynet_activation_button
];

const systemAdminPermissions = [Permissions.show_audit, Permissions.UM_users_tab, Permissions.hide_cases_tab];

const customerAdminPermissions = permanentPermissions.concat([
    Permissions.show_audit,
    Permissions.UM_users_tab,
    Permissions.query_drag_and_drop
]);

const adminPermissions = permanentPermissions.concat([
    Permissions.show_audit,
    Permissions.audit_admin,
    Permissions.show_user_guide,
    Permissions.select_all_topologies,
    Permissions.view_version,
    Permissions.filter_unknown,
    Permissions.UM_users_tab,
    Permissions.show_skynet_parsed_query,
    Permissions.query_drag_and_drop,
    Permissions.UM_system_tab,
    Permissions.UM_groups_tab,
    Permissions.UM_roles_tab,
    Permissions.UM_show_admin_role,
    Permissions.UM_show_users_with_admin_role,
    Permissions.UM_show_all_groups
]);

export enum PredefinedRole {
    User = 'user',
    SystemAdmin = 'systemAdmin',
    CustomerAdmin = 'customerAdmin',
    Admin = 'admin'
}

const predefinedRolePermissions = {
    user: permanentPermissions,
    systemAdmin: systemAdminPermissions,
    customerAdmin: customerAdminPermissions,
    admin: adminPermissions
};

const RolesManagement: React.FC<RolesManagementProps> = (props) => {
    const { t } = useTranslation('usersManagement');

    const getInitialValues = () => ({ name: '', permissions: [] });

    const [selectedRoleId, setSelectedRoleId] = useState<number>(0);
    const [initialValues, setInitialValues] = useState<AddRoleForm>(getInitialValues());
    const [isDeletingRole, setIsDeletingRole] = useState(false);

    const validationSchema = yup.object().shape<AddRoleForm>({
        name: !selectedRoleId
            ? yup
                  .string()
                  .trim()
                  .required()
                  .notOneOf(props.managementData.roles.map((role) => role.name))
            : yup.string().trim().required(),
        permissions: yup
            .array()
            .of<Permissions>(yup.mixed<Permissions>().oneOf(Object.values(Permissions)))
            .required()
    });

    const createRole = async ({ name, permissions }: AddRoleForm, helpers: FormikHelpers<AddRoleForm>) => {
        const confirmed = window.confirm(t('roles_management.confirm_create_role') + ' "' + name + '"?');
        if (!confirmed) {
            return;
        }
        try {
            await ApiService.createRole(name, permissions);
            await props.refreshManagementData();
        } catch {
            toast.error(t('roles_management.failed_create_role') + ' "' + name + '"');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const editRole = async ({ id, name, permissions }: AddRoleForm, helpers: FormikHelpers<AddRoleForm>) => {
        const confirmed = window.confirm(t('roles_management.confirm_edit_role') + ' "' + name + '"?');
        if (!confirmed) {
            return;
        }
        try {
            await ApiService.editRole(id, name, permissions);
            await props.refreshManagementData();
        } catch {
            toast.error(t('roles_management.failed_edit_role') + ' "' + name + '"');
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const deleteRole = async ({ id, name }: AddRoleForm) => {
        const confirmed = window.confirm(t('roles_management.confirm_delete_role') + ' "' + name + '"?');
        if (!confirmed) {
            return;
        }

        try {
            setIsDeletingRole(true);
            await ApiService.deleteRole(id);
            await props.refreshManagementData();
            setSelectedRoleId(0);
            setInitialValues(getInitialValues());
        } catch (error) {
            toast.error(t('roles_management.failed_delete_role') + ' "' + name + '"');
        } finally {
            setIsDeletingRole(false);
        }
    };

    const renderPredefinedPermissions = (predefinedRole: PredefinedRole) => (
        <FormControlLabel
            value={predefinedRole}
            control={<Radio color='primary' />}
            label={t('roles_management.predefined_roles.' + predefinedRole)}
        />
    );

    return (
        <div>
            <h4>{t('roles_management.title')}</h4>

            <FormControl>
                <InputLabel className='users-management-label'>{t('roles_management.role')}: </InputLabel>
                <Select
                    value={selectedRoleId}
                    onChange={(event) => {
                        const roleId = event.target.value as number;
                        const role = props.managementData.roles.find((role) => role.id === roleId);
                        setInitialValues(role as any);
                        setSelectedRoleId(roleId);
                    }}>
                    {props.managementData.roles.map((role) => (
                        <MenuItem value={role.id} key={role.id}>
                            {role.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <div style={{ margin: '10px 0 50px' }}>
                <Formik<AddRoleForm>
                    onSubmit={selectedRoleId === 0 ? createRole : editRole}
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={initialValues}>
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className='edit-user-form'>
                            <Field
                                style={{ marginRight: '10px' }}
                                name='name'
                                label={t('roles_management.role_name')}
                                disabled={isSubmitting}
                                component={TextFormField}
                            />
                            <div className='user-groups-dropdown'>
                                <SidePanelMultiSelect
                                    label={t('roles_management.permissions')}
                                    value={values.permissions}
                                    onAutoCompleteChange={(event, value) => setFieldValue('permissions', value)}
                                    onClose={() => {}}
                                    options={Object.values(Permissions)}
                                />
                                <RadioGroup
                                    aria-label='Predefined Roles'
                                    name='predefined-roles-radio-group'
                                    onChange={(event, value) => {
                                        setFieldValue('permissions', predefinedRolePermissions[value]);
                                    }}>
                                    {Object.values(PredefinedRole).map((predefinedRole) =>
                                        renderPredefinedPermissions(predefinedRole)
                                    )}
                                </RadioGroup>
                            </div>
                            <Button
                                size='small'
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <Loader size={10} color={Colors.white} />
                                ) : selectedRoleId === 0 ? (
                                    t('roles_management.create_role')
                                ) : (
                                    t('roles_management.edit_role')
                                )}
                            </Button>
                            {!!selectedRoleId && (
                                <CustomTooltip
                                    title={selectedRoleId === 1 ? t('roles_management.can_not_delete_admin') : ''}>
                                    <span>
                                        <Button
                                            disabled={selectedRoleId === 1}
                                            onClick={() => deleteRole(values)}
                                            size='small'
                                            variant='contained'
                                            style={{ backgroundColor: Colors.error, marginLeft: '10px' }}>
                                            {isDeletingRole ? (
                                                <Loader size={10} color={Colors.white} />
                                            ) : (
                                                t('roles_management.delete_role')
                                            )}
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default RolesManagement;
