import React, { useEffect, useState } from 'react';
import { MenuOption } from '../common/menuActions';
import { Coordinates, Landmark, PhotoNodeType } from '../topologyview/VisionSynapse';
import PopupImage, { Point, PopupImageShape } from './popupImage';
import PopupImageFooter from './popupImageFooter';
import PopupImageHeader from './popupImageHeader';
import { TreeSelectOption } from '../common/treeSelect/treeSelect';
import { Colors } from '../colors';
import './popupImageSectionContainer.less';
import { useTranslation } from 'react-i18next';
import PopupImageLandmarks from './popupImageLandmarks';
import Button from '@material-ui/core/Button';
import { ImageToSplit } from '../popupGallery/popupGalleryContainer';

export enum ShapeType {
    Seed = 'seed',
    Selected = 'selected',
    Other = 'other'
}
interface Shape {
    coordinates: Coordinates;
    type: ShapeType;
    label: string;
    id: string;
}
export interface Image {
    id: string;
    name: string;
    url: string;
    source?: PhotoNodeType;
    imageSourceUrl?: string;
    extraText?: string;
    metadata?: { [key: string]: string };
    shapes: Shape[];
    flagged?: boolean;
    thumbnail?: GalleryThumbnail;
    faceRecognitionEnabled: boolean;
    image2TextEnabled: boolean;
    objectDetectionEnabled: boolean;
    ocrEnabled: boolean;
    explicitContentEnabled: boolean;
    landmarksEnabled: boolean;
    similarImagesEnabled: boolean;
    detectedLandmarks: Landmark[];
}

export interface GalleryThumbnail {
    width: number;
    height: number;
    url: string;
}

export enum DisplayOptionValueType {
    SeedAndConnection = 'seed_and_connection',
    OtherConnections = 'other'
}

interface PopupImageSectionContainerProps {
    image: Image;
    imagesCount: number;
    currentImageIndex: number;
    onNext: () => void;
    onPrev: () => void;
    actions?: MenuOption[];
    showMultiSelectOptions?: boolean;
    onFlagClick?: () => void;
    onClick?: (shapesSelected: Coordinates[]) => void;
    onDoubleClick?: (id: string) => void;
    onSplit?: () => void;
    imagesToSplit?: ImageToSplit[];
    disableSimilarImage?: boolean;
    disableSplitButton?: boolean;
    personToSplitUId?: string;
    multiSelectedImages?: ImageToSplit[];
}

const shapeToFillColor = {
    [ShapeType.Seed]: Colors.seed,
    [ShapeType.Selected]: Colors.lightBlue,
    [ShapeType.Other]: Colors.secondary
};

const PopupImageSectionContainer: React.FC<PopupImageSectionContainerProps> = (props) => {
    const { t } = useTranslation('popupImage');
    const compName = 'popup-image';
    const { image } = props;
    const shapesWithLabels = image.shapes?.filter((shape) => !!shape.label) || [];
    const [isMetadataExpanded, setIsMetadataExpanded] = useState<boolean>(false);
    const [selectedDisplayOptionsValues, setSelectedDisplayOptionsValues] = useState([
        DisplayOptionValueType.SeedAndConnection,
        ...shapesWithLabels.map((shape) => shape.id)
    ]);
    const isImageData =
        image.metadata ||
        image.extraText ||
        image.faceRecognitionEnabled ||
        image.objectDetectionEnabled ||
        image.ocrEnabled;

    function toggleIsMetadataExpanded() {
        setIsMetadataExpanded(!isMetadataExpanded);

        // letting popup image canvas recalculate
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    useEffect(() => {
        const displayOptionValueTypeValues = Object.values(DisplayOptionValueType);

        const displayOptionValuesWithoutLabels = selectedDisplayOptionsValues.filter((value) =>
            displayOptionValueTypeValues.includes(value as DisplayOptionValueType)
        );

        const newLabelsDisplayOptionValues = shapesWithLabels.map((shape) => shape.id);

        setSelectedDisplayOptionsValues([...displayOptionValuesWithoutLabels, ...newLabelsDisplayOptionValues]);
    }, [image.url]);

    const getDisplayOptions = () => {
        const displayOptions: TreeSelectOption<DisplayOptionValueType | string>[] = [
            { name: t('highlight_seed_and_its_connection'), id: DisplayOptionValueType.SeedAndConnection },
            { name: t('highlight_other_faces'), id: DisplayOptionValueType.OtherConnections }
        ];

        if (shapesWithLabels?.length > 0) {
            displayOptions.push({
                name: t('show_labels', {
                    shapesWithLabelsLength: shapesWithLabels.length
                }),
                id: 'no_value',
                isExpanded: true,
                children: shapesWithLabels.map((shape) => ({ name: shape.label, id: shape.id }))
            });
        }
        return displayOptions;
    };

    const getClickedShape = (clickedPoint: Point) =>
        image.shapes?.find((shape) => {
            const { x, y, w: width, h: negativeHeight } = shape.coordinates;

            return (
                clickedPoint.x > x &&
                clickedPoint.x < x + width &&
                clickedPoint.y > y + negativeHeight &&
                clickedPoint.y < y
            );
        });

    const handleDoubleClick = (clickedPoint: Point) => {
        const shape = getClickedShape(clickedPoint);
        if (shape) {
            props.onDoubleClick?.(shape.id);
        }
    };

    const shouldShowShape = (shape: Shape) => {
        switch (shape.type) {
            case ShapeType.Seed:
            case ShapeType.Selected:
                return selectedDisplayOptionsValues.includes(DisplayOptionValueType.SeedAndConnection);
            case ShapeType.Other:
                return selectedDisplayOptionsValues.includes(DisplayOptionValueType.OtherConnections);
            default:
                return false;
        }
    };

    const shapes: PopupImageShape[] = image.shapes?.map((shape) => {
        const showShape = shouldShowShape(shape);
        const showLabel = selectedDisplayOptionsValues.includes(shape.id);

        return {
            id: shape.id,
            coordinates: shape.coordinates,
            fillColor: shapeToFillColor[shape.type],
            label: shape.label,
            showLabel,
            showShape
        };
    });

    const composeImagesSelected = () => {
        const definition = props.imagesToSplit.length > 1 ? t('images') : t('image');
        return `${props.imagesToSplit.length} ${definition}`;
    };

    const composePersonsSelected = () => {
        const personsSelected = props.imagesToSplit.filter((image) => image.facesCoordinates).length;
        const definition = personsSelected > 1 ? t('faces') : t('face');
        return personsSelected ? `, ${personsSelected} ${definition}` : '';
    };

    return (
        <div className={`${compName}-container`}>
            <div className={`${compName}-header-container`}>
                <PopupImageHeader
                    currentImageIndex={props.currentImageIndex}
                    imagesCount={props.imagesCount}
                    onNext={props.onNext}
                    onPrev={props.onPrev}
                    image={image}
                    actions={props.actions}
                    onFlagClick={props.onFlagClick}
                    displayOptions={getDisplayOptions()}
                    displayValues={selectedDisplayOptionsValues}
                    onFilterShapesChange={(selected) => setSelectedDisplayOptionsValues(selected)}
                    disableSimilarImage={props.disableSimilarImage}
                />
            </div>

            <div className={`${compName}-wrapper`}>
                <PopupImage
                    url={image.url}
                    imageId={image.id}
                    personToSplitUId={props.personToSplitUId}
                    shapes={shapes}
                    onClick={(shapesSelected) => props.onClick?.(shapesSelected)}
                    onDoubleClick={handleDoubleClick}
                    isSplit={!!props.onSplit}
                    multiSelectedImages={props.multiSelectedImages}
                />
                {!!image.detectedLandmarks?.length && (
                    <PopupImageLandmarks detectedLandmarks={image.detectedLandmarks} />
                )}
            </div>

            {!props.onSplit ? (
                isImageData && (
                    <PopupImageFooter
                        image={image}
                        isMetadataExpanded={isMetadataExpanded}
                        toggleIsMetadataExpanded={toggleIsMetadataExpanded}
                    />
                )
            ) : (
                <div className={`${compName}-split`}>
                    <p className={`${compName}-split-amount`}>
                        {composeImagesSelected() + composePersonsSelected() + ' ' + t('selected')}
                    </p>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={props.disableSplitButton}
                        onClick={props.onSplit}
                        className={`${compName}-split-btn`}>
                        {t('split_into_new_individual')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default React.memo(PopupImageSectionContainer);
