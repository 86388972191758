import AjaxClient from '../../services/ajaxClient';

export interface CompareApiResponse {
    photos: { jfileId: string; filename: string }[];
    compareResults: { jfileids: Record<'left' | 'right', string>; score: number }[];
}

export interface CompareApiResults {
    jfileIdToFilename: Map<any, any>;
    compareResults: { jfileids: Record<'left' | 'right', string>; score: number }[];
}

const CompareApiService = {
    async compare(tusdIds: string[], maxPhotos: number): Promise<CompareApiResults> {
        const results = await AjaxClient.post<CompareApiResponse>('/batch/compare', { tusdIds, maxPhotos });
        const jfileIdToFilename: Map<string, string> = new Map();
        results.data.photos.map((photo) => jfileIdToFilename.set(photo.jfileId, photo.filename));

        return { jfileIdToFilename, compareResults: results.data.compareResults };
    }
};

export default CompareApiService;
