import React, { useState } from 'react';
import { svgIcons } from '../entities/enums';
import CustomSVGIcon from '../misc/CustomSvgIcon';
import './dropdown.less';

interface DropdownProps {
    title: string;
    children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);
    return (
        <div onClick={toggleOpen} className='dropdown-container'>
            <div className='title'>{props.title}</div>
            <CustomSVGIcon size={24} type={isOpen ? svgIcons.menuUp : svgIcons.menuDown} />
            {isOpen && <div className='dropdown-content'>{props.children}</div>}{' '}
        </div>
    );
};

export default Dropdown;
