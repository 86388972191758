import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
    IndexRange,
    Masonry,
    MasonryCellProps,
    Size
} from 'react-virtualized';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import VirtualScrollImage from './virtualScrollImage';
import './virtualScrollGallery.less';
import { ImageToSplit } from '../popupGallery/popupGalleryContainer';

export interface VirtualScrollGalleryImage {
    imageId: string;
    imageUrl: string;
    flagged: boolean;
    height?: number;
    width?: number;
}

export interface VirtualScrollGalleryRef {
    scrollToTopAndResetGallery: () => void;
}
export interface VirtualScrollGalleryProps {
    images: VirtualScrollGalleryImage[];
    focusedImageId: string;
    multiSelectedImageIds?: ImageToSplit[];
    onMultiSelect?: (imageId: string) => void;
    onClick?: (imageId: string) => void;
    onFlagClick?: (imageId: string) => void;
    columnWidth?: number;
    gutterSize?: number;
    scrollToImageIndex?: number;
    loadMoreImages?: () => void;
    isLoadingMoreImages?: boolean;
    hasMoreToLoad?: boolean;
}

const defaultHeight = 250;
const defaultColumnWidth = 200;
const defaultColumnCount = 3;
const defaultGutterSize = 10;

const VirtualScrollGallery: React.ForwardRefRenderFunction<VirtualScrollGalleryRef, VirtualScrollGalleryProps> = (
    props,
    ref
) => {
    const [columnCount, setColumnCount] = useState(defaultColumnCount);
    const [imagesLength, setImagesLength] = useState(0);

    const masonryRef = useRef<Masonry>();

    useImperativeHandle(ref, () => ({
        scrollToTopAndResetGallery() {
            (masonryRef.current as any)._scrollingContainer.scrollTop = 0;
        }
    }));

    const columnWidth = props.columnWidth || defaultColumnWidth;
    const gutterSize = props.gutterSize || defaultGutterSize;
    const cache = useMemo(
        () =>
            new CellMeasurerCache({
                defaultHeight,
                defaultWidth: columnWidth,
                fixedWidth: true
            }),
        [columnWidth]
    );

    const cellPositioner = useMemo(
        () =>
            createMasonryCellPositioner({
                cellMeasurerCache: cache,
                columnCount,
                columnWidth,
                spacer: gutterSize
            }),
        [columnWidth, cache]
    );

    useEffect(() => {
        if (props.scrollToImageIndex >= 0) {
            scrollToImageIndex(props.scrollToImageIndex);
        }
    }, [props.scrollToImageIndex]);

    useDidUpdateEffect(() => resetGallery(), [props.images]);

    const resetGallery = () => {
        cache.clearAll();
        resetCellPositioner(columnCount);
        masonryRef.current.clearCellPositions();
    };

    const onResize = ({ width }: Size) => {
        const newColumnCount = calculateColumnCount(width);
        if (newColumnCount !== columnCount) {
            setColumnCount(newColumnCount);
            resetCellPositioner(newColumnCount);
            masonryRef.current?.recomputeCellPositions();
        }
    };

    const calculateColumnCount = (width: number) => Math.floor(width / (columnWidth + gutterSize));

    const resetCellPositioner = (columnCount: number) => {
        cellPositioner.reset({
            columnCount: columnCount,
            columnWidth: columnWidth,
            spacer: gutterSize
        });
    };

    const handleMultiSelect = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        image: VirtualScrollGalleryImage
    ) => {
        e.stopPropagation();
        props.onMultiSelect(image.imageId);
    };

    const handleFlagClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, image: VirtualScrollGalleryImage) => {
        e.stopPropagation();
        props.onFlagClick(image.imageId);
    };

    const getImageHeight = (image: VirtualScrollGalleryImage) => Math.round(image.height || defaultHeight);

    const cellRenderer = ({ index, key, parent, style }: MasonryCellProps) => {
        const image = props.images[index];
        if (!image) {
            return;
        }
        return (
            <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
                <div style={style}>
                    <VirtualScrollImage
                        isFocused={image.imageId === props.focusedImageId}
                        isMultiSelected={
                            !!props.multiSelectedImageIds?.find((photo) => photo.imageId === image.imageId)
                        }
                        isFlagged={image.flagged}
                        onClick={props.onClick ? () => props.onClick(image.imageId) : null}
                        onMultiSelect={props.onMultiSelect ? (e) => handleMultiSelect(e, image) : null}
                        onFlagClick={props.onFlagClick ? (e) => handleFlagClick(e, image) : null}
                        width={columnWidth}
                        height={getImageHeight(image)}
                        imageUrl={image.imageUrl}
                    />
                </div>
            </CellMeasurer>
        );
    };

    const scrollToImageIndex = (imageIndex: number) => {
        setTimeout(() => {
            const imagesTotalHeight = props.images
                .slice(0, imageIndex - (imageIndex % columnCount))
                .reduce((totalHeight, image) => totalHeight + getImageHeight(image) + gutterSize, 0);

            const buffer = 200;

            const scrollPosition = Math.max(imagesTotalHeight / columnCount - buffer, 0);
            if (masonryRef.current) {
                (masonryRef.current as any)._scrollingContainer.scrollTop = scrollPosition;
            }
        }, 1);
    };

    const handleCellRender = ({ stopIndex }: IndexRange) => {
        const pageSize = 40;
        const hasMoreToLoad = props.hasMoreToLoad;
        const isNotLoadingImages = !props.isLoadingMoreImages;
        const stopIndexIsNotTooLow = props.images.length - stopIndex <= pageSize;
        if (hasMoreToLoad && isNotLoadingImages && stopIndexIsNotTooLow && imagesLength !== props.images.length) {
            props.loadMoreImages?.();
            setImagesLength(props.images.length);
        }
    };

    return (
        <div className='virtual-scroll-gallery'>
            <AutoSizer onResize={onResize}>
                {({ width, height }) => (
                    <Masonry
                        id={Math.random().toString()}
                        overscanByPixels={400}
                        ref={masonryRef}
                        onCellsRendered={handleCellRender}
                        autoHeight={false}
                        cellCount={props.images.length}
                        cellMeasurerCache={cache}
                        cellPositioner={cellPositioner}
                        cellRenderer={cellRenderer}
                        height={height}
                        width={width}
                        className='masonry'
                    />
                )}
            </AutoSizer>
        </div>
    );
};

export default React.forwardRef(VirtualScrollGallery);
