import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables, typographySubtitle } from '../styleVariables';
import { Colors } from '../colors';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeEntityQuery,
    changeOcrQuery,
    changeSearchQueryCommon,
    TopologyRouterSelectors
} from '../../store/router/topologyActions';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { changeSearchType, clearSearchResults } from '../../store/slices/searchSlice';
import { useTranslation } from 'react-i18next';
import MagnifyingGlass from '../../images/magnifyingGlass.svg';
import { AdvancedSearchTypes } from './advancedSearch';
import { RootState } from '../../store';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: '100%'
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingRight: StyleVariables.gap * 2,
        paddingLeft: StyleVariables.gap * 2,
        '& .MuiFormGroup-root': {
            height: '100%',
            alignItems: 'center'
        },
        border: `1px solid ${Colors.contrast}`,
        borderRadius: '4px'
    },
    label: {
        fontSize: 0
    },
    input: {
        ...typographySubtitle,
        color: Colors.white,
        height: '100%',
        lineHeight: '100%',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        border: 'none',
        outline: 'none',
        flexGrow: 1,
        '&::-webkit-input-placeholder': {
            color: Colors.contrast,
            paddingLeft: StyleVariables.gap * 4
        }
    },
    clear: {
        marginRight: StyleVariables.gap * 2,
        '&:hover': {
            fill: Colors.clickable
        }
    }
});

const OcrSearchForm: React.FC = () => {
    const { t } = useTranslation(['advancedSearch', 'common']);
    const classes = useStyles();
    const dispatch = useDispatch();
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const ocrQuery = useSelector(TopologyRouterSelectors.getOcrQuery);
    const [inputValue, setInputValue] = useState(ocrQuery);

    useEffect(() => {
        Object.keys(AdvancedSearchTypes).forEach((type) => {
            if (type !== AdvancedSearchTypes.ocr) {
                dispatch(changeSearchQueryCommon(null, AdvancedSearchTypes[type]));
            }
        });
        dispatch(clearSearchResults());
    }, []);

    useEffect(() => {
        if (searchType !== AdvancedSearchTypes.ocr) {
            dispatch(changeSearchType({ searchType: AdvancedSearchTypes.ocr }));
        }
        setInputValue(ocrQuery || '');
    }, [ocrQuery]);

    const clearSearch = () => {
        setInputValue('');
        dispatch(changeOcrQuery(null));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            clearSearch();
        } else {
            setInputValue(event.target.value);
        }
    };

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        dispatch(changeSearchType({ searchType: AdvancedSearchTypes.ocr }));
        dispatch(changeOcrQuery(inputValue));
        dispatch(changeEntityQuery(null));
    };

    return (
        <div className={`ocr-form-container ${classes.root}`}>
            <form id='advancedSearchForm' className={`ocr-form ${classes.form}`} onSubmit={handleSubmit}>
                <label htmlFor='ocrSearchInput' className={`ocr-label ${classes.label}`}>
                    {t('advancedSettings.ocr', { ns: 'common' })}
                </label>
                <input
                    type='text'
                    id='ocrSearchInput'
                    name='ocrSearchInput'
                    value={inputValue || ''}
                    placeholder={t('ocr_form.placeholder')}
                    autoComplete='off'
                    maxLength={50}
                    onChange={handleInputChange}
                    className={`ocr-search-input ${classes.input}`}
                    style={!inputValue ? { backgroundImage: `url(${MagnifyingGlass})` } : null}
                />
                {inputValue && (
                    <CustomSVGIcon
                        onClick={clearSearch}
                        type={svgIcons.close}
                        size={22}
                        fillColor={Colors.white}
                        customClass={`ocr-clear ${classes.clear}`}
                    />
                )}
                <Button type='submit' size='small' variant='contained' color='primary'>
                    {t('ocr_form.submit')}
                </Button>
            </form>
        </div>
    );
};

export default OcrSearchForm;
