import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CommonService from '../../services/commonService';
import { RootState } from '../../store';
import FileUpload from './fileUpload';
import { useTranslation } from 'react-i18next';

export interface Props {
    title: React.ReactNode;
    files: File[];
    disabled: boolean;
    onChange: (files: File[]) => void;
    onRemove: () => void;
    maxSizeInMegaBytes?: number;
    withVideo: boolean;
    multiple?: boolean;
    infoTooltipText?: React.ReactNode;
}

const FileAndFolderUpload: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation('common');
    const maxSizeBuffer = 1500;
    const [zipError, setZipError] = useState('');
    const [invalidFiles, setInvalidFiles] = useState<File[]>([]);
    const { videoTypes, imageTypes, maxFilesPerUpload } = useSelector((state: RootState) => state.configurations.data);

    const isWithVideo = props.withVideo;

    const allowedFileTypes = ['zip', ...imageTypes];
    if (isWithVideo) {
        allowedFileTypes.push(...videoTypes);
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files: File[] = [...e.target.files];
        const filesSize = CommonService.getFilesSize(files);
        if (files?.length > 0) {
            const invalidFiles = files.filter(
                (file) => !allowedFileTypes.includes(file.name.split('.').pop().toLowerCase())
            );
            const validFiles = files.filter((file) =>
                allowedFileTypes.includes(file.name.split('.').pop().toLowerCase())
            );

            if (validFiles.length === 0) {
                const newLine = '\n';
                const bullet = '\u2022';
                const zip = `${newLine}${bullet}ZIP`;
                const images = `${newLine}${bullet}${imageTypes.map((type) => type.toUpperCase()).join('/')}`;
                const videos = isWithVideo
                    ? `${newLine}${bullet}${videoTypes.map((type) => type.toUpperCase()).join('/')}`
                    : '';

                alert(`${t('file_and_folder_upload.upload_one_of_following')}: ${zip}${images}${videos}`);
            } else {
                if (validFiles.length > maxFilesPerUpload) {
                    setZipError(t('file_and_folder_upload.zip_error', { maxFilesPerUpload: maxFilesPerUpload }));
                } else if (filesSize / 1024 / 1024 > props.maxSizeInMegaBytes + maxSizeBuffer) {
                    setZipError(
                        t('file_and_folder_upload.zip_error', { maxSizeInMegaBytes: props.maxSizeInMegaBytes })
                    );
                } else {
                    if (invalidFiles.length > 0) {
                        setInvalidFiles(invalidFiles);
                    }
                    setZipError(null);
                    props.onChange(validFiles);
                }
            }
        }

        e.target.value = null;
    };

    const handleRemoveFile = () => {
        setInvalidFiles([]);
        setZipError(null);
        props.onRemove();
    };

    const getInputFileTypes = () => {
        let inputFileTypes = '.zip,' + imageTypes.map((type) => `.${type}`).join(',');

        if (isWithVideo) {
            inputFileTypes = inputFileTypes.concat(',' + videoTypes.map((type) => `.${type}`).join(','));
        }

        return inputFileTypes;
    };

    return (
        <FileUpload
            multiple={props.multiple}
            errorMessage={zipError}
            warnMessage={
                invalidFiles.length > 0 ? `${invalidFiles.length} ${t('file_and_folder_upload.invalid_files')}` : ''
            }
            warnItems={invalidFiles.map((file) => file.name)}
            files={props.files}
            disabled={props.disabled}
            title={props.title}
            totalFilesCount={props.files?.length + invalidFiles.length}
            fileType={getInputFileTypes()}
            onRemove={handleRemoveFile}
            onChange={handleFileChange}
            infoTooltipText={props.infoTooltipText}
        />
    );
};

export default React.memo(FileAndFolderUpload);
