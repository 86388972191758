import { FormControl, InputLabel, OutlinedInput, InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';
import SearchByPhotoInput from './SearchByPhotoInput';
import { SearchResponse } from '../../topologyview/VisionSynapse';
import useDebounce from '../../hooks/useDebounce';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import SearchService from '../../../services/searchService';
import { toast } from 'react-toastify';
import { TopologyRouterSelectors } from '../../../store/router/topologyActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';

interface SearchByLabelAndImageProps {
    onSearchChange: (searchTerm: string) => void;
    onPersonFound: (file: File, personsIds: SearchResponse) => void;
}

const SearchByLabelAndImage: React.FC<SearchByLabelAndImageProps> = (props) => {
    const { t } = useTranslation(['common', 'errors']);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const debouncedSearchQuery = useDebounce(searchQuery, 250);
    const allQueryIds = useSelector(TopologyRouterSelectors.getAllQueryIds);
    const watchlistIdToWatchlistName = useSelector((state: RootState) => state.watchlists.watchlistIdToWatchlistName);

    useDidUpdateEffect(() => {
        props.onSearchChange(debouncedSearchQuery);
    }, [debouncedSearchQuery]);

    const handleFileChange = async (file: File) => {
        setIsLoading(true);
        setSearchQuery('');

        try {
            const res = await SearchService.searchPersonByPhoto(file, allQueryIds, [
                watchlistIdToWatchlistName[allQueryIds[0]]
            ]);
            props.onPersonFound(file, res.data);
        } catch (error) {
            toast.error(t('general_err_msg', { ns: 'errors' }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FormControl disabled={isLoading} className='search-container' variant='outlined'>
            <InputLabel style={{ fontSize: '13px', userSelect: 'none' }} htmlFor='search-label'>
                {t('search_by_label_image')}
            </InputLabel>
            <OutlinedInput
                inputProps={{ style: { padding: '10px 14px' } }}
                id='search-label'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                endAdornment={
                    <InputAdornment position='end'>
                        <SearchByPhotoInput isLoading={isLoading} onFileChange={handleFileChange} />
                    </InputAdornment>
                }
                type='search'
                labelWidth={135}
                autoComplete='off'
            />
        </FormControl>
    );
};

export default SearchByLabelAndImage;
