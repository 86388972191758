import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Link,
    Typography
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer, List } from 'react-virtualized';
import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import { Permissions } from '../../shared/model/Permissions';
import { RootState } from '../../store';
import {
    FailedFiles,
    NetworkMetadata,
    QueryRunHistory,
    SocialEntityType,
    SocialIdentifiers,
    TopologyEntry,
    UnsupportedFiles,
    VideoMetadata
} from '../../store/slices/topologiesSlice';
import { WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { Network } from '../topologyview/VisionSynapse';
import { Consts } from './consts';
import CopyToClipboard from './copyToClipboard';
import { useTranslation } from 'react-i18next';
import './batchDetailsModal.less';

interface BatchDetailsModalProps extends DialogProps {
    open: boolean;
    batch: TopologyEntry | WatchlistEntry;
    isWatchlist?: boolean;
}

const BatchDetailsModal: React.FC<BatchDetailsModalProps> = ({ open, batch, isWatchlist, onClose, ...props }) => {
    const { t } = useTranslation(['common', 'networks', 'error', 'topologiesView']);
    const { fileUploadMaxMediaFilesPerZip } = useSelector((state: RootState) => state.configurations.data);
    const { networksUrls } = useSelector((state: RootState) => state.configurations.data);

    const renderSocialIds = () => {
        const networkToSocialIdentifiers: Partial<Record<Network, SocialIdentifiers[]>> = {};
        const videosIndentifiers: Partial<Record<Network, VideoMetadata[]>> = {};
        batch.metadata.queryRunHistory
            .filter((runHistory) => runHistory.networksMetadata)
            .forEach((runHistory) => {
                Object.entries(runHistory.networksMetadata).forEach(([network, metadata]) => {
                    if (!networkToSocialIdentifiers[network]) {
                        networkToSocialIdentifiers[network] = [];
                    }
                    networkToSocialIdentifiers[network] = [
                        ...networkToSocialIdentifiers[network],
                        ...metadata.socialIdentifiersList
                    ];
                });
                if (runHistory.videos?.length) {
                    Object.values(runHistory.videos).forEach((video) => {
                        const videoNetwork = Object.values(Network).find((network) =>
                            video.name.includes(network.toLowerCase())
                        );
                        if (videoNetwork) {
                            if (!videosIndentifiers[videoNetwork]) {
                                videosIndentifiers[videoNetwork] = [];
                            }
                            videosIndentifiers[videoNetwork].push(video);
                        }
                    });
                }
            });
        if (Object.keys(networkToSocialIdentifiers).length !== 0) {
            return (
                <div style={{ marginBottom: '10px' }}>
                    <b>{t('batch_details_modal.social_ids')}: </b>
                    <ul className='details-id-list'>
                        {Object.keys(networkToSocialIdentifiers).map((key) => {
                            const socialIds = networkToSocialIdentifiers[key].filter((identity) => identity.id != null);
                            let propertyToShow = 'id';
                            if (key === Network.Twitter || key === Network.Instagram || key === Network.Telegram) {
                                propertyToShow = 'username';
                            }
                            const nullTypeIds = socialIds.filter((identity) => identity.type === null);
                            const listToCopy = buildListToCopy(
                                socialIds,
                                videosIndentifiers[key] ? videosIndentifiers[key] : null,
                                propertyToShow
                            );
                            return (
                                <li
                                    className={`details-id-list-${key.toLowerCase()}`}
                                    key={`details-id-list-${key.toLowerCase()}`}>
                                    <b>
                                        {t(key, { ns: 'networks' })}
                                        {listToCopy.length > 0 ? (
                                            <>
                                                ({listToCopy.length}):{' '}
                                                <Typography display='inline' className='text-button' color='primary'>
                                                    <CopyToClipboard
                                                        title={t('batch_details_modal.copy_to_clipboard')}
                                                        textToCopy={listToCopy.join('\n')}
                                                    />
                                                </Typography>
                                            </>
                                        ) : (
                                            <>({socialIds.length}) </>
                                        )}
                                    </b>
                                    <ul className='details-id-list-types'>
                                        {Object.keys(SocialEntityType).map((type) => {
                                            if (type === SocialEntityType.Video && videosIndentifiers[key]) {
                                                return (
                                                    <li
                                                        className={`social-id-type-${type}`}
                                                        key={`social-id-type-${type}`}>
                                                        <b>
                                                            {t('batch_details_modal.socialEntityType.' + type) + 's:'}
                                                        </b>
                                                        {renderIds(videosIndentifiers[key], 'name', type)}
                                                    </li>
                                                );
                                            } else {
                                                const typeIdentities = socialIds.filter(
                                                    (identity) => identity.type === SocialEntityType[type]
                                                );
                                                if (typeIdentities.length > 0) {
                                                    return (
                                                        <li
                                                            className={`social-id-type-${type}`}
                                                            key={`social-id-type-${type}`}>
                                                            <b>
                                                                {t('batch_details_modal.socialEntityType.' + type) +
                                                                    's:'}
                                                            </b>
                                                            {renderIds(typeIdentities, propertyToShow, type)}
                                                        </li>
                                                    );
                                                } else return null;
                                            }
                                        })}

                                        {nullTypeIds.length > 0 && (
                                            <li key='nullTypeIds' style={{ listStyle: 'none' }}>
                                                {renderIds(nullTypeIds, propertyToShow, 'nullType')}
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
    };

    const buildListToCopy = (socialIds, videosIndentifiers, propertyToShow) => {
        const idList = socialIds
            .filter((identity) => identity.id != null && identity.type !== SocialEntityType.Video)
            .map((identity) => identity[propertyToShow]);
        const videoList = videosIndentifiers ? videosIndentifiers.map((video) => video.name) : [];
        return [...idList, ...videoList];
    };

    const renderIds = (typeIdentities, propertyToShow, type) => {
        const rowRenderer = ({ key, index, style }) => {
            return (
                <ul style={style} key={key} className={`details-ids-${type}`}>
                    <li className='social-id' key={`social-id-${typeIdentities[index].id}`}>
                        {typeIdentities[index][propertyToShow] + ' '}
                    </li>
                </ul>
            );
        };

        const rowHeight = 19;
        const maxRows = 5;
        const rowCount = typeIdentities.length;
        return (
            <AutoSizer disableHeight>
                {({ width }) => (
                    <List
                        style={{ outline: 'none' }}
                        width={width}
                        height={rowCount < maxRows ? rowCount * rowHeight : rowHeight * maxRows}
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        rowRenderer={rowRenderer}
                    />
                )}
            </AutoSizer>
        );
    };

    const renderUnsupportedFiles = (unsupportedFiles: UnsupportedFiles[]) => {
        unsupportedFiles = [...unsupportedFiles].sort((fileA, fileB) => fileA.reason?.localeCompare(fileB.reason));

        const rowRenderer = ({ key, index, style }) => {
            const unsupportedFile = unsupportedFiles[index];

            return (
                <ul style={style} key={key}>
                    <li key={key + index}>
                        {unsupportedFile.name}: {t(unsupportedFile.reason, { ns: 'errors' })}
                    </li>
                </ul>
            );
        };
        const rowHeight = 20;
        const maxRows = 5;
        const rowCount = unsupportedFiles.length;
        return (
            <AutoSizer disableHeight style={{ marginTop: '2px' }}>
                {({ width }) => (
                    <List
                        style={{ outline: 'none' }}
                        width={width}
                        height={rowCount < maxRows ? rowCount * rowHeight : rowHeight * maxRows}
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        rowRenderer={rowRenderer}
                    />
                )}
            </AutoSizer>
        );
    };
    const renderFailedFiles = (failedFiles: FailedFiles[]) => (
        <ul>
            {failedFiles.slice().map((file) => (
                <li key={file.name}>{file.name}</li>
            ))}
        </ul>
    );

    const findFailedVideos = (videos: VideoMetadata[], queryRunHistory: QueryRunHistory) => {
        const getFailed = (socialIdentifiersList) =>
            _.partition(socialIdentifiersList, (socialIdentifier) => {
                if (socialIdentifier.type === SocialEntityType.Video) {
                    return videos.find((video) => video.name.includes(socialIdentifier.id));
                } else return true;
            })[1];
        return Object.entries(queryRunHistory.networksMetadata)
            .map(([network, metadata]) => {
                return getFailed(metadata.socialIdentifiersList).map((identifier) =>
                    networksUrls[network.toLowerCase()]
                        .replace('ID', identifier.id)
                        .replace('USERNAME', identifier.username)
                );
            })
            .flat();
    };

    const renderVideosMetadata = (videos: VideoMetadata[], queryRunHistory: QueryRunHistory) => {
        const [processed, filtered] = _.partition(videos, (video) => video.valid);
        let failed;
        if (queryRunHistory.networksMetadata) {
            failed = findFailedVideos(videos, queryRunHistory);
        }
        return (
            <ul className='details-video'>
                <li className='details-video-slicing-rate' key='details-video-slicing-rate'>
                    {t('batch_details_modal.video_meta_data.slicing_rate')}:{' '}
                    {queryRunHistory.automaticSlicing
                        ? t('batch_details_modal.video_meta_data.automatic')
                        : queryRunHistory.videoFramesPerMinute +
                          t('batch_details_modal.video_meta_data.frames_per_minute')}
                </li>
                {queryRunHistory.framesWithIndividualsOnly !== null && (
                    <li className='details-video-no-individuals' key='details-video-no-individuals'>
                        {t('batch_details_modal.video_meta_data.filter_out_frames_without_individuals')}:{' '}
                        {queryRunHistory.framesWithIndividualsOnly
                            ? t('batch_details_modal.video_meta_data.enabled')
                            : t('batch_details_modal.video_meta_data.disabled')}
                    </li>
                )}
                {processed.length > 0 && (
                    <li className='details-video-processed' key='details-video-processed'>
                        {t('batch_details_modal.video_meta_data.processed')}: {renderVideo(processed)}
                    </li>
                )}
                {failed?.length > 0 && (
                    <li className='details-video-failed' key='details-video-failed'>
                        {t('batch_details_modal.video_meta_data.failed')}: {renderVideo(failed, true)}
                    </li>
                )}
                {filtered.length > 0 && (
                    <li className='details-video-filtered' key='details-video-filtered'>
                        {t('batch_details_modal.video_meta_data.filtered')}: {renderVideo(filtered)}
                    </li>
                )}
            </ul>
        );
    };

    const renderVideo = (videoList, onlyLink?: boolean) => {
        const rowRenderer = ({ key, index, style }) => {
            const videoLink = onlyLink ? videoList[index] : videoList[index].name;
            return (
                <ul style={style} key={key} className='details-video-list-item'>
                    <li className='video-metadata' key={videoLink}>
                        <div style={{ wordBreak: 'break-all' }}>
                            {t('batch_details_modal.video_meta_data.name') + ': '}
                            <Link rel='noreferrer' target='_blank' href={videoLink}>
                                {videoLink}
                            </Link>
                        </div>
                        {!onlyLink && (
                            <>
                                <div>
                                    {t('batch_details_modal.video_meta_data.duration') +
                                        ': ' +
                                        CommonService.formatDuration(
                                            moment.duration(parseInt(videoList[index].duration), 'seconds')
                                        )}
                                </div>
                                <div>
                                    {t('batch_details_modal.video_meta_data.resolution') +
                                        ': ' +
                                        videoList[index].resolution}
                                </div>
                            </>
                        )}
                    </li>
                </ul>
            );
        };

        const rowHeight = onlyLink ? 22 : 65;
        const maxRows = onlyLink ? 6 : 3;
        const rowCount = videoList.length;
        return (
            <AutoSizer disableHeight>
                {({ width }) => (
                    <List
                        style={{ outline: 'none' }}
                        width={width}
                        height={rowCount < maxRows ? rowCount * rowHeight : rowHeight * maxRows}
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        rowRenderer={rowRenderer}
                    />
                )}
            </AutoSizer>
        );
    };

    const isExecutionSupportRecentAggregation = (network: Network) => !isWatchlist && network !== Network.YouTube;

    const renderNetworksMetadata = (queryRunHistory: QueryRunHistory) => {
        const networkOrder = Object.values(Network);

        const { networksMetadata, recentPhotosOnly } = queryRunHistory;

        return (
            <ul>
                {(Object.entries(networksMetadata) as [Network, NetworkMetadata][])
                    .sort(
                        ([networkA], [networkB]) =>
                            networkOrder.indexOf(networkA as Network) - networkOrder.indexOf(networkB as Network)
                    )
                    .map(([network, networkMetadata], index) => {
                        const typeToSocialIdentifiersList = _.groupBy(
                            networkMetadata.socialIdentifiersList,
                            (socialIdentifiers) => socialIdentifiers.type
                        );

                        return (
                            <div key={index}>
                                {networkMetadata.socialIdentifiersList.length > 0 && (
                                    <li
                                        className={`details-network-${network.toLowerCase()}`}
                                        key={`details-network-${network.toLowerCase()}`}>
                                        <b className='details-network-name'>{t(network, { ns: 'networks' })}:</b>
                                        <ul>
                                            {networkMetadata.socialIdentifiersList?.length > 0 && (
                                                <li key={`details-network-${network.toLowerCase()}-item`}>
                                                    {network === Network.Mugshots ? (
                                                        <>
                                                            {t('batch_details_modal.network_meta_data.state') +
                                                                ': ' +
                                                                networkMetadata.socialIdentifiersList[0].id}
                                                            <br></br>
                                                            {networkMetadata.filters?.usernames?.length
                                                                ? t(
                                                                      'batch_details_modal.network_meta_data.suspect_name'
                                                                  ) +
                                                                  ': ' +
                                                                  networkMetadata.filters.usernames.map((name) => name)
                                                                : null}
                                                        </>
                                                    ) : (
                                                        Object.entries(typeToSocialIdentifiersList).map(
                                                            ([type, socialIdentifiersList], index) => (
                                                                <div key={type + index}>
                                                                    {t('batch_details_modal.socialEntityType.' + type) +
                                                                        ' ' +
                                                                        t('batch_details_modal.network_meta_data.ids') +
                                                                        ' '}
                                                                    ({socialIdentifiersList.length}):
                                                                    {renderSocialIdsList(
                                                                        network,
                                                                        socialIdentifiersList,
                                                                        t(
                                                                            'batch_details_modal.socialEntityType.' +
                                                                                type
                                                                        )
                                                                    )}
                                                                </div>
                                                            )
                                                        )
                                                    )}
                                                </li>
                                            )}

                                            {!isWatchlist &&
                                            network !== Network.TikTok &&
                                            network !== Network.YouTube ? (
                                                <>
                                                    {authService.hasPermissions(
                                                        Permissions.minimum_number_of_people
                                                    ) && (
                                                        <li className='no-individuals' key='no-individuals'>
                                                            {`${t(
                                                                'batch_details_modal.network_meta_data.filter_images_without_individuals'
                                                            )}: ${
                                                                networkMetadata.photosWithIndividualsOnly
                                                                    ? t('batch_details_modal.network_meta_data.enabled')
                                                                    : t(
                                                                          'batch_details_modal.network_meta_data.disabled'
                                                                      )
                                                            }`}
                                                        </li>
                                                    )}
                                                    {network !== Network.Telegram && (
                                                        <li className='expanded-search' key='expanded-search'>
                                                            {`${t(
                                                                'batch_details_modal.network_meta_data.expanded_search'
                                                            )}:
                                                            ${
                                                                networkMetadata.withFriendsAggregation
                                                                    ? t('batch_details_modal.network_meta_data.enabled')
                                                                    : t(
                                                                          'batch_details_modal.network_meta_data.disabled'
                                                                      )
                                                            }`}
                                                        </li>
                                                    )}
                                                    {network === Network.Telegram && (
                                                        <li className='aggregate-videos' key='aggregate-videos'>
                                                            {`${t(
                                                                'batch_details_modal.network_meta_data.aggregate_videos'
                                                            )}:
                                                            ${
                                                                networkMetadata.aggregateVideos
                                                                    ? t('batch_details_modal.network_meta_data.enabled')
                                                                    : t(
                                                                          'batch_details_modal.network_meta_data.disabled'
                                                                      )
                                                            }`}
                                                        </li>
                                                    )}
                                                    {network === Network.Telegram && (
                                                        <li className='aggregate-images' key='aggregate-images'>
                                                            {`${t(
                                                                'batch_details_modal.network_meta_data.aggregate_images'
                                                            )}:
                                                            ${
                                                                networkMetadata.aggregateImages
                                                                    ? t('batch_details_modal.network_meta_data.enabled')
                                                                    : t(
                                                                          'batch_details_modal.network_meta_data.disabled'
                                                                      )
                                                            }`}
                                                        </li>
                                                    )}
                                                </>
                                            ) : null}
                                            {isExecutionSupportRecentAggregation(network) && (
                                                <li className='recent-images' key='recent-images'>
                                                    {`${t(
                                                        'batch_details_modal.network_meta_data.aggregate_recent_images_only'
                                                    )}:
                                                ${
                                                    recentPhotosOnly
                                                        ? t('batch_details_modal.network_meta_data.enabled')
                                                        : t('batch_details_modal.network_meta_data.disabled')
                                                }`}
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )}
                            </div>
                        );
                    })}
            </ul>
        );
    };

    const getSocialProfileLink = (network: Network, id: string, username: string) => {
        switch (network) {
            case Network.Facebook:
                return `https://facebook.com/${id}`;
            case Network.Vk:
                return `https://vk.com/${username}`;
            case Network.Instagram:
                return `https://instagram.com/${username}`;
            case Network.Telegram:
                return `https://t.me/${username}`;
        }
    };

    const renderSocialIdsList = (
        network: Network,
        socialIdentifiersList: SocialIdentifiers[] | string[],
        type: string
    ) => {
        const rowRenderer = ({ key, index, style }) => {
            const socialIdentifiers = socialIdentifiersList[index];
            return (
                <ul style={style} key={key} className='details-id-network-line'>
                    <li key={key + index}>
                        {typeof socialIdentifiers === 'string' ? (
                            socialIdentifiers
                        ) : network === Network.Telegram || type !== SocialEntityType.Video ? (
                            <Link
                                rel='noreferrer'
                                target='_blank'
                                href={getSocialProfileLink(network, socialIdentifiers.id, socialIdentifiers.username)}>
                                {socialIdentifiers.id + ' '}
                                {socialIdentifiers.username && `(${socialIdentifiers.username})`}
                            </Link>
                        ) : (
                            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {socialIdentifiers.id + ' '}
                                {network === Network.TikTok
                                    ? `(${socialIdentifiers.username})`
                                    : `(${socialIdentifiers.displayName})`}
                            </span>
                        )}
                    </li>
                </ul>
            );
        };

        const rowHeight = 20;
        const maxRows = 5;
        const rowCount = socialIdentifiersList.length;
        return (
            <AutoSizer disableHeight style={{ marginTop: '2px' }}>
                {({ width }) => (
                    <List
                        style={{ outline: 'none' }}
                        width={width}
                        height={rowCount < maxRows ? rowCount * rowHeight : rowHeight * maxRows}
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        rowRenderer={rowRenderer}
                    />
                )}
            </AutoSizer>
        );
    };

    const errorLineRendering = (error) => {
        const errorText = error
            ? t(error, {
                  ns: 'errors',
                  maxFilesInZip: fileUploadMaxMediaFilesPerZip,
                  defaultValue: null
              })
            : null;
        return <li key={`error-${error}`}>{errorText ? errorText : t('general_err_msg', { ns: 'errors' })}</li>;
    };

    const renderUpdatesData = () => (
        <div>
            <b>{t('batch_details_modal.updates_data.run_history')}:</b>

            {batch.metadata.queryRunHistory
                .slice()
                .sort(
                    (queryRunHistoryA, queryRunHistoryB) =>
                        queryRunHistoryB.startUpdateDate - queryRunHistoryA.startUpdateDate
                )
                .map((queryRunHistory, runHistoryIndex) => {
                    let noPhotosLoaded = true;
                    for (const key in queryRunHistory.networksMetadata) {
                        if (queryRunHistory.networksMetadata[key].socialIdentifiersList.length > 0) {
                            noPhotosLoaded = false;
                        }
                    }

                    const phtotosAggregationProgress = queryRunHistory.currentlyAggregatedPhotosCount
                        ? `${queryRunHistory.currentlyAggregatedPhotosCount} ${t('progress.photos', {
                              ns: 'topologiesView'
                          })}`
                        : '';
                    const videosAggregationProgress = queryRunHistory.currentlyAggregatedVideosCount
                        ? `${queryRunHistory.currentlyAggregatedVideosCount} ${t('progress.videos', {
                              ns: 'topologiesView'
                          })}`
                        : '';
                    const profilesAggregationProgress = queryRunHistory.currentlyAggregatedProfilesCount
                        ? `${queryRunHistory.currentlyAggregatedProfilesCount} ${t('progress.profiles', {
                              ns: 'topologiesView'
                          })}`
                        : '';

                    return (
                        <ul key={runHistoryIndex}>
                            {queryRunHistory.startUpdateDate ? (
                                <li key='date'>
                                    <b>
                                        {t('batch_details_modal.updates_data.date', {
                                            date: new Date(queryRunHistory.startUpdateDate)
                                        })}
                                    </b>
                                </li>
                            ) : null}
                            {queryRunHistory.fileName && (
                                <li key='file-name'>
                                    <b>{t('batch_details_modal.updates_data.file_name')}: </b>
                                    {queryRunHistory.fileName}
                                </li>
                            )}
                            {!!queryRunHistory.fileSizeKB && (
                                <li key='size'>
                                    <b>{t('batch_details_modal.updates_data.size')}: </b>
                                    {CommonService.getFileSize(queryRunHistory.fileSizeKB) + 'mb'}
                                </li>
                            )}
                            <li key='created-updated-by'>
                                <b>
                                    {batch.metadata.queryRunHistory.length === runHistoryIndex + 1
                                        ? t('batch_details_modal.updates_data.created_by')
                                        : t('batch_details_modal.updates_data.updated_by')}
                                </b>
                                {queryRunHistory.updateBy}
                            </li>
                            <li key='status'>
                                <b>{t('batch_details_modal.updates_data.status')}: </b>
                                {t('topologyStatus.' + queryRunHistory.status)}
                            </li>
                            {queryRunHistory.errors?.length > 0 && (
                                <li key='error'>
                                    <b>{t('batch_details_modal.updates_data.errors')}: </b>
                                    <ul>{queryRunHistory.errors.map((error) => errorLineRendering(error))}</ul>
                                </li>
                            )}
                            {queryRunHistory.startUpdateDate && queryRunHistory.finishedUpdateDate ? (
                                <li key='duration'>
                                    <b>{t('batch_details_modal.updates_data.processing_duration')}: </b>
                                    {CommonService.getDuration(
                                        queryRunHistory.startUpdateDate,
                                        queryRunHistory.finishedUpdateDate
                                    )}
                                </li>
                            ) : null}
                            {queryRunHistory.imageAnalysisCsvJfileIds?.length > 0 && (
                                <>
                                    <li key='image-analysis-csv'>
                                        <b>{t('batch_details_modal.updates_data.image_analysis_csv')}: </b>
                                        <a
                                            href={`/api/photo/${queryRunHistory.imageAnalysisCsvJfileIds[0]}`}
                                            download={`image_analysis_${moment(queryRunHistory.startUpdateDate).format(
                                                Consts.UNDERSCORE_DATE_TIME_FORMAT
                                            )}.csv`}>
                                            {t('batch_details_modal.updates_data.download')}
                                        </a>
                                    </li>
                                    {queryRunHistory.imageAnalysisCsvJfileIds?.length > 1 && (
                                        <li key='image-analysis-csv'>
                                            <b>{t('batch_details_modal.updates_data.image_analysis_friends_csv')}: </b>
                                            <a
                                                href={`/api/photo/${queryRunHistory.imageAnalysisCsvJfileIds[1]}`}
                                                download={`image_analysis_${moment(
                                                    queryRunHistory.startUpdateDate
                                                ).format(Consts.UNDERSCORE_DATE_TIME_FORMAT)}.csv`}>
                                                {t('batch_details_modal.updates_data.download')}
                                            </a>
                                        </li>
                                    )}
                                </>
                            )}
                            {/* {queryRunHistory.imageAnalysisCsvJfileId && (
                                <li key='image-analysis-csv'>
                                    <b>{t('batch_details_modal.updates_data.image_analysis_csv')}: </b>
                                    <a
                                        href={`/api/photo/${queryRunHistory.imageAnalysisCsvJfileId}`}
                                        download={`image_analysis_${moment(queryRunHistory.startUpdateDate).format(
                                            Consts.UNDERSCORE_DATE_TIME_FORMAT
                                        )}.csv`}>
                                        {t('batch_details_modal.updates_data.download')}
                                    </a>
                                </li>
                            )} */}
                            {phtotosAggregationProgress || videosAggregationProgress || profilesAggregationProgress ? (
                                <li key='media-aggregated'>
                                    <b>{t('batch_details_modal.updates_data.media_aggregated_with_Falcon')}: </b>
                                    {phtotosAggregationProgress && (
                                        <span className='media-aggregated-item'>{phtotosAggregationProgress}</span>
                                    )}
                                    {videosAggregationProgress && (
                                        <span className='media-aggregated-item'>{videosAggregationProgress}</span>
                                    )}
                                    {profilesAggregationProgress && (
                                        <span className='media-aggregated-item'>{profilesAggregationProgress}</span>
                                    )}
                                </li>
                            ) : null}
                            {!!queryRunHistory.uploadedPhotosCount && (
                                <li key='images-uploaded'>
                                    <b>{t('batch_details_modal.updates_data.images_uploaded')}: </b>
                                    {queryRunHistory.uploadedPhotosCount}
                                </li>
                            )}

                            {queryRunHistory.unsupportedFiles?.length > 0 && (
                                <li key='filtered-inputs'>
                                    <b>
                                        {t('batch_details_modal.updates_data.filtered_inputs')} (
                                        {queryRunHistory.unsupportedFiles.length}):
                                    </b>{' '}
                                    {renderUnsupportedFiles(queryRunHistory.unsupportedFiles)}
                                </li>
                            )}
                            {queryRunHistory.failedFiles?.length > 0 && (
                                <li key='failed-inputs'>
                                    <b>{t('batch_details_modal.updates_data.failed_inputs')}:</b>{' '}
                                    {renderFailedFiles(queryRunHistory.failedFiles)}
                                </li>
                            )}
                            {queryRunHistory.videos?.length > 0 && (
                                <li key='videos'>
                                    <b>
                                        {t('batch_details_modal.updates_data.videos')} ({queryRunHistory.videos.length}
                                        ):
                                    </b>{' '}
                                    {renderVideosMetadata(queryRunHistory.videos, queryRunHistory)}
                                </li>
                            )}
                            {!!queryRunHistory.photosFromVideosCount && (
                                <li key='images-created'>
                                    <b>{t('batch_details_modal.updates_data.images_created_from_uploaded_videos')}: </b>
                                    {queryRunHistory.photosFromVideosCount}
                                </li>
                            )}
                            {!isWatchlist && (
                                <>
                                    {queryRunHistory.includeFR !== null && (
                                        <li key='entity-matching'>
                                            <b>{t('advancedSettings.entityMatching')}: </b>
                                            {queryRunHistory.includeFR
                                                ? t('batch_details_modal.updates_data.enabled')
                                                : t('batch_details_modal.updates_data.disabled')}
                                            {queryRunHistory.includeFR && queryRunHistory.classifyMode
                                                ? ` (${t('batch_details_modal.updates_data.mode')}: ${t(
                                                      'classifyMode.' + queryRunHistory.classifyMode
                                                  )})`
                                                : ''}
                                        </li>
                                    )}
                                    {queryRunHistory.includeImage2Text !== null &&
                                        authService.hasPermissions(Permissions.show_skynet_activation_button) && (
                                            <li key='descriptive-search'>
                                                <b>{t('advancedSettings.imageDescription')}: </b>
                                                {queryRunHistory.includeImage2Text
                                                    ? t('batch_details_modal.updates_data.enabled')
                                                    : t('batch_details_modal.updates_data.disabled')}
                                            </li>
                                        )}
                                    {queryRunHistory.includeOR !== null && (
                                        <li key='or'>
                                            <b>{t('advancedSettings.or')}: </b>
                                            {queryRunHistory.includeOR
                                                ? t('batch_details_modal.updates_data.enabled')
                                                : t('batch_details_modal.updates_data.disabled')}
                                        </li>
                                    )}
                                    {queryRunHistory.includeOCR !== null && (
                                        <li key='ocr'>
                                            <b>{t('advancedSettings.ocr')}: </b>
                                            {queryRunHistory.includeOCR
                                                ? t('batch_details_modal.updates_data.enabled')
                                                : t('batch_details_modal.updates_data.disabled')}
                                        </li>
                                    )}
                                    {queryRunHistory.includeExplicitContent !== null && (
                                        <li key='explicit-content'>
                                            <b>{t('advancedSettings.explicitContent')}: </b>
                                            {queryRunHistory.includeExplicitContent
                                                ? t('batch_details_modal.updates_data.enabled')
                                                : t('batch_details_modal.updates_data.disabled')}
                                        </li>
                                    )}
                                    {queryRunHistory.includeLandmarks !== null && (
                                        <li key='explicit-content'>
                                            <b>{t('advancedSettings.landmarks')}: </b>
                                            {queryRunHistory.includeLandmarks
                                                ? t('batch_details_modal.updates_data.enabled')
                                                : t('batch_details_modal.updates_data.disabled')}
                                        </li>
                                    )}
                                    {queryRunHistory.includeSimilarImages !== null && (
                                        <li key='explicit-content'>
                                            <b>{t('advancedSettings.similarImage')}: </b>
                                            {queryRunHistory.includeSimilarImages
                                                ? t('batch_details_modal.updates_data.enabled')
                                                : t('batch_details_modal.updates_data.disabled')}
                                        </li>
                                    )}
                                </>
                            )}

                            {authService.hasPermissions(Permissions.case_show_falcon) &&
                                queryRunHistory.networksMetadata &&
                                !noPhotosLoaded && (
                                    <li className='details-network' key='details-network'>
                                        <b>{t('batch_details_modal.network_meta_data.networks')}:</b>{' '}
                                        {renderNetworksMetadata(queryRunHistory)}
                                    </li>
                                )}
                        </ul>
                    );
                })}
        </div>
    );

    return (
        <Dialog disablePortal maxWidth='md' open={open} onClose={onClose} fullWidth {...props}>
            <DialogTitle>{t('batch_details_modal.details')}</DialogTitle>
            <DialogContent>
                {batch.metadata?.queryRunHistory && renderUpdatesData()}
                {renderSocialIds()}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => onClose(null, null)} color='primary' autoFocus>
                    {t('batch_details_modal.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BatchDetailsModal;
