import React from 'react';
import { FcData } from './usersManagmentContainer';
import { useTranslation } from 'react-i18next';
import { AllGroupsUsage } from '../../shared/serviceModels/allCountersGroupsUsage';
import { PaymentCounter, PaymentCounterType } from '../../shared/serviceModels/paymentCounter';

interface SystemManagementProps {
    fcData: FcData;
    allGroupsCountersUsage: AllGroupsUsage;
    userGroupMap: { [key: number]: string };
}

const SystemManagement: React.FC<SystemManagementProps> = (props) => {
    const { t } = useTranslation('usersManagement');
    return (
        <div>
            <h4>{t('system_management.title')}</h4>
            <h5>{t('system_management.sub_title')}</h5>
            <ul>
                <li>Deployment ID: {props.fcData.deploymentId}</li>
                <li>
                    {t('system_management.version')}: {props.fcData.version}
                </li>
                <li>Model: {props.fcData.modelId}</li>
                <li>
                    {t('system_management.poi_usage')}: {props.fcData.registeredPoi}/{props.fcData.poiLimit}
                </li>
                <li>
                    FACES Usage: {props.fcData.registeredFaces}/{props.fcData.facesLimit}
                </li>
                <li>
                    {t('system_management.license_expiration_date')}:
                    {new Date(props.fcData.licenseExpiration * 1000).toUTCString()}
                </li>
            </ul>
            <h5>{t('system_management.system_plan_usage')}</h5>
            {props.allGroupsCountersUsage?.usages?.map((group) => {
                const groupId = group.groupId;
                const groupIdKeyNumber = parseInt(groupId);
                const groupName = props.userGroupMap[groupIdKeyNumber]
                    ? props.userGroupMap[groupIdKeyNumber]
                    : groupIdKeyNumber;
                const groupPayments = group.groupCounters;

                let visionYearlyPayment: PaymentCounter;
                let falconYearlyPayment: PaymentCounter;
                groupPayments.forEach((paymentCounter) => {
                    const isYear = paymentCounter.timeUnit === 'YEAR';
                    const counterName = paymentCounter.counterName;

                    if (isYear && counterName === PaymentCounterType.visionLong) {
                        visionYearlyPayment = paymentCounter;
                    } else if (isYear && counterName === PaymentCounterType.falconLong) {
                        falconYearlyPayment = paymentCounter;
                    }
                });

                const visionYearlyUsage = visionYearlyPayment?.usage;
                const visionYearlyLimit = visionYearlyPayment?.limit;
                const falconYearlyUsage = falconYearlyPayment?.usage;
                const falconYearlyLimit = falconYearlyPayment?.limit;

                const startCountUnixTime = visionYearlyPayment
                    ? visionYearlyPayment.startCountUnixTime
                    : falconYearlyPayment?.startCountUnixTime;
                const licenseStartedDate = new Date(startCountUnixTime).toUTCString();

                return (
                    <ul key={groupId}>
                        <li>
                            <h5>Group Name : {groupName}</h5>
                            {visionYearlyPayment && (
                                <div>
                                    Vision yearly : {visionYearlyUsage} / {visionYearlyLimit}
                                </div>
                            )}
                            {falconYearlyPayment && (
                                <div>
                                    Falcon yearly : {falconYearlyUsage} / {falconYearlyLimit}
                                </div>
                            )}
                            {(visionYearlyPayment || falconYearlyPayment) && (
                                <div>License started at : {licenseStartedDate}</div>
                            )}
                        </li>
                    </ul>
                );
            })}
        </div>
    );
};

export default SystemManagement;
