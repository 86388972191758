import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApiService, { ResourceType } from '../../services/apiService';
import { RootState } from '../../store';
import { changeSeed, TopologyRouterSelectors } from '../../store/router/topologyActions';
import { fetchTopology } from '../../store/slices/topologySlice';
import { svgIcons } from '../common/entities/enums';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import useOptimisticFlagImage from '../hooks/useOptimisticFlagImage';
import { Image } from '../popupImage/popupImageSectionContainer';
import PopupGalleryContainer, {
    ImageToSplit,
    PopupGalleryContainerMenuAction,
    PopupGalleryContainerProps
} from './popupGalleryContainer';
import { useTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import NewNameDialog, { newEntityForm } from '../dialogs/NewNameDialog';
import { setShowLoader } from '../../store/slices/searchSlice';

export interface DialogInfo {
    title: string;
    description: string;
    submitButtonName?: string;
}

interface TopologyPopupGalleryWrapperProps extends Omit<PopupGalleryContainerProps, 'actions'> {
    onConfirmSplitSuccess?: () => Promise<void>;
    handleDeleteImage?: (photoId: string) => void;
    disableSimilarImage?: boolean;
}

const TopologyPopupGalleryWrapper: React.FC<TopologyPopupGalleryWrapperProps> = (props) => {
    const { t } = useTranslation('popupGallery');
    const currentQueryId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const currentSeeds = useSelector((state: RootState) => TopologyRouterSelectors.getSeedIds(state));
    const photosByPersonId = useSelector((state: RootState) => state.topology.topologyMetadata.photosByPersonId);
    const topologyPersons = useSelector((state: RootState) => state.topology.data.topologyPersons);
    const queryId = useSelector((state: RootState) => state.topology.data.batchId);
    const queryName = useSelector((state: RootState) => state.topologies.currentTopology.name);
    const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showNewNameModal, setShowNewNameModal] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState<Image>();
    const [imagesToSplit, setImagesToSplit] = useState<ImageToSplit[]>([]);
    const [newName, setNewName] = useState<string>('');
    const [dialogInfo, setDialogInfo] = useState<DialogInfo>({
        title: t('delete_image'),
        description: t('confirm_delete_image')
    });
    const dispatch = useDispatch();
    const toggleImageFlag = useOptimisticFlagImage();

    const deleteImage = async () => {
        setDialogInfo({ title: t('delete_image'), description: t('confirm_delete_image') });
        try {
            setIsDeletingPhoto(true);

            const photoData = {
                photoId: selectedPhoto.id,
                jfileId: selectedPhoto.url.replace('/api/photo/', ''),
                imageName: selectedPhoto.name
            };
            await ApiService.deletePhoto(currentQueryId, photoData, ResourceType.Topology);
            handlePotentialSeedChanges();

            if (props.handleDeleteImage) {
                props.handleDeleteImage(photoData.photoId);
            } else {
                dispatch(fetchTopology());
            }

            if (props.images.length <= 1) {
                props.onClose();
            }
        } catch (error) {
            toast.error(t('failed_to_delete_photo'));
        } finally {
            setIsDeletingPhoto(false);
        }
    };

    const handlePotentialSeedChanges = () => {
        const seedsWithImages = currentSeeds.filter((seed) => {
            const seedPhotos = photosByPersonId[seed];
            return seedPhotos.length > 1 || seedPhotos[0] !== selectedPhoto.id;
        });
        if (seedsWithImages.length !== currentSeeds.length) {
            if (seedsWithImages.length === 0) {
                dispatch(changeSeed(topologyPersons.find((person) => person.personUID !== currentSeeds[0]).personUID));
            } else {
                dispatch(changeSeed(...seedsWithImages));
            }
        }
    };

    const actions: PopupGalleryContainerMenuAction[] = !props.personToSplit
        ? [
              {
                  label: t('delete_image'),
                  onClick: (image) => {
                      setShowConfirmationModal(true);
                      setSelectedPhoto(image);
                  },
                  icon: svgIcons.trashBin
              }
          ]
        : [];

    const handleSplitEntity = (imagesIds: ImageToSplit[]) => {
        setImagesToSplit(imagesIds);
        setShowNewNameModal(true);
    };

    const onGetNewName = (newNameObj?: { [newEntityForm.NewPersonName]: string }) => {
        const newName = newNameObj ? newNameObj[newEntityForm.NewPersonName] : null;
        setShowNewNameModal(false);
        if (newName) {
            setNewName(newName);
            setDialogInfo({
                title: t('split_confirm', { newName }),
                description: t('attention_message'),
                submitButtonName: t('split_confirm_button')
            });
            setShowConfirmationModal(true);
        }
    };

    const onConfirmSplitEntity = async () => {
        try {
            dispatch(setShowLoader(true));
            await ApiService.splitPerson(
                queryId,
                queryName,
                props.personToSplit.personUID,
                props.personToSplit.labels?.map(({ label }) => label),
                imagesToSplit,
                newName
            );
            toast.success(
                t('split_toast_success', {
                    personToSplit: props.personToSplit.labels.length ? props.personToSplit.labels[0].label : null,
                    newName
                })
            );
            await props.onConfirmSplitSuccess();
            dispatch(setShowLoader(false));
        } catch (error) {
            toast.error(CommonService.getErrorMessage(error));
        }
    };

    const handleConfirmation = (isConfirmed: boolean) => {
        if (isConfirmed) {
            props.personToSplit ? onConfirmSplitEntity() : deleteImage();
        }
        setShowConfirmationModal(false);
    };

    return (
        <>
            <PopupGalleryContainer
                {...props}
                actions={actions}
                onFlagClick={toggleImageFlag}
                onMultipleImagesSelected={handleSplitEntity}
            />
            <ConfirmationDialog
                open={showConfirmationModal}
                onClose={handleConfirmation}
                isLoading={isDeletingPhoto}
                title={dialogInfo.title}
                description={dialogInfo.description}
                submitButtonName={dialogInfo.submitButtonName}
            />
            <NewNameDialog open={showNewNameModal} onClose={onGetNewName} />
        </>
    );
};

export default TopologyPopupGalleryWrapper;
