import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Image2TextForm from './image2TextForm';
import { Colors } from '../colors';
import { StyleVariables } from '../styleVariables';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { svgIcons } from '../common/entities/enums';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { toggleFiltersPanel } from '../../store/slices/topologySlice';
import {
    activeServerFiltersCount,
    getInitialServerSideFiltersValues,
    updateServerFilters
} from '../../store/slices/filtersSlice';
import OcrSearchForm from './ocrSearchForm';
import { AntTab, AntTabs } from '../common/antTabs';
import { useTranslation } from 'react-i18next';
import SimilarImageForm from './similarImageForm';
import { changeSearchType } from '../../store/slices/searchSlice';
import { RootState } from '../../store';

export enum AdvancedSearchTypes {
    entity = 'entity',
    imageDescription = 'imageDescription',
    similarImage = 'similarImage',
    ocr = 'ocr'
}

interface AdvancedSearchProps {
    imageDescriptionActivated: boolean;
    similarImageActivated: boolean;
    ocrActivated: boolean;
}

const useStyles = makeStyles({
    root: {},
    panel: {
        display: 'flex',
        alignItems: 'center',
        height: StyleVariables.advancedSearchSize,
        marginRight: StyleVariables.gapLayoutHorizontal,
        marginLeft: StyleVariables.gapLayoutHorizontal
    },
    filter: {
        minWidth: StyleVariables.advancedSearchSize,
        height: StyleVariables.advancedSearchSize,
        flex: `0 0 ${StyleVariables.advancedSearchSize}`,
        marginRight: StyleVariables.gap * 2,
        border: `1px solid ${Colors.contrast}`
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 100%',
        height: '100%',
        backgroundColor: Colors.black,
        position: 'relative'
    }
});

const AdvancedSearch: React.FC<AdvancedSearchProps> = (props) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const activeServerFiltersIndicator = useSelector(activeServerFiltersCount);
    const defineActiveTab = () => {
        const types = Object.values(AdvancedSearchTypes);
        for (let i = 1; i < types.length; i++) {
            if (props[types[i] + 'Activated']) {
                return AdvancedSearchTypes[types[i]];
            }
        }
    };

    const handleTypeChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
        dispatch(updateServerFilters(getInitialServerSideFiltersValues()));
        dispatch(changeSearchType({ searchType: value as AdvancedSearchTypes }));
    };

    useEffect(() => {
        const searchTypeValue = defineActiveTab() || '';
        dispatch(changeSearchType({ searchType: searchTypeValue as AdvancedSearchTypes }));
    }, []);

    return (
        <div className={`advanced-search ${classes.root}`}>
            <AntTabs
                style={{ marginLeft: 106 }}
                value={searchType}
                onChange={handleTypeChange}
                selectedBorderColor={Colors.accent}>
                {Object.keys(AdvancedSearchTypes).map((tab) => {
                    return tab !== AdvancedSearchTypes.entity ? (
                        <AntTab
                            key={`advanced-search-${tab}`}
                            value={AdvancedSearchTypes[tab]}
                            label={t('advancedSettings.' + AdvancedSearchTypes[tab])}
                            disabled={!props[tab + 'Activated']}
                        />
                    ) : null;
                })}
            </AntTabs>
            <div className={`advanced-search-panel ${classes.panel}`}>
                <Button
                    data-test-id='open-filter-panel'
                    onClick={() => dispatch(toggleFiltersPanel(true))}
                    size='small'
                    variant='outlined'
                    className={`advanced-search-filter ${classes.filter}`}>
                    <CustomSVGIcon type={svgIcons.filter} size={24} />
                    <Typography color='primary' style={{ fontSize: 14 }}>
                        {activeServerFiltersIndicator ? `(${activeServerFiltersIndicator})` : null}
                    </Typography>
                </Button>
                <div className={`advanced-search-container ${classes.container}`}>
                    {searchType === AdvancedSearchTypes.imageDescription ? (
                        <Image2TextForm />
                    ) : searchType === AdvancedSearchTypes.similarImage ? (
                        <SimilarImageForm closePopupWhenRadioButtonPressed={true} />
                    ) : (
                        <OcrSearchForm />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdvancedSearch;
