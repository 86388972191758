import React, { SyntheticEvent } from 'react';
import { svgIcons } from '../common/entities/enums';
import { Colors } from '../colors';
import { SimilarImageSearchTypes } from './similarImageForm';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
    flexCenter,
    StyleVariables as St,
    typographySmall,
    typographySubtitle,
    typographyTiny
} from '../styleVariables';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Popover, IconButton } from '@material-ui/core';
import { similarImageTypesImages } from './similarImageTypesImages';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import Button from '@material-ui/core/Button';
import { getBase64FromImage } from '../../shared/helpers/searchHelpers';
import { AdvancedSearchTypes } from './advancedSearch';
import { changeSearchType } from '../../store/slices/searchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { popupImageId } from '../popupImage/popupImage';
import { extractBase64EncodedString } from '../../utils/utils';

interface SimilarImageTypeProps {
    onSelectType: (e) => void;
    value: SimilarImageSearchTypes;
    formWidth: number;
    withSimilarImageIcon: boolean;
    onSubmit: (imgData) => void;
    closePopupWhenRadioButtonPressed?: boolean;
}

const useStyles = makeStyles({
    btn: {
        marginRight: St.gap * 2
    },
    expanded: {
        transform: (props: SimilarImageTypeProps) => !props.withSimilarImageIcon && 'rotate(180deg)'
    },
    popoverPaper: {
        width: (props: SimilarImageTypeProps) => (props.withSimilarImageIcon ? 400 : props.formWidth),
        padding: St.gap * 2,
        backgroundColor: Colors.darkGray,
        border: `1px solid ${Colors.contrast}`,
        borderRadius: 4,
        opacity: '0.95 !important',
        top: (props: SimilarImageTypeProps) =>
            props.withSimilarImageIcon
                ? St.topbarHeight + St.gap * 2 + St.gap * 6 + St.gap / 2 + 'px !important'
                : St.topbarHeight + St.tabsHeight + St.advancedSearchSize + 2 + 'px !important',
        left: 'auto !important',
        right: St.gapLayoutHorizontal
    },
    label: {
        ...typographySubtitle
    },
    radioGroup: {},
    control: {
        flexBasis: '50%',
        marginTop: St.gap * 3
    },
    title: {
        ...flexCenter
    },
    radioLabel: {
        ...typographySubtitle,
        justifyContent: 'center'
    },
    images: {
        ...flexCenter
    },
    imageContainer: {
        ...typographyTiny,
        textAlign: 'center',
        width: St.gap * 7,
        '& ~ &': {
            marginLeft: St.gap * 3
        }
    },
    text: {
        display: 'inline-block',
        marginBottom: St.gap
    },
    tooltip: {
        ...typographySmall,
        width: 400,
        paddingTop: St.gap * 1.5,
        paddingRight: St.gap,
        paddingBottom: St.gap * 1.5,
        paddingLeft: St.gap
    },
    tooltipTitle: {
        ...typographySmall,
        fontWeight: 700,
        margin: 0
    },
    tooltipList: {
        paddingLeft: 20
    },
    submit: {
        marginTop: St.gap * 4
    }
});

const SimilarImageType = (props: SimilarImageTypeProps) => {
    const { t } = useTranslation('advancedSearch');
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (e: SyntheticEvent) => {
        setAnchorEl(null);
    };

    const handleRadioButtonPressed = (e: SyntheticEvent) => {
        props.onSelectType(e);
        if (props.closePopupWhenRadioButtonPressed) setAnchorEl(null);
    };

    const getImage = async (img) => {
        const url = img.src;
        const data = await fetch(url);
        return await data.blob();
    };

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const file = await getImage(document.getElementById(popupImageId));
        if (searchType !== AdvancedSearchTypes.similarImage) {
            dispatch(changeSearchType({ searchType: AdvancedSearchTypes.similarImage }));
        }
        getBase64FromImage(file, (res: string) => props.onSubmit(extractBase64EncodedString(res)));
        setAnchorEl(null);
    };

    const getTooltip = (type: string) => (
        <div className={`similar-image-tooltip ${classes.tooltip}`}>
            <h6 className={`similar-image-tooltip-title ${classes.tooltipTitle}`}>
                {t('similar_image_form.types.' + type)}
            </h6>
            <ol className={`similar-image-tooltip-list ${classes.tooltipList}`}>
                {[...Array(3).keys()].map((line, i) => {
                    const text = t('similar_image_form.tooltip.' + type + '.' + line);
                    return !text.includes('similar_image_form') ? <li key={type + i}>{text}</li> : null;
                })}
            </ol>
        </div>
    );

    const iconButtonLabel = props.withSimilarImageIcon
        ? t('similar_image_form.search_similar_image')
        : t('similar_image_form.select_similarity_type');

    return (
        <div className='similar-image-type'>
            <IconButton data-test-id='similar-image-type' aria-label={iconButtonLabel} onClick={handleClick}>
                <CustomSVGIcon
                    tooltip={iconButtonLabel}
                    type={props.withSimilarImageIcon ? svgIcons.photoPlus : svgIcons.arrowFilled}
                    fillColor={props.withSimilarImageIcon ? Colors.clickable : Colors.accent}
                    size={24}
                />
            </IconButton>
            <Popover
                id='similar-image-type-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleChange}
                classes={{
                    paper: classes.popoverPaper
                }}>
                <FormLabel
                    id='similar-image-type-radio-group-label'
                    className={`similar-image-type-label ${classes.label}`}>
                    {t('similar_image_form.types_label')}
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby='similar-image-type-radio-group-label'
                    name='similar-image-type-radio-group'
                    value={props.value}
                    onChange={handleRadioButtonPressed}
                    classes={{
                        root: classes.radioGroup
                    }}>
                    {Object.keys(SimilarImageSearchTypes).map((type) => (
                        <div
                            className={`similar-image-type-control ${classes.control}`}
                            key={`similar-image-type-control-${type}`}>
                            <div className={`similar-image-type-title ${classes.title}`}>
                                <FormControlLabel
                                    value={type}
                                    control={<Radio color='default' />}
                                    label={t('similar_image_form.types.' + type)}
                                    className={`similar-image-type-radioLabel ${classes.radioLabel}`}
                                />
                                <CustomSVGIcon
                                    type={svgIcons.help}
                                    size={18}
                                    fillColor={Colors.contrast}
                                    tooltip={getTooltip(type)}
                                />
                            </div>
                            <div className={`similar-image-type-images ${classes.images}`}>
                                <div className={`similar-image-type-original ${classes.imageContainer}`}>
                                    <span className={`similar-image-type-text ${classes.text}`}>
                                        {t('similar_image_form.original')}
                                    </span>
                                    {similarImageTypesImages[type].original}
                                </div>
                                <div className={`similar-image-type-result ${classes.imageContainer}`}>
                                    <span className={`similar-image-type-text ${classes.text}`}>
                                        {t('similar_image_form.result')}
                                    </span>
                                    {similarImageTypesImages[type].result}
                                </div>
                            </div>
                        </div>
                    ))}
                </RadioGroup>
                {props.withSimilarImageIcon && (
                    <Button
                        type='submit'
                        size='small'
                        variant='contained'
                        color='primary'
                        fullWidth
                        className={`similar-image-type-submit ${classes.submit}`}
                        onClick={handleSubmit}>
                        {t('similar_image_form.submit')}
                    </Button>
                )}
            </Popover>
        </div>
    );
};

export default SimilarImageType;
