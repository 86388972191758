import { AutoGroupData } from '../components/group/autoGroup';
import {
    BatchDetails,
    ExportToWatchlistRequest,
    Label,
    PersonDescriptor,
    PhotoInteraction,
    PhotoObject,
    SynapsePhoto,
    SynapseResponse,
    Synapses,
    Topology,
    TopologyPhotosWrapper,
    PhotoUpdateRequest,
    VisionWatchlist
} from '../components/topologyview/VisionSynapse';
import { ServerFilters } from '../store/slices/filtersSlice';
import { TopologyEntry } from '../store/slices/topologiesSlice';
import { FetchTopologyData } from '../store/slices/topologySlice';
import { WatchlistEntry } from '../store/slices/watchlistsSlice';
import AjaxClient from './ajaxClient';
import { AxiosResponse } from 'axios';
import { FcData, User, UserRole } from '../components/usersManagement/usersManagmentContainer';
import { Permissions } from '../shared/model/Permissions';
import { ImageToSplit } from '../components/popupGallery/popupGalleryContainer';
import { AllGroupsUsage } from '../shared/serviceModels/allCountersGroupsUsage';

export enum ResourceType {
    Watchlist = 'WATCHLIST',
    Topology = 'TOPOLOGY'
}
export interface OldVaResponse<T> {
    data: T;
    message: string;
    status: boolean;
}
export interface UserGroup {
    id: number;
    name: string;
}

export interface UserData {
    id?: number;
    username: string;
    password?: string;
    groups: number[];
    roles: number;
    enabled: boolean;
}

export type ServerSideFilters = Omit<ServerFilters, 'uploadDate' | 'creationDate'> & {
    uploadDate: number;
    creationDate: number;
};

const getUtlParams = (urlParams: Partial<Omit<FetchTopologyData, 'queryId'>>) => {
    const urlSearchParams = new URLSearchParams();
    urlParams.seedIds?.filter((id) => !!id).forEach((sId) => urlSearchParams.append('dynamicSeedIds', sId));
    urlParams.watchlists
        ?.filter((watchlist) => !!watchlist)
        .forEach((wls) => urlSearchParams.append('watchlists', wls));
    urlParams.crossTopologies
        ?.filter((topology) => !!topology)
        .forEach((topology) => urlSearchParams.append('crossTopologies', topology));
    if (urlParams.filters?.gender) {
        urlSearchParams.append('gender', urlParams.filters.gender);
    }
    urlParams.filters?.ages?.forEach((age) => urlSearchParams.append('ages', age));
    urlParams.filters?.networks?.forEach((network) => urlSearchParams.append('networks', network));
    urlParams.filters?.objects?.forEach((object) => urlSearchParams.append('detectedObjects', object));
    if (urlParams.filters?.uploadDate) {
        urlSearchParams.append('date', urlParams.filters.uploadDate.toString());
    }
    if (urlParams.filters?.creationDate) {
        urlSearchParams.append('creationDate', urlParams.filters.creationDate.toString());
    }
    if (urlParams.filters?.flagged) {
        urlSearchParams.append('flagged', 'true');
    }

    if (urlParams.ocrSearchQuery) {
        urlSearchParams.append('ocrSearchQuery', urlParams.ocrSearchQuery);
    }
    return urlSearchParams;
};

const ApiService = {
    status() {
        return AjaxClient.get('/status');
    },
    deleteWatchlist(batchId: string) {
        return AjaxClient.delete(`/batch/${batchId}/type/${ResourceType.Watchlist}`);
    },
    deleteTopology(batchId: string) {
        return AjaxClient.delete(`/batch/${batchId}/type/${ResourceType.Topology}`);
    },
    deleteProcessingWatchlist(batchId: string, deleteQuery: boolean) {
        return AjaxClient.delete(
            `/batch/processing/${batchId}/type/${ResourceType.Watchlist}?deleteQuery=${deleteQuery}`
        );
    },
    deleteProcessingTopology(batchId: string, deleteQuery: boolean) {
        return AjaxClient.delete(
            `/batch/processing/${batchId}/type/${ResourceType.Topology}?deleteQuery=${deleteQuery}`
        );
    },
    fetchAllTopologies(query?: string) {
        return AjaxClient.get<TopologyEntry[]>(
            `/batch/type/${ResourceType.Topology}${
                query?.trim() ? '?searchQuery=' + encodeURIComponent(query.trim()) : ''
            }`
        );
    },
    fetchAllWatchlists(query?: string) {
        return AjaxClient.get<WatchlistEntry[]>(
            `/batch/type/${ResourceType.Watchlist}${
                query?.trim() ? '?searchQuery=' + encodeURIComponent(query.trim()) : ''
            }`
        );
    },
    fetchTopologyPhotos(batchId: string) {
        return AjaxClient.get<SynapseResponse<TopologyPhotosWrapper>>(`/synapse/photos/${batchId}`);
    },
    topology(data: FetchTopologyData, isSynapseOnly?: boolean) {
        const urlSearchParams = getUtlParams(data);

        return AjaxClient.get<SynapseResponse<Topology | Synapses>>(
            `/synapse${isSynapseOnly ? '' : '/topology'}/${data.queryId}`,
            { params: urlSearchParams }
        );
    },
    fetchAutoGroups(batchId: string, crossTopologies: string[] = [], filters: ServerSideFilters) {
        const urlSearchParams = getUtlParams({ crossTopologies, filters });
        return AjaxClient.get<SynapseResponse<AutoGroupData>>(`/synapse/auto-groups/${batchId}`, {
            params: urlSearchParams
        });
    },
    updateLabel(batchId: string, personId: string, label: Label) {
        return AjaxClient.put<PersonDescriptor>(`/topology/${batchId}/person/${personId}/label`, label);
    },
    fetchWatchlist(batchId: string) {
        return AjaxClient.get<SynapseResponse<VisionWatchlist>>(`/watchlist/${batchId}`);
    },
    fetchTopologyMetadata(batchId: string) {
        return AjaxClient.get<TopologyEntry>(`/batch/${batchId}/type/${ResourceType.Topology}`);
    },
    fetchWatchlistMetadata(batchId: string) {
        return AjaxClient.get<WatchlistEntry>(`/batch/${batchId}/type/${ResourceType.Watchlist}`);
    },
    mergePersons(
        batchId: string,
        queryId: string,
        queryName: string,
        poiMergeInto: string,
        poiLabels: string[],
        poisToDelete: string[]
    ) {
        return AjaxClient.post(`/topology/person/merge`, {
            batchId,
            queryId,
            queryName,
            poiMergeInto,
            poiLabels,
            poisToDelete
        });
    },
    splitPerson(
        queryId: string,
        queryName: string,
        poiToSplit: string,
        poiLabelsToSplit: string[],
        imagesToSplit: ImageToSplit[],
        newPoiName: string
    ) {
        return AjaxClient.post(`/topology/person/split`, {
            queryId,
            queryName,
            poiToSplit,
            poiLabelsToSplit,
            imagesToSplit,
            newPoiName
        });
    },
    deletePerson(batchId: string, queryId: string, queryName: string, persons: PersonDescriptor[], isHard?: boolean) {
        const apiRequestData = persons.map((person) => {
            const poiLabels = person.labels?.map((label) => label.label);
            return { batchId, queryId, queryName, isHard, personId: person.personUID, poiLabels: poiLabels };
        });

        return AjaxClient.delete(`/topology/person`, {
            data: apiRequestData
        });
    },
    deletePhoto(
        batchId: string,
        photoData: { photoId: string; imageName: string; jfileId: string },
        type: ResourceType
    ) {
        return AjaxClient.delete(`/topology/photo/type/${type}`, {
            data: { batchId, ...photoData }
        });
    },
    addInteraction(batchId: string, photoInteraction: PhotoInteraction) {
        return AjaxClient.post(`/topology/${batchId}/interaction`, photoInteraction);
    },
    addObject(batchId: string, photoObject: PhotoObject) {
        return AjaxClient.post(`/topology/${batchId}/object`, photoObject);
    },
    deleteObject(batchId: string, objectAppearanceId: string) {
        return AjaxClient.delete(`/topology/${batchId}/object/${objectAppearanceId}`);
    },
    deleteInteraction(batchId: string, interactionAppearanceId: string) {
        return AjaxClient.delete(`/topology/${batchId}/interaction/${interactionAppearanceId}`);
    },
    addPersonToWatchlist(addToWatchlistPersonsRequest: ExportToWatchlistRequest) {
        return AjaxClient.post(`/watchlist/export`, addToWatchlistPersonsRequest);
    },
    editTopologyName(id: string, name: string) {
        return AjaxClient.patch(`/batch/name/${id}/type/${ResourceType.Topology}`, { name });
    },
    editWatchlistName(id: string, name: string) {
        return AjaxClient.patch(`/batch/name/${id}/type/${ResourceType.Watchlist}`, { name });
    },
    getUserGroups(): Promise<AxiosResponse<OldVaResponse<UserGroup[]>>> {
        return AjaxClient.get('/user/groups');
    },
    createGroup(data: any) {
        return AjaxClient.post('/user/groups', data);
    },
    deleteGroup(groupId: number) {
        return AjaxClient.delete(`/user/groups/${groupId}`);
    },
    createRole(name: string, permissions: Permissions[]) {
        return AjaxClient.post('/user/roles', { name, permissions });
    },
    editRole(id: string, name: string, permissions: Permissions[]) {
        return AjaxClient.put('/user/roles/' + id, { name, permissions });
    },
    deleteRole(id: string) {
        return AjaxClient.delete('/user/roles/' + id);
    },
    editUser(userData: UserData) {
        return AjaxClient.put(`/user/users/${userData.id}`, userData);
    },
    deleteUser(userName: string) {
        return AjaxClient.delete(`/user/user/${userName}`);
    },
    addUser(userData: UserData) {
        return AjaxClient.post(`/user/users`, userData);
    },
    getAllGroups(): Promise<AxiosResponse<OldVaResponse<UserGroup[]>>> {
        return AjaxClient.get('/user/groups/allGroups');
    },
    getAllRoles(): Promise<AxiosResponse<OldVaResponse<UserRole[]>>> {
        return AjaxClient.get('/user/roles');
    },
    getAllUsers(): Promise<AxiosResponse<OldVaResponse<User[]>>> {
        return AjaxClient.get('/user/users');
    },
    getSystemData(): Promise<AxiosResponse<FcData>> {
        return AjaxClient.get('/system');
    },
    getAllCountersGroupsUsageData(): Promise<AxiosResponse<AllGroupsUsage>> {
        return AjaxClient.get('/usage/allCountersGroupsUsage');
    },
    getUsersForAudit(): Promise<AxiosResponse<{ id: string; userName: string }[]>> {
        return AjaxClient.get('/user/users-light-data');
    },
    changeCurrentUserGroup(groupId: string) {
        return AjaxClient.put(`/user/changeCurrentGroup/${groupId}`);
    },
    changeUserGroup(groupId: number, userName: string) {
        return AjaxClient.put(`/user/changeUserCurrentGroup/${groupId}?userName=${userName}`);
    },
    getBatchDetails(batchId: string) {
        return AjaxClient.get<BatchDetails[]>(`/batch/${batchId}/details`);
    },
    fetchConfigurations() {
        return AjaxClient.get(`/configurations`);
    },
    fetchOktaConfig() {
        return AjaxClient.get(`/sso`);
    },
    editPerson(batchId: string, person: PersonDescriptor) {
        return AjaxClient.put<SynapseResponse<PersonDescriptor>>(`/topology/${batchId}/person/`, person);
    },
    getPhotosCount(queryIds: string[]) {
        return AjaxClient.post<SynapseResponse<Record<string, number>>>('/batch/photos/count', queryIds);
    },
    getPersonsCount(queryIds: string[]) {
        return AjaxClient.post<SynapseResponse<Record<string, number>>>('/batch/persons/count', queryIds);
    },
    getUserPhotos(userId: string, queryId?: string) {
        return AjaxClient.post<SynapseResponse<SynapsePhoto[]>>('/topology/persons/photos', {
            queryId: queryId ? queryId : '',
            personIds: [userId]
        });
    },
    fetchTopolgyDataByImageIds(queryIds: string[], imageIds: string[]) {
        return AjaxClient.post<SynapseResponse<any>>('/synapse/data-by-image-ids', {
            queryIds,
            imageIds
        });
    },
    updatePhoto(photoId: string, photo: SynapsePhoto) {
        const updatePhotoRequest: PhotoUpdateRequest = {
            caseId: photo.batchIds[0],
            fileName: photo.fileName,
            flagged: photo.flagged,
            originalSourceLink: photo.originalSourceLink,
            objectDetectionEnabled: photo.objectDetectionEnabled,
            image2TextEnabled: photo.image2TextEnabled,
            faceRecognitionEnabled: photo.faceRecognitionEnabled,
            ocrEnabled: photo.ocrEnabled,
            landmarksEnabled: photo.landmarksEnabled,
            similarImagesEnabled: photo.similarImagesEnabled
        };
        return AjaxClient.put<null>(`/photo/${photoId}`, updatePhotoRequest);
    },
    sortWaitingExecutions(draggedUp: boolean, queryIdDragged: string, queryIdDroppedBefore: string) {
        return AjaxClient.post(`/batch/sort-waiting-queue`, { draggedUp, queryIdDragged, queryIdDroppedBefore });
    },
    getRecentSearchList(groupId: string, queryId: string): Promise<AxiosResponse<string[]>> {
        return AjaxClient.get(`/photo/search/${groupId}/${queryId}/recent-searches`);
    }
};

export default ApiService;
