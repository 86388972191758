import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ApiService from '../../../services/apiService';
import { DroneData, ExecutionType, MediaFileDetails } from '../../../services/batchService';
import CommonService from '../../../services/commonService';
import PaymentService from '../../../services/paymentService';
import TusdService from '../../../services/tusdService';
import { RootState } from '../../../store';
import TusdPrompt from '../../common/tusdPrompt';
import CreateTopologyService, { BatchDescriptor } from '../../createTopologyPage/createTopologyService';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import UploadWatchlistForm from './uploadWatchlistForm';
import { useTranslation } from 'react-i18next';

interface UploadWatchlistContainerProps extends RouteComponentProps<{ batchId: string }> {}
let abortFilesUpload: () => void;

const UploadWatchlistContainer: React.FC<UploadWatchlistContainerProps> = (props) => {
    const { t } = useTranslation(['watchlists', 'errors']);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [isUploading, setIsUploading] = useStateWithCallback(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const { maxWatchlistSizeInImages } = useSelector((state: RootState) => state.configurations.data);
    const isAddPhotos = props.location.pathname.startsWith('/add-images');

    useEffect(() => {
        return () => {
            if (isUploading) {
                cancelUpload();
            }
        };
    }, []);

    const handleUploadProgress = (progressPercentage: number) => setUploadProgress(progressPercentage);

    const uploadFileToTusd = async (formValues: BatchDescriptor): Promise<MediaFileDetails[]> => {
        if (formValues.files) {
            const uploadResults = CreateTopologyService.uploadTopologyMediaZip(formValues.files, handleUploadProgress);
            try {
                abortFilesUpload = uploadResults.abortUpload;
                return uploadResults.uploadPromise;
            } catch (error) {
                console.error(t('upload_watchlist_container.failed_to_upload_topology_file_to_tusd') + ': ' + error);
                throw error;
            }
        }
    };

    const handleUpload = async (formValues: BatchDescriptor, droneData: DroneData) => {
        if (isUploading) {
            return;
        }
        setIsUploading(true);
        setError('');
        try {
            const isRateLimitExceededResponsePromise = PaymentService.isRateLimitExceeded();
            const topologyWatchlistResponsePromise = formValues.batchData.batchId
                ? ApiService.fetchWatchlist(formValues.batchData.batchId)
                : null;

            const [isRateLimitExceededResponse, topologyWatchlistResponse] = await Promise.all([
                isRateLimitExceededResponsePromise,
                topologyWatchlistResponsePromise
            ]);

            if (isRateLimitExceededResponse) {
                setIsUploading(false);
                setError(t('ERR_LIMIT_EXCEEDED', { ns: 'errors' }));
                setUploadProgress(null);
            } else if (topologyWatchlistResponse?.data.result.persons.length >= maxWatchlistSizeInImages) {
                setIsUploading(false);
                setError(
                    t('upload_watchlist_container.error_max_images', {
                        maxWatchlistSizeInImages: maxWatchlistSizeInImages.toLocaleString()
                    })
                );
                setUploadProgress(null);
            } else {
                if (formValues.files) {
                    await TusdService.throwErrorIfNotEnoughSpace(CommonService.getFilesSize(formValues.files));
                }
                const filesDetails: MediaFileDetails[] = await uploadFileToTusd(formValues);
                if (isAddPhotos) {
                    await CreateTopologyService.addImages(formValues, droneData, filesDetails);
                } else {
                    await CreateTopologyService.create(formValues, droneData, filesDetails);
                }
                setIsUploading(false, () => dispatch(push('/watchlists')));
            }
        } catch (error) {
            console.error(error);
            setIsUploading(false);
            setError(CommonService.getErrorMessage(error));
            setUploadProgress(null);
        }
    };

    const handleCancelRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        cancelUpload();
        setIsUploading(false);
        setUploadProgress(null);
    };

    const cancelUpload = () => abortFilesUpload?.();

    return (
        <>
            <TusdPrompt isUploading={isUploading} />
            <UploadWatchlistForm
                isAddPhotos={isAddPhotos}
                error={error}
                isUploading={isUploading}
                uploadProgress={uploadProgress}
                onUpload={handleUpload}
                onCancel={handleCancelRequest}
                initialValues={new BatchDescriptor(ExecutionType.WATCHLIST, props.match.params.batchId)}
            />
        </>
    );
};

export default UploadWatchlistContainer;
