import { createMuiTheme, Grid, ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import CommonService from '../../services/commonService';
import history from '../../store/history';
import '../../style/main.less';
import { Colors } from '../colors';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Loader from 'react-spinners/CircleLoader';
import authService from '../../services/authService';
import Topbar from '../topbar/topbar';
import routes from './routes';
import SideBar from '../sidebar/SideBar';
import '../../translations/i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSsoConfig } from '../../store/slices/configurationsSlice';
import { RootState } from '../../store';

const App: React.FC = () => {
    const theme = createMuiTheme({
        palette: {
            type: 'dark',
            primary: { main: Colors.clickable },
            secondary: { main: Colors.secondary },
            error: { main: Colors.error },
            text: {
                primary: Colors.textPrimary,
                secondary: Colors.textSecondary,
                disabled: 'rgba(255, 255, 255, 0.5)'
            },
            action: {
                active: Colors.white,
                hover: 'rgba(255, 255, 255, 0.08)',
                selected: 'rgba(255, 255, 255, 0.16)',
                disabled: 'rgba(255, 255, 255, 0.3)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)'
            },

            background: {
                default: '#20faa0',
                paper: Colors.darkGray
            },
            divider: 'rgba(255, 255, 255, 0.12)'
        }
    });
    const { t } = useTranslation('errors');
    const dispatch = useDispatch();
    const [isShowingTopbar, setIsShowingTopbar] = useState(CommonService.showTopbar());
    const [isAuthorized, setIsAuthorized] = useState(authService.isAuthorized());
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState<string>(null);
    const { sso_enabled } = useSelector((state: RootState) => state.configurations.data.ssoConfigClient);
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const userName = queryParams?.username as string;
    const password = queryParams?.password as string;

    useEffect(() => {
        dispatch(fetchSsoConfig());
        const removeListener = history.listen(() => setIsShowingTopbar(CommonService.showTopbar()));
        return () => {
            removeListener();
        };
    }, []);

    if (userName && password && !loginError && !isLoading) {
        setIsLoading(true);
        authService
            .login(
                { userName, password },
                {
                    'axios-retry': {
                        retries: 2,
                        retryCondition: () => true
                    }
                }
            )
            .then((res) => {
                authService.storeTokens(res.data);
                setIsAuthorized(true);
                history.push('/cases');
            })
            .catch((err) => setLoginError(t('general_err_msg')))
            .finally(() => setIsLoading(false));
    }

    return userName && password && (!isAuthorized || isLoading) ? (
        <div className='flex-center main-background'>
            {loginError ? loginError : <Loader size={100} color={Colors.lightBlue} />}
        </div>
    ) : (
        <ThemeProvider theme={theme}>
            <span className='app-container dark-theme'>
                {sso_enabled === null ? (
                    <div className='flex-center main-background'>
                        <Loader size={100} color={Colors.lightBlue} />
                    </div>
                ) : (
                    <>
                        {isShowingTopbar && <Topbar />}
                        <main className={`main ${isShowingTopbar ? ' with-topbar' : ''}`}>
                            {isShowingTopbar ? (
                                <Grid container direction='row'>
                                    <SideBar />
                                    {routes}
                                </Grid>
                            ) : (
                                routes
                            )}
                        </main>
                        <ToastContainer />
                    </>
                )}
            </span>
        </ThemeProvider>
    );
};

export default App;
