import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import Loader from 'react-spinners/ClipLoader';
import { Colors } from '../colors';
interface SimplePhotoDialogProps {
    imageUrl: string;
    isLoading: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const SimplePhotoDialog: React.FC<SimplePhotoDialogProps> = (props) => {
    return (
        <Dialog fullScreen open onClose={props.onClose} TransitionComponent={Transition}>
            <AppBar color='default' style={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge='start' color='inherit' onClick={props.onClose} aria-label='close'>
                        <CloseIcon color='primary' />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {props.isLoading ? (
                <div style={{ height: '90%' }} className='flex-center'>
                    <Loader color={Colors.white} size={100} />
                </div>
            ) : (
                <img
                    src={props.imageUrl}
                    style={{
                        objectFit: 'contain',
                        maxWidth: '100%',
                        maxHeight: '90%',
                        display: 'block',
                        margin: 'auto',
                        userSelect: 'none'
                    }}
                    alt=''
                />
            )}
        </Dialog>
    );
};

export default SimplePhotoDialog;
