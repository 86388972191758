import React, { useCallback, useEffect, useState } from 'react';
import { List, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { flexSpaceBetween, StyleVariables, typographySubtitle } from '../styleVariables';
import { Colors } from '../colors';
import ApiService from '../../services/apiService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TopologyRouterSelectors } from '../../store/router/topologyActions';
import authService from '../../services/authService';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { useTranslation } from 'react-i18next';
interface RecentSearchListProps {
    inputValue: string;
    onSelected: (searchValue: string) => void;
    onClose: () => void;
}

const listItemHeight = 40;

const useStyles = makeStyles({
    root: {
        paddingTop: StyleVariables.gap * 2,
        paddingRight: StyleVariables.gap * 2,
        paddingBottom: StyleVariables.gap * 2,
        paddingLeft: StyleVariables.gap * 2,
        '&.hidden': {
            display: 'none'
        }
    },
    header: {
        ...flexSpaceBetween,
        ...typographySubtitle,
        textTransform: 'none',
        color: Colors.contrast,
        height: `${listItemHeight}px`,
        lineHeight: `${listItemHeight}px`,
        marginTop: 0,
        marginBottom: 0
    },
    close: {
        fill: Colors.contrast,
        '&:hover': {
            fill: Colors.white,
            opacity: 0.8
        }
    },
    list: {
        maxHeight: listItemHeight * 5,
        paddingTop: 0,
        paddingBottom: 0,
        overflowY: 'auto'
    },
    item: {
        height: listItemHeight,
        paddingLeft: 0,
        '&:hover': {
            color: Colors.clickable,
            transition: 'color 280ms'
        }
    },
    text: {
        fontSize: 14
    }
});

export const RecentSearchList: React.FC<RecentSearchListProps> = (props) => {
    const { t } = useTranslation('advancedSearch');
    const classes = useStyles();
    const [recentSearchList, setRecentSearchList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [displayRecentSearch, setDisplayRecentSearch] = useState(true);
    const currentQueryId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));

    useEffect(() => {
        fetchRecentSearchList();
    }, []);

    useEffect(() => {
        if (props.inputValue && recentSearchList.length > 0) {
            const filtered = recentSearchList.filter((item) => item.startsWith(props.inputValue));
            setFilteredList(filtered);
        }
    }, [props.inputValue]);

    const fetchRecentSearchList = async () => {
        const res = await ApiService.getRecentSearchList(
            authService.getAccessTokenData().lastUsedUserGroup,
            currentQueryId
        );
        setRecentSearchList(res.data as string[]);
    };

    const handleClose = useCallback(() => {
        props.onClose();
        setDisplayRecentSearch(false);
    }, [props.onClose, setDisplayRecentSearch]);

    const listToRender = props.inputValue ? filteredList : recentSearchList;

    return displayRecentSearch ? (
        <div className={`recent-search ${classes.root} ${listToRender.length === 0 ? 'hidden' : ''}`}>
            <h5 className={`recent-search-header ${classes.header}`}>
                {t('recent_search_list')} ({listToRender.length})
                <CustomSVGIcon
                    onClick={handleClose}
                    type={svgIcons.close}
                    size={22}
                    fillColor={Colors.white}
                    customClass={`search-tips-close ${classes.close}`}
                />
            </h5>

            <List className={`recent-search-list ${classes.list}`}>
                {listToRender?.map((value) => (
                    <MenuItem
                        key={value}
                        value={value}
                        className={`recent-search-item ${classes.item}`}
                        onClick={() => props.onSelected(value)}>
                        <span className={`recent-search-item-text ${classes.text}`}>{value}</span>
                    </MenuItem>
                ))}
            </List>
        </div>
    ) : null;
};

export default RecentSearchList;
