import React, { useEffect, useRef, useState } from 'react';
import { Colors } from '../colors';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import Loader from 'react-spinners/ClipLoader';
import classnames from 'classnames';
import './popupImage.less';
import { Coordinates } from '../topologyview/VisionSynapse';
import { ImageToSplit } from '../popupGallery/popupGalleryContainer';

export const popupImageId = 'popupImage';

export interface PopupImageShape {
    id: string;
    coordinates: Coordinates;
    fillColor: string;
    label?: string;
    showShape: boolean;
    showLabel: boolean;
}

export interface Point {
    x: number;
    y: number;
}

export interface CheckboxCoordinates {
    id: string;
    coordinates: Coordinates;
    xStart: number;
    xEnd: number;
    yStart: number;
    yEnd: number;
    isChecked: boolean;
}

interface PopupImageProps {
    url: string;
    imageId?: string;
    personToSplitUId?: string;
    shapes?: PopupImageShape[];
    onClick?: (shapesSelected: Coordinates[]) => void;
    onDoubleClick?: (clickedPoint: Point) => void;
    isSplit?: boolean;
    multiSelectedImages?: ImageToSplit[];
}

const PopupImage: React.FC<PopupImageProps> = (props) => {
    const canvasRef = useRef<HTMLCanvasElement>();
    const imageRef = useRef<HTMLImageElement>();
    const fullSizeIconRef = useRef<HTMLDivElement>();
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
    const [checkboxCoordinates, setCheckboxCoordinates] = useState<CheckboxCoordinates[]>([]);
    const fullscreenHandler = useFullScreenHandle();

    useDidUpdateEffect(() => {
        drawImageOnCanvas();
    }, [fullscreenHandler.active]);

    useEffect(() => setIsLoadingPhoto(true), [props.url]);

    useEffect(() => {
        if (imageRef.current.complete && imageRef.current.naturalWidth) {
            drawImageOnCanvas();
        } else {
            imageRef.current.onload = () => {
                drawImageOnCanvas();
                if (imageRef.current) {
                    imageRef.current.onload = null;
                }
            };
        }

        window.addEventListener('resize', () => drawImageOnCanvas());
        return () => window.removeEventListener('resize', drawImageOnCanvas);
    }, [props.shapes, props.url]);

    const handleShapesCreationInSplitGallery = (
        shape: PopupImageShape,
        ctx: CanvasRenderingContext2D,
        ratioX: number,
        ratioY: number,
        checkboxCoordinatesProto: CheckboxCoordinates[],
        isDrawCheckboxForSplittedPerson: boolean
    ) => {
        if (isDrawCheckboxForSplittedPerson && shape.id === props.personToSplitUId) {
            drawCheckbox(shape, ctx, ratioX, ratioY, checkboxCoordinatesProto);
        } else {
            handleShapesCreationInGeneralGallery(shape, ctx, ratioX, ratioY);
        }
    };

    const handleShapesCreationInGeneralGallery = (
        shape: PopupImageShape,
        ctx: CanvasRenderingContext2D,
        ratioX: number,
        ratioY: number
    ) => {
        if (shape.showLabel) {
            drawLabel(shape, ctx, ratioX, ratioY);
        }
        if (shape.showShape) {
            createTriangle(shape, ctx, ratioX, ratioY);
        }
    };

    const drawImageOnCanvas = (evt?: Event, checkboxCoordinates?: CheckboxCoordinates[]) => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        const fullSizeIcon = fullSizeIconRef.current;
        if (!image || !canvas) {
            return;
        }
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.style.width = String(image.width);
        canvas.style.height = String(image.height);
        canvas.style.left = `calc(50% - ${image.width / 2}px)`;
        canvas.style.top = `calc(50% - ${image.height / 2}px)`;
        fullSizeIcon.style.right = canvas.style.left;
        fullSizeIcon.style.top = canvas.style.top;

        const ratioX = image.width / image.naturalWidth;
        const ratioY = image.height / image.naturalHeight;

        ctx.drawImage(image, 0, 0, image.width, image.height);

        const checkboxCoordinatesProto: CheckboxCoordinates[] = checkboxCoordinates ? checkboxCoordinates : [];

        const isDrawCheckboxForSplittedPerson =
            props.shapes?.filter((shape) => shape.id === props.personToSplitUId).length > 1;

        props.shapes?.forEach((shape) => {
            if (props.isSplit) {
                handleShapesCreationInSplitGallery(
                    shape,
                    ctx,
                    ratioX,
                    ratioY,
                    checkboxCoordinatesProto,
                    isDrawCheckboxForSplittedPerson
                );
            } else {
                handleShapesCreationInGeneralGallery(shape, ctx, ratioX, ratioY);
            }
        });
        setCheckboxCoordinates(checkboxCoordinatesProto);
        setIsLoadingPhoto(false);
    };

    const createTriangle = (shape: PopupImageShape, ctx: CanvasRenderingContext2D, ratioX: number, ratioY: number) => {
        const yAxis = getYAxis(shape, ratioY);
        const xAxis = shape.coordinates.x * ratioX;
        // X (shape width) + avg shape width / 2 = Y
        const shapeWidth = shape.coordinates.w * ratioX; // X
        const avgShapeWidth = 60;
        const distanceToAvgShape = (shapeWidth - avgShapeWidth) / 2; // Y
        const avgShapeStart = xAxis + distanceToAvgShape;

        const xAxisLineStart = (35 * avgShapeWidth) / 100;
        const xAxisLineCenter = (50 * avgShapeWidth) / 100;
        const xAxisLineEnd = (65 * avgShapeWidth) / 100;

        const fakeBorder = new Path2D();
        fakeBorder.moveTo(avgShapeStart + xAxisLineStart - 1, yAxis);
        fakeBorder.lineTo(avgShapeStart + xAxisLineCenter, yAxis - 6);
        fakeBorder.lineTo(avgShapeStart + xAxisLineEnd + 1, yAxis);

        ctx.strokeStyle = 'black';
        ctx.lineWidth = 5;
        ctx.globalAlpha = 0.6;
        ctx.stroke(fakeBorder);

        const triangle = new Path2D();
        triangle.moveTo(avgShapeStart + xAxisLineStart, yAxis);
        triangle.lineTo(avgShapeStart + xAxisLineCenter, yAxis - 6);
        triangle.lineTo(avgShapeStart + xAxisLineEnd, yAxis);

        ctx.strokeStyle = shape.fillColor;
        ctx.lineWidth = 3;
        ctx.globalAlpha = 1;
        ctx.stroke(triangle);
    };

    const drawLabel = (shape: PopupImageShape, ctx: CanvasRenderingContext2D, ratioX: number, ratioY: number) => {
        ctx.font = '12px Roboto';

        const textWidth = ctx.measureText(shape.label).width; // must to be after the font
        const HalfTextWidth = ctx.measureText(shape.label).width / 2;
        const lineWidth = shape.coordinates.w / 2;

        let startFrom: number;
        if (HalfTextWidth > lineWidth) {
            startFrom = (HalfTextWidth - lineWidth) * -1;
        } else {
            startFrom = lineWidth - HalfTextWidth;
        }

        const x = (shape.coordinates.x + startFrom) * ratioX - 10;
        const h = getYAxis(shape, ratioY) - 14;

        ctx.fillStyle = 'rgba(62, 62, 62, 0.6)';

        ctx.fillRect(x - 10, h + 4, textWidth + 20, -16);
        ctx.fillStyle = Colors.white;
        ctx.fillText(shape.label, x, h);
    };

    const drawCheckbox = (
        shape: PopupImageShape,
        ctx: CanvasRenderingContext2D,
        ratioX: number,
        ratioY: number,
        checkboxCoordinatesProto: CheckboxCoordinates[]
    ) => {
        const yAxis = getYAxis(shape, ratioY);
        const xAxis = shape.coordinates.x * ratioX;
        const shapeWidth = shape.coordinates.w * ratioX; // X
        const avgShapeWidth = 60;
        const distanceToAvgShape = (shapeWidth - avgShapeWidth) / 2; // Y
        const avgShapeStart = xAxis + distanceToAvgShape;
        const xAxisLineStart = (35 * avgShapeWidth) / 100;
        const boxSize = 20;
        const existingCheckboxInState = checkboxCoordinatesProto.find(
            (checkBox) =>
                checkBox.coordinates.x === shape.coordinates.x &&
                checkBox.coordinates.y === shape.coordinates.y &&
                checkBox.coordinates.w === shape.coordinates.w &&
                checkBox.coordinates.h === shape.coordinates.h
        );
        const shapesFromMultiSelect = props.multiSelectedImages.find((image) => image.imageId === props.imageId);

        const checkboxAlreadySelected = Boolean(
            shapesFromMultiSelect?.facesCoordinates.find(
                (faceCoordinates) =>
                    faceCoordinates.x === shape.coordinates.x &&
                    faceCoordinates.y === shape.coordinates.y &&
                    faceCoordinates.w === shape.coordinates.w &&
                    faceCoordinates.h === shape.coordinates.h
            )
        );

        if (checkboxAlreadySelected) {
            ctx.fillStyle = Colors.clickable;
            ctx.fillRect(avgShapeStart + xAxisLineStart, yAxis, boxSize, boxSize * -1);
            ctx.strokeStyle = Colors.white;
            ctx.lineWidth = 2;
            const check = new Path2D();
            check.moveTo(avgShapeStart + xAxisLineStart + 4, yAxis - 12);
            check.lineTo(avgShapeStart + xAxisLineStart + 9, yAxis - 6);
            check.lineTo(avgShapeStart + xAxisLineStart + 16, yAxis - 16);
            ctx.stroke(check);
        } else {
            ctx.strokeStyle = Colors.clickable;
            ctx.lineWidth = 3;
            const checkBox = new Path2D();
            checkBox.moveTo(avgShapeStart + xAxisLineStart, yAxis);
            checkBox.lineTo(avgShapeStart + xAxisLineStart + boxSize, yAxis);
            checkBox.lineTo(avgShapeStart + xAxisLineStart + boxSize, yAxis - boxSize);
            checkBox.lineTo(avgShapeStart + xAxisLineStart, yAxis - boxSize);
            checkBox.lineTo(avgShapeStart + xAxisLineStart, yAxis);
            ctx.stroke(checkBox);
        }

        const checkBoxParams = {
            id: shape.id,
            coordinates: shape.coordinates,
            xStart: avgShapeStart + xAxisLineStart,
            xEnd: avgShapeStart + xAxisLineStart + boxSize,
            yStart: yAxis,
            yEnd: yAxis - boxSize,
            isChecked: checkboxAlreadySelected
        };
        updateCheckboxCoordinates(checkboxCoordinatesProto, existingCheckboxInState, checkBoxParams);
    };

    const updateCheckboxCoordinates = (
        checkboxCoordinatesProto: CheckboxCoordinates[],
        existingCheckbox: CheckboxCoordinates,
        checkBoxParams: CheckboxCoordinates
    ) => {
        if (!existingCheckbox) {
            checkboxCoordinatesProto.push(checkBoxParams);
        }
    };

    const getYAxis = (shape: PopupImageShape, ratioY: number) => {
        const heightOfTriangleCombinedWithLabel = 25;
        const yAxis = (shape.coordinates.y + shape.coordinates.h) * ratioY;
        // making sure triangle and label are not out of bounds (top only)
        return yAxis < heightOfTriangleCombinedWithLabel ? heightOfTriangleCombinedWithLabel : yAxis;
    };

    const handleDoubleClick = (clickEvent: React.MouseEvent<HTMLCanvasElement>) => {
        const { x, y } = getClickPosition(canvasRef.current, clickEvent);
        const ratioX = imageRef.current.width / imageRef.current.naturalWidth;
        const ratioY = imageRef.current.height / imageRef.current.naturalHeight;
        props.onDoubleClick?.({ x: x / ratioX, y: y / ratioY });
    };

    const handleClick = (clickEvent: React.MouseEvent<HTMLCanvasElement>) => {
        const { x, y } = getClickPosition(canvasRef.current, clickEvent);
        const shape = checkboxCoordinates.find((shape) => {
            return x > shape.xStart && x < shape.xEnd && y > shape.yEnd && y < shape.yStart;
        });
        if (shape) {
            shape.isChecked = !shape.isChecked;
            const shapesSelected = checkboxCoordinates
                .filter((shape) => shape.isChecked)
                .map((shape) => shape.coordinates);
            props.onClick?.(shapesSelected);
            drawImageOnCanvas(null, checkboxCoordinates);
        }
    };

    const getClickPosition = (canvas: HTMLCanvasElement, clickEvent: React.MouseEvent<HTMLCanvasElement>) => {
        const rect = canvas.getBoundingClientRect();
        const mousePosition = {
            x: ((clickEvent.clientX - rect.left) / (rect.right - rect.left)) * canvas.width,
            y: ((clickEvent.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height
        };
        return { x: mousePosition.x, y: mousePosition.y };
    };

    return (
        <FullScreen handle={fullscreenHandler}>
            <img id={popupImageId} ref={imageRef} src={props.url} className='image' alt='' />
            {isLoadingPhoto && (
                <div className='image-loader'>
                    <Loader color={Colors.white} size={18} />
                </div>
            )}
            <canvas
                className={classnames('canvas', { hide: isLoadingPhoto })}
                ref={canvasRef}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            />
            <div ref={fullSizeIconRef} className='full-size-icon'>
                <CustomSVGIcon
                    onClick={fullscreenHandler.active ? fullscreenHandler.exit : fullscreenHandler.enter}
                    type={fullscreenHandler.active ? svgIcons.collapse : svgIcons.expand}
                    size={24}
                />
            </div>
        </FullScreen>
    );
};

export default PopupImage;
