import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getInitialServerSideFiltersValues, ServerFilters } from '../../store/slices/filtersSlice';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { PhotoSourceNetwork } from '../topologyview/VisionSynapse';
import AllMediaFilters from './allMediaFilters';
import AggregationMediaFilters from './aggregationMediaFilters';
import './sideFilters.less';
import UploadedMediaFilters from './uploadedMediaFilters';
import { useTranslation } from 'react-i18next';

interface SideFiltersProps {
    initialValues: ServerFilters;
    onClose: (filters?: ServerFilters) => void;
}

const SideFilters: React.FC<SideFiltersProps> = (props) => {
    const { t } = useTranslation('filters');
    const [filters, setFilters] = useState(props.initialValues);
    const originalTopologyPhotos = useSelector((state: RootState) => state.topology.originalTopology.topologyPhotos);
    const currentTopologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const { objectFilters } = useSelector((state: RootState) => state.configurations.data);
    const { serverFilters } = useSelector((state: RootState) => state.filters);

    useEffect(() => {
        setFilters(serverFilters);
    }, [serverFilters]);

    const aggregatedPhotosCount = useMemo(
        () =>
            originalTopologyPhotos.filter((photo) =>
                Object.values(PhotoSourceNetwork).includes(photo.photoType as PhotoSourceNetwork)
            ).length,
        [originalTopologyPhotos]
    );
    const aggregationFilteredPhotosCount = useMemo(
        () =>
            currentTopologyPhotos.filter((photo) =>
                Object.values(PhotoSourceNetwork).includes(photo.photoType as PhotoSourceNetwork)
            ).length,
        [currentTopologyPhotos]
    );

    const relevantObjects = useMemo(() => {
        const noAppearancesFilters = new Map();
        objectFilters?.forEach((objectFilter) => noAppearancesFilters.set(objectFilter, null));

        for (const currentPhoto of currentTopologyPhotos) {
            currentPhoto.detectedObjects.forEach((detectedObject) => {
                noAppearancesFilters.delete(detectedObject);
            });

            if (noAppearancesFilters.size === 0) {
                break;
            }
        }

        return objectFilters ? objectFilters.filter((objectFilter) => !noAppearancesFilters.has(objectFilter)) : [];
    }, [currentTopologyPhotos]);

    const allPhotosCount = originalTopologyPhotos.length;
    const filteredPhotosCount = currentTopologyPhotos.length;

    const uploadedMediaPhotosCount = allPhotosCount - aggregatedPhotosCount;
    const uploadedMediaFilteredPhotosCount = filteredPhotosCount - aggregationFilteredPhotosCount;

    const isAggregationFiltersDisabled = () => !!filters.creationDate;

    const isUploadedMediaFiltersDisabled = () => !!filters.uploadDate || filters.networks.length > 0;

    return (
        <div className='side-filter-container' role='presentation'>
            <div className='flex-justify-between header'>
                <Typography color='textPrimary'>{t('side_filters.filters')}</Typography>
                <CustomSVGIcon
                    data-test-id='close-filters'
                    onClick={() => props.onClose()}
                    type={svgIcons.close}
                    size={20}
                />
            </div>

            <Grid container>
                <AllMediaFilters
                    filters={filters}
                    relevantObjects={relevantObjects}
                    setFilters={setFilters}
                    allPhotosCount={allPhotosCount}
                    filteredPhotosCount={filteredPhotosCount}
                />

                <UploadedMediaFilters
                    filters={filters}
                    setFilters={setFilters}
                    uploadedMediaFilteredPhotosCount={uploadedMediaFilteredPhotosCount}
                    uploadedMediaPhotosCount={uploadedMediaPhotosCount}
                    disabled={isUploadedMediaFiltersDisabled()}
                />

                <AggregationMediaFilters
                    filters={filters}
                    setFilters={setFilters}
                    aggregationFilteredPhotosCount={aggregationFilteredPhotosCount}
                    aggregatedPhotosCount={aggregatedPhotosCount}
                    disabled={isAggregationFiltersDisabled()}
                />
            </Grid>

            <div className='flex buttons-container'>
                <Button
                    className='clear-button'
                    variant='outlined'
                    onClick={() => setFilters(getInitialServerSideFiltersValues())}
                    color='primary'>
                    {t('side_filters.clear')}
                </Button>
                <Button onClick={() => props.onClose(filters)} variant='contained' color='primary'>
                    {t('side_filters.apply')}
                </Button>
            </div>
        </div>
    );
};

export default React.memo(SideFilters);
