import AjaxClient from './ajaxClient';
import { AxiosResponse } from 'axios';
import { PaymentCounter, PaymentCounterType } from '../shared/serviceModels/paymentCounter';
import authService from './authService';

const PaymentService = {
    fetchVisionUsage(groupId: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.get(`/usage/${PaymentCounterType.visionLong}/group/` + groupId);
    },
    fetchFalconLongUsage(groupId: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.get(`/usage/${PaymentCounterType.falconLong}/group/` + groupId);
    },
    fetchFalconShortUsage(groupId: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.get(`/usage/${PaymentCounterType.falconShort}/group/` + groupId);
    },
    updateVisionLongUsage(groupId: number, quantity: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.post(`/usage/update-usage/${PaymentCounterType.visionLong}/group/` + groupId, { quantity });
    },
    updateFalconLongUsage(groupId: number, quantity: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.post(`/usage/update-usage/${PaymentCounterType.falconLong}/group/` + groupId, { quantity });
    },
    updateFalconShortUsage(groupId: number, quantity: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.post(`/usage/update-usage/${PaymentCounterType.falconShort}/group/` + groupId, { quantity });
    },
    updateFalconShortLimit(groupId: number, limit: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.put(`/usage/update-limit/${PaymentCounterType.falconShort}/group/` + groupId, { limit });
    },
    updateFalconLongLimit(groupId: number, limit: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.put(`/usage/update-limit/${PaymentCounterType.falconLong}/group/` + groupId, { limit });
    },
    updateVisionLongLimit(groupId: number, limit: number): Promise<AxiosResponse<PaymentCounter>> {
        return AjaxClient.put(`/usage/update-limit/${PaymentCounterType.visionLong}/group/` + groupId, { limit });
    },
    async isRateLimitExceeded(): Promise<boolean> {
        const counter = await PaymentService.fetchVisionUsage(+authService.getAccessTokenData().lastUsedUserGroup);
        return counter.data.usage >= counter.data.limit;
    }
};

export default PaymentService;
