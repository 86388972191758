import _ from 'lodash';
import AjaxClient from '../../services/ajaxClient';
import authService from '../../services/authService';
import {
    DroneData,
    CreateBatchApiData,
    ExecutionType,
    MediaFileDetails,
    AdvancedSettingsApi
} from '../../services/batchService';
import TusdService from '../../services/tusdService';
import { Permissions } from '../../shared/model/Permissions';
import store from '../../store';
import {
    AdvancedSettings,
    getFormattedImageTypes,
    getFormattedVideoTypes
} from '../../store/slices/configurationsSlice';
import i18n from 'i18next';

export enum ClassifyMode {
    MODERATE = 'MODERATE',
    STRICT = 'STRICT',
    MODERATE_FOR_VIDEOS = 'MODERATE_FOR_VIDEOS'
}
export interface BatchData {
    batchId?: string;
    batchName: string;
    classifyMode: ClassifyMode;
    supportVideo: boolean;
    videoFramesPerMinute: number;
    automaticSlicing: boolean;
    framesWithIndividualsOnly: boolean;
    advancedSettings: AdvancedSettings;
}

export class BatchDescriptor {
    constructor(type: ExecutionType, batchId?: string) {
        this.type = type;
        this.batchData = {
            batchName: '',
            batchId,
            supportVideo: false,
            videoFramesPerMinute: 1,
            automaticSlicing: true,
            classifyMode: ClassifyMode.MODERATE_FOR_VIDEOS,
            framesWithIndividualsOnly: true,
            advancedSettings: {
                entityMatching: true,
                imageDescription: false,
                or: false,
                ocr: false,
                explicitContent: false,
                landmarks: false,
                similarImage: false
            }
        };
    }

    type: ExecutionType;
    batchData: BatchData;
    files: File[];
}

const CreateTopologyService = {
    getCreateCaseTooltip() {
        return (
            i18n.t('create_topology_service.max_images_per_case', {
                ns: 'createTopology'
            }) +
            ': ' +
            store.getState().configurations.data.maxTopologySizeInImages?.toLocaleString()
        );
    },
    getInstructions() {
        const commonInstructions = [
            i18n.t('create_topology_service.common_instructions.folder_or_multiple_files', { ns: 'createTopology' }),
            i18n.t('create_topology_service.common_instructions.max_size', {
                ns: 'createTopology',
                fileUploadMaxSizeInGigaBytes: store.getState().configurations.data.fileUploadMaxSizeInMegaBytes / 1000
            }),
            i18n.t('create_topology_service.common_instructions.max_files_per_upload', {
                ns: 'createTopology',
                maxFilesPerUpload: store.getState().configurations.data.maxFilesPerUpload.toLocaleString()
            }),
            i18n.t('create_topology_service.common_instructions.max_images_in_zip_per_upload', {
                ns: 'createTopology',
                fileUploadMaxMediaFilesPerZip: store
                    .getState()
                    .configurations.data.fileUploadMaxMediaFilesPerZip.toLocaleString()
            }),
            i18n.t('create_topology_service.common_instructions.supported_graphic_types', {
                ns: 'createTopology',
                getFormattedImageTypes: getFormattedImageTypes(store.getState())
            }),
            i18n.t('create_topology_service.common_instructions.supported_video_types', {
                ns: 'createTopology',
                getFormattedVideoTypes: getFormattedVideoTypes(store.getState())
            }),
            i18n.t('create_topology_service.common_instructions.recommended_video_resolution', { ns: 'createTopology' })
        ];
        return commonInstructions;
    },
    prepareCreationData(
        formData: BatchDescriptor,
        droneData: DroneData,
        filesDetails: MediaFileDetails[]
    ): CreateBatchApiData {
        formData = _.cloneDeep(formData);

        formData.batchData.supportVideo = true;

        if (!formData.batchData.videoFramesPerMinute) {
            formData.batchData.videoFramesPerMinute = store.getState().configurations.data.videoDefaultFramesPerMinute;
        }

        if (!formData.batchData.classifyMode) {
            delete formData.batchData.classifyMode;
        }

        const queryData: AdvancedSettingsApi = {
            includeFR: formData.batchData.advancedSettings.entityMatching,
            includeOR: formData.batchData.advancedSettings.or,
            includeOCR: formData.batchData.advancedSettings.ocr,
            includeExplicitContent: formData.batchData.advancedSettings.explicitContent,
            includeImage2Text: formData.batchData.advancedSettings.imageDescription,
            includeLandmarks: formData.batchData.advancedSettings.landmarks,
            includeSimilarImages: formData.batchData.advancedSettings.similarImage
        };

        const { advancedSettings, ...batchData } = formData.batchData;

        return {
            type: formData.type,
            queryData: Object.assign(batchData, queryData),
            aggregationMetadata: droneData,
            maxFileSizeInZip: store.getState().configurations.data.fileUploadMaxMediaFilesPerZip,
            files: filesDetails
        };
    },
    async create(formData: BatchDescriptor, droneData: DroneData, filesDetails: MediaFileDetails[]) {
        const preparedCreationData = this.prepareCreationData(formData, droneData, filesDetails);

        await AjaxClient.post<CreateBatchApiData>('/batch', preparedCreationData, {
            timeout: 60 * 30 * 1000
        });
    },
    async addImages(formData: BatchDescriptor, droneData: DroneData, filesDetails: MediaFileDetails[]) {
        const preparedCreationData = this.prepareCreationData(formData, droneData, filesDetails);

        await AjaxClient.post<CreateBatchApiData>('/batch/add-photos', preparedCreationData, {
            timeout: 60 * 30 * 1000
        });
    },
    uploadTopologyMediaZip: (
        files: File[],
        onProgressCallback: (progressPercentage: number, uploadedCount?: number) => void
    ) => {
        return TusdService.uploadFiles('/api/files/', files, onProgressCallback);
    },
    getBoxError() {
        return authService.hasPermissions(Permissions.case_show_falcon)
            ? i18n.t('create_topology_service.upload_or_bring', { ns: 'createTopology' })
            : i18n.t('create_topology_service.upload_media', { ns: 'createTopology' });
    }
};

export default CreateTopologyService;
