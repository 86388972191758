import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldProps, getIn } from 'formik';
import React from 'react';

const TextFormField: React.FC<FieldProps<TextFieldProps>> = ({ field, form, ...props }) => {
    const errorText = getIn(form.touched, field.name) && getIn(form.errors, field.name);

    return <TextField margin='normal' helperText={errorText} error={!!errorText} {...field} {...props} />;
};

export default TextFormField;
