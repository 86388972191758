import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GalleryService from '../../../services/galleryService';
import { RootState } from '../../../store';
import { changeSearchQueryCommon, TopologyRouterSelectors } from '../../../store/router/topologyActions';
import VirtualScrollGallery from '../../virtualScrollGallery/virtualScrollGallery';
import Loader from 'react-spinners/CircleLoader';
import { Colors } from '../../colors';
import { clearSearchResults, serverSearch } from '../../../store/slices/searchSlice';
import OldVirtualScrollGallery from '../../virtualScrollGallery/oldVirtualScrollGallery';
import SearchResultsHeader from './searchResultsHeader';
import useOptimisticFlagImage from '../../hooks/useOptimisticFlagImage';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchTypes } from '../../advancedSearch/advancedSearch';
import { SearchByTextRequestData, SearchSimilarImageData } from '../../../services/searchService';
import {
    AdvSearchRequestParams,
    buildAdvSearchRequestData,
    getSimilarImageNameAndType
} from '../../../shared/helpers/searchHelpers';
import { SimilarImageSearchTypes } from '../../advancedSearch/similarImageForm';
import authService from '../../../services/authService';
import { GalleryProps } from './galleryTab';

const SearchResultsGallery: React.FC<GalleryProps> = (props: GalleryProps) => {
    const { t } = useTranslation(['common', 'advancedSearch']);
    const dispatch = useDispatch();
    const isShowingLoader = useSelector((state: RootState) => state.search.isShowingLoader);
    const searchByTextResults = useSelector((state: RootState) => state.search.searchByTextImageResults);
    const allPhotosHaveThumbnail = useSelector((state: RootState) => state.topology.allPhotosHaveThumbnail);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const groupId = authService.getAccessTokenData().lastUsedUserGroup;
    const queries = {
        imageDescriptionQueryObj: useSelector(TopologyRouterSelectors.getImage2TextQuery),
        similarImage: useSelector(TopologyRouterSelectors.getSimilarImageQuery)
    };
    const photos = searchByTextResults?.data.photos || [];
    const virtualScrollGalleryPhotos = useMemo(() => GalleryService.convertToVirtualScrollGalleryPhotos(photos), [
        photos
    ]);
    const toggleImageFlag = useOptimisticFlagImage();

    const clearSearchQuery = useCallback(() => {
        Object.keys(AdvancedSearchTypes).forEach((type) => {
            dispatch(changeSearchQueryCommon(null, AdvancedSearchTypes[type]));
        });
        dispatch(clearSearchResults());
    }, []);

    const handleLoadMoreImages = () => {
        const params: AdvSearchRequestParams = {
            searchType,
            query: queries.imageDescriptionQueryObj.imageDescriptions ? queries.imageDescriptionQueryObj : null,
            similarImageSearchType: getSimilarImageNameAndType(
                queries.similarImage,
                t('similar_image_form.by', { ns: 'advancedSearch' }),
                false
            )?.toUpperCase() as SimilarImageSearchTypes,
            groupId: queries.similarImage ? groupId : null
        };
        const data: SearchByTextRequestData | SearchSimilarImageData = buildAdvSearchRequestData(params);
        dispatch(serverSearch({ ...data, bookmark: searchByTextResults.nextBookmark }, searchType));
    };

    return !searchByTextResults ? (
        <div className='flex-center' style={{ height: '100%' }}>
            <Loader size={100} color={Colors.lightBlue} />
        </div>
    ) : (
        <>
            <SearchResultsHeader onClose={clearSearchQuery} noResults={photos.length === 0} title={props.title} />
            {allPhotosHaveThumbnail ? (
                <VirtualScrollGallery
                    onFlagClick={toggleImageFlag}
                    onClick={props.onImageClicked}
                    gutterSize={16}
                    columnWidth={236}
                    focusedImageId={null}
                    images={virtualScrollGalleryPhotos}
                    loadMoreImages={handleLoadMoreImages}
                    isLoadingMoreImages={isShowingLoader}
                    hasMoreToLoad={!!searchByTextResults.nextBookmark}
                />
            ) : (
                <OldVirtualScrollGallery
                    onFlagClick={toggleImageFlag}
                    onClick={props.onImageClicked}
                    gutterSize={16}
                    columnWidth={236}
                    focusedImageId={null}
                    images={virtualScrollGalleryPhotos}
                    loadMoreImages={handleLoadMoreImages}
                    isLoadingMoreImages={isShowingLoader}
                    hasMoreToLoad={!!searchByTextResults.nextBookmark}
                />
            )}
        </>
    );
};

export default SearchResultsGallery;
