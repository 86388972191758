import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum DeleteDialogResult {
    Hard = 'hard',
    Soft = 'soft'
}

interface DeletePersonDialogProps extends DialogProps {
    open: boolean;
    onClose: (result: DeleteDialogResult) => void;
    isLoading?: boolean;
}

const DeletePersonDialog: React.FC<DeletePersonDialogProps> = ({ onClose, isLoading, open = true, ...props }) => {
    const { t } = useTranslation('dialogs');

    const [deleteMode, setDeleteMode] = useState(DeleteDialogResult.Soft);

    return (
        <Dialog maxWidth='sm' open={open} fullWidth {...props} onClose={() => onClose(null)}>
            <DialogTitle>{t('delete_person_dialog.delete_individual')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('delete_person_dialog.choose_delete_mode')}</DialogContentText>
                <RadioGroup onChange={(e) => setDeleteMode(e.target.value as DeleteDialogResult)} value={deleteMode}>
                    <FormControlLabel
                        value={DeleteDialogResult.Soft}
                        control={<Radio />}
                        label={t('delete_person_dialog.delete_from_current')}
                    />
                    <FormControlLabel
                        value={DeleteDialogResult.Hard}
                        control={<Radio />}
                        label={t('delete_person_dialog.delete_from_all')}
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} autoFocus onClick={() => onClose(null)} color='primary'>
                    {t('cancel')}
                </Button>
                <Button
                    disabled={isLoading}
                    startIcon={<DeleteIcon />}
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => onClose(deleteMode)}
                    color='primary'
                    variant='contained'>
                    {isLoading && <CircularProgress style={{ marginRight: '5px' }} size={14} />}
                    <span>{t('delete')}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeletePersonDialog;
