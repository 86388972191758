import AjaxClient from './ajaxClient';
import {
    SearchResponse,
    PersonDescriptor,
    PhotoAppearance,
    SynapsePhoto
} from '../components/topologyview/VisionSynapse';
import { CancelTokenSource } from 'axios';
import CancelApiRequestService, { CancelTokenTypes } from './cancelApiRequestService';

export enum NotFoundReason {
    NoResults = 'NoResults',
    OneFaceButNotInCurrentQuery = 'OneFaceButNotInCurrentQuery',
    MultipleFacesButNotInCurrentQuery = 'MultipleFacesButNotInCurrentQuery'
}

export interface SearchWithFiltersRequest {
    queryIds: string[];
    createDateRange: [number, number];
    uploadDateRange: [number, number];
    networks: string[];
    flagged: boolean;
}

export interface SearchByTextRequestData {
    imageDescriptions: string[];
    operators: string[];
    bookmark?: string;
}

export interface SearchSimilarImageData {
    type: string;
    imageBase64: string;
    groupId: string;
    pageSize: number;
    bookmark?: string;
}

export interface SearchImageByTextResults {
    data: SearchImageByTextResultsData;
    nextBookmark: string;
    numberOfMatchedImages: number;
    parsedQuery: { amount: number; type: string; attributes: string[] }[];
    pois: string[];
    exact: boolean;
}

export interface SearchImageByTextResultsData {
    photos: SynapsePhoto[];
    appearances: PhotoAppearance[];
    persons: PersonDescriptor[];
}

const SearchService = {
    searchPersonByPhoto(person: File, queryIds: string[], queryNames: string[]) {
        return AjaxClient.postFormData<SearchResponse>(`/person/search-by-image`, { person, queryIds, queryNames });
    },
    searchImagesByText(data: SearchWithFiltersRequest & SearchByTextRequestData) {
        return AjaxClient.post<SearchImageByTextResults>('/photo/search', data);
    },
    searchSimilarImage(data: SearchWithFiltersRequest & SearchSimilarImageData) {
        const source: CancelTokenSource = CancelApiRequestService.generateAndSaveCancelToken(
            CancelTokenTypes.SimilarImage
        );

        return AjaxClient.post<SearchImageByTextResults>('/photo/similar-image', data, {
            cancelToken: source.token
        });
    },
    getPersonsFromSearchByPhotoResults: (searchResults: SearchResponse, persons: PersonDescriptor[]) => {
        if (!searchResults || searchResults.persons.length === 0) {
            throw new Error(NotFoundReason.NoResults);
        }
        const personsFoundInTopology: PersonDescriptor[] = [];

        // Check if founded persons exists in current topology.
        searchResults.persons.forEach((foundedPerson) => {
            const person = persons.find((person) => person.personUID === foundedPerson.personId);
            if (person) {
                personsFoundInTopology.push(person);
            }
        });

        // If one face detected, and face doesn't exists in current topology.
        if (searchResults.persons.length === 1 && personsFoundInTopology.length === 0) {
            throw new Error(NotFoundReason.OneFaceButNotInCurrentQuery);
        }
        // If many faces detected, and all faces don't exists in current topology.
        else if (personsFoundInTopology.length === 0) {
            throw new Error(NotFoundReason.MultipleFacesButNotInCurrentQuery);
        }

        return personsFoundInTopology;
    }
};

export default SearchService;
