import React from 'react';
import authService from '../../../services/authService';
import { Permissions } from '../../../shared/model/Permissions';
import * as Version from '../../../version.json';
import { ReactComponent as LogoSvg } from './logo.svg';
import './logo.less';

const Logo = () => {
    return (
        <div className='logo'>
            <LogoSvg />
            {authService.hasPermissions(Permissions.view_version) && <span className='version'>{Version.version}</span>}
        </div>
    );
};

export default Logo;
