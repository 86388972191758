import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps extends DialogProps {
    open: boolean;
    title: string;
    description: string;
    onClose: (result: boolean) => void;
    isLoading?: boolean;
    submitButtonName?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    title,
    description,
    onClose,
    isLoading,
    open,
    submitButtonName,
    ...props
}) => {
    const { t } = useTranslation('dialogs');
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth='xs' open={open} fullWidth {...props}>
            <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} autoFocus onClick={() => onClose(false)} color='primary'>
                    {t('cancel')}
                </Button>
                <Button
                    disabled={isLoading}
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => onClose(true)}
                    color='primary'>
                    {isLoading && <CircularProgress style={{ marginRight: '5px' }} size={14} />}
                    <span>{submitButtonName ?? t('ok')}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
