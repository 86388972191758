export enum svgIcons {
    flag,
    flagOutline,
    magnify,
    help,
    searchByPhoto,
    tag,
    merge,
    binoculars,
    trashBin,
    photos,
    left,
    right,
    close,
    ballot,
    group,
    plus,
    minus,
    profile,
    language,
    logout,
    back,
    edit,
    facebook,
    vk,
    instagram,
    imageSearch,
    info,
    alert,
    warn,
    moreVert,
    noSeed,
    filter,
    searchMinus,
    groupActions,
    createGroup,
    autoGroup,
    video,
    falcon,
    stop,
    export,
    uploadFolder,
    uploadFile,
    collapse,
    expand,
    split,
    download,
    cardAccountDetails,
    createIndividual,
    playlistEdit,
    menuDown,
    menuUp,
    settings,
    document,
    face,
    ocr,
    or,
    tiktok,
    twitter,
    youtube,
    telegram,
    colors,
    person,
    clothing,
    accessories,
    locations,
    infrastructure,
    vehicles,
    objects,
    union,
    arrowFilled,
    photoPlus,
    clock,
    location
}

export enum AvatarColor {
    seed = '#f38657',
    other = '#00ddc5',
    regular = '#74787d',
    selectedConnection = '#26b3ff',
    identifiedTnt = '#72ef96'
}

export enum ImageTypes {
    jpeg = 'JPEG'
}

export enum TabsNames {
    Relationships = 'relationships',
    Gallery = 'gallery'
}
