import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables } from '../styleVariables';
import { Colors } from '../colors';
import SearchTips from './searchTips';
import RecentSearchList from './recentSearchList';

interface Image2TextDropdownProps {
    isRecentSearchExpanded: boolean;
    inputValue: string;
    onSelected: (searchValue: string) => void;
    isTipsExpanded: boolean;
    onCloseTips: () => void;
    onCloseRecentSearch: () => void;
}

const useStyles = makeStyles({
    root: {
        width: 'calc(100% + 2px)',
        borderRight: `1px solid ${Colors.contrast}`,
        borderBottom: `1px solid ${Colors.contrast}`,
        borderLeft: `1px solid ${Colors.contrast}`,
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        position: 'absolute',
        top: `calc(${StyleVariables.advancedSearchSize} - 2px)`,
        left: '-1px',
        zIndex: 1
    },
    background: {
        width: '100%',
        height: 'calc(100% - 1px)',
        backgroundImage: Colors.recentSearchBg,
        opacity: 0.95,
        position: 'absolute',
        top: '1px',
        left: 0,
        zIndex: -1
    }
});

export const Image2TextDropdown: React.FC<Image2TextDropdownProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={`image2Text-dropdown ${classes.root}`}>
            {props.isRecentSearchExpanded && (
                <RecentSearchList
                    inputValue={props.inputValue}
                    onSelected={props.onSelected}
                    onClose={() => props.onCloseRecentSearch()}
                />
            )}
            {props.isTipsExpanded && <SearchTips onClose={() => props.onCloseTips()} />}
            <div className={`image2Text-dropdown-background ${classes.background}`} />
        </div>
    );
};

export default Image2TextDropdown;
