import { Button } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import Loader from 'react-spinners/PulseLoader';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ApiService from '../../../services/apiService';
import { Colors } from '../../colors';
import TextFormField from '../../common/formFields/TextFormField';
import { UserManagementData } from '../usersManagmentContainer';
import EditGroup from './editGroup';
import './groupsManagement.less';
import { useTranslation } from 'react-i18next';

interface GroupsManagementProps {
    managementData: UserManagementData;
    refreshManagementData: () => Promise<void>;
}

interface AddGroupForm {
    groupName: string;
    yearlyCounter: number;
    dailyCounter: number;
}

const GroupsManagement: React.FC<GroupsManagementProps> = (props) => {
    const { t } = useTranslation('usersManagement');
    const validationSchema = yup.object().shape({
        groupName: yup
            .string()
            .trim()
            .required()
            .notOneOf(props.managementData.groups.map((group) => group.name)),
        yearlyCounter: yup.number().required(),
        dailyCounter: yup.number().required()
    });

    const createGroup = async (formValues: AddGroupForm, helpers: FormikHelpers<AddGroupForm>) => {
        const confirmed = window.confirm(
            t('groups_management.confirm_create_group', { groupName: formValues.groupName })
        );
        if (!confirmed) {
            return;
        }
        try {
            await ApiService.createGroup(formValues);
            toast.success(t('groups_management.group_created', { groupName: formValues.groupName }));
            await props.refreshManagementData();
        } catch (error) {
            toast.error(t('groups_management.failed_create_group', { groupName: formValues.groupName }));
        } finally {
            helpers.setSubmitting(false);
        }
    };

    return (
        <div>
            <EditGroup refreshManagementData={props.refreshManagementData} managementData={props.managementData} />
            <div style={{ margin: '10px 0 50px' }}>
                <h4>{t('groups_management.title')}</h4>
                <Formik<AddGroupForm>
                    onSubmit={createGroup}
                    validationSchema={validationSchema}
                    initialValues={{ groupName: '', dailyCounter: 50000, yearlyCounter: 600000 }}>
                    {({ isSubmitting }) => (
                        <Form className='flex-align-center edit-user-form'>
                            <Field
                                style={{ marginRight: '10px' }}
                                name='groupName'
                                label={t('groups_management.group_name')}
                                disabled={isSubmitting}
                                component={TextFormField}
                            />

                            <Field
                                style={{ marginRight: '10px' }}
                                name='yearlyCounter'
                                label={t('groups_management.yearly_counter')}
                                disabled={isSubmitting}
                                component={TextFormField}
                                type='number'
                            />
                            <Field
                                style={{ marginRight: '10px' }}
                                name='dailyCounter'
                                label={t('groups_management.daily_counter')}
                                disabled={isSubmitting}
                                component={TextFormField}
                                type='number'
                            />
                            <Button
                                size='small'
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <Loader size={10} color={Colors.white} />
                                ) : (
                                    t('groups_management.create_group')
                                )}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>

            <div>
                <h5 className='users-management-subtitle'>{t('groups_management.all_groups')}</h5>
                <ul>
                    {props.managementData.groups.map((group) => (
                        <li key={group.id}>
                            {t('groups_management.name')}: {group.name}, id: {group.id}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GroupsManagement;
