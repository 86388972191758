import { Button } from '@material-ui/core';
import React from 'react';
import { Colors } from '../../colors';
import { svgIcons } from '../../common/entities/enums';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import './advancedSettings.less';
import FrModeFormikSection from './frModeFormikSection';
import ProcessingModeFormikSection from './processingModeFormikSection';
import VideoSettingsFormikSection from './videoSettingsFormikSection';
import { useTranslation } from 'react-i18next';
import { AdvancedSettings } from '../../../store/slices/configurationsSlice';

interface AdvancedSettingsFromikSectionProps {
    onClose: (isCancel: boolean) => void;
    disabledOptions: Partial<AdvancedSettings>;
}

const AdvancedSettingsFromikSection: React.FC<AdvancedSettingsFromikSectionProps> = (props) => {
    const { t } = useTranslation('createTopology');

    const renderHeader = () => (
        <h4 className='flex-align-center'>
            <CustomSVGIcon
                type={svgIcons.settings}
                fillColor={Colors.white}
                size={25}
                customStyle={{ margin: '0 10px' }}
            />
            <span>{t('adv_settings_formik_section.advanced_settings')}</span>
        </h4>
    );

    const handleCancelCLicked = () => props.onClose(true);
    const handleApplyCLicked = () => props.onClose(false);

    const renderSubmitAndCancelButton = () => (
        <div className='buttons'>
            <Button variant='outlined' onClick={handleCancelCLicked}>
                {t('adv_settings_formik_section.cancel')}
            </Button>

            <Button
                className='apply-button'
                variant='contained'
                type='submit'
                color='primary'
                onClick={handleApplyCLicked}>
                {t('adv_settings_formik_section.apply')}
            </Button>
        </div>
    );
    return (
        <>
            {renderHeader()}
            <div className='advanced-settings-form'>
                <ProcessingModeFormikSection disabledOptions={props.disabledOptions} />
                <FrModeFormikSection />
                <VideoSettingsFormikSection />
                {renderSubmitAndCancelButton()}
            </div>
        </>
    );
};

export default AdvancedSettingsFromikSection;
