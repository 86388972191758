import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Colors } from '../colors';

interface ThumbnailImageProps {
    image: string;
    thumbnailMaxWidth?: number;
    description?: string;
    hoverWidth: number;
    hoverHeight: number;
    maxExpandWidth?: number;
    maxExpandHeight?: number;
}

interface StyleProps {
    thumbnailMaxWidth: number;
    hoverWidth: number;
    hoverHeight: number;
    maxExpandWidth: number;
    maxExpandHeight: number;
}

const useStyles = makeStyles<Theme, StyleProps>({
    thumbnailImage: {
        height: '100%',
        maxWidth: (p) => `${p.thumbnailMaxWidth}px`,
        objectFit: 'contain',
        '&:hover': {
            width: (p) => `${p.hoverWidth}px`,
            height: (p) => `${p.hoverHeight}px`,
            position: 'absolute',
            right: '150px',
            zIndex: 1,
            border: `1px ${Colors.white} solid`,
            maxWidth: (p) => `${p.maxExpandWidth}px`,
            maxHeight: (p) => `${p.maxExpandHeight}px`
        }
    }
});

const ThumbnailImage: React.FC<ThumbnailImageProps> = ({
    image = '',
    description = 'thumbnail',
    thumbnailMaxWidth = 120,
    hoverWidth = 100,
    hoverHeight = 100,
    maxExpandWidth = 250,
    maxExpandHeight = 200
}) => {
    const props = { hoverWidth, hoverHeight, maxExpandWidth, maxExpandHeight, thumbnailMaxWidth };

    const classes = useStyles(props);

    return image && <img className={classes.thumbnailImage} src={image} alt={description} />;
};

export default ThumbnailImage;
