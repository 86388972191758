import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React from 'react';

import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import { Permissions } from '../../shared/model/Permissions';

import { Age, Gender } from '../../store/slices/filtersSlice';
import { SynapseMetaData } from '../../store/slices/topologySlice';
import { getFinishedWatchlists, WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { AvatarColor } from '../common/entities/enums';

import ExportToZipDialog, { ExportDialogMode } from '../dialogs/exportToZipDialog';
import AddLabel from './addLabel';
import AddPersonToWatchlist from './addPersonToWatchlist';
import EditPerson from './editPerson';

import SelectedConnectionActions from './selectedConnectionActions';
import IndividualDetails from './individualDetails';

import { PersonDescriptor } from './VisionSynapse';
import './styles/sidePanel.less';

import { useTranslation } from 'react-i18next';

import SidePanelGalleryButton from './SidePanelGalleryButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TopologyRouterSelectors } from '../../store/router/topologyActions';

interface SidePanelSingleSelectProps {
    handleSeeSelectedPhotosClick: () => void;
    onDeleteClick: (persons: PersonDescriptor[]) => void;
    onDetailsClick: () => void;
    onMergeClick: () => void;
    onSplitClick: () => void;
    onAddLabelClick: () => void;
    onAddPersonToWatchlistClick: () => void;
    onEditPersonProperties: () => void;
    onExportToZip: () => void;
    showAddLabel: boolean;
    showAddPersonToWatchlist: boolean;
    showEditPerson: boolean;
    showExportToZip: boolean;
    showDetails: boolean;
    handleLabelClose: () => void;
    handleEditPersonClose: () => void;
    handleExportToZipProcessing: (handleExportToZipProcessing: ExportDialogMode) => void;
    handleExportToZipCancel: () => void;
    isExportToZipLoading: boolean;
    scrollToBottomRef: React.RefObject<HTMLDivElement>;
    onSaveOrCancelWatchlist: () => void;
    onCloseIndividualDetails: () => void;
    caseName: string;
}

const getSelectedPersonTooltip = (selectedPerson: PersonDescriptor, watchlistEntries: WatchlistEntry[]) => {
    if (!selectedPerson) {
        return '';
    }
    return authService.hasPermissions(Permissions.show_key_individuals_score)
        ? CommonService.getAllLabelsAndScoreForToolTip(selectedPerson.labels, selectedPerson.score, watchlistEntries)
        : CommonService.getAllLabelsForToolTip(selectedPerson.labels, watchlistEntries);
};

const SidePanelSingleSelect: React.FC<SidePanelSingleSelectProps> = (props) => {
    const { t } = useTranslation(['topologyView']);
    const seedIds: string[] = useSelector((state: RootState) => TopologyRouterSelectors.getSeedIds(state) || []);
    const selectedPerson: PersonDescriptor = useSelector((state: RootState) => state.topology.selectedConnection);
    const synapseMetadata: SynapseMetaData = useSelector((state: RootState) => state.topology.synapseMetadata);
    const batchId: string = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const watchlistEntries: WatchlistEntry[] = useSelector((state: RootState) => getFinishedWatchlists(state));
    const labelsText: string = getSelectedPersonTooltip(selectedPerson, watchlistEntries);

    const getAvatarTitle = () =>
        seedIds.includes(selectedPerson.personUID) ? t('synapse_toolbar.seed') : t('side_panel.selected_relationship');

    const handleDeleteClick = () => props.onDeleteClick([selectedPerson]);

    return (
        <div className='side-panel-section selected-connection-container'>
            <>
                <div className='side-panel-section-header avatar-title-text'>{getAvatarTitle()}</div>
                <Avatar
                    className='avatar bigAvatar'
                    imgProps={{}}
                    alt={labelsText}
                    title={labelsText}
                    src={`/api/photo/${selectedPerson.representingPhotoId}`}
                    style={{
                        borderColor: seedIds.includes(selectedPerson.personUID)
                            ? AvatarColor.seed
                            : AvatarColor.selectedConnection
                    }}
                />
                <div title={labelsText} className='label-name'>
                    {CommonService.getHighestPriority(selectedPerson.labels)}{' '}
                    {selectedPerson.labels &&
                        selectedPerson.labels.length > 1 &&
                        `+(${selectedPerson.labels.length - 1})`}
                </div>
            </>

            <Grid item style={{ flex: 1, marginTop: '4px' }}>
                <div style={{ width: 'fit-content', margin: '0 auto' }}>
                    {authService.hasPermissions(Permissions.show_gender) && selectedPerson.gender !== Gender.Unknown && (
                        <Typography>
                            {t('side_panel.gender')}:{' '}
                            {t(`gender_filter.${selectedPerson.gender}`, {
                                ns: 'filters'
                            })}
                        </Typography>
                    )}
                    {authService.hasPermissions(Permissions.show_age) && selectedPerson.age !== Age.Unknown && (
                        <Typography>
                            {t('side_panel.age')}:{' '}
                            {t(`age_filter.${selectedPerson.age}`, {
                                ns: 'filters'
                            })}
                        </Typography>
                    )}
                </div>
            </Grid>

            <SidePanelGalleryButton
                selectedPerson={selectedPerson}
                synapseMetadata={synapseMetadata}
                handleSeeSelectedPhotosClick={props.handleSeeSelectedPhotosClick}
                seedIds={seedIds}
            />

            <SelectedConnectionActions
                onDeleteClick={handleDeleteClick}
                onDetailsClick={props.onDetailsClick}
                onMergeClick={props.onMergeClick}
                onSplitClick={props.onSplitClick}
                onAddLabelClick={props.onAddLabelClick}
                onAddPersonToWatchlistClick={props.onAddPersonToWatchlistClick}
                onEditPersonProperties={props.onEditPersonProperties}
                onExportToZip={props.onExportToZip}
                isSeed={seedIds.includes(selectedPerson.personUID)}
                isGroup={synapseMetadata.isGroupTopology}
                selectedPersonFilteredPhotosCount={synapseMetadata.filteredSelectedPhotoUIDs.length}
            />
            {!!props.showAddLabel && <AddLabel person={selectedPerson} onSaveOrCancel={props.handleLabelClose} />}
            {!!props.showAddPersonToWatchlist && (
                <AddPersonToWatchlist
                    selectedPerson={selectedPerson}
                    watchlists={watchlistEntries}
                    onSaveOrCancel={props.onSaveOrCancelWatchlist}
                    caseName={props.caseName}
                />
            )}
            {!!props.showEditPerson && (
                <EditPerson
                    batchId={batchId}
                    selectedPerson={selectedPerson}
                    onSaveOrCancel={props.handleEditPersonClose}
                />
            )}
            {!!props.showExportToZip && (
                <ExportToZipDialog
                    onCancel={props.handleExportToZipCancel}
                    onClose={props.handleExportToZipProcessing}
                    isLoading={props.isExportToZipLoading}
                />
            )}
            {!!props.showDetails && (
                <IndividualDetails individual={selectedPerson} onClose={props.onCloseIndividualDetails} />
            )}
            <div ref={props.scrollToBottomRef} />
        </div>
    );
};

export default SidePanelSingleSelect;
