import React from 'react';
import { svgIcons } from '../../common/entities/enums';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables } from '../../styleVariables';
import { Colors } from '../../colors';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchTypes } from '../../advancedSearch/advancedSearch';
import { TopologyRouterSelectors } from '../../../store/router/topologyActions';

interface SearchResultsHeaderProps {
    onClose: () => void;
    noResults: boolean;
    title: string;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: StyleVariables.gap
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 0,
        marginBottom: 0
    },
    query: {
        fontSize: '16px',
        fontWeight: 700,
        color: Colors.lightGray,
        '&.centered': {
            textAlign: 'center',
            width: '100%'
        }
    },
    warning: {
        display: 'flex',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        fontSize: '14px',
        color: Colors.contrast,
        marginLeft: StyleVariables.gap * 2
    },
    warningIcon: {
        marginLeft: StyleVariables.gap / 2
    },
    close: {}
});

const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = (props) => {
    const { t } = useTranslation(['topologyView', 'common', 'advancedSearch']);
    const classes = useStyles();
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const isWarning = useSelector((state: RootState) => state.search.searchByTextImageResults?.exact);
    const similarImageRawQuery = useSelector(TopologyRouterSelectors.getSimilarImageQuery);
    const similarImageSearchType = similarImageRawQuery
        ?.split(t('similar_image_form.by', { ns: 'advancedSearch' }))[1]
        .trim()
        .toUpperCase();

    const renderSearchType = () => {
        return similarImageSearchType
            ? t('similar_image_form.types.' + similarImageSearchType, { ns: 'advancedSearch' })
            : t(`advancedSettings.${searchType}`, { ns: 'common' });
    };

    return (
        <div className={`search-results-header ${classes.root}`}>
            {props.noResults ? (
                <p className={`search-results-header-query centered ${classes.query}`}>
                    {t(`search_section_container.no_results_found`)}
                </p>
            ) : (
                <>
                    <p className={`search-results-header-container ${classes.container}`}>
                        <span className={`search-results-header-query ${classes.query}`}>
                            {t(`search_results_header.${searchType}_title`) + ' ' + renderSearchType()?.toLowerCase()}
                        </span>
                        {searchType === AdvancedSearchTypes.imageDescription && !isWarning && (
                            <span className={`search-results-header-warning ${classes.warning}`}>
                                {t('search_results_header.warning')}{' '}
                                <CustomSVGIcon
                                    customClass={`search-results-header-warning-icon ${classes.warningIcon}`}
                                    type={svgIcons.alert}
                                    fillColor='#C7A206'
                                    size={18}
                                    tooltip={t('search_results_header.warning_tooltip')}
                                />
                            </span>
                        )}
                    </p>
                    <CustomSVGIcon
                        onClick={props.onClose}
                        type={svgIcons.close}
                        size={24}
                        customClass={`search-results-header-close ${classes.close}`}
                    />
                </>
            )}
        </div>
    );
};

export default SearchResultsHeader;
