import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables, typographySubtitle } from '../styleVariables';
import { Colors } from '../colors';
import {
    changeEntityQuery,
    changeSearchQueryImageDescription,
    changeSearchQueryOperators,
    changeSearchQueryCommon,
    navigateSynapse,
    TopologyRouterSelectors
} from '../../store/router/topologyActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { svgIcons } from '../common/entities/enums';
import { changeSearchType, clearSearchResults, serverSearch } from '../../store/slices/searchSlice';
import Image2TextDropdown from './image2TextDropdown';
import MagnifyingGlass from '../../images/magnifyingGlass.svg';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchTypes } from './advancedSearch';
import Image2TextAdvancedSearchForm from './image2textAdvancedSearchForm';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative'
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingRight: StyleVariables.gap * 2,
        paddingLeft: StyleVariables.gap * 2,
        '& .MuiFormGroup-root': {
            height: '100%',
            alignItems: 'center'
        },
        border: `1px solid ${Colors.contrast}`,
        borderRadius: '4px'
    },
    label: {
        fontSize: 0
    },
    input: {
        ...typographySubtitle,
        color: Colors.white,
        height: '100%',
        lineHeight: '100%',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        border: 'none',
        outline: 'none',
        flexGrow: 1,
        '&::-webkit-input-placeholder': {
            color: Colors.contrast,
            paddingLeft: StyleVariables.gap * 4
        }
    },
    clear: {
        marginRight: StyleVariables.gap * 2,
        '&:hover': {
            fill: Colors.clickable
        }
    },
    advancedSearchButton: {
        marginLeft: StyleVariables.gap * 2,
        width: StyleVariables.gap * 20
    }
});

enum AdvancedSearchQueryTypes {
    ENTITY = 'entity',
    IMAGE_DESCRIPTION = 'imageDescription',
    OPERATORS = 'operators',
    SIMILAR_IMAGE = 'similarImage',
    OCR = 'ocr'
}

export enum DropdownImage2TextOperators {
    OR = 'OR',
    AND = 'AND',
    NOT = 'NOT'
}

export const ExpressionInput = 'expression';
const OperatorInput = 'operator';

export const AdvancedSearchImage2TextInputs = {
    expression1: `${ExpressionInput}1`,
    expression2: `${ExpressionInput}2`,
    expression3: `${ExpressionInput}3`,
    operator1: `${OperatorInput}1`,
    operator2: `${OperatorInput}2`
};

export const emptyAdvancedFormInputs = {
    [AdvancedSearchImage2TextInputs.expression1]: '',
    [AdvancedSearchImage2TextInputs.expression2]: '',
    [AdvancedSearchImage2TextInputs.expression3]: '',
    [AdvancedSearchImage2TextInputs.operator1]: DropdownImage2TextOperators.OR,
    [AdvancedSearchImage2TextInputs.operator2]: DropdownImage2TextOperators.NOT
};

const avoidElementNameWhenClickOutsidAdvancedForm = 'operator';

const Image2TextForm: React.FC = () => {
    const { t } = useTranslation(['advancedSearch', 'common']);
    const classes = useStyles();
    const dispatch = useDispatch();
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const searchQuery = useSelector(TopologyRouterSelectors.getImage2TextQuery);
    const topologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const batchId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const [inputValue, setInputValue] = useState(searchQuery?.imageDescriptions[0]);
    const [isRecentSearchExpanded, setRecentSearchExpanded] = useState(false);
    const [isTipsExpanded, setIsTipsExpanded] = useState(false);
    const [displayAdvancedSearchForm, setDisaplayAdvancedSearchForm] = useState(false);
    const wrapperRef = useRef(null);
    const [advanceSearchFormInputs, setAdvanceSearchFormInputs] = useState<Record<string, string>>(
        emptyAdvancedFormInputs
    );
    const isImage2TextExpanded = isRecentSearchExpanded || isTipsExpanded;

    useEffect(() => {
        if (searchType !== AdvancedSearchTypes.imageDescription) {
            dispatch(changeSearchType({ searchType: AdvancedSearchTypes.imageDescription }));
        }
        Object.keys(AdvancedSearchQueryTypes).forEach((type) => {
            if (type !== AdvancedSearchQueryTypes.IMAGE_DESCRIPTION) {
                dispatch(changeSearchQueryCommon(null, AdvancedSearchQueryTypes[type]));
            }
        });
    }, []);

    useEffect(() => {
        if (searchQuery?.imageDescriptions.length > 0) {
            dispatch(serverSearch(searchQuery, AdvancedSearchTypes.imageDescription));
        } else {
            clearSearch();
        }
    }, [searchQuery, topologyPhotos]);

    useOnClickOutside(wrapperRef, () => setDisaplayAdvancedSearchForm(false));

    const clearSearch = () => {
        dispatch(clearSearchResults());
        dispatch(changeSearchType({ searchType: AdvancedSearchTypes.imageDescription }));
        setInputValue('');
        dispatch(changeSearchQueryImageDescription(null));
        dispatch(changeSearchQueryOperators(null));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            clearSearch();
        } else {
            setInputValue(event.target.value);
        }
    };

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        onSubmit(inputValue);
    };

    const handleRecentSearchSelected = (value: string) => {
        setInputValue(value);
        setRecentSearchExpanded(false);
    };

    const onSubmit = (value: string) => {
        setRecentSearchExpanded(false);
        setIsTipsExpanded(false);
        dispatch(changeEntityQuery(null));
        if (value) {
            dispatch(
                navigateSynapse(
                    { batchId, tab: 'gallery' },
                    {
                        imageDescription: [value, '', ''],
                        operators: [DropdownImage2TextOperators.OR, DropdownImage2TextOperators.NOT]
                    }
                )
            );
        }
    };

    const handleClearInput = () => {
        clearSearch();
    };

    const handleDropdown = () => {
        setRecentSearchExpanded(!isTipsExpanded);
        setIsTipsExpanded(!isTipsExpanded);
    };

    return displayAdvancedSearchForm ? (
        <div ref={wrapperRef}>
            <Image2TextAdvancedSearchForm
                operatorClassName={avoidElementNameWhenClickOutsidAdvancedForm}
                onCloseButtonClickedCallback={() => setDisaplayAdvancedSearchForm(false)}
                onSubmitEndCallback={() => {
                    setDisaplayAdvancedSearchForm(false);
                }}
                formInputs={advanceSearchFormInputs}
                setFormInputs={setAdvanceSearchFormInputs}
            />
        </div>
    ) : (
        <div className={`image2text-form-container ${classes.root}`}>
            <form id='image2TextForm' className={`image2text-form ${classes.form}`} onSubmit={handleSubmit}>
                <label htmlFor='image2TextInput' className={`image2text-label ${classes.label}`}>
                    {t('advancedSettings.imageDescription', { ns: 'common' })}
                </label>
                <input
                    type='text'
                    id='image2TextInput'
                    name='image2TextInput'
                    value={inputValue || ''}
                    placeholder={t('image2text_form.placeholder')}
                    autoComplete='off'
                    maxLength={50}
                    onChange={handleInputChange}
                    onFocus={() => setRecentSearchExpanded(true)}
                    className={`image2text-input ${classes.input}`}
                    style={!inputValue ? { backgroundImage: `url(${MagnifyingGlass})` } : null}
                />
                {inputValue && (
                    <CustomSVGIcon
                        onClick={handleClearInput}
                        type={svgIcons.close}
                        size={22}
                        fillColor={Colors.white}
                        customClass={`ocr-clear ${classes.clear}`}
                    />
                )}

                <CustomSVGIcon
                    onClick={handleDropdown}
                    type={svgIcons.help}
                    size={22}
                    fillColor={Colors.white}
                    customClass={`ocr-clear ${classes.clear}`}
                />
                <Button type='submit' size='small' variant='contained' color='primary'>
                    {t('image2text_form.submit')}
                </Button>
                <Button
                    className={classes.advancedSearchButton}
                    onClick={() => {
                        setInputValue('');
                        setDisaplayAdvancedSearchForm(!displayAdvancedSearchForm);
                    }}
                    size='small'
                    variant='contained'
                    color='primary'>
                    {t('image2text_form.advanced_search')}
                </Button>
            </form>
            {isImage2TextExpanded && (
                <Image2TextDropdown
                    isRecentSearchExpanded={isRecentSearchExpanded}
                    inputValue={inputValue}
                    onSelected={handleRecentSearchSelected}
                    isTipsExpanded={isTipsExpanded}
                    onCloseTips={() => setIsTipsExpanded(false)}
                    onCloseRecentSearch={() => setRecentSearchExpanded(false)}
                />
            )}
        </div>
    );
};

export default Image2TextForm;
