import { green } from '@material-ui/core/colors';
import SnackbarContent, { SnackbarContentProps } from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import classnames from 'classnames';
import React from 'react';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles = makeStyles((theme: Theme) => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.background.paper,
        color: 'white'
    },
    warning: {
        backgroundColor: '#181818',
        color: '#ffa444'
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },

    message: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export interface Props extends SnackbarContentProps {
    className?: string;
    message: React.ReactNode;
    variant: any;
    hideIcon?: boolean;
}

const Message: React.FC<Props> = (props: Props) => {
    const classes = useStyles({});
    const { className, message, variant, hideIcon, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            style={{ padding: 0 }}
            className={classnames(classes[variant], className)}
            message={
                <span id='client-snackbar' className={classes.message}>
                    {!hideIcon && <Icon className={classnames(classes.icon, classes.iconVariant)} />}
                    {message}
                </span>
            }
            {...other}
        />
    );
};

export default Message;
