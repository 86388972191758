import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import CommonService from '../../services/commonService';
import { svgIcons } from '../common/entities/enums';
import { MenuOption } from '../common/menuActions';
import PopupImageSectionContainer, { Image } from '../popupImage/popupImageSectionContainer';
import VirtualScrollGallery, {
    VirtualScrollGalleryImage,
    VirtualScrollGalleryProps,
    VirtualScrollGalleryRef
} from '../virtualScrollGallery/virtualScrollGallery';
import './popupGallery.less';
import PopupGalleryHeader, { SearchOption } from './popupGalleryHeader';
import { useMemo } from 'react';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import GalleryService from '../../services/galleryService';
import OldVirtualScrollGallery from '../virtualScrollGallery/oldVirtualScrollGallery';
import { useTranslation } from 'react-i18next';
import Loader from 'react-spinners/CircleLoader';
import { Colors } from '../colors';
import { Coordinates, PersonDescriptor } from '../topologyview/VisionSynapse';
import NotificationDialog from '../dialogs/NotificationDialog';

export interface PopupGalleryContainerMenuAction extends Omit<MenuOption, 'onClick'> {
    onClick: (image: Image) => void;
}

export interface ImageToSplit {
    imageId: string;
    jfileId: string;
    facesCoordinates?: Coordinates[];
}

export interface PopupGalleryContainerProps {
    onDoubleClick?: (id: string) => void;
    onClose: () => void;
    images: Image[];
    currentImageId?: string;
    actions?: PopupGalleryContainerMenuAction[];
    onMultipleImagesSelected?: (imagesToSplit: ImageToSplit[]) => void;
    onFlagClick?: (imageId: string) => void;
    title: string;
    virtualScrollGalleryProps?: { ref?: React.ForwardedRef<VirtualScrollGalleryRef> } & Partial<
        VirtualScrollGalleryProps
    >;
    hideSearchByImageName?: boolean;
    imageCount?: number;
    personToSplit?: Nullable<PersonDescriptor>;
    disableSimilarImage?: boolean;
}

const convertImagesToVirtualScrollImages = (images: Image[]): VirtualScrollGalleryImage[] =>
    images.map((image) => ({
        imageId: image.id,
        imageUrl: image.thumbnail?.url || image.url,
        flagged: image.flagged,
        width: image.thumbnail?.width,
        height: image.thumbnail?.height
            ? GalleryService.getHeightWhileKeepingAspectRatio(image.thumbnail.width, image.thumbnail.height, 200)
            : null
    }));

const PopupGalleryContainer: React.FC<PopupGalleryContainerProps> = (props) => {
    const compName = 'popup-gallery';
    const { t } = useTranslation('popupGallery');
    const { images = [] } = props;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState<Image>(images[currentImageIndex]);
    const [currentImageId, setCurrentImageId] = useState(props.currentImageId || currentImage?.id);
    const [selectedImagesToSplit, setSelectedImagesToSplit] = useState<ImageToSplit[]>([]);
    const [showNotificationModal, setNotificationModal] = useState('');
    const allPhotosHaveThumbnail = useSelector((state: RootState) => state.topology.allPhotosHaveThumbnail);
    const isShowingLoader = useSelector((state: RootState) => state.search.isShowingLoader);
    const firstPagination = useSelector((state: RootState) => state.search.firstPagination);
    const extractJfileId = (url: string) => url.split('/').pop();
    const { imageTypes } = useSelector((state: RootState) => state.configurations.data);

    const rearrangeGallery = () => {
        // in first render after similar image trigger, the props.images will be empty array
        // in this case we do not want to forward to the next checks
        if (images.length === 0) {
            setCurrentImageIndex(0);
            return;
        }

        const matchingCurrentImageIndex = images.findIndex((photo) => photo.id === currentImageId);
        const isCurrentImageDeleted = matchingCurrentImageIndex < 0;
        if (isCurrentImageDeleted) {
            handleImageAfterDeleted();
        } else {
            focusImage(matchingCurrentImageIndex);
        }
    };

    useEffect(() => {
        rearrangeGallery();
    }, [images]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [images, currentImageIndex]);

    const handleKeyDown = (event: KeyboardEvent) => {
        const LEFT_KEY = 'ArrowLeft';
        const RIGHT_KEY = 'ArrowRight';
        const ESCAPE_KEY = 'Escape';

        switch (event.key) {
            case LEFT_KEY:
                goToPrevImage();
                break;
            case RIGHT_KEY:
                goToNextImage();
                break;
            case ESCAPE_KEY:
                props.onClose();
                break;

            default:
                break;
        }
    };

    const handleThumbnailClick = (imageId: string) => {
        setCurrentImageId(imageId);
        const imageIndex = images.findIndex((image) => image.id === imageId);
        focusImage(imageIndex);
    };

    const handleImageAfterDeleted = () => {
        const newImageIndex = currentImageIndex === 0 ? currentImageIndex + 1 : currentImageIndex - 1;
        setCurrentImageId(images[newImageIndex]?.id);
        focusImage(newImageIndex);
    };

    const focusImage = (imageIndex: number) => {
        setCurrentImage(images[imageIndex]);
        setCurrentImageIndex(imageIndex);
    };

    const handleGalleryCheckboxClicked = (imageId: string) => {
        let updatedImagesToSplit: ImageToSplit[];
        const thumbImageClicked = images.find((image) => image.id === imageId);
        const isThumbImageAlreadySelected = selectedImagesToSplit.find(
            (selectedImage) => selectedImage.imageId === imageId
        );

        if (isThumbImageAlreadySelected) {
            updatedImagesToSplit = selectedImagesToSplit.filter((selectedImage) => selectedImage.imageId !== imageId);
        } else {
            const personToSplitFacesCoordinates = thumbImageClicked?.shapes
                ?.filter((shape) => shape.id === props.personToSplit?.personUID)
                .map((shape) => shape.coordinates);
            let selectedImageToSplit: ImageToSplit = {
                imageId: imageId,
                jfileId: extractJfileId(thumbImageClicked.url)
            };

            if (personToSplitFacesCoordinates.length > 1) {
                selectedImageToSplit = { ...selectedImageToSplit, facesCoordinates: personToSplitFacesCoordinates };
            }
            updatedImagesToSplit = [...selectedImagesToSplit, selectedImageToSplit];
        }
        setSelectedImagesToSplit(updatedImagesToSplit);
    };

    const handleShapeClicked = (shapesSelected: Coordinates[]) => {
        const multiSelectedImageIdsFiltered = selectedImagesToSplit.filter(
            (includedPhoto) => includedPhoto.imageId !== currentImageId
        );
        let imagesToBeIncluded: ImageToSplit[];
        if (shapesSelected?.length > 0) {
            imagesToBeIncluded = [
                ...multiSelectedImageIdsFiltered,
                {
                    imageId: currentImageId,
                    jfileId: extractJfileId(currentImage.url),
                    facesCoordinates: shapesSelected
                }
            ];
        } else {
            imagesToBeIncluded = multiSelectedImageIdsFiltered;
        }
        setSelectedImagesToSplit(imagesToBeIncluded);
    };

    const goToPrevImage = useCallback(() => {
        if (currentImageIndex !== 0) {
            focusImage(currentImageIndex - 1);
        }
    }, [images, currentImageIndex]);

    const goToNextImage = useCallback(() => {
        if (currentImageIndex + 1 !== images.length) {
            focusImage(currentImageIndex + 1);
        }
    }, [images, currentImageIndex]);

    const handleFlagClicked = useCallback(() => props.onFlagClick(currentImage.id), [currentImage]);

    const optionalActions =
        props.actions?.map((action) => ({ ...action, onClick: () => action.onClick(currentImage) })) || [];

    const composeFileName = (name: string) => {
        if (imageTypes.includes(name.split('.').pop().toLowerCase())) {
            return name;
        } else {
            return name + '.jpg';
        }
    };

    const mandatoryActions = [
        {
            label: t('download_image'),
            onClick: () => CommonService.downloadURI(currentImage.url, composeFileName(currentImage.name)),
            icon: svgIcons.download
        }
    ];

    const menuActions: MenuOption[] = [...mandatoryActions, ...optionalActions];

    const searchOptions: SearchOption[] = useMemo(
        () => images.map((image, index) => ({ id: image.id, label: image.name, index })),
        [images]
    );

    const focusAndScrollToImage = (option: SearchOption) => {
        focusImage(option.index);
        setCurrentImageId(option.id);
    };

    const onSplit = () => {
        let notificationModalState = '';
        selectedImagesToSplit.forEach((imageToSplit) => {
            const selectedImage = images.find((image) => image.id === imageToSplit.imageId);
            const ifMultipleFacesOfSamePerson =
                selectedImage?.shapes?.filter((entityShape) => props.personToSplit?.personUID === entityShape.id)
                    .length > 1;
            if (ifMultipleFacesOfSamePerson && !imageToSplit.facesCoordinates) {
                notificationModalState = selectedImage.name;
                return;
            }
        });
        if (notificationModalState) {
            setNotificationModal(notificationModalState);
        } else {
            props.onMultipleImagesSelected(selectedImagesToSplit);
            setSelectedImagesToSplit([]);
        }
    };

    const numOfOptionalFacesToSelect = useMemo(() => {
        return images.reduce((total, image) => {
            return total + (image?.shapes?.filter(({ id }) => id === props.personToSplit?.personUID).length ?? 0);
        }, 0);
    }, [images, props.personToSplit]);

    const numOfSelectedPersonToSplitFaces = useMemo(() => {
        return selectedImagesToSplit.reduce((total, { facesCoordinates }) => {
            return total + (facesCoordinates ? facesCoordinates.length : 1);
        }, 0);
    }, [selectedImagesToSplit]);

    const isSplitDisable =
        numOfSelectedPersonToSplitFaces === 0 || numOfOptionalFacesToSelect === numOfSelectedPersonToSplitFaces;

    return (
        <>
            <Modal
                className={`${compName}-container dark-modal`}
                open={images.length >= 0}
                disableEscapeKeyDown
                disableRestoreFocus>
                <>
                    <div className={`${compName}-header`}>
                        <PopupGalleryHeader
                            title={props.title}
                            onClose={props.onClose}
                            onSearch={focusAndScrollToImage}
                            searchOptions={searchOptions}
                            hideSearch={props.hideSearchByImageName}
                        />
                    </div>

                    <div className={`${compName}-content`}>
                        {isShowingLoader && firstPagination ? (
                            <div className={`${compName}-virtual-scroll-gallery-container flex-center`}>
                                <Loader size={100} color={Colors.lightBlue} />
                            </div>
                        ) : (
                            images.length > 0 && (
                                <div className={`${compName}-virtual-scroll-gallery-container`}>
                                    {props.personToSplit && (
                                        <div className={`${compName}-split`}>
                                            <p className={`${compName}-split-title`}>{t('split_title')}</p>
                                            <p className={`${compName}-split-subtitle`}>
                                                {t('split_subtitle') +
                                                    ' ' +
                                                    (props.personToSplit.labels.length
                                                        ? props.personToSplit.labels[0].label
                                                        : t('this_individual'))}
                                            </p>
                                        </div>
                                    )}
                                    {allPhotosHaveThumbnail ? (
                                        <VirtualScrollGallery
                                            images={convertImagesToVirtualScrollImages(images)}
                                            focusedImageId={currentImageId}
                                            multiSelectedImageIds={selectedImagesToSplit}
                                            onClick={handleThumbnailClick}
                                            onMultiSelect={props.personToSplit ? handleGalleryCheckboxClicked : null}
                                            scrollToImageIndex={currentImageIndex}
                                            onFlagClick={props.onFlagClick}
                                            {...props.virtualScrollGalleryProps}
                                        />
                                    ) : (
                                        <OldVirtualScrollGallery
                                            images={convertImagesToVirtualScrollImages(images)}
                                            focusedImageId={currentImageId}
                                            multiSelectedImageIds={selectedImagesToSplit}
                                            onClick={handleThumbnailClick}
                                            onMultiSelect={props.personToSplit ? handleGalleryCheckboxClicked : null}
                                            scrollToImageIndex={currentImageIndex}
                                            onFlagClick={props.onFlagClick}
                                            {...props.virtualScrollGalleryProps}
                                        />
                                    )}
                                </div>
                            )
                        )}

                        {currentImage && (
                            <div className={`${compName}-image-container`}>
                                <PopupImageSectionContainer
                                    image={currentImage}
                                    imagesCount={props.imageCount || images.length}
                                    currentImageIndex={currentImageIndex}
                                    onPrev={goToPrevImage}
                                    onNext={goToNextImage}
                                    onClick={handleShapeClicked}
                                    onDoubleClick={props.onDoubleClick}
                                    onFlagClick={props.onFlagClick ? handleFlagClicked : undefined}
                                    actions={menuActions}
                                    showMultiSelectOptions={selectedImagesToSplit.length > 1}
                                    onSplit={props.personToSplit ? onSplit : null}
                                    personToSplitUId={props.personToSplit?.personUID}
                                    imagesToSplit={selectedImagesToSplit}
                                    disableSimilarImage={props.disableSimilarImage}
                                    disableSplitButton={isSplitDisable}
                                    multiSelectedImages={selectedImagesToSplit}
                                />
                            </div>
                        )}
                    </div>
                </>
            </Modal>
            <NotificationDialog
                open={!!showNotificationModal}
                onClose={() => setNotificationModal('')}
                title={t('select_some_face') + ' ' + showNotificationModal}
            />
        </>
    );
};

export default PopupGalleryContainer;
