export enum Permissions {
    show_key_individuals_score = 'vv_show_key_individuals_score',
    fc_customization = 'fc_customization',
    minimum_number_of_people = 'minimum_number_of_people',
    case_show_falcon = 'case_show_falcon',
    watchlist_show_falcon = 'watchlist_show_falcon',
    delete_individual = 'delete_individual',
    merge_individual = 'merge_individual',
    split_individual = 'split_individual',
    view_version = 'view_version',
    filter_unknown = 'filter_unknown',
    show_age = 'show_age',
    show_gender = 'show_gender',
    show_audit = 'show_audit',
    audit_admin = 'audit_admin',
    show_user_guide = 'show_user_guide',
    select_all_topologies = 'select_all_topologies',
    show_video_advanced_options = 'show_video_advanced_options',
    show_white_label = 'show_white_label',
    hide_logout = 'hide_logout',
    export_person_to_zip = 'export_person_to_zip',
    export_zip = 'export_zip',
    watchlist_sort = 'watchlist_sort',
    show_skynet_parsed_query = 'show_skynet_parsed_query',
    show_skynet_activation_button = 'show_skynet_activation_button',
    UM_users_tab = 'UM_users_tab',
    UM_system_tab = 'UM_system_tab',
    UM_groups_tab = 'UM_groups_tab',
    UM_roles_tab = 'UM_roles_tab',
    UM_show_all_groups = 'UM_show_all_groups',
    UM_show_users_with_admin_role = 'UM_show_users_with_admin_role',
    UM_show_admin_role = 'UM_show_admin_role',
    query_drag_and_drop = 'query_drag_and_drop',
    hide_cases_tab = 'hide_cases_tab'
}
