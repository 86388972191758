import GalleryService from '../../services/galleryService';
import { PhotoIdToAppearence, SynapsePhoto } from '../topologyview/VisionSynapse';
import { TopologyMetaData } from '../../store/slices/topologySlice';

// The appearences optional param used when we want to convert appearances outside of the current redux state.
export const synapsePhotoToImage = (
    synapsePhotos: SynapsePhoto[],
    topologyMetadata: TopologyMetaData,
    seedIds: string[],
    personUID?: string,
    appearances?: PhotoIdToAppearence
) => {
    return synapsePhotos.map((photo) => {
        const convertedAppearancesToShapes = GalleryService.convertAppearancesToShapes(
            appearances ? appearances[photo.photoUID] : topologyMetadata.photoUIDtoPhotoAppearances[photo.photoUID],
            topologyMetadata.personUIDtoPerson,
            seedIds,
            personUID
        );
        return {
            id: photo.photoUID,
            url: `/api/photo/${photo.jFileId}`,
            name: photo.customFileName || photo.fileName,
            imageSourceUrl: GalleryService.getImageSourceUrl(photo),
            shapes: convertedAppearancesToShapes,
            extraText: GalleryService.getPhotoDateText(photo.creationDate, photo.timestamp),
            metadata: GalleryService.getPhotoMetaData(photo),
            source: photo.photoType,
            flagged: photo.flagged,
            thumbnail: photo.thumbnails?.[0] ? GalleryService.convertThumbnail(photo.thumbnails[0]) : null,
            faceRecognitionEnabled: photo.faceRecognitionEnabled,
            image2TextEnabled: photo.image2TextEnabled,
            objectDetectionEnabled: photo.objectDetectionEnabled,
            ocrEnabled: photo.ocrEnabled,
            explicitContentEnabled: photo.explicitContentEnabled,
            landmarksEnabled: photo.landmarksEnabled,
            similarImagesEnabled: photo.similarImagesEnabled,
            detectedLandmarks: photo.detectedLandmarks
        };
    });
};
