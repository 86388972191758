import { Button, LinearProgress, SnackbarContent } from '@material-ui/core';
import { Field, Form, Formik, FormikErrors, FormikProps } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../store';
import { getFormattedImageTypes } from '../../../store/slices/configurationsSlice';
import { Consts } from '../../common/consts';
import { svgIcons } from '../../common/entities/enums';
import TextFormField from '../../common/formFields/TextFormField';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import FileAndFolderUpload from '../../common/fileAndFolderUpload';
import { BatchDescriptor } from '../../createTopologyPage/createTopologyService';
import DroneButton from '../../createTopologyPage/droneButton';
import { DroneData } from '../../../services/batchService';
import authService from '../../../services/authService';
import { Permissions } from '../../../shared/model/Permissions';
import CustomTooltip from '../../common/misc/CustomTooltip';
import { useTranslation } from 'react-i18next';

interface UploadWatchlistFormProps {
    isUploading: boolean;
    uploadProgress: number;
    error: string;
    isAddPhotos: boolean;
    initialValues: BatchDescriptor;
    onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onUpload: (formValues: BatchDescriptor, droneData: DroneData) => void;
}

const UploadWatchlistForm: React.FC<UploadWatchlistFormProps> = (props) => {
    const { t } = useTranslation('watchlists');
    const {
        fileUploadMaxMediaFilesPerZip,
        fileUploadMaxSizeInMegaBytes,
        maxWatchlistSizeInImages,
        maxFilesPerUpload,
        droneLink
    } = useSelector((state: RootState) => state.configurations.data);
    const formattedImageTypes = useSelector(getFormattedImageTypes);

    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const [droneData, setDroneData] = useState(null);
    const formikRef = useRef<FormikProps<BatchDescriptor>>(null);
    useEffect(() => {
        window.addEventListener('message', handleMessageReceived, false);
        return () => {
            window.removeEventListener('message', handleMessageReceived);
        };
    }, [droneLink]);

    const handleMessageReceived = (event: MessageEvent) => {
        if (event.origin === droneLink) {
            setDroneData(event.data);

            if (formikRef.current) {
                if (formikRef.current.values.files?.length > 0) {
                    formikRef.current.setFieldValue('files', null);
                }
                formikRef.current.setFieldError('files', null);
            }
        }
    };

    const getHeaderTooltipText = () =>
        t('upload_watchlist_form.max_images_per_watchlist') + ': ' + maxWatchlistSizeInImages?.toLocaleString();

    const getUploadMediaTooltip = () => (
        <ul>
            <li>{t('upload_watchlist_form.upload_media_tootip.upload_images')}</li>
            <li>
                {t('upload_watchlist_form.upload_media_tootip.max_size', {
                    fileUploadMaxSizeInGigaBytes: fileUploadMaxSizeInMegaBytes / 1000
                })}
            </li>
            <li>
                {t('upload_watchlist_form.upload_media_tootip.max_number_upload', {
                    maxFilesPerUpload: maxFilesPerUpload?.toLocaleString()
                })}
            </li>
            <li>
                {t('upload_watchlist_form.upload_media_tootip.max_number_zip', {
                    fileUploadMaxMediaFilesPerZip: fileUploadMaxMediaFilesPerZip?.toLocaleString()
                })}
            </li>
            <li>
                {t('upload_watchlist_form.upload_media_tootip.supported_graphic', {
                    formattedImageTypes: formattedImageTypes
                })}
            </li>
            <li>{t('upload_watchlist_form.upload_media_tootip.filename')}</li>
            <li>{t('upload_watchlist_form.upload_media_tootip.one_individual')}</li>
            <li>{t('upload_watchlist_form.upload_media_tootip.appear_once')}</li>
        </ul>
    );

    const validate = (values: BatchDescriptor) => {
        const errors: FormikErrors<BatchDescriptor> = {};

        if (!values.batchData.batchName && !props.isAddPhotos) {
            errors.batchData = {
                ...errors.batchData,
                batchName: t('upload_watchlist_form.watchlist_name_is_required')
            };
        }

        if (!droneData && !values.files) {
            errors.files = authService.hasPermissions(Permissions.watchlist_show_falcon)
                ? t('upload_watchlist_form.upload_or_bring_from_falcon')
                : t('upload_watchlist_form.upload_images_please');
        }

        return errors;
    };

    const getHeaderTitle = () => {
        let header = t('upload_watchlist_form.create_watchlist');
        if (props.isAddPhotos) {
            header = t('upload_watchlist_form.add_images_to_watchlist');
        }
        if (props.isUploading) {
            header = `${t('upload_watchlist_form.uploading_images')} (${props.uploadProgress || 0}%) ...`;
        }
        return header;
    };

    const disabledTooltipText = t('upload_watchlist_form.disabled_tooltip');

    return (
        <div className='scrollable-container' style={{ flex: 1 }}>
            <div className='create-watchlist-container'>
                <Formik<BatchDescriptor>
                    initialValues={props.initialValues}
                    enableReinitialize
                    //@ts-ignore
                    innerRef={formikRef}
                    onSubmit={(values) => props.onUpload(values, droneData)}
                    validate={validate}>
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form className='create-topology-form'>
                            <h4 className='flex-align-center'>
                                <span>{getHeaderTitle()}</span>
                                <CustomSVGIcon
                                    type={svgIcons.info}
                                    size={25}
                                    customStyle={{ margin: '0 10px' }}
                                    tooltip={getHeaderTooltipText()}
                                    tooltipProps={{ placement: 'right-start' }}
                                />
                            </h4>
                            {queryParams?.name && (
                                <b>
                                    {t('upload_watchlist_form.watchlist_name')}: {queryParams.name}
                                </b>
                            )}
                            <div style={{ display: 'inline-flex', flexDirection: 'column', overflowY: 'auto' }}>
                                {props.error && (
                                    <SnackbarContent
                                        style={{ marginTop: '1em', backgroundColor: '#d32f2f' }}
                                        message={props.error}
                                    />
                                )}
                                <div style={{ display: props.isUploading ? 'block' : 'none' }}>
                                    <LinearProgress
                                        className='query-progress'
                                        variant='determinate'
                                        value={props.uploadProgress}
                                    />
                                </div>
                                {!props.isAddPhotos && (
                                    <div style={{ width: 500, margin: '0 auto' }}>
                                        <Field
                                            inputProps={{ maxLength: 120 }}
                                            style={{ width: '100%', marginTop: '0' }}
                                            name='batchData.batchName'
                                            label={t('upload_watchlist_form.watchlist_name')}
                                            disabled={props.isUploading}
                                            component={TextFormField}
                                        />
                                    </div>
                                )}
                                <div className={`upload-card${errors.files && touched.files ? ' has-error' : ''}`}>
                                    <CustomTooltip title={!!droneData ? disabledTooltipText : ''}>
                                        <div style={{ width: 500, marginBottom: '20px' }}>
                                            <FileAndFolderUpload
                                                withVideo={false}
                                                multiple
                                                maxSizeInMegaBytes={fileUploadMaxSizeInMegaBytes}
                                                files={values.files}
                                                title={t('upload_watchlist_form.upload_images')}
                                                infoTooltipText={getUploadMediaTooltip()}
                                                onChange={(files) => {
                                                    setFieldValue('files', files);
                                                    if (!values.batchData.batchName) {
                                                        setFieldValue(
                                                            'batchData.batchName',
                                                            files[0].name.replace(Consts.regex.fileExtension, '')
                                                        );
                                                    }
                                                }}
                                                onRemove={() => setFieldValue('files', null)}
                                                disabled={props.isUploading || !!droneData}
                                            />
                                        </div>
                                    </CustomTooltip>

                                    {authService.hasPermissions(Permissions.watchlist_show_falcon) && (
                                        <div style={{ width: 500, marginBottom: '34px' }}>
                                            <div className='and-or'>{t('upload_watchlist_form.or')}</div>
                                            <CustomTooltip title={!!values.files ? disabledTooltipText : ''}>
                                                <div>
                                                    <DroneButton
                                                        disabled={!!values.files}
                                                        isConnected={!!droneData}
                                                        droneLink={droneLink + '/watchlist'}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        </div>
                                    )}
                                    {touched.files && (
                                        <small className='text-red create-topology-box-error'>{errors.files}</small>
                                    )}
                                </div>

                                <div style={{ width: 500, margin: '32px auto' }}>
                                    {props.isUploading && values.files ? (
                                        <Button
                                            fullWidth
                                            variant='outlined'
                                            color='primary'
                                            onClick={props.onCancel}
                                            disabled={!props.isUploading}>
                                            {t('upload_watchlist_form.cancel')}
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            type='submit'
                                            disabled={props.isUploading}
                                            color='primary'>
                                            {props.isAddPhotos
                                                ? t('upload_watchlist_form.add_images')
                                                : t('upload_watchlist_form.create')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default UploadWatchlistForm;
