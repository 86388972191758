import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import { Colors } from '../colors';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { useTranslation } from 'react-i18next';

export enum ExportDialogMode {
    All = 'all',
    Current = 'current'
}

interface ExportToZipDialogProps extends Omit<DialogProps, 'open'> {
    onClose: (result?: ExportDialogMode) => void;
    onCancel: () => void;
    isLoading?: boolean;
}

const ExportToZipDialog: React.FC<ExportToZipDialogProps> = ({ onClose, isLoading, onCancel, ...props }) => {
    const { t } = useTranslation('dialogs');
    const [exportTo, setExportTo] = useState(ExportDialogMode.Current);

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth='sm'
            open
            fullWidth
            {...props}
            onClose={() => onClose(null)}>
            <DialogTitle>{t('export_to_zip_dialog.export_to_zip')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('export_to_zip_dialog.export_from')}</DialogContentText>
                <RadioGroup onChange={(e) => setExportTo(e.target.value as ExportDialogMode)} value={exportTo}>
                    <FormControlLabel
                        value={ExportDialogMode.Current}
                        control={<Radio />}
                        label={t('export_to_zip_dialog.current_case_only')}
                    />
                    <FormControlLabel
                        value={ExportDialogMode.All}
                        control={<Radio />}
                        label={t('export_to_zip_dialog.all_cases')}
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} autoFocus onClick={() => onCancel()} color='primary'>
                    {t('cancel')}
                </Button>
                <Button
                    disabled={isLoading}
                    startIcon={<CustomSVGIcon type={svgIcons.export} size={20} fillColor={Colors.white} />}
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => onClose(exportTo)}
                    color='primary'
                    variant='contained'>
                    {isLoading && <CircularProgress style={{ marginRight: '5px' }} size={14} />}
                    <span>{t('export_to_zip_dialog.export')}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportToZipDialog;
