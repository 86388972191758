import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core';
import React from 'react';
import { Colors } from '../colors';

interface WhiteSelectProps {
    options: { id: number | string; name: string }[];
    value: any;
    onChange?(event: React.ChangeEvent<HTMLSelectElement>);
    onOpen?;
    onClose?;
    classes?: any;
}

const styles = () => ({
    select: {
        '&:before': {
            borderColor: 'white'
        },
        '&:after': {
            borderColor: 'white'
        }
    },
    root: {
        color: 'white',
        opacity: 0.6,
        fontSize: 13,
        fontWeight: 300
    },
    icon: {
        fill: Colors.clickable
    }
});

const WhiteSelect: React.FC<WhiteSelectProps> = (props) =>
    props.options?.length > 0 ? (
        <FormControl>
            <Select
                onChange={props.onChange}
                onOpen={props.onOpen}
                onClose={props.onClose}
                classes={props.classes}
                value={props.value || 0}>
                {props.options &&
                    props.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    ) : null;

export default withStyles(styles)(WhiteSelect);
