import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from '..';
import ApiService from '../../services/apiService';
import CommonService from '../../services/commonService';
import { SupportedLanguages } from '../../translations/languageSelect';

export interface Configurations {
    droneLink: string;
    fileUploadMaxMediaFilesPerZip: number;
    maxFilesPerUpload: number;
    recommendedVideoResolution: number;
    fileUploadMaxSizeInMegaBytes: number;
    fileUploadChunkSizeInMegaBytes: number;
    maxTopologySizeInImages: number;
    maxWatchlistSizeInImages: number;
    videoDefaultFramesPerMinute: number;
    defaultMaxPeopleInSynapse: number;
    compareMaxPhotos: number;
    imageTypes: string[];
    videoTypes: string[];
    faceMinWidthByPixels: string;
    faceMaxObscuredByPercentage: string;
    faceMaxRotatedByDegrees: string;
    objectFilters: string[];
    includeImage2TextDefaultValue: boolean;
    includeORDefaultValue: boolean;
    includeOCRDefaultValue: boolean;
    includeExplicitContentDefaultValue: boolean;
    includeLandmarksDefaultValue: boolean;
    advancedSettings: AdvancedSettings;
    supportedLanguages: SupportedLanguages[];
    googleApiKey: string;
    networksUrls: NetworksUrls;
    ssoConfigClient: SSOConfig;
}

interface NetworksUrls {
    facebook: string;
    tiktok: string;
    youtube: string;
}

export interface SSOConfig {
    sso_enabled: boolean | null;
    oidc_details_userNameKey: string;
    server_url: string;
    default_group: number;
}

export interface ConfigurationsState {
    data: Configurations;
    isLoading: boolean;
    error: string;
}

export interface AdvancedSettings {
    entityMatching: boolean;
    imageDescription: boolean;
    or: boolean;
    ocr: boolean;
    explicitContent: boolean;
    landmarks: boolean;
    similarImage: boolean;
}

const initialState: ConfigurationsState = {
    data: {
        imageTypes: [],
        videoTypes: [],
        droneLink: null,
        fileUploadMaxMediaFilesPerZip: null,
        maxFilesPerUpload: null,
        recommendedVideoResolution: null,
        fileUploadMaxSizeInMegaBytes: null,
        fileUploadChunkSizeInMegaBytes: null,
        maxTopologySizeInImages: null,
        maxWatchlistSizeInImages: null,
        videoDefaultFramesPerMinute: null,
        defaultMaxPeopleInSynapse: null,
        compareMaxPhotos: null,
        faceMinWidthByPixels: null,
        faceMaxObscuredByPercentage: null,
        faceMaxRotatedByDegrees: null,
        objectFilters: [],
        includeImage2TextDefaultValue: false,
        includeORDefaultValue: false,
        includeOCRDefaultValue: false,
        includeExplicitContentDefaultValue: false,
        includeLandmarksDefaultValue: false,
        advancedSettings: null,
        supportedLanguages: [],
        googleApiKey: '',
        networksUrls: {
            facebook: '',
            tiktok: '',
            youtube: ''
        },
        ssoConfigClient: {
            sso_enabled: null,
            oidc_details_userNameKey: '',
            server_url: '',
            default_group: null
        }
    },
    isLoading: false,
    error: ''
};

const configurationSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        getConfigurationsStart(state) {
            state.isLoading = true;
        },
        getConfigurationsSuccess(state, action: PayloadAction<Configurations>) {
            state.data = action.payload;
            state.isLoading = false;
        },
        getConfigurationsFailed(state, action: PayloadAction<AxiosError>) {
            state.error = CommonService.getErrorMessage(action.payload);
            state.isLoading = false;
        },
        getSsoConfigSuccess(state, action: PayloadAction<SSOConfig>) {
            state.data.ssoConfigClient = action.payload;
            state.isLoading = false;
        }
    }
});

export const getFormattedImageTypes = (state: RootState) => state.configurations.data.imageTypes.join('/');
export const getFormattedVideoTypes = (state: RootState) => state.configurations.data.videoTypes.join('/');

export const {
    getConfigurationsStart,
    getConfigurationsSuccess,
    getConfigurationsFailed,
    getSsoConfigSuccess
} = configurationSlice.actions;

export default configurationSlice.reducer;

export const fetchConfigurations = (): any => async (dispatch) => {
    try {
        dispatch(getConfigurationsStart());
        const response = await ApiService.fetchConfigurations();
        dispatch(getConfigurationsSuccess(response.data));
    } catch (err) {
        dispatch(getConfigurationsFailed(err));
    }
};

export const fetchSsoConfig = (): any => async (dispatch) => {
    try {
        dispatch(getConfigurationsStart());
        const response = await ApiService.fetchOktaConfig();
        dispatch(getSsoConfigSuccess(response.data));
    } catch (err) {
        dispatch(getConfigurationsFailed(err));
    }
};
