import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getFormattedImageTypes, getFormattedVideoTypes } from '../../store/slices/configurationsSlice';
import { useTranslation } from 'react-i18next';

interface CreationErrorTooltipInfoProps {
    isWatchlist: boolean;
    errors: string[];
}

export const shouldDisplayInfoTooltip = (errors: string[]) =>
    errors.includes('ERR_NO_SUPPORTED_FILES') ||
    errors.includes('ERR_NO_FACES_DETECTED') ||
    errors.includes('ERR_NO_VALID_PHOTO');

const CreationErrorTooltipInfo: React.FC<CreationErrorTooltipInfoProps> = (props) => {
    const formattedImageTypes = useSelector(getFormattedImageTypes);
    const formattedVideoTypes = useSelector(getFormattedVideoTypes);

    const { faceMinWidthByPixels, faceMaxObscuredByPercentage, faceMaxRotatedByDegrees } = useSelector(
        (state: RootState) => state.configurations.data
    );

    const { t } = useTranslation('topologiesView');

    const getInfoTooltip = (errors: string[]) => {
        const infoArray = [];
        if (errors.includes('ERR_NO_SUPPORTED_FILES')) {
            infoArray.push(`${t('creation_error_tooltip.supported_graphic_file_types')} ${formattedImageTypes}`);
            if (!props.isWatchlist) {
                infoArray.push(`${t('creation_error_tooltip.supported_video_file_types')} ${formattedVideoTypes}`);
            }
        } else if (errors.includes('ERR_NO_FACES_DETECTED')) {
            infoArray.push(
                t('creation_error_tooltip.valid_face_width', { faceMinWidthByPixels }),
                t('creation_error_tooltip.valid_face_obscured_percentage', { faceMaxObscuredByPercentage }),
                t('creation_error_tooltip.valid_face_rotation_degree', { faceMaxRotatedByDegrees })
            );
        } else if (errors.includes('ERR_NO_VALID_PHOTO')) {
            infoArray.push(
                t('creation_error_tooltip.each_image_one_face'),
                t('creation_error_tooltip.each_individual_appear_once')
            );
        }

        return infoArray;
    };
    return (
        <ul style={{ marginRight: '16px' }}>
            {getInfoTooltip(props.errors).map((info) => (
                <li key={info}>{info}</li>
            ))}
        </ul>
    );
};

export default CreationErrorTooltipInfo;
