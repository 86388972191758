import { Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { Colors } from '../colors';
import { svgIcons } from './entities/enums';
import Message from './message';
import CustomSVGIcon from './misc/CustomSvgIcon';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

export interface Props {
    errorMessage?: string;
    warnMessage?: React.ReactNode;
    warnItems?: string[];
    fileType?: string;
    disabled?: boolean;
    isLoading?: boolean;
    title: React.ReactNode;
    files: File[];
    totalFilesCount: number;
    multiple?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemove: () => void;
    infoTooltipText?: React.ReactNode;
}

const FileUpload: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation('common');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleInputFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleClose();
        props.onChange(event);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOneFile = props.files?.length === 1;

    const renderWarnList = () => {
        return (
            <div style={{ width: '100%' }}>
                <b>{props.warnMessage}</b>
                <ul style={{ maxHeight: '40px', overflowY: 'auto' }}>
                    {props.warnItems.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </div>
        );
    };

    const renderFileList = () => {
        return (
            <div style={{ width: '100%' }}>
                <b>{props.files.length} valid files</b>
                <ul style={{ maxHeight: '80px', overflowY: 'auto' }}>
                    {props.files.map((file) => (
                        <li key={file.name}>{file.name}</li>
                    ))}
                </ul>
            </div>
        );
    };

    const renderHiddenInput = () => (
        <>
            <input
                id='files'
                multiple={props.multiple}
                disabled={props.isLoading}
                type='file'
                style={{ display: 'none' }}
                accept={props.fileType}
                onChange={handleInputFileChanged}
            />
            <input
                id='folder'
                multiple={props.multiple}
                disabled={props.isLoading}
                type='file'
                style={{ display: 'none' }}
                accept={props.fileType}
                onChange={handleInputFileChanged}
                //@ts-ignore
                webkitdirectory=''
                //@ts-ignore
                directory=''
            />
        </>
    );

    const renderFileAndFolderMenu = () => (
        <Menu
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem style={{ width: '500px', fontSize: '17px' }} component='label' htmlFor='files'>
                <div className='flex-align-center'>
                    <CustomSVGIcon
                        customStyle={{ marginRight: '6px', opacity: '0.9' }}
                        size={24}
                        fillColor={Colors.white}
                        type={svgIcons.uploadFile}
                    />
                    {t('file_upload.files')}
                </div>
            </MenuItem>
            <MenuItem style={{ width: '500px', fontSize: '17px' }} component='label' htmlFor='folder'>
                <CustomSVGIcon
                    customStyle={{ marginRight: '6px', opacity: '0.9' }}
                    size={24}
                    fillColor={Colors.white}
                    type={svgIcons.uploadFolder}
                />
                {t('file_upload.folder')}
            </MenuItem>
        </Menu>
    );

    return (
        <>
            {props.files?.length > 0 ? (
                <Grid item container direction='row' justify='space-between' alignItems='center'>
                    {isOneFile && (
                        <Grid item style={{ display: 'flex' }}>
                            <Typography style={{ margin: 'auto', fontSize: 16 }}>
                                {t('file_upload.selected_file')}:
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography style={{ margin: 'auto', fontWeight: 'bold', fontSize: 16 }}>
                            {isOneFile
                                ? props.files[0]?.name
                                : `${props.totalFilesCount} ${t('file_upload.total_files')}`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' disabled={props.disabled} onClick={props.onRemove}>
                            {isOneFile ? t('file_upload.remove') : t('file_upload.remove_all')}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <>
                    {renderHiddenInput()}
                    <Button
                        component='label'
                        variant='outlined'
                        className={classnames('file-upload-button', { disabled: props.disabled })}
                        disabled={props.disabled || props.isLoading}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        style={{ width: '100%', height: '35px' }}>
                        {props.title}
                        {props.infoTooltipText ? (
                            <CustomSVGIcon
                                type={svgIcons.info}
                                size={18}
                                customStyle={{ margin: '0 10px' }}
                                tooltip={props.infoTooltipText}
                            />
                        ) : null}
                    </Button>
                    {renderFileAndFolderMenu()}
                </>
            )}
            {props.errorMessage && (
                <Message style={{ marginTop: '20px' }} variant='error' message={props.errorMessage} />
            )}
            {props.warnMessage && (
                <Message
                    style={{ marginTop: '20px' }}
                    variant='warning'
                    message={props.warnItems ? renderWarnList() : props.warnMessage}
                />
            )}
            {!isOneFile && props.files?.length > 0 && (
                <Message hideIcon style={{ marginTop: '20px' }} variant='info' message={renderFileList()} />
            )}
        </>
    );
};

export default React.memo(FileUpload);
