import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import classnames from 'classnames';

import './treeSelect.less';

interface TreeSelectProps<T extends string = string> {
    options: TreeSelectOption[];
    selectedOptions: T[];
    onChange: (selectedOptions: string[]) => void;
    className?: string;
}

export interface TreeSelectOption<T extends string = string> {
    id: T;
    name: string;
    isExpanded?: boolean;
    children?: Omit<TreeSelectOption, 'isExpanded'>[];
}

const TreeSelect: React.FC<TreeSelectProps> = (props) => {
    const getChildById = (option: TreeSelectOption, id: string) => {
        let array: string[] = [];

        const getAllChildren = (option: TreeSelectOption | null) => {
            if (option === null) {
                return [];
            }
            array.push(option.id);

            if (Array.isArray(option.children)) {
                option.children.forEach((childOption) => {
                    array = [...array, ...getAllChildren(childOption)];
                    array = array.filter((v, i) => array.indexOf(v) === i);
                });
            }
            return array;
        };

        const getOptionById = (option: TreeSelectOption, id: string): TreeSelectOption<string> => {
            if (option.id === id) {
                return option;
            } else if (Array.isArray(option.children)) {
                let result = null;
                option.children.forEach((childOption) => {
                    if (!!getOptionById(childOption, id)) {
                        result = getOptionById(childOption, id);
                    }
                });
                return result;
            }

            return null;
        };

        return getAllChildren(getOptionById(option, id));
    };

    const handleChange = (checked: boolean, option: TreeSelectOption) => {
        const childrenOption: string[] = getChildById(option, option.id);
        let selectedOptions = checked
            ? [...props.selectedOptions, ...childrenOption]
            : props.selectedOptions.filter((value) => !childrenOption.includes(value));

        selectedOptions = selectedOptions.filter((v, i) => selectedOptions.indexOf(v) === i);

        props.onChange(selectedOptions);
    };

    const renderOption = (option: TreeSelectOption) => {
        let atLeastOneChildIsChecked = false;
        let atLeastOneChildIsNotChecked = false;
        getChildById(option, option.id)
            .filter((child) => option.id !== child)
            .forEach((child) => {
                if (props.selectedOptions.includes(child)) {
                    atLeastOneChildIsChecked = true;
                } else {
                    atLeastOneChildIsNotChecked = true;
                }
            });

        const checked =
            option.children?.length > 0
                ? atLeastOneChildIsChecked
                : props.selectedOptions.some((item) => item === option.id);

        return (
            <TreeItem
                className={classnames('tree-item', { selected: checked })}
                key={option.id}
                nodeId={option.id}
                label={
                    <FormControlLabel
                        style={{ width: '100%' }}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(event) => handleChange(event.currentTarget.checked, option)}
                                indeterminate={atLeastOneChildIsChecked && atLeastOneChildIsNotChecked}
                                color='primary'
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        label={<>{option.name}</>}
                        key={option.id}
                    />
                }>
                {Array.isArray(option.children)
                    ? option.children.map((childOption) => renderOption(childOption))
                    : null}
            </TreeItem>
        );
    };

    return (
        <TreeView
            className={classnames('tree-view', props.className)}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={props.selectedOptions}
            expanded={props.options.filter((note) => note.isExpanded).map((note) => note.id)}>
            {props.options.map((option) => renderOption(option))}
        </TreeView>
    );
};

export default TreeSelect;
