import { Fab } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { RootState } from '../../store';
import {
    clearTopologies,
    fetchTopologies,
    getFinishedTopologies,
    getPendingTopologies,
    getProcessingTopologies,
    TopologyEntry,
    TopologyStatus
} from '../../store/slices/topologiesSlice';
import BatchDetailsModal from '../common/batchDetailsModal';
import DebouncedSearchInput from '../common/search/debouncedSearchInput';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import EditNameDialog from '../dialogs/editNameDialog';
import SingleProcessingListItem from './SingleProcessingListItem';
import SingleTopologyListItem from './SingleTopologyListItem';
import './topologies.less';
import ExportQueryModal from '../common/exportQueryModal/exportQueryModal';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../colors';
import { withTranslation, WithTranslation } from 'react-i18next';
import { sortingSingleListItem } from './sortingSingleListItem';

export enum TopologyModalType {
    Edit = 'Edit',
    Delete = 'Delete',
    Details = 'Details',
    Export = 'Export'
}

interface TopologiesViewContainerState {
    selectedTopology: TopologyEntry;
    isModalLoading: boolean;
    isSorting: boolean;
    currentModal: TopologyModalType | null;
    query: string;
}

interface TopologiesViewContainerProps extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {}

class TopologiesViewContainer extends Component<
    TopologiesViewContainerProps & WithTranslation,
    TopologiesViewContainerState
> {
    getTopologiesInterval: NodeJS.Timeout;
    getTopologiesIntervalMs = 1000 * 10;

    state: TopologiesViewContainerState = {
        selectedTopology: null,
        currentModal: null,
        isModalLoading: false,
        isSorting: false,
        query: ''
    };

    componentDidMount() {
        this.initializeTopologiesFetchingInterval();
    }

    componentWillUnmount() {
        this.props.clearTopologies();
        this.stopTopologiesInterval();
    }

    initializeTopologiesFetchingInterval = () => {
        this.props.fetchTopologies();

        this.getTopologiesInterval = setInterval(
            () => this.props.fetchTopologies(this.state.query),
            this.getTopologiesIntervalMs
        );
    };

    stopTopologiesInterval = () => {
        clearInterval(this.getTopologiesInterval);
    };

    handleEditName = (name?: string) => {
        if (name) {
            this.setState({ isModalLoading: true }, () =>
                ApiService.editTopologyName(this.state.selectedTopology.batchId, name)
                    .then(() => this.props.fetchTopologies(this.state.query))
                    .catch(() => toast.error('an error occurred while changing the topology name'))
                    .finally(() => this.setState({ selectedTopology: null, isModalLoading: false, currentModal: null }))
            );
        } else {
            this.closeModal();
        }
    };

    handleMoveQuery = async (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        this.stopTopologiesInterval();

        try {
            this.setState({ isSorting: true });
            await ApiService.sortWaitingExecutions(
                result.destination.index < result.source.index ? true : false,
                this.props.pendingTopologies[result.source.index].batchId,
                this.props.pendingTopologies[result.destination.index].batchId
            );
            this.setState({ isSorting: false });
        } catch (err) {
            console.error('Failed to sort pending cases', err);
            toast.error('Failed to sort pending cases');
        }

        this.initializeTopologiesFetchingInterval();
    };

    handleDeleteTopology = (result: boolean, shouldDeleteQuery: boolean) => {
        if (result) {
            const deleteProcessing =
                this.state.selectedTopology.status === TopologyStatus.Processing ||
                this.state.selectedTopology.status === TopologyStatus.Pending;

            this.setState({ isModalLoading: true }, () =>
                (deleteProcessing
                    ? ApiService.deleteProcessingTopology(this.state.selectedTopology.batchId, shouldDeleteQuery)
                    : ApiService.deleteTopology(this.state.selectedTopology.batchId)
                )
                    .catch(() => toast.error('an error occurred while deleting topology'))
                    .finally(() => this.setState({ selectedTopology: null, isModalLoading: false, currentModal: null }))
                    .then(() => this.props.fetchTopologies(this.state.query))
            );
        } else {
            this.closeModal();
        }
    };

    renderModals = () => {
        const isCurrentTopologyRunningAddMedia =
            this.state.selectedTopology?.metadata?.queryRunHistory?.length > 1 &&
            (this.state.selectedTopology.status === TopologyStatus.Processing ||
                this.state.selectedTopology.status === TopologyStatus.Pending);

        return (
            <>
                {this.state.currentModal === TopologyModalType.Delete && (
                    <ConfirmationDialog
                        open
                        onClose={(result) => this.handleDeleteTopology(result, !isCurrentTopologyRunningAddMedia)}
                        title={`${
                            isCurrentTopologyRunningAddMedia
                                ? this.props.t('results.confirm_delete_add_media_title')
                                : this.props.t('results.confirm_delete_title')
                        } "${this.state.selectedTopology?.name}"`}
                        description={
                            isCurrentTopologyRunningAddMedia
                                ? this.props.t('results.confirm_delete_add_media_text')
                                : this.props.t('results.confirm_delete_text')
                        }
                        isLoading={this.state.isModalLoading}
                    />
                )}
                {this.state.currentModal === TopologyModalType.Edit && (
                    <EditNameDialog
                        type='Case'
                        open
                        name={this.state.selectedTopology ? this.state.selectedTopology.name : ''}
                        onClose={this.handleEditName}
                        isLoading={this.state.isModalLoading}
                    />
                )}
                {this.state.currentModal === TopologyModalType.Details && (
                    <BatchDetailsModal open onClose={this.closeModal} batch={this.state.selectedTopology} />
                )}

                {this.state.currentModal === TopologyModalType.Export && (
                    <ExportQueryModal
                        isWatchlist={false}
                        onClose={this.closeModal}
                        query={this.state.selectedTopology}
                    />
                )}
            </>
        );
    };

    renderPendingTopologies = () => {
        return this.props.pendingTopologies?.length > 0 ? (
            <DragDropContext onDragEnd={this.handleMoveQuery}>
                <Droppable droppableId='list'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className='processing-container'>
                            {this.props.pendingTopologies.map((topology, index) => (
                                <SingleProcessingListItem
                                    query={this.state.query}
                                    data={topology}
                                    key={topology.batchId}
                                    isWatchlist={false}
                                    onDelete={() => this.showModal(TopologyModalType.Delete, topology)}
                                    onDetailsClick={() => this.showModal(TopologyModalType.Details, topology)}
                                    index={index}
                                    isDraggable={!this.state.isSorting}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : null;
    };

    renderProcessingTopologies = () =>
        this.props.processingTopologies?.length > 0 ? (
            <div className='processing-container'>
                {this.props.processingTopologies.map((topology, index) => (
                    <SingleProcessingListItem
                        query={this.state.query}
                        data={topology}
                        key={topology.batchId}
                        isWatchlist={false}
                        onDelete={() => this.showModal(TopologyModalType.Delete, topology)}
                        onDetailsClick={() => this.showModal(TopologyModalType.Details, topology)}
                        index={index}
                        isDraggable={false}
                    />
                ))}
            </div>
        ) : null;

    handleSelect = (topology: TopologyEntry) => this.props.push(`/case/${topology.batchId}/gallery`);

    showModal = (modalType: TopologyModalType, topology: TopologyEntry) =>
        this.setState({ selectedTopology: topology, currentModal: modalType });

    closeModal = () => this.setState({ selectedTopology: null, currentModal: null });

    handleAddToExisting = (topology: TopologyEntry) => this.props.push(`/add-media/${topology.batchId}`);

    renderFinishedTopologies = () => {
        const sortedTopologies = this.props.finishedTopologies.slice().sort(sortingSingleListItem);
        return (
            <div>
                <h4>
                    {this.props.t('results_title')} ({this.props.finishedTopologies.length})
                </h4>
                {sortedTopologies.map((topology) => (
                    <SingleTopologyListItem
                        query={this.state.query}
                        data={topology}
                        photosCount={this.props.topologyIdToPhotosCount[topology.batchId]}
                        isWatchlist={false}
                        onSelect={this.handleSelect}
                        onDelete={() => this.showModal(TopologyModalType.Delete, topology)}
                        onExport={() => this.showModal(TopologyModalType.Export, topology)}
                        onDetailsClick={() => this.showModal(TopologyModalType.Details, topology)}
                        onEdit={() => this.showModal(TopologyModalType.Edit, topology)}
                        onAddToExisting={this.handleAddToExisting}
                        key={topology.batchId}
                    />
                ))}
            </div>
        );
    };

    render() {
        return (
            <div className='scrollable-container' style={{ flex: 1 }}>
                {this.renderModals()}
                <div className='topologies-container'>
                    <div className='flex-align-center' style={{ marginTop: '16px', justifyContent: 'space-between' }}>
                        <Fab color='primary' size='small' aria-label='add' onClick={() => this.props.push(`/create`)}>
                            <CustomSVGIcon type={svgIcons.plus} size={24} fillColor='white' />
                        </Fab>
                        <DebouncedSearchInput
                            id='topologies-search'
                            delay={400}
                            onDebounced={(query) =>
                                this.props.fetchTopologies(query).then(() => this.setState({ query }))
                            }
                        />
                    </div>
                    {(this.props.processingTopologies?.length > 0 || this.props.pendingTopologies?.length > 0) && (
                        <h4>
                            {this.props.t('processing_title')} (
                            {this.props.processingTopologies.length + this.props.pendingTopologies.length})
                        </h4>
                    )}
                    <div>
                        {this.state.isSorting ? (
                            <>
                                <div
                                    style={{
                                        position: 'relative'
                                    }}>
                                    {this.renderPendingTopologies()}
                                    {this.renderProcessingTopologies()}
                                    <div className='clip-loader-wrapper'>
                                        <ClipLoader size={100} color={Colors.white}></ClipLoader>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>
                                {this.renderPendingTopologies()}
                                {this.renderProcessingTopologies()}
                            </div>
                        )}
                        {}
                    </div>
                    {this.state.query &&
                    this.props.pendingTopologies.length === 0 &&
                    this.props.processingTopologies.length === 0 &&
                    this.props.finishedTopologies.length === 0 ? (
                        <h4>no cases found</h4>
                    ) : (
                        this.renderFinishedTopologies()
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    pendingTopologies: getPendingTopologies(state),
    processingTopologies: getProcessingTopologies(state),
    finishedTopologies: getFinishedTopologies(state),
    topologyIdToPhotosCount: state.topologies.topologyIdToPhotosCount
});

const mapDispatchToProps = { push, fetchTopologies, clearTopologies };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('topologiesView')(TopologiesViewContainer));
