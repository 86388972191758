import { FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import React, { useState } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import ApiService from '../../../services/apiService';
import PaymentService from '../../../services/paymentService';
import { PaymentCounter, PaymentCounterType } from '../../../shared/serviceModels/paymentCounter';
import { Colors } from '../../colors';
import { UserManagementData } from '../usersManagmentContainer';
import RefundCounter from './refundCounters';
import UpdateCountersLimit from './updateCountersLimit';
import authService from '../../../services/authService';
import { useTranslation } from 'react-i18next';

interface EditGroupProps {
    managementData: UserManagementData;
    refreshManagementData: () => Promise<void>;
}

const EditGroup: React.FC<EditGroupProps> = (props) => {
    const { t } = useTranslation('usersManagement');
    const [selectedGroupId, setSelectedGroupId] = useState(0);
    const [selectedGroupCounters, setSelectedGroupCounters] = useState<Record<string, PaymentCounter>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const accessTokenData = authService.getAccessTokenData();

    const handleCurrentGroupChanged = (groupId: number) => {
        setSelectedGroupId(groupId);

        fetchAllCounters(groupId);
    };

    const fetchAllCounters = (groupId: number) => {
        const visionLongPromise = PaymentService.fetchVisionUsage(groupId);
        const falconLongPromise = PaymentService.fetchFalconLongUsage(groupId);
        const falconShortPromise = PaymentService.fetchFalconShortUsage(groupId);

        return Promise.all([visionLongPromise, falconLongPromise, falconShortPromise]).then(
            ([visionLong, falconLong, falconShort]) => {
                setSelectedGroupCounters({
                    [visionLong.data.counterName]: visionLong.data,
                    [falconLong.data.counterName]: falconLong.data,
                    [falconShort.data.counterName]: falconShort.data
                });
            }
        );
    };

    const handleGroupChange = async () => {
        try {
            if (parseFloat(accessTokenData?.lastUsedUserGroup) === selectedGroupId) {
                await ApiService.changeCurrentUserGroup(
                    props.managementData.groups[0].id !== selectedGroupId
                        ? props.managementData.groups[0].id.toString()
                        : props.managementData.groups[1].id.toString()
                );
            }
            const res = await authService.refreshToken(authService.getRefreshToken());
            authService.storeTokens(res.data);
            window.location.replace('/');
        } catch (error) {
            toast.error(t('edit_group.unable_change_group'));
        }
    };

    const deleteGroup = async () => {
        const confirmed = window.confirm(t('edit_group.confirm_delete_group') + ' "' + selectedGroupId + '"?');
        if (!confirmed) {
            return;
        }

        try {
            setIsLoading(true);
            await ApiService.deleteGroup(selectedGroupId);
            const deletedGroupName = props.managementData.groups.find((group) => group.id === selectedGroupId).name;
            toast.success(
                t('edit_group.group_is_deleted', {
                    selectedGroupId: selectedGroupId,
                    deletedGroupName: deletedGroupName
                })
            );
            await handleGroupChange();
            await props.refreshManagementData();
        } catch (error) {
            toast.error(t('edit_group.failed_delete_group'));
        } finally {
            setIsLoading(false);
        }
    };

    const resetDailyCounter = async () => {
        const confirmed = window.confirm(t('edit_group.confirm_reset_daily_counter'));
        if (!confirmed) {
            return;
        }
        try {
            setIsLoading(true);
            await fetchAllCounters(selectedGroupId);

            await PaymentService.updateFalconShortUsage(
                selectedGroupId,
                -selectedGroupCounters[PaymentCounterType.falconShort].usage
            );
        } catch (error) {
            toast.error(t('edit_group.failed_reset_daily_counter'));
        } finally {
            setIsLoading(false);
            fetchAllCounters(selectedGroupId);
        }
    };

    const resetYearlyCounters = async () => {
        const confirmed = window.confirm(t('edit_group.confirm_reset_yearly_counter'));
        if (!confirmed) {
            return;
        }
        try {
            setIsLoading(true);
            await fetchAllCounters(selectedGroupId);

            await Promise.all([
                PaymentService.updateVisionLongUsage(
                    selectedGroupId,
                    -selectedGroupCounters[PaymentCounterType.visionLong].usage
                ),

                PaymentService.updateFalconLongUsage(
                    selectedGroupId,
                    -selectedGroupCounters[PaymentCounterType.falconLong].usage
                )
            ]);
        } catch (error) {
            toast.error(t('edit_group.failed_reset_yearly_counter'));
        } finally {
            setIsLoading(false);
            fetchAllCounters(selectedGroupId);
        }
    };

    const refundFalconCounter = async (refundFalconAmount: number) => {
        const confirmed = window.confirm(
            t('edit_group.confirm_reset_falcon_counter', { refundFalconAmount: refundFalconAmount })
        );
        if (!confirmed) {
            return;
        }
        try {
            setIsLoading(true);
            await PaymentService.updateFalconLongUsage(selectedGroupId, -refundFalconAmount);
        } catch (error) {
            toast.error(t('edit_group.failed_reset_falcon_counter'));
        } finally {
            setIsLoading(false);
            fetchAllCounters(selectedGroupId);
        }
    };

    const refundVisionCounter = async (refundVisionAmount: number) => {
        const confirmed = window.confirm(
            t('edit_group.confirm_reset_vision_counter', { refundVisionAmount: refundVisionAmount })
        );
        if (!confirmed) {
            return;
        }
        try {
            setIsLoading(true);
            await PaymentService.updateVisionLongUsage(selectedGroupId, -refundVisionAmount);
        } catch (error) {
            toast.error(t('edit_group.failed_reset_vision_counter'));
        } finally {
            setIsLoading(false);
            fetchAllCounters(selectedGroupId);
        }
    };

    const updateDailyCounter = async (dailyCounterAmount: number) => {
        const confirmed = window.confirm(
            t('edit_group.confirm_reset_vision_counter', { dailyCounterAmount: dailyCounterAmount })
        );
        if (!confirmed) {
            return;
        }
        try {
            setIsLoading(true);
            await PaymentService.updateFalconShortLimit(selectedGroupId, dailyCounterAmount);
        } catch (error) {
            toast.error(t('edit_group.confirm_reset_vision_counter'));
        } finally {
            setIsLoading(false);
            fetchAllCounters(selectedGroupId);
        }
    };

    const updateYearlyCounters = async (yearlyCounterAmount: number) => {
        const confirmed = window.confirm(
            t('edit_group.confirm_reset_vision_counter', { yearlyCounterAmount: yearlyCounterAmount })
        );
        if (!confirmed) {
            return;
        }
        try {
            setIsLoading(true);
            await PaymentService.updateFalconLongLimit(selectedGroupId, yearlyCounterAmount);
            await PaymentService.updateVisionLongLimit(selectedGroupId, yearlyCounterAmount);
        } catch (error) {
            toast.error(t('edit_group.failed_update_yearly_counter'));
        } finally {
            setIsLoading(false);
            fetchAllCounters(selectedGroupId);
        }
    };

    const renderCurrentGroupCounters = () =>
        selectedGroupCounters ? (
            <div style={{ marginBottom: '36px' }}>
                Current Usages:
                {Object.values(selectedGroupCounters)?.map((group) => (
                    <span style={{ margin: '0 12px' }}>
                        {t('edit_group.counters.' + group.counterName)}: {group.usage.toLocaleString()}/
                        {group.limit.toLocaleString()}
                    </span>
                ))}
            </div>
        ) : null;

    return (
        <div className='edit-group-container'>
            <h4>{t('edit_group.edit_group')}</h4>
            <FormControl>
                <InputLabel className='users-management-label'>{t('edit_group.group')}: </InputLabel>
                <Select
                    value={selectedGroupId}
                    onChange={(event) => handleCurrentGroupChanged(event.target.value as number)}>
                    {props.managementData.groups
                        .sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))
                        .map((group) => (
                            <MenuItem value={group.id} key={group.id}>
                                {group.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            {selectedGroupId > 0 && (
                <div className='edit-user-form'>
                    {renderCurrentGroupCounters()}
                    <div className='counter-action-container'>
                        <strong style={{ marginRight: '4px' }}>{t('edit_group.reset_yearly_counter')}: </strong>
                        <Button onClick={resetDailyCounter} size='small' variant='contained' color='primary'>
                            {t('edit_group.reset_daily_counter')}
                        </Button>
                        <Button
                            style={{ marginLeft: '10px' }}
                            onClick={resetYearlyCounters}
                            size='small'
                            variant='contained'
                            color='primary'>
                            {t('edit_group.reset_yearly_counter')}
                        </Button>
                    </div>
                    <RefundCounter
                        refundFalconCounter={refundFalconCounter}
                        refundVisionCounter={refundVisionCounter}
                    />
                    <UpdateCountersLimit
                        updateDailyCounter={updateDailyCounter}
                        updateYearlyCounters={updateYearlyCounters}
                    />
                    <div className='counter-action-container'>
                        <Button
                            onClick={deleteGroup}
                            size='small'
                            variant='contained'
                            style={{
                                backgroundColor: Colors.error
                            }}>
                            {t('edit_group.delete_group')}
                        </Button>
                    </div>
                    {isLoading && <Loader color={Colors.white} size={20} />}{' '}
                </div>
            )}
        </div>
    );
};

export default EditGroup;
