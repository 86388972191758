import { useEffect, useRef, useState } from 'react';

const useStateWithCallback = <S>(initialState: S): [S, (state: S, cb?: (state?: S) => void) => void] => {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // mutable ref to store current callback

    const setStateCallback = (state: S, cb?: (state?: S) => void) => {
        cbRef.current = cb; // store passed callback to ref
        setState(state);
    };

    useEffect(() => {
        // cb.current is `null` on initial render, so we only execute cb on state *updates*
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null; // reset callback after execution
        }
    }, [state]);

    return [state, setStateCallback];
};
export default useStateWithCallback;
