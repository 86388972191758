import { Field, useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React from 'react';
import authService from '../../../services/authService';
import { Permissions } from '../../../shared/model/Permissions';
import { svgIcons } from '../../common/entities/enums';
import RadioGroupField from '../../common/formFields/RadioGroup';
import TextFormField from '../../common/formFields/TextFormField';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { BatchDescriptor } from '../createTopologyService';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../colors';

interface VideoSettingsFormikSectionProps {}

const VideoSettingsFormikSection: React.FC<VideoSettingsFormikSectionProps> = () => {
    const { t } = useTranslation('createTopology');
    const { values, setFieldValue, isSubmitting } = useFormikContext<BatchDescriptor>();

    useDidUpdateEffect(() => {
        setFieldValue('batchData.framesWithIndividualsOnly', values.batchData.advancedSettings.entityMatching);
    }, [values.batchData.advancedSettings.entityMatching]);

    return (
        <div className='video-settings'>
            <div className='slicing-section'>
                <div className='flex-align-center'>
                    <span>{t('video_settings_formik_section.title')}</span>
                    <CustomSVGIcon
                        size={20}
                        fillColor={Colors.lightGray}
                        customStyle={{ marginLeft: '8px' }}
                        type={svgIcons.info}
                        tooltip={
                            <ul>
                                <li>
                                    {t('video_settings_formik_section.tooltip.automatic')}
                                    <br />
                                    {t('video_settings_formik_section.tooltip.adjustable')}
                                </li>
                                <li>{t('video_settings_formik_section.tooltip.filter_frames')}</li>
                            </ul>
                        }
                    />
                </div>

                {authService.hasPermissions(Permissions.show_video_advanced_options) && (
                    <div>
                        <RadioGroupField
                            style={{ marginBottom: '15px' }}
                            name={`batchData.automaticSlicing`}
                            disabled={isSubmitting}
                            options={[
                                {
                                    value: 'true',
                                    label: t('video_settings_formik_section.automatic')
                                },
                                {
                                    value: 'false',
                                    label: t('video_settings_formik_section.adjustable')
                                }
                            ]}
                            value={values.batchData.automaticSlicing ? 'true' : 'false'}
                            onChange={(event, value) => {
                                setFieldValue('batchData.automaticSlicing', value === 'true');
                            }}
                        />
                    </div>
                )}
                {!values.batchData.automaticSlicing && (
                    <Field
                        style={{ width: '150px' }}
                        name='batchData.videoFramesPerMinute'
                        label={t('video_settings_formik_section.frames_per_minute')}
                        disabled={isSubmitting}
                        component={TextFormField}
                        type='number'
                        inputProps={{ step: '1', min: 1, max: 120 }}
                    />
                )}
            </div>
            <div className='filter-section'>
                <Field
                    component={CheckboxWithLabel}
                    type='checkbox'
                    name='batchData.framesWithIndividualsOnly'
                    Label={{ label: t('video_settings_formik_section.filter_out_frames_without_individuals') }}
                    disabled={!values.batchData.advancedSettings.entityMatching}
                />
            </div>
        </div>
    );
};

export default VideoSettingsFormikSection;
