import { FormControl, InputLabel, makeStyles, OutlinedInput } from '@material-ui/core';
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@material-ui/lab/Autocomplete';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer, List } from 'react-virtualized';
import { RootState } from '../../../store';
import { Colors } from '../../colors';
import Dropdown from '../dropdown/dropdown';
import TreeSelect from '../treeSelect/treeSelect';
import _ from 'lodash';
import './virtualizedAutocomplete.less';
import { svgIcons } from '../entities/enums';
import CustomSVGIcon from '../misc/CustomSvgIcon';

const useStyles = makeStyles({
    paper: {
        backgroundColor: Colors.black,
        color: '#bdbdbd',
        boxShadow: '2px 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: `solid 1px ${Colors.individualsOutlineGrey}`,
        borderRadius: '5px',

        '&:empty': {
            border: 'none'
        }
    },
    option: {
        height: '38px',
        color: '#bdbdbd',
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent'
        },
        '&[data-focus="true"]': {
            opacity: 0.7,
            backgroundColor: '#13212f',
            color: Colors.clickable,
            fontWeight: 'bold'
        }
    },
    listbox: {
        maxHeight: '80vh'
    }
});

interface ListboxComponentProps {
    hasTreeSelect: boolean;
}
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props: React.HTMLAttributes<HTMLElement> & ListboxComponentProps,
    ref: React.Ref<HTMLDivElement>
) {
    const { children, role, hasTreeSelect, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 38;

    const topologies = useSelector((state: RootState) => state.topologies.data);
    return (
        <div ref={ref}>
            {hasTreeSelect && (
                <Dropdown title='select cases'>
                    <div className='tree-select'>
                        <TreeSelect
                            options={[
                                {
                                    id: 'case',
                                    name: 'Cases',
                                    children: topologies.map((topology) => ({
                                        id: topology.batchId,
                                        name: topology.name
                                    })),
                                    isExpanded: true
                                }
                            ]}
                            selectedOptions={[]}
                            onChange={() => {}}
                        />
                    </div>
                </Dropdown>
            )}
            <div {...other}>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <List
                            height={300}
                            width={width}
                            rowHeight={itemSize}
                            overscanCount={5}
                            rowCount={itemCount}
                            rowRenderer={(props) => {
                                return React.cloneElement(children[props.index], {
                                    style: props.style
                                });
                            }}
                            role={role}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    );
});

interface VirtualizedAutocompleteProps<T extends { label: string } = any>
    extends Partial<AutocompleteProps<T, any, any, any>> {
    options: T[];
    isLoading?: boolean;
    inputLabel?: string;
    searchQuery?: string;
    iconType?: svgIcons;
    searchValue?: string;
}

const VirtualizedAutocomplete: React.FC<VirtualizedAutocompleteProps> = ({
    isLoading,
    inputLabel,
    searchQuery,
    iconType,
    ...props
}) => {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState(props.searchValue || '');

    useEffect(() => {
        setInputValue(props.searchValue || '');
    }, [props.searchValue]);

    const handleDebouncedInputChange = useCallback(
        _.debounce((event: React.ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
            props.onInputChange?.(event, value, reason);
        }, 300),
        []
    );

    return (
        <Autocomplete
            classes={{ paper: classes.paper, option: classes.option, listbox: classes.listbox }}
            openOnFocus
            id='virtualized-auto-complete'
            className='virtualized-auto-complete'
            disableListWrap
            getOptionLabel={(option) => option.label || ''}
            ListboxComponent={ListboxComponent}
            ListboxProps={{ hasTreeSelect: false }}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <FormControl size='small' style={{ width: '100%' }} disabled={isLoading} variant='outlined'>
                        <InputLabel style={{ fontSize: '14px', lineHeight: '16px' }} shrink={false} htmlFor='search'>
                            {!(params.inputProps as any).value && (
                                <div className='virtualized-auto-complete-label'>
                                    {iconType && <CustomSVGIcon type={iconType} fillColor={'currentColor'} size={24} />}
                                    {inputLabel}
                                </div>
                            )}
                        </InputLabel>
                        <OutlinedInput
                            type='search'
                            id='search'
                            value={searchQuery}
                            {...params.inputProps}
                            endAdornment={props.children}
                        />
                    </FormControl>
                </div>
            )}
            {...props}
            value={props.searchValue ?? inputValue}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
                if (reason !== 'reset' || value) {
                    setInputValue(value);

                    handleDebouncedInputChange(event, value, reason);
                }
            }}
        />
    );
};

export default VirtualizedAutocomplete;
