enum LocalStorageItems {
    language = 'i18nextLng'
}

const LocalStorageService = {
    getLanguage: () => {
        return localStorage.getItem(LocalStorageItems.language);
    },
    setLanguage: (value: string) => {
        return localStorage.setItem(LocalStorageItems.language, value);
    }
};

export default LocalStorageService;
