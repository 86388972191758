import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import history from './history';
import configurationsReducer from './slices/configurationsSlice';
import filtersReducer from './slices/filtersSlice';
import searchReducer from './slices/searchSlice';
import topologiesReducer from './slices/topologiesSlice';
import topologyReducer from './slices/topologySlice';
import usageReducer from './slices/usageSlice';
import watchlistsReducer from './slices/watchlistsSlice';

const rootReducer = combineReducers({
    usage: usageReducer,
    topology: topologyReducer,
    topologies: topologiesReducer,
    watchlists: watchlistsReducer,
    filters: filtersReducer,
    search: searchReducer,
    configurations: configurationsReducer,
    router: connectRouter(history)
});

export default rootReducer;
