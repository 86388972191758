import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LandmarksMap from './landmarksMap';
import { StyleVariables as St } from '../styleVariables';
import { Landmark } from '../topologyview/VisionSynapse';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface PopupImageLandmarksProps {
    detectedLandmarks: Landmark[];
}

const useStyles = makeStyles({
    landmarks: {
        display: 'flex',
        width: St.landmarks.boxWidth + St.gap * 2,
        height: '100%',
        marginTop: St.gap * 2,
        marginBottom: St.gap * 2,
        marginLeft: St.gap * 2,
        flexShrink: 0,
        overflow: 'auto'
    },
    container: {}
});

export const PopupImageLandmarks: React.FC<PopupImageLandmarksProps> = (props) => {
    const classes = useStyles();
    const { googleApiKey } = useSelector((state: RootState) => state.configurations.data);

    return (
        <div className={`landmarks ${classes.landmarks}`}>
            {googleApiKey && (
                <div className={`landmarks-container ${classes.container}`}>
                    {props.detectedLandmarks?.map((landmark, index) => (
                        <LandmarksMap
                            landmark={landmark}
                            index={index}
                            googleApiKey={googleApiKey}
                            key={landmark.mid + index}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default PopupImageLandmarks;
