export const sortingSingleListItem = (prev, next) => {
    const prevIsUpdated = prev.metadata?.queryRunHistory?.length > 1;
    const nextIsUpdated = next.metadata?.queryRunHistory?.length > 1;
    const prevTime = prevIsUpdated ? prev.updatedAt : prev.created;
    const nextTime = nextIsUpdated ? next.updatedAt : next.created;
    if (nextTime < prevTime) {
        return -1;
    }
    if (nextTime > prevTime) {
        return 1;
    }
    return 0;
};
