import {
    Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CompareApiResults } from './compareApiService';
import { useTranslation } from 'react-i18next';

interface CompareResultsProps {
    results: CompareApiResults;
}

interface TableData {
    fileA: string;
    photoA: string;
    fileB: string;
    photoB: string;
    score: number;
}

interface TableCellData {
    id: keyof TableData;
    label: string;
    show: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: Record<Key, number | string>, b: Record<Key, number | string>) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const getTableData = (results: CompareApiResults): TableData[] => {
    return results.compareResults.map(({ jfileids, score }) => ({
        score,
        fileA: results.jfileIdToFilename.get(jfileids.left),
        photoA: jfileids.left,
        fileB: results.jfileIdToFilename.get(jfileids.right),
        photoB: jfileids.right
    }));
};

const CompareResults: React.FC<CompareResultsProps> = (props) => {
    const { t } = useTranslation('compareApi');
    const [showPhotos, setShowPhotos] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof TableData>('score');
    const [tableData, setTableData] = useState<TableData[]>([]);

    useEffect(() => setTableData(getTableData(props.results)), [props.results]);

    const handleRequestSort = (property: keyof TableData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const headCells: TableCellData[] = [
        { id: 'fileA', label: t('compare_results.table_cells.file_a'), show: true },
        { id: 'photoA', label: t('compare_results.table_cells.photo_a'), show: showPhotos },
        { id: 'fileB', label: t('compare_results.table_cells.file_b'), show: true },
        { id: 'photoB', label: t('compare_results.table_cells.photo_b'), show: showPhotos },
        { id: 'score', label: t('compare_results.table_cells.score'), show: true }
    ];

    const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div style={{ width: '90%', margin: '0 auto' }}>
            <FormControlLabel
                control={<Checkbox checked={showPhotos} onChange={() => setShowPhotos(!showPhotos)} />}
                label={t('compare_results.table_cells.show_photos')}
            />
            <Paper style={{ width: '100%', margin: '10px auto' }}>
                <TableContainer style={{ maxHeight: '65vh' }}>
                    <Table stickyHeader aria-label='compare-results-table'>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) =>
                                    headCell.show ? (
                                        <TableCell
                                            key={headCell.id}
                                            sortDirection={orderBy === headCell.id ? order : false}>
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={() => handleRequestSort(headCell.id)}>
                                                {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : null
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort<TableData>(tableData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role='checkbox' tabIndex={-1} key={row.fileA + row.fileB}>
                                            <TableCell scope='row'>{row.fileA}</TableCell>
                                            {showPhotos && (
                                                <TableCell scope='row'>
                                                    <img
                                                        width='60px'
                                                        height='60px'
                                                        src={`/api/photo/${row.photoA}`}
                                                        alt={row.fileA}
                                                    />
                                                </TableCell>
                                            )}
                                            <TableCell>{row.fileB}</TableCell>
                                            {showPhotos && (
                                                <TableCell scope='row'>
                                                    <img
                                                        width='60px'
                                                        height='60px'
                                                        src={`/api/photo/${row.photoB}`}
                                                        alt={row.fileB}
                                                    />
                                                </TableCell>
                                            )}
                                            <TableCell>{row.score}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component='div'
                    count={props.results.compareResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};

export default CompareResults;
