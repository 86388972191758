import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { RootState } from '../../store';
import { updatePhotoByPhotoId } from '../../store/slices/topologySlice';
import { updateSearchPhotoByPhotoId } from '../../store/slices/searchSlice';
import { SynapsePhoto } from '../topologyview/VisionSynapse';
import { useTranslation } from 'react-i18next';

const useOptimisticFlagImage = () => {
    const { t } = useTranslation('errors');
    const topologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const searchPhotos = useSelector((state: RootState) => state.search.searchByTextImageResults?.data?.photos);

    const dispatch = useDispatch();

    const getUpdatedPhoto = (photos: SynapsePhoto[], imageId: string) => {
        const photoIndex = photos.findIndex((photo) => photo.photoUID === imageId);
        const photo = { ...photos[photoIndex] };
        photo.flagged = !photo.flagged;

        return photo;
    };
    const toggleImageFlag = (imageId: string) => {
        try {
            let photo = getUpdatedPhoto(topologyPhotos, imageId);
            dispatch(updatePhotoByPhotoId({ id: imageId, photo }));

            if (searchPhotos) {
                photo = getUpdatedPhoto(searchPhotos, imageId);
                dispatch(updateSearchPhotoByPhotoId({ id: imageId, photo }));
            }
            ApiService.updatePhoto(imageId, photo);
        } catch {
            const photo = getUpdatedPhoto(topologyPhotos, imageId);
            dispatch(updatePhotoByPhotoId({ id: imageId, photo }));
            dispatch(updateSearchPhotoByPhotoId({ id: imageId, photo }));

            toast.error(t('failed_flag_photo'));
        }
    };

    return toggleImageFlag;
};

export default useOptimisticFlagImage;
