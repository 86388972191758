import { Chip, Typography } from '@material-ui/core';
import React from 'react';
import VvAvatar from '../common/vvAvatar';
import VirtualAvatarList from './virtualAvatarList';
import { Label, LabelOrigin, PersonDescriptor } from './VisionSynapse';
import _ from 'lodash';
import { IndividualSortType } from './SidePanel';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    setIsMultipleSelectedConnectionsMode,
    onMultipleSelectAllClicked,
    resetAllSelectedConnections
} from '../../store/slices/topologySlice';
import { RootState } from '../../store';

export interface KeyIndividualsProps extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {
    personsInTopology: PersonDescriptor[];
    onDoubleClick(person: PersonDescriptor);
    doSelectPerson(person: PersonDescriptor, ctrlKey: boolean);
    individualSortType: IndividualSortType;
    resetViewMode: () => void;
}

export enum LabelFilter {
    watchlist = 'Watchlist',
    system = 'System',
    social = 'Social',
    none = 'None'
}

interface LabelFilterOption {
    type: LabelFilter;
    isActive: boolean;
    numberOfPeople: number;
}

export interface KeyIndividualsState {
    isShowingAll: boolean;
    labelFilters: LabelFilterOption[];
    topIndividuals: PersonDescriptor[];
}

class KeyIndividuals extends React.PureComponent<KeyIndividualsProps & WithTranslation, KeyIndividualsState> {
    peopleInRow = 5;

    constructor(props: KeyIndividualsProps & WithTranslation) {
        super(props);

        this.state = {
            isShowingAll: false,
            labelFilters: Object.values(LabelFilter).map((filter) => ({
                type: filter,
                isActive: true,
                numberOfPeople: this.getPeopleCount(props.personsInTopology, filter)
            })),
            topIndividuals: this.getSortedPersonsInTopology(props.personsInTopology).slice(0, this.peopleInRow * 2)
        };
    }

    componentDidUpdate(prevProps: Readonly<KeyIndividualsProps>) {
        if (!_.isEqual(this.props.personsInTopology, prevProps.personsInTopology)) {
            const labelFilters = this.state.labelFilters.map((filter) => ({
                ...filter,
                numberOfPeople: this.getPeopleCount(this.props.personsInTopology, filter.type)
            }));
            const topIndividuals = this.getSortedPersonsInTopology(this.props.personsInTopology).slice(
                0,
                this.peopleInRow * 2
            );
            this.setState({ labelFilters, topIndividuals });
        }
    }

    getSortedPersonsInTopology(personsInTopology: PersonDescriptor[]): PersonDescriptor[] {
        return this.props.individualSortType === IndividualSortType.Rank
            ? personsInTopology
            : personsInTopology.slice().sort((a, b) => b.batchIds.length - a.batchIds.length);
    }

    getSortedList(): PersonDescriptor[] {
        const list = this.getSortedPersonsInTopology(this.props.personsInTopology).filter((person) =>
            this.shouldFilterPersonByLabel(person.labels)
        );
        return this.state.isShowingAll ? list : list.slice(0, this.peopleInRow * 2);
    }

    getPeopleCount = (persons: PersonDescriptor[], filter: LabelFilter) => {
        return persons.filter((person) => {
            switch (filter) {
                case LabelFilter.none:
                    return person.labels?.length === 0;
                case LabelFilter.social:
                    return person.labels?.some((label) => label.origin === LabelOrigin.Social);
                case LabelFilter.watchlist:
                    return person.labels?.some((label) => label.origin === LabelOrigin.Watchlist);
                case LabelFilter.system:
                    return person.labels?.some((label) => label.origin === LabelOrigin.System);
                default:
                    return false;
            }
        }).length;
    };

    handleFilterClick = (filter: LabelFilter) => {
        const labelFilter = [...this.state.labelFilters];
        const index = labelFilter.findIndex(({ type }) => type === filter);
        labelFilter[index].isActive = !labelFilter[index].isActive;
        this.setState({ labelFilters: labelFilter });
    };

    shouldFilterPersonByLabel = (labels: Label[]) => {
        const { labelFilters } = this.state;
        if (labelFilters.find((filter) => filter.type === LabelFilter.none).isActive && labels?.length === 0) {
            return true;
        }
        return !!labels?.find((label) => {
            const socialOption = labelFilters.find(({ type }) => type === LabelFilter.social);
            const watchlistOption = labelFilters.find(({ type }) => type === LabelFilter.watchlist);
            const systemOption = labelFilters.find(({ type }) => type === LabelFilter.system);
            if (socialOption.isActive && socialOption.numberOfPeople && label.origin === LabelOrigin.Social) {
                return true;
            }
            if (watchlistOption.isActive && watchlistOption.numberOfPeople && label.origin === LabelOrigin.Watchlist) {
                return true;
            }
            if (systemOption.isActive && systemOption.numberOfPeople && label.origin === LabelOrigin.System) {
                return true;
            }
            return false;
        });
    };

    toggleShow = () => {
        this.setState((prevState) => ({ isShowingAll: !prevState.isShowingAll }));
    };

    renderPerson = (person: PersonDescriptor) => (
        <VvAvatar
            key={person.personUID}
            person={person}
            onClick={this.props.doSelectPerson}
            onDoubleClick={this.props.onDoubleClick}
        />
    );

    handleClearClicked = () => {
        this.props.resetAllSelectedConnections();
    };

    handleCancelClicked = () => {
        this.props.setIsMultipleSelectedConnectionsMode(false);
        this.props.resetAllSelectedConnections();
    };

    handleSelectMultipleClicked = () => {
        this.props.setIsMultipleSelectedConnectionsMode(true);
        this.props.resetAllSelectedConnections();
        this.props.resetViewMode();
    };

    render() {
        const maxRows = 4;

        return (
            <>
                <div className='key-individuals-top side-panel-header flex-justify-between'>
                    {this.state.isShowingAll
                        ? `${this.props.t('key_individuals.all_individuals_sorted')} (${
                              this.props.personsInTopology.length
                          })`
                        : `${this.props.t('key_individuals.top_individuals')} (${this.state.topIndividuals.length}/${
                              this.props.personsInTopology.length
                          })`}
                    <Typography className='text-button key-individuals-button' onClick={this.toggleShow}>
                        {this.state.isShowingAll
                            ? this.props.t('key_individuals.show_less')
                            : this.props.t('key_individuals.show_all')}
                    </Typography>
                </div>
                {this.state.isShowingAll && (
                    <>
                        <Typography color='textSecondary' className='side-panel-subheader'>
                            {this.props.t('key_individuals.labeled')}:
                        </Typography>
                        <div className='key-individuals-all'>
                            {this.state.labelFilters.map((filter) => (
                                <Chip
                                    key={filter.type}
                                    className='key-individuals-chip'
                                    clickable
                                    color='primary'
                                    disabled={!filter.numberOfPeople}
                                    onClick={() => this.handleFilterClick(filter.type)}
                                    variant={filter.isActive ? 'default' : 'outlined'}
                                    size='small'
                                    label={`${this.props.t('key_individuals.LabelFilter.' + filter.type)} ${
                                        filter.type === LabelFilter.none ? '' : ` (${filter.numberOfPeople})`
                                    }`}
                                />
                            ))}
                        </div>
                    </>
                )}
                {this.props.isMultipleSelectedConnectionsMode ? (
                    <div className='selected-buttons-wrapper'>
                        <Typography className='selected-button' onClick={this.props.onMultipleSelectAllClicked}>
                            {this.props.t('side_panel.select_all')}
                        </Typography>
                        <div className='clear-and-cancel-wrapper'>
                            <Typography className='selected-button' onClick={this.handleClearClicked}>
                                {this.props.t('side_panel.clear')}
                            </Typography>
                            <Typography className='selected-button cancel-button' onClick={this.handleCancelClicked}>
                                {this.props.t('side_panel.cancel')}
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <Typography className='selected-button' onClick={this.handleSelectMultipleClicked}>
                        {this.props.t('side_panel.select_multiple')}
                    </Typography>
                )}
                <VirtualAvatarList
                    maxRows={maxRows}
                    rowHeight={46}
                    doSelectPerson={this.props.doSelectPerson}
                    onDoubleClick={this.props.onDoubleClick}
                    persons={this.getSortedList()}
                />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    isMultipleSelectedConnectionsMode: state.topology.isMultipleSelectedConnectionsMode
});

const mapDispatchToProps = {
    setIsMultipleSelectedConnectionsMode,
    onMultipleSelectAllClicked,
    resetAllSelectedConnections
};

export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('topologyView')(KeyIndividuals))
);
