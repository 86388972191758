import { Colors } from './colors';

export const StyleVariables = {
    fontFamily: 'Roboto, sans-serif',
    sidebarWidth: 270,
    gapHalf: 4,
    gap: 8,
    gapLayoutHorizontal: 30,

    topbarHeight: 62,
    tabsHeight: 48,
    advancedSearchSize: 56,

    //landmarks
    landmarks: {
        boxWidth: 200,
        boxHeight: 164
    }
};

export const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const flexSpaceBetween = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
};

export const typographySubtitle = {
    fontFamily: StyleVariables.fontFamily,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
    color: Colors.white
};

export const typographySmall = {
    fontFamily: StyleVariables.fontFamily,
    fontSize: 13,
    fontWeight: 400,
    letterSpacing: '0.16px',
    color: Colors.white
};

export const typographyTiny = {
    fontFamily: StyleVariables.fontFamily,
    fontSize: 10,
    fontWeight: 500,
    color: Colors.contrast
};
