import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GalleryService from '../../../services/galleryService';
import { RootState } from '../../../store';
import { changeOcrQuery, TopologyRouterSelectors } from '../../../store/router/topologyActions';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useOptimisticFlagImage from '../../hooks/useOptimisticFlagImage';
import OldVirtualScrollGallery from '../../virtualScrollGallery/oldVirtualScrollGallery';
import VirtualScrollGallery, { VirtualScrollGalleryRef } from '../../virtualScrollGallery/virtualScrollGallery';
import SearchResultsHeader from './searchResultsHeader';
import { GalleryProps } from './galleryTab';

const AllImagesGallery: React.FC<GalleryProps> = (props: GalleryProps) => {
    const topologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const allPhotosHaveThumbnail = useSelector((state: RootState) => state.topology.allPhotosHaveThumbnail);
    const commonTopologies = useSelector(TopologyRouterSelectors.getCommonTopologies);
    const ocrQuery = useSelector(TopologyRouterSelectors.getOcrQuery);
    const dispatch = useDispatch();
    const virtualScrollGalleryRef = useRef<VirtualScrollGalleryRef>();

    const toggleImageFlag = useOptimisticFlagImage();

    useDidUpdateEffect(() => {
        virtualScrollGalleryRef.current?.scrollToTopAndResetGallery();
    }, [commonTopologies]);

    const virtualScrollGalleryPhotos = useMemo(
        () => GalleryService.convertToVirtualScrollGalleryPhotos(topologyPhotos),
        [topologyPhotos]
    );

    const handleVirtualScrollGalleryImageClicked = (id: string) => {
        props.onImageClicked(id);
    };

    const clearOcrQuery = useCallback(() => dispatch(changeOcrQuery('')), []);

    return (
        <>
            {(ocrQuery || topologyPhotos.length === 0) && (
                <SearchResultsHeader
                    onClose={clearOcrQuery}
                    noResults={topologyPhotos.length === 0}
                    title={props.title}
                />
            )}

            {allPhotosHaveThumbnail ? (
                <VirtualScrollGallery
                    onFlagClick={toggleImageFlag}
                    ref={virtualScrollGalleryRef}
                    onClick={handleVirtualScrollGalleryImageClicked}
                    gutterSize={16}
                    columnWidth={236}
                    focusedImageId={null}
                    images={virtualScrollGalleryPhotos}
                />
            ) : (
                <OldVirtualScrollGallery
                    onFlagClick={toggleImageFlag}
                    ref={virtualScrollGalleryRef}
                    onClick={handleVirtualScrollGalleryImageClicked}
                    gutterSize={16}
                    columnWidth={236}
                    focusedImageId={null}
                    images={virtualScrollGalleryPhotos}
                />
            )}
        </>
    );
};

export default AllImagesGallery;
