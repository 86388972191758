import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import PaymentService from '../../services/paymentService';
import { PaymentCounter } from '../../shared/serviceModels/paymentCounter';

export interface ImageCounter {
    limit: number;
    usage: number;
}

export interface UsageState {
    imageCounter: ImageCounter;
    isLoading: boolean;
    error: string;
}

const initialState: UsageState = {
    imageCounter: null,
    isLoading: false,
    error: ''
};

const usageSlice = createSlice({
    name: 'usage',
    initialState,
    reducers: {
        getUsageStart(state) {
            state.isLoading = true;
        },
        getUsageSuccess(state, action: PayloadAction<PaymentCounter>) {
            state.imageCounter = {
                limit: action.payload.limit,
                usage: action.payload.usage
            };
            state.isLoading = false;
        },
        getUsageFailed(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const { getUsageStart, getUsageSuccess, getUsageFailed } = usageSlice.actions;

export default usageSlice.reducer;

export const fetchUsages = (): any => async (dispatch) => {
    try {
        dispatch(getUsageStart());
        const response = await PaymentService.fetchVisionUsage(+authService.getAccessTokenData().lastUsedUserGroup);
        dispatch(getUsageSuccess(response.data));
    } catch (err) {
        dispatch(getUsageFailed(CommonService.getErrorMessage(err)));
    }
};
