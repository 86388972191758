import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogProps,
    Typography,
    Link
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../services/authService';
import { RootState } from '../../store';
import CommonService from '../../services/commonService';
import { Permissions } from '../../shared/model/Permissions';
import { TopologyRouterSelectors } from '../../store/router/topologyActions';
import VvAvatar from '../common/vvAvatar';
import { Label, LabelOrigin, PersonDescriptor } from './VisionSynapse';
import { fetchTopologies } from '../../store/slices/topologiesSlice';
import { fetchWatchlists } from '../../store/slices/watchlistsSlice';
import Loader from 'react-spinners/PulseLoader';
import { Colors } from '../colors';
import FullSizeRepresentingPhoto from '../common/fullSizeRepresentingPhoto';
import { useTranslation } from 'react-i18next';

interface IndividualDetailsProps {
    dialogProps?: Omit<DialogProps, 'open'>;
    enableShowFullSizeRepresentingPhoto?: boolean;
    individual: PersonDescriptor;
    onClose: () => void;
}

const IndividualDetails: React.FC<IndividualDetailsProps> = (props) => {
    const { t } = useTranslation(['topologyView', 'filters']);
    const dispatch = useDispatch();
    const [showFullSizeRepresentingPhoto, setShowFullSizeRepresentingPhoto] = useState(false);
    useEffect(() => {
        dispatch(fetchTopologies());
        dispatch(fetchWatchlists());
    }, []);

    const topologyIdToTopologyName = useSelector((state: RootState) => state.topology.queryIdToTopologyName);
    const watchlistIdToTopologyName = useSelector((state: RootState) => state.watchlists.watchlistIdToWatchlistName);
    const watchlists = useSelector((state: RootState) => state.watchlists.data);
    const currentQueryId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));

    const userWatchlistIds = props.individual.batchIds.filter((batchId) => !!watchlistIdToTopologyName?.[batchId]);
    const userTopologyIds = props.individual.batchIds.filter((batchId) => !!topologyIdToTopologyName?.[batchId]);

    const renderTopologies = () =>
        userTopologyIds.length > 0 ? (
            <Typography component='span'>
                {t('individual_details.cases')}({userTopologyIds.length}):
                <ul>
                    {userTopologyIds
                        .sort((topologyId) => (topologyId === currentQueryId ? -1 : 0))
                        .map((topologyId) => {
                            const isCurrent = currentQueryId === topologyId;
                            return (
                                <li key={topologyId}>
                                    <Link
                                        style={{ fontWeight: isCurrent ? 'bold' : 'normal' }}
                                        href={`/case/${topologyId}/relationships?sid=${props.individual.personUID}`}>
                                        {topologyIdToTopologyName[topologyId]}
                                        {isCurrent ? ` (${t('individual_details.current')})` : ''}
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
            </Typography>
        ) : null;

    const renderWatchlists = () =>
        userWatchlistIds.length > 0 && (
            <Typography component='span'>
                {t('individual_details.watchlists')}({userWatchlistIds.length}):
                <ul>
                    {userWatchlistIds
                        .sort((watchlistId) => (watchlistId === currentQueryId ? -1 : 0))
                        .map((watchlistId) => {
                            const isCurrent = currentQueryId === watchlistId;

                            return (
                                <li key={watchlistId}>
                                    <Link
                                        style={{ fontWeight: isCurrent ? 'bold' : 'normal' }}
                                        href={`/watchlist/${watchlistId}`}>
                                        {watchlistIdToTopologyName[watchlistId]}
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
            </Typography>
        );

    const renderLabels = () => {
        if (props.individual.labels?.length === 0) {
            return null;
        }

        const socialLabels = props.individual.labels.filter((label) => label.origin === LabelOrigin.Social);
        const watchlistLabels = props.individual.labels.filter((label) => label.origin === LabelOrigin.Watchlist);
        const systemLabels = props.individual.labels.filter((label) => label.origin === LabelOrigin.System);
        return (
            <Typography component='span'>
                {t('individual_details.labels')}:
                <ul>
                    {getLabelsList(t('individual_details.labels_list.watchlists'), watchlistLabels)}
                    {getLabel(t('individual_details.labels_list.system'), systemLabels[0])}
                    {getLabelsList(t('individual_details.labels_list.social'), socialLabels)}
                </ul>
            </Typography>
        );
    };

    const getLabelsList = (originText: string, labels: Label[]) =>
        labels.length > 0 ? (
            <li>
                {originText}:
                <ul>
                    {labels.map((label) => (
                        <li key={label.label + label.origin}>
                            {CommonService.getLabelOriginText(label, watchlists)}: {label.label}{' '}
                            {renderLabelSource(label)}
                        </li>
                    ))}
                </ul>
            </li>
        ) : null;

    const getLabel = (originText: string, label: Label) =>
        label && (
            <li>
                {originText}: {CommonService.getLabelOriginText(label, watchlists)}: {label.label}{' '}
                {renderLabelSource(label)}
            </li>
        );

    const renderLabelSource = (label: Label) =>
        label.metadata?.source && (
            <span>
                (
                {label.metadata?.source?.toLowerCase() === 'profile photo' ||
                label.metadata?.source?.toLowerCase() === 'profile image' ? (
                    t('individual_details.source_profile_image')
                ) : (
                    <Link target='_blank' rel='noreferrer' href={label.metadata?.source}>
                        {t('individual_details.source')}
                    </Link>
                )}
                )
            </span>
        );

    return (
        <Dialog maxWidth='sm' open onClose={props.onClose} fullWidth {...props.dialogProps}>
            <DialogTitle>{t('individual_details.title')}</DialogTitle>

            <DialogContent>
                {!watchlists || !topologyIdToTopologyName ? (
                    <div className='flex-center'>
                        <Loader size={10} color={Colors.white}></Loader>
                    </div>
                ) : (
                    <>
                        <VvAvatar
                            onClick={() => {
                                if (props.enableShowFullSizeRepresentingPhoto) {
                                    setShowFullSizeRepresentingPhoto(true);
                                }
                            }}
                            style={{ marginBottom: '16px' }}
                            person={props.individual}
                        />

                        {authService.hasPermissions(Permissions.show_age) && (
                            <Typography>
                                {t('individual_details.age')}:{' '}
                                {t(`age_filter.${props.individual.age}`, { ns: 'filters' })}
                            </Typography>
                        )}
                        {authService.hasPermissions(Permissions.show_gender) && (
                            <Typography>
                                {t('individual_details.gender')}:{' '}
                                {t(`gender_filter.${props.individual.gender}`, { ns: 'filters' })}
                            </Typography>
                        )}
                        {renderTopologies()}
                        {renderWatchlists()}
                        {renderLabels()}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color='primary' autoFocus>
                    {t('individual_details.ok')}
                </Button>
            </DialogActions>
            {showFullSizeRepresentingPhoto && (
                <FullSizeRepresentingPhoto
                    onClose={() => setShowFullSizeRepresentingPhoto(false)}
                    individual={props.individual}
                    queryId={currentQueryId}
                />
            )}
        </Dialog>
    );
};

export default IndividualDetails;
