import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables, typographySubtitle, flexSpaceBetween } from '../styleVariables';
import { Colors } from '../colors';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import dict from './image2TextDictionary.json';
import { useTranslation } from 'react-i18next';

interface SearchTipsProps {
    onClose: () => void;
}

interface TipGroup {
    key: string;
    isColored: boolean;
    group: { subgroup: string; list: string[] }[];
}

interface ColorsList {
    key: string;
    list: { name: string; color: string }[];
}

const listItemHeight = 40;

const useStyles = makeStyles({
    root: {
        fontSize: 14,
        paddingTop: StyleVariables.gap * 2,
        paddingRight: StyleVariables.gap * 2,
        paddingBottom: StyleVariables.gap * 2,
        paddingLeft: StyleVariables.gap * 2,
        overflowY: 'hidden',
        '.recent-search:not(.hidden) ~ &': {
            borderTop: `1px solid ${Colors.contrast}`
        }
    },
    header: {
        ...flexSpaceBetween,
        ...typographySubtitle,
        textTransform: 'none',
        color: Colors.contrast,
        height: `${listItemHeight}px`,
        lineHeight: `${listItemHeight}px`,
        marginTop: 0,
        marginBottom: 0
    },
    close: {
        fill: Colors.contrast,
        '&:hover': {
            fill: Colors.white,
            opacity: 0.8
        }
    },
    container: {
        maxHeight: 350,
        paddingRight: StyleVariables.gap * 2,
        overflowY: 'auto'
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse'
    },
    row: {
        '& ~ &': {
            borderTop: `1px solid ${Colors.contrast}`
        }
    },
    colorsRow: {
        '& .search-tips-icon': {
            paddingBottom: 0
        }
    },
    icon: {
        verticalAlign: 'top',
        paddingTop: StyleVariables.gap * 2 - 2,
        paddingRight: StyleVariables.gap * 2,
        paddingBottom: StyleVariables.gap * 2
    },
    group: {
        fontWeight: 700,
        padding: StyleVariables.gap * 2,
        verticalAlign: 'top'
    },
    subgroup: {
        paddingLeft: StyleVariables.gap * 2
    },
    line: {
        display: 'flex',
        marginTop: StyleVariables.gap * 2,
        marginBottom: StyleVariables.gap * 2
    },
    color: {
        display: 'inline-block',
        textTransform: 'capitalize',
        height: 24,
        lineHeight: '22px',
        marginBottom: StyleVariables.gap,
        paddingRight: StyleVariables.gap,
        paddingLeft: StyleVariables.gap,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '100px',
        '& ~ &': {
            marginLeft: StyleVariables.gap
        }
    },
    title: {
        width: '100px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    keyword: {
        color: Colors.contrast,
        '& ~ &:before': {
            content: '" / "',
            display: 'inline'
        }
    },
    isColored: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: StyleVariables.gap * 2,
        paddingBottom: StyleVariables.gap * 2,
        borderTop: `1px solid ${Colors.transparentContrast}`,
        '& .search-tips-isColored-icon': {
            marginRight: StyleVariables.gap
        }
    }
});

export const SearchTips: React.FC<SearchTipsProps> = (props) => {
    const { t } = useTranslation('advancedSearch');
    const classes = useStyles();
    const keywords: TipGroup[] = dict.keywords;
    const colors: ColorsList = dict.colors;

    return (
        <div className={`search-tips ${classes.root}`}>
            <h5 className={`search-tips-header ${classes.header}`}>
                {t('search_tips')}:
                <CustomSVGIcon
                    onClick={props.onClose}
                    type={svgIcons.close}
                    size={22}
                    fillColor={Colors.white}
                    customClass={`search-tips-close ${classes.close}`}
                />
            </h5>
            <div className={`search-tips-container ${classes.container}`}>
                <table className={`search-tips-table ${classes.table}`}>
                    <tr className={`search-tips-row search-tips-colors-row ${classes.row} ${classes.colorsRow}`}>
                        <td className={`search-tips-icon ${classes.icon}`}>
                            <CustomSVGIcon type={svgIcons.colors} size={24} fillColor={Colors.white} />
                        </td>
                        <td className={`search-tips-group ${classes.group}`}>{t('colors.key')}</td>
                        <td className={`search-tips-subgroup search-tips-colors ${classes.subgroup}`}>
                            {colors.list.map((color) => (
                                <span
                                    className={`search-tips-color ${classes.color}`}
                                    style={{ color: color.color, borderColor: color.color }}>
                                    {t(`colors.colors_options.${color.name}`)}
                                </span>
                            ))}
                        </td>
                    </tr>

                    {keywords.map((tip) => {
                        const tipKey = tip.key;
                        return (
                            <tr className={`search-tips-row ${classes.row}`} key={tip.key}>
                                <td className={`search-tips-icon ${classes.icon}`}>
                                    <CustomSVGIcon
                                        type={svgIcons[tip.key.toLocaleLowerCase()]}
                                        size={24}
                                        fillColor={Colors.white}
                                    />
                                </td>
                                <td className={`search-tips-group ${classes.group}`}>{t(`${tipKey}.key`)}</td>
                                <td className={`search-tips-subgroup ${classes.subgroup}`}>
                                    {tip.group.map((line) => {
                                        const subgroup = `group.subgroup_${line.subgroup.split(' ').join('_')}`;
                                        return (
                                            <dl className={`search-tips-line ${classes.line}`}>
                                                <dt className={`search-tips-title ${classes.title}`}>
                                                    {t(`${tipKey}.${subgroup}.key`)}:
                                                </dt>

                                                <dd className='search-tips-list'>
                                                    {line.list.map((keyword) => {
                                                        return (
                                                            <span className={`search-tips-keyword ${classes.keyword}`}>
                                                                {t(`${tipKey}.${subgroup}.list.${keyword}`)}
                                                            </span>
                                                        );
                                                    })}
                                                </dd>
                                            </dl>
                                        );
                                    })}
                                    {tip.isColored && (
                                        <div className={`search-tips-isColored ${classes.isColored}`}>
                                            <CustomSVGIcon
                                                type={svgIcons.colors}
                                                size={22}
                                                fillColor={Colors.white}
                                                customClass='search-tips-isColored-icon'
                                            />
                                            {t('include_colors')}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
};

export default SearchTips;
