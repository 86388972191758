import * as d3 from 'd3';
import _ from 'lodash';
import CommonService from '../../services/commonService';
import { Configurations } from '../../store/slices/configurationsSlice';
import { ClientFilters } from '../../store/slices/filtersSlice';
import { SynapseMetaData, TopologyMetaData } from '../../store/slices/topologySlice';
import {
    CommonPersons,
    PersonDescriptor,
    PhotoAppearance,
    Synapse,
    SynapseConnection,
    SynapsePhoto,
    Synapses,
    Topology
} from './VisionSynapse';

const TopologyViewService = {
    getConnectionStrengthFilterDefaultValue(
        personsInSynapse: PersonDescriptor[],
        connectionStrengthByPersonUID: Record<string, number>,
        maxPeopleInSynapse: number
    ) {
        const strengthToPersonCount = personsInSynapse.reduce((strengthToPersonCount: Map<number, number>, person) => {
            const strength = Math.round(connectionStrengthByPersonUID[person.personUID]);
            if (strength) {
                strengthToPersonCount.set(strength, (strengthToPersonCount.get(strength) || 0) + 1);
            }
            return strengthToPersonCount;
        }, new Map());

        const maxStrength = 10;
        let peopleCount = 0;
        for (let strength = maxStrength; strength >= 1; strength--) {
            peopleCount += strengthToPersonCount.get(strength) || 0;
            if (peopleCount > maxPeopleInSynapse) {
                return strength === maxStrength ? strength : strength + 1;
            }
        }
        return 1;
    },

    createTopologyMeta(topology: Topology): TopologyMetaData {
        const personIdsInPhoto: Record<string, string[]> = {};
        const photosByPersonId: Record<string, string[]> = {};
        const personUIDtoPerson: Record<string, PersonDescriptor> = {};
        const photoUIDtoPhotoAppearances: Record<string, PhotoAppearance[]> = {};

        topology.topologyAppearances.forEach((appearance) => {
            photoUIDtoPhotoAppearances[appearance.photoUID] = photoUIDtoPhotoAppearances[appearance.photoUID]
                ? [...photoUIDtoPhotoAppearances[appearance.photoUID], appearance]
                : [appearance];

            const personIds = personIdsInPhoto[appearance.photoUID];
            const photoIds = photosByPersonId[appearance.personUID];

            if (!personIds || personIds.indexOf(appearance.personUID) === -1) {
                personIdsInPhoto[appearance.photoUID] = [...(personIds || []), appearance.personUID];
            }

            if (!photoIds || photoIds.indexOf(appearance.photoUID) === -1) {
                photosByPersonId[appearance.personUID] = [...(photoIds || []), appearance.photoUID];
            }
        });

        topology.topologyPersons.map((person) => (personUIDtoPerson[person.personUID] = person));

        return {
            personIdsInPhoto,
            photosByPersonId,
            personUIDtoPerson,
            photoUIDtoPhotoAppearances
        };
    },

    getCurrentSeeds(synapse: Synapse, topologyMetadata: TopologyMetaData): PersonDescriptor[] {
        let seeds = synapse.seedIds
            .map((seedId) => topologyMetadata.personUIDtoPerson[seedId])
            .filter((seed) => !!seed);

        const seedIsNotInAnyPhotos = seeds.length === 0;

        if (seedIsNotInAnyPhotos) {
            seeds = synapse.seedIds
                .map((seedId) => synapse.personsInSynapse.find((person) => person.personUID === seedId))
                .filter((seed) => !!seed);
        }
        return seeds;
    },

    createSynapseMeta(
        topology: Topology,
        currentSynapse: Synapse,
        isTnt: boolean,
        configuration: Configurations
    ): SynapseMetaData {
        const personToGroupNumConnections: Record<string, number> = {};
        const seedIds: string[] = currentSynapse.seedIds;

        currentSynapse.connections.forEach((connection) => {
            if (seedIds.includes(connection.toPersonUID)) {
                personToGroupNumConnections[connection.fromPersonUID] =
                    (personToGroupNumConnections[connection.fromPersonUID] || 0) + 1;
            } else if (seedIds.includes(connection.fromPersonUID)) {
                personToGroupNumConnections[connection.toPersonUID] =
                    (personToGroupNumConnections[connection.toPersonUID] || 0) + 1;
            }
        });

        const maxStrength = currentSynapse.connections.reduce(
            (out, connection) => (out > connection.strength ? out : connection.strength),
            0
        );

        const d3ScaleFunc = d3.scaleLinear().domain([0, maxStrength]).range([1, 10]);

        const connectionStrengthByPersonUIDMap: Map<string, number> = currentSynapse.connections.reduce(
            (out, connection) =>
                out.set(
                    connection.toPersonUID,
                    (out.get(connection.toPersonUID) || 0) > d3ScaleFunc(connection.strength)
                        ? out.get(connection.toPersonUID)
                        : d3ScaleFunc(connection.strength)
                ),
            new Map<string, number>()
        );

        const connectionStrengthByPersonUID = Object.fromEntries(connectionStrengthByPersonUIDMap);

        const isGroupTopology = seedIds.length > 1;

        const defaultConnectionStrengthValue =
            isGroupTopology || isTnt
                ? 1
                : TopologyViewService.getConnectionStrengthFilterDefaultValue(
                      currentSynapse.personsInSynapse,
                      connectionStrengthByPersonUID,
                      configuration.defaultMaxPeopleInSynapse
                  );

        const identifiedPeopleIdsInTnt = _.uniq(
            topology.synapses.tnt.connections
                .filter((connection) => !seedIds.includes(connection.fromPersonUID))
                .map((connection) => connection.toPersonUID)
        );

        const synapseMeta: SynapseMetaData = {
            personToGroupNumConnections,
            maxPersonToSeedGroupConnections: Math.max(
                ...Object.values(personToGroupNumConnections),
                isGroupTopology ? 2 : 1
            ),
            seedIds,
            connectionStrengthByPersonUID,
            filteredPersonUIDsInSynapse: [],
            filteredSelectedPhotoUIDs: [],
            isGroupTopology,
            defaultConnectionStrengthValue,
            identifiedPeopleIdsInTnt
        };

        synapseMeta.filteredPersonUIDsInSynapse = TopologyViewService.getPersonUIDsInSynapse(
            { strength: defaultConnectionStrengthValue, isLabeledOnly: false },
            synapseMeta,
            currentSynapse,
            isTnt
        );

        return synapseMeta;
    },

    getPersonUIDsInSynapse(
        filter: ClientFilters,
        synapseMeta: SynapseMetaData,
        currentSynapse: Synapse,
        isTnt: boolean
    ): string[] {
        return currentSynapse.personsInSynapse
            .filter((person) => {
                // exclude seed
                if (synapseMeta.seedIds.includes(person.personUID)) {
                    return true;
                }
                // filter by label
                if (filter.isLabeledOnly && person.labels?.length === 0) {
                    return false;
                }
                // filter by strength
                if (isTnt) {
                    return TopologyViewService.calcTntSliderFilter(
                        person.personUID,
                        synapseMeta.seedIds,
                        currentSynapse.connections,
                        filter.strength
                    );
                } else if (synapseMeta.isGroupTopology) {
                    return synapseMeta.personToGroupNumConnections[person.personUID] >= filter.strength;
                }
                return Math.round(synapseMeta.connectionStrengthByPersonUID[person.personUID]) >= filter.strength;
            })
            .map((person) => person.personUID);
    },

    calcTntSliderFilter(
        personUID: string,
        seedIds: string[],
        connections: SynapseConnection[],
        strength: number,
        connectedCountToFirstSphere?: Map<string, boolean>
    ): boolean {
        if (seedIds.some((seed) => seed === personUID)) {
            return true; // If it's seed return true
        }

        if (!connectedCountToFirstSphere) {
            connectedCountToFirstSphere = new Map();
        }

        // If person is first sphere, and he in the map
        if (connectedCountToFirstSphere.has(personUID)) {
            return connectedCountToFirstSphere.get(personUID);
        } else {
            if (connections.some((connection) => connection.fromPersonUID === personUID)) {
                // If person is first sphere
                const connectionsCount = connections.filter((connection) => connection.fromPersonUID === personUID)
                    .length;
                const isNotHidden = connectionsCount >= strength;
                connectedCountToFirstSphere.set(personUID, isNotHidden);
                return isNotHidden;
            } else {
                // If person is second sphere
                for (const firstSphere of connections.filter((connection) => connection.toPersonUID === personUID)) {
                    const result = TopologyViewService.calcTntSliderFilter(
                        firstSphere.fromPersonUID,
                        seedIds,
                        connections,
                        strength,
                        connectedCountToFirstSphere
                    );
                    if (result) {
                        return true;
                    }
                }
            }
        }
    },

    getFilteredSelectedPhotoUIDs: (
        selectedConnectionId: string,
        topologyMetadata: TopologyMetaData,
        currentSynapse: Synapse,
        isTnt: boolean
    ) => {
        if (!!selectedConnectionId) {
            let connectedPeopleToSelectedPerson: Set<string> = new Set();

            if (isTnt && !currentSynapse.seedIds.includes(selectedConnectionId)) {
                connectedPeopleToSelectedPerson = new Set([
                    ...currentSynapse.connections
                        .filter((connection) => connection.fromPersonUID === selectedConnectionId)
                        .map((connection) => connection.toPersonUID),
                    ...currentSynapse.connections
                        .filter((connection) => connection.toPersonUID === selectedConnectionId)
                        .map((connection) => connection.fromPersonUID)
                ]);
            }

            const filteredSelectedPhotoUIDs = (topologyMetadata.photosByPersonId[selectedConnectionId] || []).filter(
                (photoUID) => {
                    return (
                        !!currentSynapse.seedIds.find(
                            (seedId) => topologyMetadata.personIdsInPhoto[photoUID]?.indexOf(seedId) !== -1
                        ) ||
                        !![...connectedPeopleToSelectedPerson].find(
                            (seedId) => topologyMetadata.personIdsInPhoto[photoUID]?.indexOf(seedId) !== -1
                        )
                    );
                }
            );

            return filteredSelectedPhotoUIDs;
        } else {
            return [];
        }
    },

    getCurrentSynapse: (synapses: Synapses, isTnt: boolean) => (isTnt ? synapses.tnt : synapses.regular),

    sortLabels(synapses: Synapses) {
        synapses.regular.personsInSynapse.forEach((person) => CommonService.sortLabels(person.labels));
        synapses.tnt.personsInSynapse.forEach((person) => CommonService.sortLabels(person.labels));
    },

    addTopologyNameToPhotos(topologyPhotos: SynapsePhoto[], batchIdToTopologyName: Record<string, string>) {
        return topologyPhotos.map((photo) => ({
            ...photo,
            customFileName: batchIdToTopologyName[photo.batchIds[0]] + ' - ' + photo.fileName
        }));
    },

    getDefaultPerson: (persons: PersonDescriptor[], batchIds: string[]) => {
        return persons.find((person) => person.batchIds.some((batchId) => batchIds.includes(batchId)))?.personUID;
    },

    isPrevAndCurrentTopologiesAreDifferent: (
        prevCommonPersons: CommonPersons[],
        nextCommonPersons: CommonPersons[]
    ) => {
        const previousTopologyIds = prevCommonPersons?.map((commonPersons) => commonPersons.topologyId);
        const topologyIds = nextCommonPersons?.map((commonPersons) => commonPersons.topologyId);
        return !_.isEqual(previousTopologyIds, topologyIds);
    }
};

export default TopologyViewService;
