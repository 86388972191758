import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Loader from 'react-spinners/CircleLoader';
import { toast } from 'react-toastify';
import { AutoSizer, List } from 'react-virtualized';
import ApiService, { ResourceType } from '../../services/apiService';
import AuditService from '../../services/auditService';
import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import SearchService, { NotFoundReason } from '../../services/searchService';
import { Permissions } from '../../shared/model/Permissions';
import { RootState } from '../../store';
import { fetchWatchlists } from '../../store/slices/watchlistsSlice';
import { Colors } from '../colors';
import SearchByLabelAndImage from '../common/search/searchByLabelAndImage';
import SimplePhotoDialog from '../common/simplePhotoDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import EditNameDialog from '../dialogs/editNameDialog';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import IndividualDetails from '../topologyview/individualDetails';
import { PersonDescriptor, SearchResponse, VisionWatchlist } from '../topologyview/VisionSynapse';
import WatchlistIndividual from './watchlistIndividual';
import './watchlists.less';
import { useTranslation } from 'react-i18next';

interface TParams {
    batchId: string;
}

export enum WatchlistAction {
    Edit = 'Edit',
    Delete = 'Delete',
    Details = 'Details'
}

export enum SortOptions {
    Name = 'Name',
    Appearance = 'Appearance'
}

interface WatchlistViewProps extends RouteComponentProps<TParams> {}

const WatchlistView: React.FC<WatchlistViewProps> = (props) => {
    const { t } = useTranslation('watchlists');
    const [watchlist, setWatchlist] = useState<VisionWatchlist>(null);
    const [allPersonsSorted, setAllPersonsSorted] = useState<PersonDescriptor[]>([]);
    const [filteredPersons, setFilteredPersons] = useState<PersonDescriptor[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedPerson, setSelectedPerson] = useState<PersonDescriptor>(null);
    const [currentModal, setCurrentModal] = useState<WatchlistAction | null>(null);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [searchByPhotoResult, setSearchByPhotoResult] = useState<SearchResponse>(null);
    const [showFullSizedImage, setShowFullSizedImage] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState(SortOptions.Name);

    const watchlistIdToWatchlistName = useSelector((state: RootState) => state.watchlists.watchlistIdToWatchlistName);
    const dispatch = useDispatch();

    const isViewEventLogged = useRef(false);
    const batchId = props.match.params.batchId;
    const currentLabel = selectedPerson ? CommonService.getWatchlistLabel(selectedPerson.labels, batchId) : null;

    useEffect(() => {
        getWatchlist();
        if (!watchlistIdToWatchlistName[batchId]) {
            dispatch(fetchWatchlists());
        }
    }, []);

    useEffect(() => logViewEvent(), [watchlistIdToWatchlistName]);

    const logViewEvent = () => {
        if (!isViewEventLogged.current && watchlistIdToWatchlistName[batchId]) {
            AuditService.logViewEvent(batchId, watchlistIdToWatchlistName[batchId] as string, ResourceType.Watchlist);
            isViewEventLogged.current = true;
        }
    };

    useDidUpdateEffect(() => {
        const persons =
            selectedSortOption === SortOptions.Appearance
                ? [...watchlist.persons].sort((personA, personB) => personB.batchIds.length - personA.batchIds.length)
                : watchlist?.persons || [];

        setAllPersonsSorted(persons);
    }, [selectedSortOption, watchlist?.persons]);

    useEffect(() => {
        const filteredPersons = searchValue
            ? allPersonsSorted.filter((person) =>
                  CommonService.getWatchlistLabel(person.labels, batchId).label.toLowerCase().includes(searchValue)
              )
            : allPersonsSorted;
        setFilteredPersons(filteredPersons);
    }, [searchValue, allPersonsSorted]);

    const handleActionClicked = (individual: PersonDescriptor, action: WatchlistAction) => {
        setCurrentModal(action);
        setSelectedPerson(individual);
    };

    const handleModalClosed = () => {
        setIsModalLoading(false);
        setCurrentModal(null);
        setSelectedPerson(null);
    };

    const handlePersonFound = (file: File, searchResults: SearchResponse) => {
        try {
            const personsFoundInWatchlist = SearchService.getPersonsFromSearchByPhotoResults(
                searchResults,
                watchlist.persons
            );
            setFilteredPersons(personsFoundInWatchlist);
            setSearchByPhotoResult(searchResults);
        } catch (error) {
            let warning = t('watchlist_view.no_results_found');
            switch (error?.message) {
                case NotFoundReason.OneFaceButNotInCurrentQuery:
                    warning = t('watchlist_view.face_not_detected');
                    break;
                case NotFoundReason.MultipleFacesButNotInCurrentQuery:
                    warning = t('watchlist_view.faces_not_detected');
                    break;
                default:
                    warning = t('watchlist_view.no_results_found');
                    break;
            }
            toast.warn(warning);
        }
    };

    const handleClearImageSearchClicked = () => {
        setFilteredPersons(allPersonsSorted);
        setSearchByPhotoResult(null);
    };

    const renderSort = () => (
        <FormControl style={{ width: '130px', marginBottom: '6px' }}>
            <InputLabel id='sort'>{t('watchlist_view.sort_by')}</InputLabel>
            <Select
                labelId='sort'
                value={selectedSortOption}
                onChange={(e) => setSelectedSortOption(e.target.value as SortOptions)}>
                <MenuItem value={SortOptions.Name}>{t('watchlist_view.sort_options.name')}</MenuItem>
                <MenuItem value={SortOptions.Appearance}>{t('watchlist_view.sort_options.appearances')}</MenuItem>
            </Select>
        </FormControl>
    );

    const renderWatchlist = () => {
        const rowHeight = 78;

        return (
            <div className='watchlist-container' style={{ height: '100%' }}>
                <div className='flex-justify-between flex-align-center'>
                    <h4>{`${watchlistIdToWatchlistName[batchId]} (${filteredPersons.length})`}</h4>
                    <div>
                        <SearchByLabelAndImage
                            onPersonFound={handlePersonFound}
                            onSearchChange={(value) => {
                                setSearchValue(value.toLowerCase());
                                if (searchByPhotoResult) {
                                    setSearchByPhotoResult(null);
                                }
                            }}
                        />
                        {searchByPhotoResult && (
                            <div className='flex-align-center flex-justify-between'>
                                <Button onClick={() => setShowFullSizedImage(true)} size='small'>
                                    {t('watchlist_view.see_image')}
                                </Button>
                                <Button onClick={handleClearImageSearchClicked} size='small'>
                                    {t('watchlist_view.clear')}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                {authService.hasPermissions(Permissions.watchlist_sort) && renderSort()}
                <Grid item style={{ marginTop: '1em', height: '100%' }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                style={{ outline: 'none' }}
                                width={width}
                                height={height}
                                rowCount={filteredPersons.length}
                                rowHeight={rowHeight}
                                rowRenderer={({ key, index, style }) => (
                                    <div key={key} style={style}>
                                        <WatchlistIndividual
                                            searchValue={searchValue}
                                            queryId={batchId}
                                            onActionClicked={handleActionClicked}
                                            individual={filteredPersons[index]}
                                        />
                                    </div>
                                )}
                            />
                        )}
                    </AutoSizer>
                </Grid>
            </div>
        );
    };

    const getWatchlist = async () => {
        const response = await ApiService.fetchWatchlist(batchId);

        setWatchlist(response.data.result);
    };

    const handleDeleteClose = async (result: boolean) => {
        if (result) {
            setIsModalLoading(true);
            try {
                await ApiService.deletePerson(
                    batchId,
                    batchId,
                    watchlistIdToWatchlistName[batchId],
                    [selectedPerson],
                    false
                );
                getWatchlist();
            } catch (error) {
                toast.error(t('watchlist_view.failed_to_delete', { currentLabel: currentLabel.label }));
            }
        }
        handleModalClosed();
    };

    const handleEditName = async (label: string) => {
        if (label) {
            setIsModalLoading(true);
            try {
                const res = await ApiService.updateLabel(batchId, selectedPerson.personUID, {
                    ...currentLabel,
                    label
                });
                const indexOfPerson = watchlist.persons.findIndex((person) => person.personUID === res.data.personUID);
                const watchlistCopy = { ...watchlist };
                const watchlistPersonsCopy = [...watchlistCopy.persons];
                watchlistPersonsCopy[indexOfPerson] = res.data;
                watchlistCopy.persons = watchlistPersonsCopy;

                setWatchlist(watchlistCopy);
            } catch (error) {
                toast.error(t('watchlist_view.failed_to_update_label') + ' ' + currentLabel.label);
            }
        }
        handleModalClosed();
    };

    return (
        <Grid className='scrollable-container' item style={{ flex: 1 }}>
            {!!watchlist ? (
                renderWatchlist()
            ) : (
                <Grid container alignItems='center' justify='center' style={{ height: '100%' }}>
                    <Loader size={70} color={Colors.lightBlue} />
                </Grid>
            )}
            {currentModal === WatchlistAction.Delete && (
                <ConfirmationDialog
                    open
                    onClose={handleDeleteClose}
                    title={t('watchlist_view.delete_individual_from_watchlist')}
                    description={`${t('watchlist_view.confirm_delete_individual')} "${
                        selectedPerson ? currentLabel.label : ''
                    }"`}
                    isLoading={isModalLoading}
                />
            )}
            {currentModal === WatchlistAction.Edit && (
                <EditNameDialog
                    open
                    type='Individual'
                    onClose={handleEditName}
                    name={selectedPerson ? currentLabel.label : ''}
                    isLoading={isModalLoading}
                />
            )}
            {currentModal === WatchlistAction.Details && (
                <IndividualDetails
                    enableShowFullSizeRepresentingPhoto
                    individual={selectedPerson}
                    onClose={handleModalClosed}
                />
            )}
            {showFullSizedImage && (
                <SimplePhotoDialog
                    isLoading={false}
                    imageUrl={`/api/photo/${searchByPhotoResult.photoId}`}
                    onClose={() => setShowFullSizedImage(false)}
                />
            )}
        </Grid>
    );
};

export default WatchlistView;
