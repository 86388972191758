import { Age, Gender } from '../../store/slices/filtersSlice';

export type PhotoNodeType = 'Batch' | 'VA' | 'Seed' | 'WatchList' | PhotoSourceNetwork;

export enum PhotoSourceNetwork {
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    Vk = 'Vk',
    Tiktok = 'Tiktok',
    Twitter = 'Twitter',
    YouTube = 'YouTube',
    Telegram = 'Telegram'
}

export interface Coordinates {
    x: number;
    y: number;
    w: number;
    h: number;
}

// sorted by priority
export enum LabelOrigin {
    Watchlist = 'WATCHLIST',
    System = 'SYSTEM',
    WatchlistSocial = 'WatchlistSocial',
    Social = 'SOCIAL'
}

// sorted by priority
export enum LabelNetwork {
    Mugshots = 'MUGSHOTS',
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Vk = 'VK',
    Twitter = 'TWITTER',
    Telegram = 'TELEGRAM'
}

// sorted by priority
export enum Network {
    Mugshots = 'MUGSHOTS',
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Vk = 'VK',
    Twitter = 'TWITTER',
    TikTok = 'TIKTOK',
    YouTube = 'YOUTUBE',
    Telegram = 'TELEGRAM'
}

export interface Label {
    origin: LabelOrigin;
    label: string;
    metadata?: Partial<{ batchId: string; network: LabelNetwork; source: string }>;
}
export interface PersonDescriptor {
    personUID: string;
    labels?: Label[];
    score: number;
    representingPhotoId: string;
    batchIds?: string[];
    gender?: Gender;
    age?: Age;
}

export interface SynapseConnection {
    fromPersonUID: string;
    toPersonUID: string;
    strength: number;
}

export interface Synapse {
    seedIds: string[];
    connections: SynapseConnection[];
    personsInSynapse: PersonDescriptor[];
}

export interface SynapsePhoto {
    photoUID: string;
    jFileId: string;
    photoType: PhotoNodeType;
    originalSourceLink: string;
    fileName: string;
    customFileName?: string;
    batchIds: string[];
    timestamp?: number;
    creationDate?: number;
    flagged: boolean;
    vendor: string;
    mobileType: string;
    location: string;
    objectDetectionEnabled: boolean;
    image2TextEnabled: boolean;
    faceRecognitionEnabled: boolean;
    ocrEnabled: boolean;
    explicitContentEnabled: boolean;
    landmarksEnabled: boolean;
    similarImagesEnabled: boolean;
    ocr: string;
    detectedObjects: string[];
    thumbnails: Thumbnail[];
    detectedLandmarks: Landmark[];
}

export interface Thumbnail {
    width: number;
    height: number;
    jfileId: string;
}

export interface PhotoInteraction {
    appearanceId?: string;
    photoUID: string;
    interaction: string;
    coordinates: Coordinates;
}

export interface PhotoAppearance {
    personUID: string;
    photoUID: string;
    coordinates: Coordinates;
}

export interface PhotoIdToAppearence {
    [key: number]: PhotoAppearance[];
}

export interface PhotoObject {
    appearanceId?: string;
    photoUID: string;
    object: string;
    coordinates: Coordinates;
}

export interface PhotoUpdateRequest {
    caseId: string;
    flagged: boolean;
    originalSourceLink: string;
    fileName: string;
    objectDetectionEnabled: boolean;
    image2TextEnabled: boolean;
    faceRecognitionEnabled: boolean;
    ocrEnabled: boolean;
    landmarksEnabled: boolean;
    similarImagesEnabled: boolean;
}

export interface WatchListsPersons {
    watchListId: string;
    personsIds: string[];
}

export interface SynapseResponse<T> {
    result: T;
    message?: string;
}

export interface TopologyPhotosWrapper {
    topologyPhotos: SynapsePhoto[];
}

export enum SynapseType {
    Regular = 'regular',
    Tnt = 'tnt'
}

export interface Landmark {
    mid: string;
    description: string;
    score: number;
    location: {
        latitude: number;
        longitude: number;
    };
}

export type Synapses = Record<SynapseType, Synapse>;

export interface Topology {
    synapses?: Synapses;
    username: string;
    topologyName: string;
    batchId: string;
    topologyPhotos: SynapsePhoto[];
    topologyInteractions: PhotoInteraction[];
    topologyAppearances: PhotoAppearance[];
    topologyObjects: PhotoObject[];
    topologyPersons: PersonDescriptor[];
    objectsAndInteractionsForReasoning: string[]; // ??
    watchLists: WatchListsPersons[];
    commonPersons: CommonPersons[];
}

export interface CommonPersons {
    topologyId: string;
    personsIds: string[];
}

export interface VisionWatchlist {
    persons: PersonDescriptor[];
}

export interface PersonWatchlistDetails {
    personUID: string;
    label: string;
    representingPhotoId: string;
    coordinates: Coordinates;
}

export interface ExportToWatchlistRequest {
    persons: PersonWatchlistDetails[];
    watchListId: string;
}

export interface BatchDetails {
    origin: string;
    batchId: string;
    socialId: string;
}

export interface SearchResponse {
    photoId: string;
    persons: SearchResult[];
}

export interface SearchResult {
    personId: string;
    croppedImgId: string;
    coordinates: Coordinates;
}
