import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import React from 'react';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { Age, Gender, ServerFilters } from '../../store/slices/filtersSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { svgIcons } from '../common/entities/enums';
import './allMediaFilters.less';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchTypes } from '../advancedSearch/advancedSearch';

interface AllMediaFiltersProps {
    filters: ServerFilters;
    setFilters: (filters: ServerFilters) => void;
    allPhotosCount: number;
    filteredPhotosCount: number;
    relevantObjects: string[];
}

const AllMediaFilters: React.FC<AllMediaFiltersProps> = (props) => {
    const { t } = useTranslation('filters');
    const showUnknown = () => authService.hasPermissions(Permissions.filter_unknown);
    const { objectFilters: objectFiltersConfig } = useSelector((state: RootState) => state.configurations.data);
    const currentTopologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const objectFiltersDisabled =
        props.relevantObjects?.length === 0 || searchType === AdvancedSearchTypes.similarImage;

    return (
        <div className={'all-media-filters-container'}>
            <Typography style={{ width: '100%' }} variant='subtitle2' color='textPrimary'>
                {t('all_media_filters.all_media')} ({props.filteredPhotosCount}/{props.allPhotosCount})
            </Typography>
            {authService.hasPermissions(Permissions.show_gender) && (
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor='gender'>Gender</InputLabel>
                    <Select
                        id='gender'
                        value={props.filters.gender || ''}
                        onChange={(e) => props.setFilters({ ...props.filters, gender: e.target.value as Gender })}
                        input={<Input id='gender' />}>
                        MenuProps=
                        {{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                            },
                            getContentAnchorEl: null
                        }}
                        {Object.values(Gender)
                            .filter((gender) => gender !== Gender.Unknown || showUnknown())
                            .map((gender) => (
                                <MenuItem key={gender} value={gender}>
                                    {t(`gender_filter.${gender}`)}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )}
            {authService.hasPermissions(Permissions.show_age) && (
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor='ages'>Age</InputLabel>
                    <Select
                        multiple
                        value={props.filters.ages}
                        onChange={(e) => props.setFilters({ ...props.filters, ages: e.target.value as Age[] })}
                        input={<Input id='ages' />}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                            },
                            getContentAnchorEl: null
                        }}
                        renderValue={(ages: Age[]) =>
                            ages.map((value) => (
                                <Chip
                                    style={{ marginLeft: '2px' }}
                                    key={value}
                                    label={t(`age_filter.${value}`, { ns: 'filters' })}
                                    size='small'
                                />
                            ))
                        }>
                        {Object.values(Age)
                            .filter((age) => age !== Age.Unknown || showUnknown())
                            .map((age) => (
                                <MenuItem key={age} value={age}>
                                    <Checkbox color='primary' checked={props.filters.ages.indexOf(age) > -1} />
                                    <ListItemText primary={t(`age_filter.${age}`, { ns: 'filters' })} />
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        checked={props.filters.flagged}
                        onChange={(e) => props.setFilters({ ...props.filters, flagged: e.target.checked })}
                    />
                }
                disabled={!props.filters.flagged && !currentTopologyPhotos.some((photo) => photo.flagged)}
                label={t('all_media_filters.flagged_only')}
            />

            {objectFiltersConfig.length > 0 && (
                <FormControl data-test-id='objects-filter' style={{ width: '100%' }}>
                    <InputLabel htmlFor='objects' shrink>
                        <div className='objects-filter-label'>
                            {t('all_media_filters.objects')}
                            <CustomSVGIcon
                                type={svgIcons.info}
                                size={20}
                                tooltip={<div>{t('all_media_filters.tooltip')}</div>}
                            />
                        </div>
                    </InputLabel>

                    <Select
                        multiple
                        disabled={objectFiltersDisabled}
                        value={props.filters.objects}
                        onChange={(e) => {
                            props.setFilters({ ...props.filters, objects: e.target.value as string[] });
                        }}
                        input={<Input id='objects' />}
                        label={'objects'}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                            },
                            getContentAnchorEl: null,
                            style: {
                                maxHeight: '65vh'
                            }
                        }}
                        renderValue={(objects: string[]) =>
                            objects.map((object) => (
                                <Chip
                                    style={{ marginLeft: '2px' }}
                                    key={object}
                                    label={t('object_filters.' + object)}
                                    size='small'
                                />
                            ))
                        }>
                        {objectFiltersConfig.map((object) => (
                            <MenuItem
                                key={object}
                                value={object}
                                disabled={!props.relevantObjects.some((objectFilter) => object === objectFilter)}>
                                <Checkbox color='primary' checked={props.filters.objects.indexOf(object) > -1} />
                                <ListItemText primary={t('object_filters.' + object)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </div>
    );
};

export default AllMediaFilters;
