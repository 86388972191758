import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UpdateCountersLimitProps {
    updateDailyCounter: (dailyCounterAmount: number) => void;
    updateYearlyCounters: (yearlyCounterAmount: number) => void;
}

const UpdateCountersLimit: React.FC<UpdateCountersLimitProps> = (props) => {
    const { t } = useTranslation('usersManagement');
    const [dailyCounterAmount, setDailyCounterAmount] = useState(0);
    const [yearlyCounterAmount, setYearlyCounterAmount] = useState(0);

    return (
        <div className='counter-action-container'>
            <strong style={{ marginBottom: '4px' }}> Update Counters Limit: </strong>
            <div style={{ marginBottom: '10px' }} className='flex-align-center'>
                <TextField
                    style={{ marginRight: '10px' }}
                    value={dailyCounterAmount || ''}
                    onChange={(e) => setDailyCounterAmount(+e.target.value)}
                    type='number'
                    label={t('update_counters_limit.new_limit')}
                    size='small'
                />

                <Button
                    disabled={dailyCounterAmount <= 0}
                    onClick={() => props.updateDailyCounter(dailyCounterAmount)}
                    size='small'
                    variant='contained'
                    color='primary'>
                    {t('update_counters_limit.update_daily_counter')}
                </Button>
            </div>
            <div className='flex-align-center'>
                <TextField
                    style={{ marginRight: '10px' }}
                    value={yearlyCounterAmount || ''}
                    onChange={(e) => setYearlyCounterAmount(+e.target.value)}
                    type='number'
                    label={t('update_counters_limit.new_limit')}
                    size='small'
                />

                <Button
                    disabled={yearlyCounterAmount <= 0}
                    onClick={() => props.updateYearlyCounters(yearlyCounterAmount)}
                    size='small'
                    variant='contained'
                    color='primary'>
                    {t('update_counters_limit.update_yearly_counter')}
                </Button>
            </div>
        </div>
    );
};

export default UpdateCountersLimit;
