import { CancelTokenSource, CancelTokenStatic } from 'axios';
import { AjaxUtils } from './ajaxClient';

interface CancelTokenMap {
    [key: string]: CancelTokenSource;
}

export enum CancelTokenTypes {
    SimilarImage = 'similarImage'
}

let source: CancelTokenSource;
let cancelToken: CancelTokenStatic;

let cancelTokenMap: CancelTokenMap = {
    similarImage: null
};

const CancelApiRequestService = {
    generateAndSaveCancelToken: (key: CancelTokenTypes) => {
        cancelToken = AjaxUtils.createCancelToken();
        source = cancelToken.source();

        cancelTokenMap = {
            ...cancelTokenMap,
            [key]: source
        };

        return source;
    },
    cancelApiRequests(serviceKey: string) {
        cancelTokenMap[serviceKey]?.cancel(`${serviceKey} request cancelled due to new request`);
        cancelTokenMap[serviceKey] = null;
    }
};

export default CancelApiRequestService;
