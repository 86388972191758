import React, { useState } from 'react';
import { UserManagementData, UserRole } from './usersManagmentContainer';
import ApiService, { UserGroup } from '../../services/apiService';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core';
import SidePanelMultiSelect from '../topologyview/sidePanelMultiSelect';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import Loader from 'react-spinners/ClipLoader';
import PulseLoader from 'react-spinners/PulseLoader';

import { Colors } from '../colors';
import TextFormField from '../common/formFields/TextFormField';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import authService from '../../services/authService';
import CustomTooltip from '../common/misc/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { Permissions } from '../../shared/model/Permissions';
import { PredefinedRole } from './rolesManagement';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface UsersManagementProps {
    managementData: UserManagementData;
    refreshManagementData: () => Promise<void>;
}

interface EditUserForm {
    id: number;
    username: string;
    groups: UserGroup[];
    roles: number;
    enabled: boolean;
    password?: string;
}

interface CreateUserForm {
    username: string;
    groups: UserGroup[];
    roles: number;
    enabled: boolean;
    password?: string;
}

const convertGroupIdsToGroups = (groupIds: number[], managementData: UserManagementData) => {
    return managementData.groups.filter((group) => groupIds.indexOf(group.id) >= 0);
};

const UsersManagement: React.FC<UsersManagementProps> = (props: UsersManagementProps) => {
    const { t } = useTranslation('usersManagement');
    const [selectedUserId, setSelectedUserId] = useState<number>(0);
    const [currentUserInitialValues, setCurrentUserInitialValues] = useState<EditUserForm>(null);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const { sso_enabled } = useSelector((state: RootState) => state.configurations.data.ssoConfigClient);

    const handleCurrentUserChanged = (userId: number) => {
        const user = props.managementData.users.find((user) => user.id === userId);
        setCurrentUserInitialValues({
            id: user.id,
            groups: convertGroupIdsToGroups(user.userGroups, props.managementData),
            enabled: user.enabled,
            password: '',
            roles: user.userRoles[0],
            username: user.userName
        });
        setSelectedUserId(userId);
    };

    const addUserValidationSchema = yup.object().shape<Partial<CreateUserForm>>({
        username: yup
            .string()
            .trim()
            .required()
            .notOneOf(props.managementData.users.map((user) => user.userName)),
        password: yup.string().trim().required(),
        roles: yup.number().required(),
        groups: yup
            .array()
            .of(
                yup.object().shape<UserGroup>({
                    id: yup.number(),
                    name: yup.string()
                })
            )
            .required()
    });

    const editUserValidationSchema = yup.object().shape<Partial<CreateUserForm>>({
        roles: yup.number().required(),
        groups: yup
            .array()
            .of(
                yup.object().shape<UserGroup>({
                    id: yup.number(),
                    name: yup.string()
                })
            )
            .required()
    });

    const handleEditUserFormSubmit = async (values: EditUserForm, helpers: FormikHelpers<CreateUserForm>) => {
        try {
            await ApiService.editUser({ ...values, groups: values.groups.map((group) => group.id) });
            await ApiService.changeUserGroup(values.groups[0].id, values.username);
            await props.refreshManagementData();
        } catch {
            toast.error(t('users_management.failed_to_edit_user'));
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const handleAddUserFormSubmit = async (values: CreateUserForm, helpers: FormikHelpers<CreateUserForm>) => {
        try {
            await ApiService.addUser({ ...values, groups: values.groups.map((group) => group.id) });
            await ApiService.changeUserGroup(values.groups[0].id, values.username);
            await props.refreshManagementData();
            helpers.resetForm();
        } catch {
            toast.error(t('users_management.failed_to_create_user'));
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const deleteUser = async (values: EditUserForm, resetForm: () => void) => {
        const confirmed = window.confirm(t('users_management.confirm_delete_user') + ' "' + values.username + '"?');

        if (!confirmed) {
            return;
        }
        try {
            setIsDeletingUser(true);
            await ApiService.deleteUser(values.username);
            await props.refreshManagementData();
            setSelectedUserId(0);
            resetForm();
        } catch {
            toast.error(t('users_management.failed_to_delete_user'));
        } finally {
            setIsDeletingUser(false);
        }
    };

    const filterItems = (items: any[]) => {
        if (authService.hasPermissions(Permissions.hide_cases_tab)) {
            const checkRole = (role: UserRole) =>
                role.name.toLowerCase() ===
                    t('roles_management.predefined_roles.' + PredefinedRole.User).toLowerCase() ||
                role.name.toLowerCase() ===
                    t('roles_management.predefined_roles.' + PredefinedRole.SystemAdmin).toLowerCase();
            const availableRoles = [];
            props.managementData.roles.forEach((role) => {
                if (checkRole(role)) availableRoles.push(role.id);
            });
            return items[0].hasOwnProperty('userRoles')
                ? items.filter((item) => item.userRoles.find((role) => availableRoles.includes(role)))
                : items.filter((item) => checkRole(item));
        } else {
            return items;
        }
    };

    return (
        <div>
            <h4 className='users-management-title'>{t('users_management.edit_user')}</h4>
            <FormControl>
                <InputLabel className='users-management-label'>{t('users_management.user')}:</InputLabel>
                <Select
                    value={selectedUserId}
                    onChange={(event) => {
                        const userId: number = event.target.value as number;
                        handleCurrentUserChanged(userId);
                    }}>
                    {!!props.managementData.users?.length &&
                        filterItems(props.managementData.users)
                            .sort((userA, userB) => userA.userName.localeCompare(userB.userName))
                            .map((user) => (
                                <MenuItem value={user.id} key={user.id}>
                                    {user.userName}
                                </MenuItem>
                            ))}
                </Select>
            </FormControl>

            {selectedUserId > 0 && (
                <Formik<EditUserForm>
                    enableReinitialize
                    onSubmit={handleEditUserFormSubmit}
                    validationSchema={editUserValidationSchema}
                    initialValues={currentUserInitialValues}>
                    {({ isSubmitting, values, setFieldValue, resetForm }) => (
                        <Form className='edit-user-form'>
                            <div className='user-groups-dropdown'>
                                <SidePanelMultiSelect
                                    label={t('users_management.user_groups')}
                                    value={values.groups}
                                    onAutoCompleteChange={(event, value) => setFieldValue('groups', value)}
                                    onClose={() => {}}
                                    options={props.managementData.groups}
                                />
                            </div>

                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>{t('users_management.role')}</InputLabel>
                                <Select
                                    style={{ width: '270px' }}
                                    value={values.roles}
                                    name='roles'
                                    disabled={
                                        authService.hasPermissions(Permissions.hide_cases_tab) &&
                                        selectedUserId === authService.getAccessTokenData().userId
                                    }
                                    onChange={(event) => setFieldValue('roles', event.target.value)}>
                                    {!!props.managementData.roles?.length &&
                                        filterItems(props.managementData.roles).map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <hr style={{ margin: '36px 0 26px 0', borderColor: '#7a7d7f', opacity: 0.4 }} />
                            <div>{t('users_management.optional_fields')}:</div>
                            {!sso_enabled && (
                                <Field
                                    name='password'
                                    label={t('users_management.password')}
                                    disabled={isSubmitting}
                                    component={TextFormField}
                                />
                            )}
                            <div style={{ marginBottom: '26px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.enabled}
                                            onChange={(e) => setFieldValue('enabled', e.target.checked)}
                                            color='primary'
                                        />
                                    }
                                    label={t('users_management.user_enabled')}
                                />
                            </div>

                            <Button
                                size='small'
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <Loader size={10} color={Colors.white} />
                                ) : (
                                    t('users_management.edit_user')
                                )}
                            </Button>
                            {!!selectedUserId && (
                                <CustomTooltip
                                    title={
                                        selectedUserId === 1
                                            ? t('users_management.can_not_delete_admin')
                                            : selectedUserId === +authService.getAccessTokenData().id
                                            ? t('users_management.can_not_delete_current_user')
                                            : ''
                                    }>
                                    <span>
                                        <Button
                                            disabled={
                                                selectedUserId === 1 ||
                                                selectedUserId === authService.getAccessTokenData().userId
                                            }
                                            onClick={() => deleteUser(values, resetForm)}
                                            size='small'
                                            variant='contained'
                                            style={{ backgroundColor: Colors.error, marginLeft: '10px' }}>
                                            {isDeletingUser ? (
                                                <PulseLoader size={10} color={Colors.white} />
                                            ) : (
                                                t('users_management.delete_user')
                                            )}
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            )}
                            <Button
                                onClick={() => setSelectedUserId(0)}
                                style={{ marginLeft: '10px' }}
                                size='small'
                                variant='outlined'
                                color='primary'
                                disabled={isSubmitting}>
                                {t('users_management.cancel')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}

            <div>
                <h4 className='users-management-title'>{t('users_management.create_new_user')}</h4>

                <Formik<CreateUserForm>
                    onSubmit={handleAddUserFormSubmit}
                    validationSchema={addUserValidationSchema}
                    initialValues={{
                        password: '',
                        groups: [],
                        enabled: true,
                        roles: null,
                        username: ''
                    }}>
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className='edit-user-form'>
                            <Field
                                style={{ marginRight: '10px' }}
                                name='username'
                                label={t('users_management.form.username')}
                                disabled={isSubmitting}
                                component={TextFormField}
                            />
                            {!sso_enabled && (
                                <Field
                                    name='password'
                                    label={t('users_management.password')}
                                    disabled={isSubmitting}
                                    component={TextFormField}
                                />
                            )}
                            <div className='user-groups-dropdown'>
                                <SidePanelMultiSelect
                                    label={t('users_management.form.user_groups')}
                                    value={values.groups}
                                    onAutoCompleteChange={(event, value) => setFieldValue('groups', value)}
                                    onClose={() => {}}
                                    options={props.managementData.groups}
                                />
                            </div>
                            <FormControl style={{ width: '100%', marginBottom: '26px' }}>
                                <InputLabel>{t('users_management.form.role')}</InputLabel>
                                <Select
                                    style={{ width: '270px' }}
                                    value={values.roles || ''}
                                    name='roles'
                                    onChange={(event) => setFieldValue('roles', event.target.value)}>
                                    {!!props.managementData.roles?.length &&
                                        filterItems(props.managementData.roles).map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <Button
                                size='small'
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <Loader size={10} color={Colors.white} />
                                ) : (
                                    t('users_management.form.create_user')
                                )}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default UsersManagement;
