import { BatchData } from '../components/createTopologyPage/createTopologyService';

export interface CreateBatchApiData {
    type: ExecutionType;
    queryData: Partial<BatchData> & AdvancedSettingsApi;
    aggregationMetadata: DroneData;
    files?: MediaFileDetails[];
    maxFileSizeInZip: number;
}

export interface MediaFileDetails {
    tusdId: string;
    name: string;
    size: number;
}
export interface DroneData {
    recentPhotosOnly?: boolean;
    networksData: Record<string, NetworkData>;
}

export interface NetworkData {
    minNumberOfPeopleInImage: number;
    aggregateFriends?: boolean;
    socialIdentifiersList: SocialIdentifiers[];
}

export interface SocialIdentifiers {
    id: string;
    userName: string;
    displayName: string;
    type: SocialEntityType;
    collectionOptions?: Map<string, string>;
}

export interface SocialEntityType {
    User: string;
    Video: string;
    Page: string;
    Group: string;
    Channel: string;
}

export enum ExecutionType {
    NoSeed = 'NO_SEED',
    WATCHLIST = 'WATCHLIST'
}

export interface AdvancedSettingsApi {
    includeFR: boolean;
    includeOR: boolean;
    includeOCR: boolean;
    includeExplicitContent: boolean;
    includeImage2Text: boolean;
    includeLandmarks: boolean;
    includeSimilarImages: boolean;
}
