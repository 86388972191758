import { LinearProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CommonService from '../../services/commonService';
import TusdService from '../../services/tusdService';
import { RootState } from '../../store';
import './compareApi.less';
import CompareApiService, { CompareApiResults } from './compareApiService';
import CompareApiUpload from './compareApiUpload';
import CompareResults from './compareResults';
import { useTranslation } from 'react-i18next';

interface CompareApiContanierProps {}

const CompareApiContainer: React.FC<CompareApiContanierProps> = () => {
    const { t } = useTranslation('compareApi');
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [results, setResults] = useState<CompareApiResults>(null);
    const [error, setError] = useState('');

    const compareMaxPhotos = useSelector((state: RootState) => state.configurations.data?.compareMaxPhotos);

    const handleUploadProgress = (uploadProgress: number) => {
        setUploadProgress(uploadProgress);
    };

    const handleUpload = async (files: File[]) => {
        try {
            setError('');
            const tusdUploadResults = TusdService.uploadFiles('/api/files/', files, handleUploadProgress);
            const filesDetails = await tusdUploadResults.uploadPromise;

            const response = await CompareApiService.compare(
                filesDetails.map((fileDetails) => fileDetails.tusdId),
                compareMaxPhotos
            );
            setResults(response);
        } catch (error) {
            setError(CommonService.getErrorMessage(error, compareMaxPhotos));
            setResults(null);
        } finally {
            setUploadProgress(null);
        }
    };

    return (
        <div className='compare-container'>
            <h1 className='text-center'>{t('compare_api_container.compare_faces')}</h1>
            {uploadProgress && (
                <>
                    <h3 className='status'>
                        {uploadProgress < 100
                            ? t('compare_api_container.uploading')
                            : t('compare_api_container.processing')}
                    </h3>
                    <LinearProgress
                        className='query-progress'
                        variant={uploadProgress === 100 ? 'indeterminate' : 'determinate'}
                        value={uploadProgress}
                    />
                </>
            )}
            <CompareApiUpload maxPhotos={compareMaxPhotos} onUpload={handleUpload} disabled={!!uploadProgress} />

            {error && <div className='error'>{error}</div>}
            {results && <CompareResults results={results} />}
        </div>
    );
};

export default CompareApiContainer;
