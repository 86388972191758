import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import React from 'react';
import { Colors } from '../../colors';

const styles = {
    tooltip: {
        color: '#fff',
        backgroundColor: Colors.black,
        border: 'solid 1px rgba(255, 255, 255, 0.32)',
        maxWidth: 'none',
        fontSize: '15px'
    }
};

const CustomTooltip: React.FC<TooltipProps> = (props) =>
    props.title ? (
        <Tooltip classes={props.classes} {...props}>
            {props.children}
        </Tooltip>
    ) : (
        props.children
    );

export default withStyles(styles)(CustomTooltip);
