import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import authService from '../../services/authService';
import Logo from '../common/logo/logo';
import './login.less';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Permissions } from '../../shared/model/Permissions';

export interface LoginContainerProps extends RouteComponentProps<any, any, { from: string }> {}

export interface LoginFormData {
    userName: string;
    password: string;
}

const LoginContainer: React.FC<LoginContainerProps> = (props) => {
    const { t } = useTranslation('login');
    const [error, setError] = useState('');
    const ssoConfigClient = useSelector((state: RootState) => state.configurations.data.ssoConfigClient);

    const loginValidation = yup.object().shape({
        userName: yup.string().required(t('username_is_required_field')),
        password: yup.string().required(t('password_is_required_field'))
    });

    const handleSubmit = async (values: LoginFormData, { setSubmitting }: FormikHelpers<any>) => {
        try {
            const res = await authService.login(values);
            authService.storeTokens(res.data);
            let path = '';
            authService.hasPermissions(Permissions.hide_cases_tab) ? (path = '/usersManagement') : (path = '/cases');
            props.history.push(props.location.state ? props.location.state.from : path);
        } catch (error) {
            setError(t('error_login_failed'));
        }

        setSubmitting(false);
    };

    const handleSsoLogin = async () => {
        try {
            window.location.href = ssoConfigClient.server_url + '/api/sso/login';
        } catch (error) {
            console.log('Error logging in ', error);
        }
    };

    return (
        <div className='login-container'>
            <div className='card'>
                <div className='logo-container'>
                    <Logo />
                </div>
                {ssoConfigClient.sso_enabled ? (
                    <>
                        <button className='login-sso-btn submit-button' type='submit' onClick={handleSsoLogin}>
                            {t('login')}
                        </button>
                    </>
                ) : (
                    <LoginForm
                        error={error}
                        onSubmit={handleSubmit}
                        initialValues={{ userName: '', password: '' }}
                        validationSchema={loginValidation}
                    />
                )}
            </div>
        </div>
    );
};

export default LoginContainer;
