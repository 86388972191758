import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TopologyEntry } from '../../store/slices/topologiesSlice';
import { WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { svgIcons } from '../common/entities/enums';
import HighlightedText from '../common/highlightedText';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import CreationErrorTooltipInfo, { shouldDisplayInfoTooltip } from './creationErrorTooltipInfo';
import RowDetails from './rowDetails';
import TopologyProgress from './topologyProgress';
import { Draggable } from 'react-beautiful-dnd';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { useTranslation } from 'react-i18next';

interface SingleProcessingListItemProps {
    index: number;
    data: WatchlistEntry | TopologyEntry;
    query: string;
    onDelete: () => void;
    onDetailsClick: () => void;
    isWatchlist: boolean;
    isDraggable: boolean;
}

const SingleProcessingListItem: React.FC<SingleProcessingListItemProps> = ({
    index,
    data,
    onDelete,
    onDetailsClick,
    query,
    isWatchlist,
    isDraggable
}) => {
    const { t } = useTranslation('errors');
    const { fileUploadMaxMediaFilesPerZip } = useSelector((state: RootState) => state.configurations.data);

    const getErrorMessage = (errors: string[]) => {
        let errorMessage = '';
        for (const error of errors) {
            const err = t(error?.trim(), {
                ns: 'errors',
                maxFilesInZip: fileUploadMaxMediaFilesPerZip,
                defaultValue: null
            });
            if (err) {
                errorMessage = errorMessage + err + ', ';
            } else {
                return t('general_err_msg');
            }
        }

        // remove the " ," in the end
        return errorMessage.slice(0, -2);
    };

    const renderErrors = () => {
        const errors = data.error.substring(1, data.error.length - 1).split(',') as string[];

        return (
            <div className='flex-align-center' style={{ color: 'red' }}>
                {getErrorMessage(errors)}
                {shouldDisplayInfoTooltip(errors) && (
                    <CustomSVGIcon
                        type={svgIcons.info}
                        size={20}
                        customStyle={{ margin: '0 10px' }}
                        tooltip={<CreationErrorTooltipInfo isWatchlist={isWatchlist} errors={errors} />}
                    />
                )}
            </div>
        );
    };

    const renderListItem = () => {
        return (
            <>
                <div style={{ flex: 1 }}>
                    <div className='item-title'>
                        <HighlightedText highlight={query}>{data.name}</HighlightedText>
                        <TopologyProgress
                            isWatchlist={isWatchlist}
                            status={data.status}
                            queryRunHistory={lastRunHistory}
                        />
                        {data.error && data.error !== '[]' ? renderErrors() : null}
                    </div>
                    <RowDetails data={data} />
                </div>
                <div onClick={onDelete} style={{ margin: '0 10px' }}>
                    <CustomSVGIcon type={svgIcons.trashBin} size={25} />
                </div>
            </>
        );
    };

    const lastRunHistory = data.metadata?.queryRunHistory?.[data.metadata.queryRunHistory.length - 1];

    const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.ctrlKey || e.metaKey) {
            onDetailsClick();
        }
    };

    return isDraggable && authService.hasPermissions(Permissions.query_drag_and_drop) ? (
        <Draggable draggableId={data.batchId} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={handleRowClick}
                    className='flex-align-center content-item'>
                    {renderListItem()}
                </div>
            )}
        </Draggable>
    ) : (
        <div onClick={handleRowClick} className='flex-align-center content-item'>
            {renderListItem()}
        </div>
    );
};

export default React.memo(
    SingleProcessingListItem,
    (prevProps, nextProps) => _.isEqual(prevProps.data, nextProps.data) && prevProps.query === nextProps.query
);
