import React, { useEffect, useState } from 'react';
import ApiService from '../../services/apiService';
import { PersonDescriptor, SynapsePhoto } from '../topologyview/VisionSynapse';
import SimplePhotoDialog from './simplePhotoDialog';
interface FullSizeRepresentingPhotoProps {
    individual: PersonDescriptor;
    queryId: string;
    onClose: () => void;
}

const FullSizeRepresentingPhoto: React.FC<FullSizeRepresentingPhotoProps> = (props) => {
    const [photo, setPhoto] = useState<SynapsePhoto>(null);
    const [isLoading, setIsLoading] = useState(true);

    const getPhoto = async () => {
        const res = await ApiService.getUserPhotos(props.individual.personUID, props.queryId);
        setPhoto(res.data?.result?.[0]);
        setIsLoading(false);
    };

    useEffect(() => {
        getPhoto();
    }, []);

    return (
        <SimplePhotoDialog
            isLoading={isLoading}
            onClose={props.onClose}
            imageUrl={`/api/photo/${photo?.jFileId || props.individual.representingPhotoId}`}
        />
    );
};

export default FullSizeRepresentingPhoto;
