import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import Loader from 'react-spinners/PulseLoader';
import { Colors } from '../colors';
import { LoginFormData as ILoginFrom } from './LoginPage';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
    validationSchema: any;
    initialValues: ILoginFrom;
    error: string;
    onSubmit: (values: any, formikActions: FormikHelpers<any>) => void;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
    const { t } = useTranslation('login');

    return (
        <Formik<ILoginFrom>
            onSubmit={props.onSubmit}
            validationSchema={props.validationSchema}
            initialValues={props.initialValues}>
            {({ isSubmitting }) => (
                <Form className='login-form'>
                    <div className='form-group'>
                        <label htmlFor='userName'>{t('username')}</label>
                        <Field autoFocus className='form-field' name='userName' />
                        <ErrorMessage name='userName' component='small' />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='password'>{t('password')}</label>
                        <Field className='form-field' type='password' name='password' />
                        <ErrorMessage name='password' component='small' />
                    </div>
                    {props.error && <div className='general-error'>{props.error}</div>}
                    <button disabled={isSubmitting} className='submit-button' type='submit'>
                        {isSubmitting ? <Loader size={10} color={Colors.white}></Loader> : t('login')}
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
