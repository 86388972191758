import React from 'react';

export const similarImageTypesImages = {
    SIMILAR_IMAGE: {
        original: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 49'>
                <rect x='4' y='4.7' fill='#4BE7A5' width='55.7' height='39.4' />
                <path
                    fill='#14B8A6'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M23.4,41.3V14.7H11.1v26.7H23.4z M8,11.5h18.6v11.1H39V11.5h18v33H42h-3H26.6h-3.1H8V11.5z M26.6,41.3h12.3  V25.8H26.6V41.3z M53.9,14.7H42v26.7h11.9V14.7z M49.7,29h3.1v3.2h-3.1V29z M46.9,29h-3.1v3.2h3.1V29z M43.9,34.3h3.1v3.2h-3.1V34.3  z M52.7,34.3h-3.1v3.2h3.1V34.3z M49.7,18.2h3.1v3.2h-3.1V18.2z M46.9,18.2h-3.1v3.2h3.1V18.2z M43.9,23.6h3.1v3.2h-3.1V23.6z   M52.7,23.6h-3.1v3.2h3.1V23.6z M15.6,29h-3.1v3.2h3.1V29z M18.4,29h3.1v3.2h-3.1V29z M21.5,34.3h-3.1v3.2h3.1V34.3z M12.4,34.3h3.1  v3.2h-3.1V34.3z M15.6,18.2h-3.1v3.2h3.1V18.2z M18.4,18.2h3.1v3.2h-3.1V18.2z M21.5,23.6h-3.1v3.2h3.1V23.6z M12.4,23.6h3.1v3.2  h-3.1V23.6z M31.3,29.3h-3.1v3.2h3.1V29.3z M34.1,29.3h3.1v3.2h-3.1V29.3z M37.3,34.7h-3.1v3.2h3.1V34.7z M28.1,34.7h3.1v3.2h-3.1  V34.7z'
                />
                <path
                    fill='#404061'
                    stroke='#404061'
                    strokeWidth='2'
                    strokeLinejoin='round'
                    d='M38.2,16.7c-0.5,1.2-1.4,2.3-2.5,3c-1.1,0.7-2.4,1.1-3.8,1.1c-1.4,0-2.7-0.4-3.8-1.2c-1.1-0.8-2-1.9-2.5-3.1  C23.2,17.6,15,21.4,15,21.4l2.5,6.9h4.8v15.5h19V28.3h4.2l3.4-6.7L38.2,16.7z'
                />
                <path
                    className='st3'
                    fill='#75758C'
                    d='M58.6,47.5c0.6,0,1.1-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9c0.4-0.4,0.7-0.9,1-1.4c0.2-0.5,0.3-1.1,0.3-1.7V5.8  c0-0.6-0.1-1.1-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4c-0.4-0.4-0.9-0.7-1.4-0.9c-0.5-0.2-1.1-0.3-1.7-0.3H5.3C4.2,1.5,3.1,2,2.3,2.8  C1.5,3.6,1,4.7,1,5.8v37.3c0,1.2,0.5,2.2,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3H58.6z M4.3,4.8h55.3v39.3H4.3V4.8z'
                />
                <path
                    fill='#4BE7A5'
                    d='M32.2,24.6l1.3,4c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0h4.2c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1  c0,0,0,0.1-0.1,0.1l-3.4,2.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l1.3,4c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0  c0,0-0.1,0-0.1,0l-3.4-2.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-3.4,2.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1  c0,0,0-0.1,0-0.1l1.3-4c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1L26,29c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1  c0,0,0.1,0,0.1,0h4.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1l1.3-4c0,0,0-0.1,0.1-0.1s0.1,0,0.1,0C32,24.5,32.1,24.5,32.2,24.6  C32.1,24.5,32.1,24.5,32.2,24.6z'
                />
            </svg>
        ),
        result: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 49'>
                <rect
                    x='4.3'
                    y='4.9'
                    transform='matrix(-1 -1.224647e-16 1.224647e-16 -1 64.3043 49.1742)'
                    fill='#75758C'
                    width='55.7'
                    height='39.4'
                />
                <path
                    fill='#404061'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M40.6,7.7v26.7h12.3V7.7H40.6z M56,37.5H37.4V26.4H25v11.1H7v-33h15h3h12.4h3.1H56V37.5z M37.4,7.7H25.1v15.5  h12.3V7.7z M10.1,34.3H22V7.7H10.1V34.3z M14.3,20h-3.1v-3.2h3.1V20z M17.1,20h3.1v-3.2h-3.1V20z M20.1,14.7h-3.1v-3.2h3.1V14.7z   M11.3,14.7h3.1v-3.2h-3.1V14.7z M14.3,30.8h-3.1v-3.2h3.1V30.8z M17.1,30.8h3.1v-3.2h-3.1V30.8z M20.1,25.4h-3.1v-3.2h3.1V25.4z   M11.3,25.4h3.1v-3.2h-3.1V25.4z M48.4,20h3.1v-3.2h-3.1V20z M45.6,20h-3.1v-3.2h3.1V20z M42.5,14.7h3.1v-3.2h-3.1V14.7z M51.6,14.7  h-3.1v-3.2h3.1V14.7z M48.4,30.8h3.1v-3.2h-3.1V30.8z M45.6,30.8h-3.1v-3.2h3.1V30.8z M42.5,25.4h3.1v-3.2h-3.1V25.4z M51.6,25.4  h-3.1v-3.2h3.1V25.4z M32.7,19.7h3.1v-3.2h-3.1V19.7z M29.9,19.7h-3.1v-3.2h3.1V19.7z M26.7,14.3h3.1v-3.2h-3.1V14.3z M35.9,14.3  h-3.1v-3.2h3.1V14.3z'
                />
                <path
                    fill='#252541'
                    stroke='#252541'
                    strokeWidth='2'
                    strokeLinejoin='round'
                    d='M25.8,32.3c0.5-1.2,1.4-2.3,2.5-3c1.1-0.7,2.4-1.1,3.8-1.1c1.4,0,2.7,0.4,3.8,1.2c1.1,0.8,2,1.9,2.5,3.1  c2.3-1.1,10.6-4.9,10.6-4.9l-2.5-6.9h-4.8V5.3h-19v15.5h-4.2l-3.4,6.7L25.8,32.3z'
                />
                <path
                    fill='#75758C'
                    d='M5.4,1.5c-0.6,0-1.1,0.1-1.7,0.3C3.2,2,2.7,2.4,2.3,2.8c-0.4,0.4-0.7,0.9-1,1.4C1.1,4.7,1,5.3,1,5.8v37.3  c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.4,0.9c0.5,0.2,1.1,0.3,1.7,0.3h53.3c1.2,0,2.2-0.5,3.1-1.3  c0.8-0.8,1.3-1.9,1.3-3.1V5.8c0-1.2-0.5-2.2-1.3-3.1c-0.8-0.8-1.9-1.3-3.1-1.3H5.4z M59.7,44.2H4.4V4.8h55.3V44.2z'
                />
                <path
                    fill='#75758C'
                    d='M31.8,24.4l-1.3-4c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0h-4.2c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1  c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1l3.4-2.5c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l-1.3-4c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1  c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l3.4,2.5c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l3.4-2.5c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0  c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1l-1.3,4c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1L38,20c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1  c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0h-4.2c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l-1.3,4c0,0,0,0.1-0.1,0.1s-0.1,0-0.1,0  C32,24.5,31.9,24.5,31.8,24.4C31.9,24.5,31.9,24.5,31.8,24.4z'
                />
            </svg>
        )
    },
    SIMILAR_OBJECT: {
        original: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 49'>
                <g>
                    <rect x='4.1' y='4.7' fill='#404061' width='55.7' height='39.4' />
                    <path
                        fill='#75758C'
                        d='M58.6,47.5c0.6,0,1.1-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9s0.7-0.9,1-1.4c0.2-0.5,0.3-1.1,0.3-1.7V5.8   c0-0.6-0.1-1.1-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4S60.9,2,60.4,1.8s-1.1-0.3-1.7-0.3H5.3c-1.1,0-2.2,0.5-3,1.3S1,4.7,1,5.8v37.3   c0,1.2,0.5,2.2,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3C5.4,47.5,58.6,47.5,58.6,47.5z M4.3,4.8h55.3v39.3H4.3V4.8z'
                    />
                </g>
                <g>
                    <g>
                        <path
                            fill='#4BE7A5'
                            d='M41.3,39.1h-19c-0.6,0-1-0.4-1-1V23.7h-3.8c-0.4,0-0.8-0.3-0.9-0.7l-2.5-6.9c-0.2-0.5,0.1-1,0.5-1.2    c0,0,8.2-3.8,10.6-4.9c0.3-0.1,0.5-0.1,0.8,0c0.3,0.1,0.5,0.3,0.6,0.6c0.4,1.1,1.2,2,2.2,2.7c1,0.7,2.1,1,3.3,1c0,0,0,0,0,0    c1.1,0,2.3-0.3,3.2-1c1-0.6,1.7-1.5,2.2-2.6c0.1-0.2,0.3-0.4,0.6-0.5c0.3-0.1,0.5-0.1,0.8,0l10.6,4.9c0.2,0.1,0.4,0.3,0.5,0.6    c0.1,0.3,0.1,0.5-0.1,0.8l-3.4,6.7c-0.2,0.3-0.5,0.5-0.9,0.5h-3.2v14.5C42.3,38.7,41.9,39.1,41.3,39.1z M23.3,37.1h17V22.7    c0-0.6,0.4-1,1-1H45l2.6-5.2l-8.8-4.1c-0.6,1-1.4,1.9-2.4,2.5c-1.3,0.8-2.8,1.3-4.3,1.3c0,0,0,0,0,0c-1.6,0-3.1-0.5-4.4-1.4    c-1-0.7-1.8-1.6-2.4-2.6c-2.4,1.1-7,3.2-8.8,4.1l1.9,5.3h4.1c0.6,0,1,0.4,1,1V37.1z'
                        />
                    </g>
                    <g>
                        <path
                            fill='#4BE7A5'
                            d='M32.2,18.9l1.3,4c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0h4.2c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1    c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1l-3.4,2.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1l1.3,4c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1    c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-3.4-2.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-3.4,2.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0    c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1l1.3-4c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1L26,23.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1    c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0h4.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1l1.3-4c0,0,0-0.1,0.1-0.1s0.1,0,0.1,0    C32,18.8,32.1,18.8,32.2,18.9C32.1,18.8,32.1,18.8,32.2,18.9z'
                        />
                    </g>
                </g>
            </svg>
        ),
        result: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 49'>
                <g>
                    <rect x='4.1' y='4.7' fill='#404061' width='55.7' height='39.4' />
                    <path
                        fill='#75758C'
                        d='M58.6,47.5c0.6,0,1.1-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9s0.7-0.9,1-1.4c0.2-0.5,0.3-1.1,0.3-1.7V5.8   c0-0.6-0.1-1.1-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4S60.9,2,60.4,1.8s-1.1-0.3-1.7-0.3H5.3c-1.1,0-2.2,0.5-3,1.3S1,4.7,1,5.8v37.3   c0,1.2,0.5,2.2,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3C5.4,47.5,58.6,47.5,58.6,47.5z M4.3,4.8h55.3v39.3H4.3V4.8z'
                    />
                </g>
                <g>
                    <path
                        fill='#4BE7A5'
                        d='M29.2,19.1L28,22.5c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0h-3.7c-0.1,0-0.3,0.2-0.2,0.3l2.6,2.1c0,0,0,0.1,0,0.1   c0,0,0,0.1,0,0.1l-1.3,3.9c-0.1,0.2,0,0.3,0.1,0.2l2.9-2.3c0.1,0,0.1,0,0.2,0l2.8,2.3c0.1,0.1,0.3,0,0.3-0.2l-0.8-3.8   c0-0.1,0.1-0.1,0.1-0.1l3.9-1.9c0.1-0.1,0.2-0.3,0-0.3l-4.4-0.1c-0.1,0-0.1,0-0.1-0.1l-0.8-3.6C29.5,18.9,29.3,18.9,29.2,19.1z'
                    />
                    <path
                        fill='#4BE7A5'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M26.3,11.3c0.1-0.3,0-0.6-0.2-0.9c-0.2-0.3-0.5-0.4-0.8-0.4c-1.9,0.1-4.1,0.3-5.7,2.2c-0.5,0.6-1,1.5-1.5,2.6   c-0.5,1.1-0.9,2.3-1.3,3.6c-0.4,1.3-0.6,2.6-0.8,3.7c-0.1,1.1-0.1,2.3,0.4,3.2c0.2,0.3,0.5,0.6,0.9,0.6h2.9V38c0,0.6,0.4,1,1,1   h20.2c0.6,0,1-0.4,1-1V25.7H47c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7l-1,0.1c1-0.1,1-0.1,1-0.1v0l0,0l0,0l0,0l0-0.1   c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5-0.1-0.8c-0.1-0.7-0.2-1.7-0.5-2.8c-0.5-2.2-1.3-5-2.9-7c-3.2-4.1-7-4-8.7-2.6   c-0.8,0.6-1.7,1.2-2.8,1.7c-1.1,0.5-2.2,0.9-3.1,0.9c-0.9,0-1.6-0.1-2.2-0.2c-0.6-0.1-1-0.3-1.2-0.4c-0.2-0.1-0.3-0.3-0.3-0.5   C26.1,12.3,26.1,11.9,26.3,11.3z M45.9,23.7h-3.4L42.3,22c-0.1-0.5-0.5-0.9-1-0.9c-0.5,0-0.9,0.5-0.9,1V37H22.2V26.5   c0-0.4,0-0.9,0-1.5v-0.1c0-0.1,0-0.1,0-0.2c-0.2-1.9-0.7-4.2-0.7-4.2l-1,3.3H18c-0.1-0.4-0.1-0.9,0-1.5c0.1-1,0.3-2.2,0.7-3.3   c0.3-1.2,0.8-2.3,1.2-3.3c0.5-1,0.9-1.7,1.2-2.1l0,0l0,0c0.8-0.9,1.8-1.3,3-1.4c0,0.3,0,0.6,0.1,0.9c0.2,0.7,0.5,1.3,1.1,1.7   c0.6,0.4,1.2,0.6,2,0.8c0.7,0.1,1.6,0.2,2.6,0.2c1.4,0,2.8-0.5,4-1c1.2-0.6,2.3-1.3,3.2-1.9l0,0c0.6-0.5,3.2-1.1,5.9,2.3l0,0   c1.3,1.7,2.1,4.1,2.5,6.2C45.7,22.2,45.8,23.1,45.9,23.7z'
                    />
                </g>
            </svg>
        )
    },
    SIMILAR_BACKGROUND: {
        original: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 49'>
                <g>
                    <rect x='4.1' y='4.7' fill='#4BE7A5' width='55.7' height='39.4' />
                    <path
                        fill='#14B8A6'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M22.9,41.3V14.7H10.6v26.7h12.3V41.3z M7.5,11.5h18.6v11.1h12.4V11.5h18v33h-15h-3H26.1H23H7.5V11.5z    M26.1,41.3h12.3V25.8H26.1V41.3z M53.4,14.7H41.5v26.7h11.9V14.7z M49.2,29h3.1v3.2h-3.1V29z M46.4,29h-3.1v3.2h3.1V29z    M43.4,34.3h3.1v3.2h-3.1V34.3z M52.2,34.3h-3.1v3.2h3.1V34.3z M49.2,18.2h3.1v3.2h-3.1V18.2z M46.4,18.2h-3.1v3.2h3.1V18.2z    M43.4,23.6h3.1v3.2h-3.1V23.6z M52.2,23.6h-3.1v3.2h3.1V23.6z M15.1,29H12v3.2h3.1V29z M17.9,29H21v3.2h-3.1V29z M21,34.3h-3.1   v3.2H21V34.3z M11.9,34.3H15v3.2h-3.1V34.3z M15.1,18.2H12v3.2h3.1V18.2z M17.9,18.2H21v3.2h-3.1V18.2z M21,23.6h-3.1v3.2H21V23.6z    M11.9,23.6H15v3.2h-3.1V23.6z M30.8,29.3h-3.1v3.2h3.1V29.3z M33.6,29.3h3.1v3.2h-3.1V29.3z M36.8,34.7h-3.1v3.2h3.1V34.7z    M27.6,34.7h3.1v3.2h-3.1V34.7z'
                    />
                    <path
                        fill='#75758C'
                        d='M58.6,47.5c0.6,0,1.1-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9s0.7-0.9,1-1.4c0.2-0.5,0.3-1.1,0.3-1.7V5.8   c0-0.6-0.1-1.1-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4S60.9,2,60.4,1.8c-0.5-0.2-1.1-0.3-1.7-0.3H5.3c-1.1,0-2.2,0.5-3,1.3S1,4.7,1,5.8   v37.3c0,1.2,0.5,2.2,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3C5.4,47.5,58.6,47.5,58.6,47.5z M4.3,4.8h55.3v39.3H4.3V4.8z'
                    />
                    <path
                        fill='#404061'
                        d='M32,11.1c-4.6,0-8.2,3.7-8.2,8.2c0,4.6,3.7,8.2,8.2,8.2s8.2-3.7,8.2-8.2C40.2,14.8,36.6,11.1,32,11.1z    M32,29.7c-5.5,0-16.5,2.8-16.5,8.2v6.2h33v-6.2C48.5,32.5,37.5,29.7,32,29.7z'
                    />
                </g>
            </svg>
        ),
        result: (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 49'>
                <g>
                    <rect x='4.1' y='4.7' fill='#4BE7A5' width='55.7' height='39.4' />
                    <path
                        fill='#14B8A6'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M22.9,41.3V14.7H10.6v26.7h12.3V41.3z M7.5,11.5h18.6v11.1h12.4V11.5h18v33h-15h-3H26.1H23H7.5V11.5z    M26.1,41.3h12.3V25.8H26.1V41.3z M53.4,14.7H41.5v26.7h11.9V14.7z M49.2,29h3.1v3.2h-3.1V29z M46.4,29h-3.1v3.2h3.1V29z    M43.4,34.3h3.1v3.2h-3.1V34.3z M52.2,34.3h-3.1v3.2h3.1V34.3z M49.2,18.2h3.1v3.2h-3.1V18.2z M46.4,18.2h-3.1v3.2h3.1V18.2z    M43.4,23.6h3.1v3.2h-3.1V23.6z M52.2,23.6h-3.1v3.2h3.1V23.6z M15.1,29H12v3.2h3.1V29z M17.9,29H21v3.2h-3.1V29z M21,34.3h-3.1   v3.2H21V34.3z M11.9,34.3H15v3.2h-3.1V34.3z M15.1,18.2H12v3.2h3.1V18.2z M17.9,18.2H21v3.2h-3.1V18.2z M21,23.6h-3.1v3.2H21V23.6z    M11.9,23.6H15v3.2h-3.1V23.6z M30.8,29.3h-3.1v3.2h3.1V29.3z M33.6,29.3h3.1v3.2h-3.1V29.3z M36.8,34.7h-3.1v3.2h3.1V34.7z    M27.6,34.7h3.1v3.2h-3.1V34.7z'
                    />
                    <path
                        fill='#75758C'
                        d='M58.6,47.5c0.6,0,1.1-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9s0.7-0.9,1-1.4c0.2-0.5,0.3-1.1,0.3-1.7V5.8   c0-0.6-0.1-1.1-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4S60.9,2,60.4,1.8c-0.5-0.2-1.1-0.3-1.7-0.3H5.3c-1.1,0-2.2,0.5-3,1.3S1,4.7,1,5.8   v37.3c0,1.2,0.5,2.2,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3C5.4,47.5,58.6,47.5,58.6,47.5z M4.3,4.8h55.3v39.3H4.3V4.8z'
                    />
                </g>
            </svg>
        )
    }
};
