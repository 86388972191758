import React from 'react';
import { useState } from 'react';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import VirtualizedAutocomplete from '../common/virtualizedAutocomplete/virtualizedAutocomplete';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../colors';
import { flexSpaceBetween, StyleVariables as St } from '../styleVariables';
import { getSimilarImageNameAndType } from '../../shared/helpers/searchHelpers';
import { useTranslation } from 'react-i18next';

export interface SearchOption {
    id: string;
    label: string;
    index: number;
}

interface PopupGalleryHeaderProps {
    title: string;
    onClose: () => void;
    onSearch: (option: SearchOption) => void;
    searchOptions: SearchOption[];
    hideSearch?: boolean;
}

const useStyles = makeStyles({
    header: {
        ...flexSpaceBetween,
        height: St.topbarHeight,
        paddingRight: St.gapLayoutHorizontal,
        paddingLeft: St.gapLayoutHorizontal,
        backgroundColor: Colors.darkGray,
        borderBottom: `1px solid ${Colors.individualsOutlineGrey}`
    },
    search: {
        width: 280,
        backgroundColor: Colors.darkGray,
        '&:focus-within': {
            width: `calc(100% - ${St.gapLayoutHorizontal}px * 3 - 24px)`,
            position: 'absolute'
        }
    },
    expanded: {
        width: `calc(100% - ${St.gapLayoutHorizontal}px * 3 - 24px)`,
        position: 'absolute'
    },
    title: {
        fontWeight: 700,
        color: Colors.lightGray,
        textAlign: 'center',
        marginLeft: 'auto',
        flexBasis: '33%'
    },
    section: {
        textAlign: 'right',
        flexBasis: '33%'
    },
    close: {
        '&:hover': {
            fill: Colors.clickable
        }
    }
});

const PopupGalleryHeader: React.FC<PopupGalleryHeaderProps> = (props) => {
    const { t } = useTranslation(['popupGallery', 'advancedSearch']);
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState(null);
    const popupTitle = getSimilarImageNameAndType(
        props.title,
        t('similar_image_form.by', { ns: 'advancedSearch' }),
        true
    );

    return (
        <div className={`popup-gallery-header ${classes.header}`}>
            {!props.hideSearch && (
                <div className={`popup-gallery-header-search ${classes.search} ${selectedOption && classes.expanded}`}>
                    <VirtualizedAutocomplete
                        options={props.searchOptions}
                        inputLabel={t('search_by_image_name')}
                        iconType={svgIcons.magnify}
                        onChange={(e, selectedOption) => {
                            if (selectedOption) {
                                props.onSearch(selectedOption);
                            }
                            setSelectedOption(selectedOption);
                        }}
                    />
                </div>
            )}
            <div className={`popup-gallery-header-title ${classes.title}`}>{popupTitle}</div>
            <div className={`popup-gallery-header-section ${classes.section}`}>
                <CustomSVGIcon
                    data-test-id='close-popup-gallery'
                    onClick={props.onClose}
                    customClass={`popup-gallery-header-close ${classes.close}`}
                    type={svgIcons.close}
                    fillColor={Colors.lightGray}
                    size={24}
                />
            </div>
        </div>
    );
};

export default React.memo(PopupGalleryHeader);
