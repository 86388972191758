import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import CommonService from '../../services/commonService';
import { WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { PersonDescriptor } from './VisionSynapse';
import { useTranslation } from 'react-i18next';

interface AddPersonToWatchlistProps {
    selectedPerson?: PersonDescriptor;
    watchlists: WatchlistEntry[];
    onSaveOrCancel: () => void;
    caseName?: string;
}

const AddPersonToWatchlist: React.FC<AddPersonToWatchlistProps> = ({
    selectedPerson,
    onSaveOrCancel,
    watchlists,
    caseName
}) => {
    const { t } = useTranslation(['topologyView', 'errors']);

    const [error, setError] = useState<string>('');
    const [selectedWatchlist, setSelectedWatchlist] = useState<WatchlistEntry>();
    const [label, setLabel] = useState(
        selectedPerson?.labels[0]?.label || `${caseName}_individ_${selectedPerson?.personUID?.substring(0, 8)}`
    );

    const handleSave = useCallback(async () => {
        if (!label) {
            setError(t('add_person_to_watchlist.label_is_required'));
        } else if (!selectedWatchlist) {
            setError(t('add_person_to_watchlist.watchlist_is_required'));
        } else {
            try {
                const [width, height] = await CommonService.getImageDimensions(
                    `/api/photo/${selectedPerson.representingPhotoId}`
                );

                const personsDetails = [
                    {
                        personUID: selectedPerson.personUID,
                        label: label,
                        representingPhotoId: selectedPerson.representingPhotoId,
                        watchListId: selectedWatchlist.batchId,
                        coordinates: { x: 0, y: 0, h: height, w: width }
                    }
                ];

                await ApiService.addPersonToWatchlist({
                    persons: personsDetails,
                    watchListId: selectedWatchlist.batchId
                });

                toast.success(
                    t('add_person_to_watchlist.success_in_adding_to_watchlist', {
                        label: label,
                        selectedWatchlistName: selectedWatchlist.name
                    })
                );
                onSaveOrCancel();
            } catch (error) {
                setError(t('general_err_msg', { ns: 'errors' }));
            }
        }
    }, [selectedPerson, selectedWatchlist]);

    return (
        <Grid item style={{ padding: '7px 28px' }}>
            <Typography style={{ textAlign: 'center' }}>{t('add_person_to_watchlist.add_to_watchlist')}</Typography>
            <Grid container direction='column'>
                <Grid item>
                    <TextField
                        id='person-label'
                        label={t('add_person_to_watchlist.individual_label')}
                        value={label || ''}
                        onChange={(e) => setLabel(e.target.value)}
                        margin='normal'
                        style={{ width: '100%' }}
                        inputProps={{ maxLength: 100 }}
                    />
                </Grid>
                <Grid item style={{ flex: 1, marginBottom: '15px' }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor='select-watchlist'>
                            {t('add_person_to_watchlist.select_watchlist')}
                        </InputLabel>
                        <Select
                            value={selectedWatchlist}
                            onChange={(e) => {
                                setSelectedWatchlist(e.target.value as WatchlistEntry);
                            }}>
                            {watchlists.map((value) => (
                                <MenuItem key={value.batchId} value={value as any}>
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Typography color='error' style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                    {error}
                </Typography>
                <Grid item container justify='space-between'>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleSave}>
                            {t('add_person_to_watchlist.save')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' color='primary' onClick={() => onSaveOrCancel()}>
                            {t('add_person_to_watchlist.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddPersonToWatchlist;
