import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface TusdPromptProps {
    isUploading: boolean;
}

const TusdPrompt: React.FC<TusdPromptProps> = (props) => {
    const { t } = useTranslation('common');
    useEffect(() => {
        if (props.isUploading) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
        return () => {
            window.onbeforeunload = undefined;
        };
    }, [props.isUploading]);

    return <Prompt when={props.isUploading} message={t('tusd_prompt.confirm_leave_page')} />;
};

export default TusdPrompt;
