import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '..';
import TopologyViewService from '../../components/topologyview/topologyViewService';
import {
    PersonDescriptor,
    Synapse,
    Synapses,
    Topology,
    PhotoAppearance,
    SynapsePhoto
} from '../../components/topologyview/VisionSynapse';
import ApiService, { ServerSideFilters } from '../../services/apiService';
import CommonService from '../../services/commonService';
import { navigateSynapse, TopologyRouterSelectors } from '../router/topologyActions';
import { Configurations } from './configurationsSlice';
import {
    activeServerFiltersCount,
    ClientFilters,
    getInitialServerSideFiltersValues,
    ServerFilters
} from './filtersSlice';
import { topologyExit } from './sharedActions';
import { getTopologiesSuccess, TopologyEntry } from './topologiesSlice';
import _ from 'lodash';

export interface TopologyMetaData {
    personIdsInPhoto: Record<string, string[]>;
    personUIDtoPerson: Record<string, PersonDescriptor>;
    photosByPersonId: Record<string, string[]>;
    photoUIDtoPhotoAppearances: Record<string, PhotoAppearance[]>;
}

export interface SynapseMetaData {
    personToGroupNumConnections: Record<string, number>;
    maxPersonToSeedGroupConnections: number;
    seedIds: string[];
    connectionStrengthByPersonUID: Record<string, number>;
    filteredPersonUIDsInSynapse: string[];
    filteredSelectedPhotoUIDs: string[];
    isGroupTopology: boolean;
    defaultConnectionStrengthValue: number;
    identifiedPeopleIdsInTnt: string[];
}

export interface TopologyState {
    originalTopology: Topology;
    data: Topology;
    currentSynapse?: Synapse;
    topologyMetadata: TopologyMetaData;
    synapseMetadata: SynapseMetaData;
    currentSeeds: PersonDescriptor[];
    selectedConnection: PersonDescriptor | null;
    queryIdToTopologyName?: Record<string, string>;
    isFetchingSynapse: boolean;
    isFetchingTopology: boolean;
    error: string;
    allPhotosHaveThumbnail: boolean;
    isFiltersOpen: boolean;
    selectedMultipleConnections: PersonDescriptor[];
    isMultipleSelectedConnectionsMode: boolean;
}

const initialState: TopologyState = {
    originalTopology: null,
    data: null,
    currentSynapse: null,
    topologyMetadata: null,
    synapseMetadata: null,
    currentSeeds: null,
    selectedConnection: null,
    isFetchingSynapse: false,
    isFetchingTopology: false,
    error: null,
    allPhotosHaveThumbnail: false,
    isFiltersOpen: false,
    selectedMultipleConnections: [],
    isMultipleSelectedConnectionsMode: false
};

interface GetSynapseSuccessPayload {
    synapses: Synapses;
    isTnt: boolean;
    configuration: Configurations;
}

interface GetTopologySuccessPayload {
    topology: Topology;
    isTnt: boolean;
    configuration: Configurations;
    isAtLeastOneFilterActive: boolean;
}

const topologySlice = createSlice({
    name: 'topology',
    initialState,
    reducers: {
        getTopologyStart(state) {
            state.isFetchingTopology = true;
            state.isFetchingSynapse = true;
        },
        getTopologySuccess(state, action: PayloadAction<GetTopologySuccessPayload>) {
            const { topology, isTnt, configuration } = action.payload;

            topology.topologyPersons.forEach((person) => CommonService.sortLabels(person.labels));
            if (topology.synapses) {
                TopologyViewService.sortLabels(topology.synapses);
            }

            if (topology.commonPersons.length > 0 && state.queryIdToTopologyName) {
                topology.topologyPhotos = TopologyViewService.addTopologyNameToPhotos(
                    topology.topologyPhotos,
                    state.queryIdToTopologyName
                );
            }

            const topologyMetadata = TopologyViewService.createTopologyMeta(topology);

            let synapseMetadata;
            let currentSeeds;
            let currentSynapse;
            if (topology.synapses) {
                currentSynapse = TopologyViewService.getCurrentSynapse(topology.synapses, isTnt);
                synapseMetadata = TopologyViewService.createSynapseMeta(topology, currentSynapse, isTnt, configuration);
                currentSeeds = TopologyViewService.getCurrentSeeds(currentSynapse, topologyMetadata);
                if (state.selectedConnection) {
                    state.selectedConnection =
                        topology.topologyPersons.find(
                            (person) => person.personUID === state.selectedConnection.personUID
                        ) || null;

                    synapseMetadata.filteredSelectedPhotoUIDs = state.selectedConnection
                        ? TopologyViewService.getFilteredSelectedPhotoUIDs(
                              state.selectedConnection.personUID,
                              topologyMetadata,
                              currentSynapse,
                              isTnt
                          )
                        : [];
                }
            }

            const isPrevAndCurrentTopologiesDifferent = TopologyViewService.isPrevAndCurrentTopologiesAreDifferent(
                state.originalTopology?.commonPersons,
                topology.commonPersons
            );

            const differentTopologiesAndNoFilters =
                isPrevAndCurrentTopologiesDifferent && !action.payload.isAtLeastOneFilterActive;

            const noPreviousData = !state.originalTopology;
            if (
                noPreviousData ||
                differentTopologiesAndNoFilters ||
                state.originalTopology.batchId !== topology.batchId
            ) {
                state.originalTopology = topology;
            }

            state.synapseMetadata = synapseMetadata;
            state.currentSeeds = currentSeeds;
            state.currentSynapse = currentSynapse;
            state.allPhotosHaveThumbnail = topology.topologyPhotos.every((photo) => photo.thumbnails?.length > 0);
            state.topologyMetadata = topologyMetadata;
            state.data = topology;
            state.isFetchingSynapse = false;
            state.isFetchingTopology = false;
            state.error = null;
        },
        getTopologyFailed(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isFetchingSynapse = false;
            state.isFetchingTopology = false;
        },
        getSynapseStart(state) {
            state.isFetchingSynapse = true;
        },
        getSynapseSuccess(state, action: PayloadAction<GetSynapseSuccessPayload>) {
            const { isTnt, synapses, configuration } = action.payload;
            TopologyViewService.sortLabels(synapses);
            const currentSynapse = TopologyViewService.getCurrentSynapse(synapses, isTnt);

            const synapseMetadata = TopologyViewService.createSynapseMeta(
                state.data,
                currentSynapse,
                isTnt,
                configuration
            );
            if (state.selectedConnection) {
                synapseMetadata.filteredSelectedPhotoUIDs = TopologyViewService.getFilteredSelectedPhotoUIDs(
                    state.selectedConnection.personUID,
                    state.topologyMetadata,
                    currentSynapse,
                    isTnt
                );
            }

            state.data.synapses = synapses;
            state.synapseMetadata = synapseMetadata;
            state.currentSeeds = TopologyViewService.getCurrentSeeds(currentSynapse, state.topologyMetadata);
            state.currentSynapse = currentSynapse;
            state.isFetchingSynapse = false;
            state.error = null;
        },
        getSynapseFailed(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isFetchingSynapse = false;
        },
        onTntChange(state, action: PayloadAction<{ isTnt: boolean; configuration: Configurations }>) {
            const isTnt = action.payload.isTnt;
            const currentSynapse = TopologyViewService.getCurrentSynapse(state.data.synapses, isTnt);

            state.synapseMetadata = TopologyViewService.createSynapseMeta(
                state.data,
                currentSynapse,
                isTnt,
                action.payload.configuration
            );
            state.currentSynapse = currentSynapse;
            state.selectedConnection = null;
        },
        onClientFilterDifferent(state, action: PayloadAction<{ clientFilters: ClientFilters; isTnt: boolean }>) {
            state.synapseMetadata.filteredPersonUIDsInSynapse = TopologyViewService.getPersonUIDsInSynapse(
                action.payload.clientFilters,
                state.synapseMetadata,
                state.currentSynapse,
                action.payload.isTnt
            );
        },
        onSelectedConnectionChange(state, action: PayloadAction<{ selectedConnectionId: string; isTnt: boolean }>) {
            const connectionsIds = state.selectedMultipleConnections.map((connection) => connection.personUID);
            if (
                state.isMultipleSelectedConnectionsMode &&
                !connectionsIds.includes(action.payload.selectedConnectionId)
            ) {
                const selectedConnection = state.data.topologyPersons.find(
                    (connection) => connection.personUID === action.payload.selectedConnectionId
                );
                state.selectedMultipleConnections.push(selectedConnection);
            }

            state.synapseMetadata.filteredSelectedPhotoUIDs = TopologyViewService.getFilteredSelectedPhotoUIDs(
                action.payload.selectedConnectionId,
                state.topologyMetadata,
                state.currentSynapse,
                action.payload.isTnt
            );

            state.selectedConnection = state.data.topologyPersons.find(
                (person) => person.personUID === action.payload.selectedConnectionId
            );
        },
        setIsMultipleSelectedConnectionsMode(state, action: PayloadAction<boolean>) {
            state.isMultipleSelectedConnectionsMode = action.payload;
        },
        resetSelectedConnection(state) {
            state.selectedConnection = null;
        },
        resetMultipleSelectedConnections(state, action: PayloadAction<{ connections: string[] }>) {
            state.selectedMultipleConnections = [];
        },
        resetAllSelectedConnections(state) {
            state.selectedConnection = null;
            state.selectedMultipleConnections = [];
        },
        onMultipleSelectAllClicked(state) {
            state.selectedMultipleConnections = state.data.topologyPersons.map((person) => person);
        },
        updatePersons(
            state,
            action: PayloadAction<{ topologyPersons: PersonDescriptor[]; personsInSynapse: PersonDescriptor[] }>
        ) {
            state.data.topologyPersons = action.payload.topologyPersons;
            state.currentSynapse.personsInSynapse = action.payload.personsInSynapse;
            state.currentSeeds = TopologyViewService.getCurrentSeeds(state.currentSynapse, state.topologyMetadata);
            state.selectedConnection = state.data.topologyPersons.find(
                (person) => person.personUID === state.selectedConnection.personUID
            );
        },
        updatePhotoByPhotoId(state, action: PayloadAction<{ id: string; photo: SynapsePhoto }>) {
            const { id, photo } = action.payload;

            let photoIndex = state.data.topologyPhotos.findIndex((photo) => photo.photoUID === id);
            state.data.topologyPhotos[photoIndex] = photo;

            photoIndex = state.originalTopology.topologyPhotos.findIndex((photo) => photo.photoUID === id);
            state.originalTopology.topologyPhotos[photoIndex] = photo;
        },
        updateOriginalTopology(state, action: PayloadAction<Topology>) {
            state.originalTopology = action.payload;
        },
        toggleFiltersPanel(state, action: PayloadAction<boolean>) {
            state.isFiltersOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTopologiesSuccess.type, (state, action: PayloadAction<TopologyEntry[]>) => {
                state.queryIdToTopologyName = action.payload.reduce((batchIdToTopologyName, topology) => {
                    batchIdToTopologyName[topology.batchId] = topology.name;
                    return batchIdToTopologyName;
                }, {});
                if (state.data) {
                    if (state.data.commonPersons.length > 0) {
                        state.data.topologyPhotos = TopologyViewService.addTopologyNameToPhotos(
                            state.data.topologyPhotos,
                            state.queryIdToTopologyName
                        );
                    }
                }
            })
            .addCase(topologyExit.type, (state) => initialState);
    }
});

export const {
    getTopologyStart,
    getTopologySuccess,
    getTopologyFailed,
    getSynapseStart,
    getSynapseSuccess,
    getSynapseFailed,
    onClientFilterDifferent,
    onSelectedConnectionChange,
    resetSelectedConnection,
    resetMultipleSelectedConnections,
    resetAllSelectedConnections,
    setIsMultipleSelectedConnectionsMode,
    onMultipleSelectAllClicked,
    updatePersons,
    updatePhotoByPhotoId,
    onTntChange,
    updateOriginalTopology,
    toggleFiltersPanel
} = topologySlice.actions;

export default topologySlice.reducer;

export interface FetchTopologyData {
    queryId: string;
    seedIds: string[];
    watchlists: string[];
    crossTopologies: string[];
    filters: ServerSideFilters;
    ocrSearchQuery: string;
}

export const fetchTopology = (data: Partial<FetchTopologyData> = {}): any => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {
    const state = getState();
    const queryId = TopologyRouterSelectors.getBatchId(state);
    const commonTopologies = TopologyRouterSelectors.getCommonTopologies(state);
    const serverData: FetchTopologyData = {
        watchlists: TopologyRouterSelectors.getWatchlistIds(state),
        crossTopologies: TopologyRouterSelectors.getCommonTopologies(state),
        queryId,
        seedIds: TopologyRouterSelectors.getSeedIds(state),
        filters: getServerSideFilter(state.filters.serverFilters),
        ocrSearchQuery: TopologyRouterSelectors.getOcrQuery(state),
        ...data
    };
    const isAtLeastOneFilterActive = activeServerFiltersCount(state) > 0;

    try {
        dispatch(getTopologyStart());

        const response = await ApiService.topology(serverData);
        const isTnt = TopologyRouterSelectors.isTnt(getState());

        if (
            TopologyRouterSelectors.getCurrentTab(state) === 'relationships' &&
            !(response.data.result as Topology).synapses
        ) {
            dispatch(navigateSynapse({ batchId: queryId, tab: 'gallery' }));
        }

        const shouldFetchWithoutFilters =
            isAtLeastOneFilterActive &&
            !_.isEqual(
                commonTopologies,
                state.topology.data.commonPersons.map((data) => data.topologyId)
            );
        if (shouldFetchWithoutFilters) {
            ApiService.topology({
                ...serverData,
                filters: getServerSideFilter(getInitialServerSideFiltersValues())
            }).then((response) => {
                dispatch(updateOriginalTopology(response.data.result as Topology));
            });
        }

        return dispatch(
            getTopologySuccess({
                topology: response.data.result as Topology,
                isTnt,
                configuration: getState().configurations.data,
                isAtLeastOneFilterActive
            })
        );
    } catch (err) {
        console.log(err);

        dispatch(getTopologyFailed(CommonService.getErrorMessage(err)));
    }
};

export const fetchSynapses = (data: Partial<FetchTopologyData> = {}): any => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {
    try {
        const state = getState();
        const serverData: FetchTopologyData = {
            watchlists: TopologyRouterSelectors.getWatchlistIds(state),
            crossTopologies: TopologyRouterSelectors.getCommonTopologies(state),
            queryId: TopologyRouterSelectors.getBatchId(state),
            seedIds: TopologyRouterSelectors.getSeedIds(state),
            filters: getServerSideFilter(state.filters.serverFilters),
            ocrSearchQuery: TopologyRouterSelectors.getOcrQuery(state),
            ...data
        };
        dispatch(getSynapseStart());
        const response = await ApiService.topology(serverData, true);
        const isTnt = TopologyRouterSelectors.isTnt(getState());

        dispatch(
            getSynapseSuccess({
                synapses: response.data.result as Synapses,
                isTnt,
                configuration: getState().configurations.data
            })
        );
    } catch (err) {
        dispatch(getSynapseFailed(CommonService.getErrorMessage(err)));
    }
};

export const getServerSideFilter = (serverFilters: ServerFilters): ServerSideFilters => {
    const uploadDate = CommonService.getDateByOption(serverFilters.uploadDate, true);
    const creationDate = CommonService.getDateByOption(serverFilters.creationDate, false);

    return {
        uploadDate,
        creationDate,
        ages: serverFilters.ages,
        gender: serverFilters.gender,
        networks: serverFilters.networks,
        flagged: serverFilters.flagged,
        objects: serverFilters.objects
    };
};
