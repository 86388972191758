import React from 'react';
import classnames from 'classnames';
import { Checkbox } from '@material-ui/core';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';

import './virtualScrollImage.less';

interface VirtualScrollImageProps {
    imageUrl: string;
    height: number;
    width: number;
    onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMultiSelect?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onFlagClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
    isFocused?: boolean;
    isMultiSelected?: boolean;
    isFlagged?: boolean;
}

const VirtualScrollImage: React.FC<VirtualScrollImageProps> = (props) => {
    return (
        <div
            onClick={props.onClick}
            style={{ height: props.height, width: props.width }}
            className={classnames('virtual-scroll-image', {
                focused: props.isFocused,
                'is-multi-selected': props.isMultiSelected,
                'is-flagged': props.isFlagged
            })}>
            {props.onMultiSelect ? (
                <Checkbox
                    data-test-id='multi-select-image'
                    className='multi-select-checkbox'
                    checked={props.isMultiSelected}
                    onClick={props.onMultiSelect}
                    color='primary'
                />
            ) : (
                (props.onFlagClick || props.isFlagged) && (
                    <CustomSVGIcon
                        data-test-id='quick-flag-image'
                        customClass='flag-icon'
                        type={props.isFlagged ? svgIcons.flag : svgIcons.flagOutline}
                        fillColor='red'
                        size={24}
                        onClick={props.onFlagClick}
                    />
                )
            )}
            <img className='image' src={props.imageUrl} alt='' />
        </div>
    );
};

export default VirtualScrollImage;
