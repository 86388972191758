import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { changeSeed } from '../../../store/router/topologyActions';
import BottomSelectIndividuals from '../../common/bottomSelectIndividuals';
import AutoGroup from '../../group/autoGroup';
import GroupActions, { GroupActionTypes } from '../../group/groupActions';
import TopologyPopupGalleryWrapper from '../../popupGallery/topologyPopupGalleryWrapper';
import GraphVizView from './GraphVizView';
import SynapseToolbar from './SynapseToolbar';
import { AutoGroupMode, GroupingMode, MultiSelectMode, PersonSelectionMode, ViewMode } from '../TopologyView';
import { PersonDescriptor } from '../VisionSynapse';

import './connectionsMapTab.less';
import GalleryService from '../../../services/galleryService';
import { useTranslation } from 'react-i18next';

interface ConnectionsMapTabProps {
    personSelectionMode: PersonSelectionMode;
    setPersonSelectionMode: (personSelectionMode: PersonSelectionMode) => void;
    onSelectPerson: (person: PersonDescriptor, ctrlKey: boolean) => void;
    onMerge: (mergeInto: PersonDescriptor, merging: PersonDescriptor[]) => Promise<void>;
    getSharedPhotoIds: (selectedPersons: PersonDescriptor[]) => string[];
}

const ConnectionsMapTab: React.FC<ConnectionsMapTabProps> = (props) => {
    const { t } = useTranslation('topologyView');
    const topology = useSelector((state: RootState) => state.topology.data);
    const topologyMetadata = useSelector((state: RootState) => state.topology.topologyMetadata);
    const selectedConnection = useSelector((state: RootState) => state.topology.selectedConnection);

    const [showSharedImagesGallery, setShowSharedImagesGallery] = useState(false);

    useEffect(() => {
        if (showSharedImagesGallery && props.personSelectionMode instanceof ViewMode) {
            setShowSharedImagesGallery(false);
        }
    }, [props.personSelectionMode]);

    const dispatch = useDispatch();

    const resetViewMode = () => props.setPersonSelectionMode(new ViewMode());

    const handleGroupActionClick = (actionType: GroupActionTypes) => {
        if (actionType === GroupActionTypes.CreateGroup) {
            props.setPersonSelectionMode(new GroupingMode());
        } else if (actionType === GroupActionTypes.AutoGroup) {
            props.setPersonSelectionMode(new AutoGroupMode());
        }
    };

    const handleViewSharedPhotos = () => setShowSharedImagesGallery(true);

    const closeSharedPhotosBottomBar = () => {
        setShowSharedImagesGallery(false);
        resetViewMode();
    };

    const closeSharedPhotosGallery = () => setShowSharedImagesGallery(false);

    const renderSharedImagesGallery = () => (
        <TopologyPopupGalleryWrapper
            title='Shared Photos'
            onClose={closeSharedPhotosGallery}
            images={topology.topologyPhotos
                .filter((photo) => (props.personSelectionMode as MultiSelectMode).sharedPhotoIds?.has(photo.photoUID))
                .map((photo) => ({
                    id: photo.photoUID,
                    url: `/api/photo/${photo.jFileId}`,
                    name: photo.customFileName || photo.fileName,
                    imageSourceUrl: GalleryService.getImageSourceUrl(photo),
                    shapes: GalleryService.convertAppearancesToShapes(
                        topologyMetadata.photoUIDtoPhotoAppearances[photo.photoUID],
                        topologyMetadata.personUIDtoPerson,
                        (props.personSelectionMode as MultiSelectMode).selected.map((person) => person.personUID),
                        selectedConnection?.personUID
                    ),
                    extraText: GalleryService.getPhotoDateText(photo.creationDate, photo.timestamp),
                    metadata: GalleryService.getPhotoMetaData(photo),
                    source: photo.photoType,
                    faceRecognitionEnabled: photo.faceRecognitionEnabled,
                    image2TextEnabled: photo.image2TextEnabled,
                    objectDetectionEnabled: photo.objectDetectionEnabled,
                    ocrEnabled: photo.ocrEnabled,
                    explicitContentEnabled: photo.explicitContentEnabled,
                    landmarksEnabled: photo.landmarksEnabled,
                    similarImagesEnabled: photo.similarImagesEnabled,
                    detectedLandmarks: photo.detectedLandmarks
                }))}
        />
    );

    const renderCreateGroupBar = () => (
        <BottomSelectIndividuals
            onPersonClick={(selectedPerson) =>
                props.setPersonSelectionMode(
                    new GroupingMode(
                        (props.personSelectionMode as GroupingMode).grouping.filter(
                            (person) => person.personUID !== selectedPerson.personUID
                        )
                    )
                )
            }
            persons={(props.personSelectionMode as GroupingMode).grouping}
            onClose={resetViewMode}
            onButtonClick={() => {
                resetViewMode();
                return dispatch(
                    changeSeed(
                        ...(props.personSelectionMode as GroupingMode).grouping.map((person) => person.personUID)
                    )
                );
            }}
            buttonText={t('connections_map_tab.connections_map_tab')}
            buttonDisabled={(props.personSelectionMode as GroupingMode).grouping.length < 2}
            selectIndividualsTooltip={t('connections_map_tab.create_group_tooltip')}
        />
    );

    const renderMultiSelectBar = () => (
        <BottomSelectIndividuals
            onPersonClick={(selectedPerson) => {
                const filteredPersons = (props.personSelectionMode as MultiSelectMode).selected.filter(
                    (person) => person.personUID !== selectedPerson.personUID
                );
                props.setPersonSelectionMode(
                    new MultiSelectMode(filteredPersons, props.getSharedPhotoIds(filteredPersons))
                );
            }}
            persons={(props.personSelectionMode as MultiSelectMode).selected}
            onClose={closeSharedPhotosBottomBar}
            onButtonClick={handleViewSharedPhotos}
            buttonText={
                (props.personSelectionMode as MultiSelectMode).sharedPhotoIds.size > 0
                    ? `${t('connections_map_tab.view_shared_photos')} (${
                          (props.personSelectionMode as MultiSelectMode).sharedPhotoIds.size
                      })`
                    : t('connections_map_tab.no_shared_photos')
            }
            buttonDisabled={(props.personSelectionMode as MultiSelectMode).sharedPhotoIds.size === 0}
        />
    );

    return (
        <>
            <div className='toolbar-container'>
                <SynapseToolbar
                    synapsePersonInteractionMode={props.personSelectionMode}
                    setViewMode={resetViewMode}
                    doMerge={props.onMerge}
                />
            </div>
            <GroupActions onActionClick={handleGroupActionClick} />
            <GraphVizView doSelectPerson={props.onSelectPerson} />
            {props.personSelectionMode instanceof GroupingMode && renderCreateGroupBar()}
            {props.personSelectionMode instanceof MultiSelectMode && renderMultiSelectBar()}
            {props.personSelectionMode instanceof AutoGroupMode && (
                <AutoGroup onGroupClick={(seedIds) => dispatch(changeSeed(...seedIds))} onClose={resetViewMode} />
            )}
            {showSharedImagesGallery && renderSharedImagesGallery()}
        </>
    );
};

export default ConnectionsMapTab;
