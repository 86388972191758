import { Field, useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React, { useState } from 'react';
import authService from '../../../services/authService';
import { Permissions } from '../../../shared/model/Permissions';
import { svgIcons } from '../../common/entities/enums';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import WarningDialog from '../../dialogs/warningDialog';
import { BatchDescriptor } from '../createTopologyService';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../colors';
import { AdvancedSettings } from '../../../store/slices/configurationsSlice';

interface ProcessingModeProps {
    disabledOptions?: Partial<AdvancedSettings>;
}

const ProcessingModeFormikSection: React.FC<ProcessingModeProps> = (props) => {
    const { t } = useTranslation(['createTopology', 'common']);
    const { values, setFieldValue } = useFormikContext<BatchDescriptor>();
    const [warningDialogShown, setWarningDialogShown] = useState(false);
    const someFeatureIncluded = Object.values(values.batchData.advancedSettings).some((value) => value === true);
    const withPermission = {
        imageDescription: Permissions.show_skynet_activation_button
    };

    const buildCheckBox = (advSettingsKey) => {
        const checkBox = (
            <div className='processing-mode-option' key={advSettingsKey}>
                <Field
                    component={CheckboxWithLabel}
                    type='checkbox'
                    name={`batchData.advancedSettings.${advSettingsKey}`}
                    Label={{ label: t('advancedSettings.' + advSettingsKey, { ns: 'common' }) }}
                    disabled={props.disabledOptions[advSettingsKey]}
                />
                <CustomSVGIcon
                    size={20}
                    type={svgIcons.info}
                    fillColor={Colors.lightGray}
                    tooltip={t('processing_model_formik_section.tooltip.' + advSettingsKey)}
                />
            </div>
        );
        return withPermission.hasOwnProperty(advSettingsKey)
            ? authService.hasPermissions(withPermission[advSettingsKey])
                ? checkBox
                : null
            : checkBox;
    };

    return (
        <div className='processing-mode'>
            {!warningDialogShown && !someFeatureIncluded && (
                <WarningDialog
                    title={t('processing_model_formik_section.processing_mode_selection')}
                    onClose={() => {
                        setWarningDialogShown(true);
                    }}
                    description={t('processing_model_formik_section.only_gallery')}
                />
            )}
            <div className='flex-align-center'>
                <span className='processing-mode-title'>{t('advancedSettings.processing_mode', { ns: 'common' })}</span>
            </div>
            {Object.keys(values.batchData.advancedSettings).map((advSettingsKey) => buildCheckBox(advSettingsKey))}
        </div>
    );
};

export default ProcessingModeFormikSection;
