import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import CommonService from '../../services/commonService';
import { WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { PersonDescriptor } from './VisionSynapse';
import { useTranslation } from 'react-i18next';
import { StyleVariables } from '../styleVariables';

interface AddPersonToWatchlistProps {
    selectedPersons?: PersonDescriptor[];
    watchlists: WatchlistEntry[];
    onSaveOrCancel: () => void;
    caseName?: string;
}

const MAX_PERSONS_ADDED_TO_WATCHLIST_IN_ONE_SESSION = 20;

const AddMultiplePersonsToWatchlist: React.FC<AddPersonToWatchlistProps> = ({
    selectedPersons,
    onSaveOrCancel,
    watchlists,
    caseName
}) => {
    const { t } = useTranslation(['topologyView', 'errors']);

    const [error, setError] = useState<string>('');
    const [selectedWatchlist, setSelectedWatchlist] = useState<WatchlistEntry>();

    const handleSave = useCallback(async () => {
        if (!selectedWatchlist) {
            setError(t('add_person_to_watchlist.watchlist_is_required'));
            return;
        }

        if (selectedPersons.length > MAX_PERSONS_ADDED_TO_WATCHLIST_IN_ONE_SESSION) {
            setError(
                t('add_person_to_watchlist.max_persons_error', {
                    maxPersons: MAX_PERSONS_ADDED_TO_WATCHLIST_IN_ONE_SESSION
                })
            );
            return;
        }

        try {
            const personsDetails = await Promise.all(
                selectedPersons.map(async (person) => {
                    const [width, height] = await CommonService.getImageDimensions(
                        `/api/photo/${person.representingPhotoId}`
                    );
                    console.log(person?.personUID.substring(0, 8));

                    return {
                        personUID: person.personUID,
                        label: person?.labels[0]?.label || `${caseName}_individ_${person?.personUID.substring(0, 8)}`,
                        representingPhotoId: person.representingPhotoId,
                        coordinates: { x: 0, y: 0, h: height, w: width }
                    };
                })
            );

            await ApiService.addPersonToWatchlist({
                persons: personsDetails,
                watchListId: selectedWatchlist.batchId
            });
            toast.success(
                t('add_person_to_watchlist.success_in_multiple_adding_to_watchlist', {
                    numOfPersons: selectedPersons.length,
                    selectedWatchlistName: selectedWatchlist.name
                })
            );
            onSaveOrCancel();
        } catch (error) {
            setError(t('general_err_msg', { ns: 'errors' }));
        }
    }, [selectedPersons, selectedWatchlist]);

    return (
        <Grid item style={{ padding: `${StyleVariables.gap}px calc(${StyleVariables.gapHalf} * 4px)` }}>
            <Typography style={{ textAlign: 'center' }}>{t('add_person_to_watchlist.add_to_watchlist')}</Typography>
            <Grid container direction='column'>
                <Grid item style={{ flex: 1, marginBottom: `calc(${StyleVariables.gap} * 2px)` }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor='select-watchlist'>
                            {t('add_person_to_watchlist.select_watchlist')}
                        </InputLabel>
                        <Select
                            value={selectedWatchlist}
                            onChange={(e) => {
                                setSelectedWatchlist(e.target.value as WatchlistEntry);
                            }}>
                            {watchlists.map((value) => (
                                <MenuItem key={value.batchId} value={value as any}>
                                    {value.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Typography
                    color='error'
                    style={{ fontWeight: 'bold', marginBottom: `calc(${StyleVariables.gap} * 2px)` }}>
                    {error}
                </Typography>
                <Grid item container justify='space-between'>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleSave}>
                            {t('add_person_to_watchlist.save')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' color='primary' onClick={() => onSaveOrCancel()}>
                            {t('add_person_to_watchlist.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddMultiplePersonsToWatchlist;
