import Resizer from 'react-image-file-resizer';
import { Consts } from '../../components/common/consts';

const { maxWidth, maxHeight, quality, rotation, returnType, imageType } = Consts.defaultResizeImageFileParams;

export const resizeFile = (
    file: File,
    maxWidthParam = maxWidth,
    maxHeightParam = maxHeight,
    imageTypeParam = imageType,
    qualityParam = quality,
    rotationParam = rotation,
    returnTypeParam = returnType
): Promise<File> => {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            maxWidthParam,
            maxHeightParam,
            imageTypeParam,
            qualityParam,
            rotationParam,
            (uri) => {
                resolve(uri as File);
            },
            returnTypeParam
        );
    });
};
