import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NotificationDialogProps extends DialogProps {
    open: boolean;
    title: string;
    description?: string;
    onClose: () => void;
    buttonName?: string;
}

const NotificationDialog: React.FC<NotificationDialogProps> = (props) => {
    const { t } = useTranslation('dialogs');
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth='xs' open={props.open} fullWidth {...props}>
            <DialogTitle id='notification-dialog-title'>{props.title}</DialogTitle>
            {props.description && (
                <DialogContent>
                    <DialogContentText>{props.description}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button autoFocus onClick={props.onClose} color='primary'>
                    {props.buttonName ?? t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotificationDialog;
