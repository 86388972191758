import { FormControl, InputAdornment, InputLabel, OutlinedInput, StandardTextFieldProps } from '@material-ui/core';
import React, { useState } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { Colors } from '../../colors';
import useDebounce from '../../hooks/useDebounce';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { svgIcons } from '../entities/enums';
import CustomSVGIcon from '../misc/CustomSvgIcon';
import { useTranslation } from 'react-i18next';

interface DebouncedSearchInputProps extends StandardTextFieldProps {
    onDebounced: (searchQuery: string) => Promise<void>;
    delay: number;
}

const DebouncedSearchInput: React.FC<DebouncedSearchInputProps> = ({ onDebounced, delay, ...searchProps }) => {
    const { t } = useTranslation('common');
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const debouncedSearchQuery = useDebounce(searchQuery, delay);

    useDidUpdateEffect(() => {
        setIsLoading(true);
        onDebounced(debouncedSearchQuery).finally(() => setIsLoading(false));
    }, [debouncedSearchQuery]);

    return (
        <FormControl variant='outlined'>
            <InputLabel htmlFor='search-by-name'>{t('debounced_search_input')}</InputLabel>
            <OutlinedInput
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                type='search'
                startAdornment={
                    <InputAdornment position='start' disablePointerEvents>
                        <CustomSVGIcon size={20} type={svgIcons.searchByPhoto}></CustomSVGIcon>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position='end'>
                        {isLoading && <Loader size={10} color={Colors.white}></Loader>}
                    </InputAdornment>
                }
                id='search-by-name'
                labelWidth={140}
            />
        </FormControl>
    );
};

export default DebouncedSearchInput;
