import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '..';
import { PersonDescriptor, SynapsePhoto } from '../../components/topologyview/VisionSynapse';
import CommonService from '../../services/commonService';
import SearchService, {
    SearchByTextRequestData,
    SearchImageByTextResults,
    SearchImageByTextResultsData,
    SearchSimilarImageData,
    SearchWithFiltersRequest
} from '../../services/searchService';
import { TopologyRouterSelectors } from '../router/topologyActions';
import { topologyExit } from './sharedActions';
import { getServerSideFilter } from './topologySlice';
import { AdvancedSearchTypes } from '../../components/advancedSearch/advancedSearch';
import { buildImage2TextQuery } from '../../shared/helpers/searchHelpers';
import { AjaxUtils } from '../../services/ajaxClient';
import CancelApiRequestService, { CancelTokenTypes } from '../../services/cancelApiRequestService';

interface SearchState {
    error?: string;
    searchByTextImageResults?: SearchImageByTextResults;
    searchByTextPersonsResults?: PersonDescriptor[];
    searchType?: AdvancedSearchTypes;
    isShowingLoader?: boolean;
    firstPagination?: boolean;
    displaySearchGallery?: boolean;
    isInnerSearching?: boolean;
}

const initialState: SearchState = {
    searchByTextImageResults: null,
    searchByTextPersonsResults: null,
    searchType: null,
    firstPagination: true
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        searchStart(state, action: PayloadAction<{ firstPagination: boolean; searchType: AdvancedSearchTypes }>) {
            if (action.payload.firstPagination) {
                state.searchByTextImageResults = null;
                state.searchByTextPersonsResults = null;
                state.firstPagination = true;
            }
            state.error = null;
            state.isShowingLoader = true;
            state.searchType = action.payload.searchType;
        },
        searchSuccess(state, action: PayloadAction<{ results: SearchImageByTextResults }>) {
            if (state.searchByTextImageResults) {
                const { data: newData, nextBookmark } = action.payload.results;
                const { data: oldData } = state.searchByTextImageResults;
                const oldAndNewData: SearchImageByTextResultsData = {
                    persons: oldData.persons.concat(newData.persons),
                    photos: oldData.photos.concat(newData.photos),
                    appearances: oldData.appearances.concat(newData.appearances)
                };
                state.searchByTextImageResults.data = oldAndNewData;
                state.searchByTextImageResults.nextBookmark = nextBookmark;
            } else {
                state.searchByTextImageResults = action.payload.results;
            }
            state.isShowingLoader = false;
            state.displaySearchGallery = true;
            state.firstPagination = false;
        },
        searchFailed(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isShowingLoader = false;
        },
        clearSearchResults() {
            return initialState;
        },
        updateSearchPhotoByPhotoId(state, action: PayloadAction<{ id: string; photo: SynapsePhoto }>) {
            const photoIndex = state.searchByTextImageResults.data.photos.findIndex(
                (photo) => photo.photoUID === action.payload.id
            );
            state.searchByTextImageResults.data.photos[photoIndex] = action.payload.photo;
        },
        changeSearchType(state, action: PayloadAction<{ searchType: AdvancedSearchTypes }>) {
            state.searchType = action.payload.searchType;
        },
        deleteImageFromUISearchGallery(state, action: PayloadAction<{ photoId: string }>) {
            state.searchByTextImageResults.data.photos = state.searchByTextImageResults.data.photos.filter(
                (photo) => photo.photoUID !== action.payload.photoId
            );
        },
        setDisplaySearchGallery(state, action: PayloadAction<boolean>) {
            state.displaySearchGallery = action.payload;
        },
        setIsInnerSearching(state, action: PayloadAction<boolean>) {
            state.isInnerSearching = action.payload;
        },
        setShowLoader(state, action: PayloadAction<boolean>) {
            state.isShowingLoader = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(topologyExit.type, (state) => initialState);
    }
});

export const {
    searchFailed,
    searchStart,
    searchSuccess,
    clearSearchResults,
    updateSearchPhotoByPhotoId,
    changeSearchType,
    deleteImageFromUISearchGallery,
    setDisplaySearchGallery,
    setIsInnerSearching,
    setShowLoader
} = searchSlice.actions;

export const serverSearch = (
    data: SearchByTextRequestData | SearchSimilarImageData,
    searchType: AdvancedSearchTypes
): any => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();

        const { isInnerSearching } = state.search;
        const firstSearchingRequest = !data.bookmark;
        const newServerSearchWhilePending = isInnerSearching && firstSearchingRequest;

        if (newServerSearchWhilePending) {
            CancelApiRequestService.cancelApiRequests(CancelTokenTypes.SimilarImage);
        }

        dispatch(setIsInnerSearching(true));

        try {
            await searchInner(data, searchType, dispatch, getState);
        } catch (err) {
            dispatch(searchFailed(CommonService.getErrorMessage(err)));
        }
        dispatch(setIsInnerSearching(false));
    };
};

const searchInner = async (
    data: SearchByTextRequestData | SearchSimilarImageData,
    searchType: AdvancedSearchTypes,
    dispatch: AppDispatch,
    getState: () => RootState
) => {
    let state = getState();
    if (state.topology.isFetchingSynapse) {
        return;
    }
    const allQueryIds = TopologyRouterSelectors.getAllQueryIds(getState());
    const serverFilters = getServerSideFilter(state.filters.serverFilters);
    const objectFiltersConfig = state.configurations.data.objectFilters;
    const serverDataBase: SearchWithFiltersRequest = {
        queryIds: allQueryIds,
        createDateRange: serverFilters.creationDate ? [serverFilters.creationDate, Date.now()] : null,
        uploadDateRange: serverFilters.uploadDate ? [serverFilters.uploadDate, Date.now()] : null,
        networks: serverFilters.networks.map((network) => network.toUpperCase()),
        flagged: serverFilters.flagged
    };

    let serverData;
    if (searchType === AdvancedSearchTypes.imageDescription) {
        serverData = {
            ...serverDataBase,
            query: buildImage2TextQuery(objectFiltersConfig, serverFilters, data as SearchByTextRequestData),
            bookmark: data.bookmark
        };
    } else if (searchType === AdvancedSearchTypes.similarImage) {
        serverData = { ...serverDataBase, ...data };
    }
    dispatch(searchStart({ firstPagination: !data.bookmark, searchType }));
    try {
        let response;
        if (searchType === AdvancedSearchTypes.imageDescription) {
            response = await SearchService.searchImagesByText(serverData);
        } else if (searchType === AdvancedSearchTypes.similarImage) {
            response = await SearchService.searchSimilarImage(serverData);
        }
        state = getState();
        dispatch(searchSuccess({ results: response.data }));
    } catch (err) {
        if (AjaxUtils.isCancelError(err)) {
            return;
        }
        dispatch(searchFailed(CommonService.getErrorMessage(err)));
    }
};

export default searchSlice.reducer;
