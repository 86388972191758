import { AxiosError } from 'axios';
import moment, { Duration, Moment } from 'moment';
import queryString from 'query-string';
import { DateOption } from '../components/common/dateRangeSelect';
import i18n from 'i18next';
import { Label, LabelNetwork, LabelOrigin } from '../components/topologyview/VisionSynapse';
import { WatchlistEntry } from '../store/slices/watchlistsSlice';
import authService from './authService';

type Dimensions = [width: number, height: number];

const CommonService = {
    getErrorMessage(error: AxiosError | string, maxFilesInZip?: number) {
        if (error && typeof error !== 'string' && error.response && error.response.data) {
            if (typeof error.response.data === 'string') {
                return (
                    i18n.t(error.response.data, { ns: 'errors', defaultValue: null, maxFilesInZip }) ||
                    i18n.t('general_err_msg', { ns: 'errors' })
                );
            } else {
                return (
                    i18n.t(error.response.data.errorType || error.response.data.message, {
                        ns: 'errors',
                        defaultValue: null,
                        maxFilesInZip
                    }) || i18n.t('general_err_msg', { ns: 'errors' })
                );
            }
        }
        return (
            i18n.t((error as Error)?.message, { ns: 'errors', defaultValue: null, maxFilesInZip }) ||
            i18n.t(error as string, { ns: 'errors', defaultValue: null, maxFilesInZip }) ||
            i18n.t('general_err_msg', { ns: 'errors' })
        );
    },
    getFileSize: (size: number) => {
        const megaByte = 1024;
        if (!size) {
            return size;
        }
        return size > megaByte ? Math.round(size / megaByte) : (size / megaByte).toFixed(2);
    },
    downloadURI: (url: string, name: string) => {
        fetch(url, { method: 'GET', headers: {} }).then((response) => {
            response.arrayBuffer().then((buffer) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([buffer]));
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            });
        });
    },
    pluralize: (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`,
    // https://stackoverflow.com/a/10834843/9363633
    isNormalInteger(str: string) {
        return /^\+?\d+$/.test(str);
    },
    shadeColor: (color: string, percent: number) => {
        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);

        // @ts-ignore
        R = parseInt((R * (100 + percent)) / 100);

        // @ts-ignore
        G = parseInt((G * (100 + percent)) / 100);

        // @ts-ignore
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
        const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
        const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

        return '#' + RR + GG + BB;
    },
    showTopbar: () =>
        authService.isAuthorized() &&
        !window.location.pathname.startsWith('/case/') &&
        !window.location.pathname.startsWith('/login'),

    getHighestPriority: (labels: Label[]) => (labels && labels[0] ? labels[0].label : ''),
    sortLabels: (labels: Label[]) => {
        const labelOriginOrder = Object.values(LabelOrigin);
        const networkOrder = Object.values(LabelNetwork);

        const getLabelOriginSortRank = (label: Label) => {
            if (label.origin === LabelOrigin.Watchlist && label.metadata?.network) {
                return labelOriginOrder.indexOf(LabelOrigin.WatchlistSocial);
            }
            return labelOriginOrder.indexOf(label.origin);
        };

        const getNetworkSortRank = (label: Label) => networkOrder.indexOf(label.metadata?.network);

        return labels.sort(
            (labelA, labelB) =>
                getLabelOriginSortRank(labelA) - getLabelOriginSortRank(labelB) ||
                getNetworkSortRank(labelA) - getNetworkSortRank(labelB)
        );
    },
    getSystemLabel: (labels: Label[]) => labels.find((label) => label.origin === LabelOrigin.System),
    getWatchlistLabel: (labels: Label[], watchlistId: string) =>
        labels.find((label) => label.origin === LabelOrigin.Watchlist && label.metadata?.batchId === watchlistId),
    getAllLabelsForToolTip: (labels: Label[], watchlists?: WatchlistEntry[]) => {
        if (!labels) {
            return '';
        }

        let isWatchlistAlreadyFound = false;
        let isSocialAlreadyFound = false;
        return labels
            .map((label) => {
                let labelPrefix = '';
                if (label.origin === LabelOrigin.Watchlist) {
                    if (!isWatchlistAlreadyFound) {
                        isWatchlistAlreadyFound = true;
                        labelPrefix = i18n.t('common_service.watchlist_labels', { ns: 'common' });
                    } else {
                        labelPrefix = ' \u2022 ';
                    }
                } else if (label.origin === LabelOrigin.Social) {
                    if (!isSocialAlreadyFound) {
                        isSocialAlreadyFound = true;
                        labelPrefix = i18n.t('common_service.social_labels', { ns: 'common' });
                    } else {
                        labelPrefix = ' \u2022 ';
                    }
                }

                return `${labelPrefix}${CommonService.getLabelOriginText(label, watchlists)} - ${label.label}`;
            })
            .join(' \n');
    },
    getAllLabelsAndScoreForToolTip: (labels: Label[], score: number, watchlists: WatchlistEntry[]) =>
        `${i18n.t('common_service.score', { ns: 'common' })} - ${
            Math.round(score * 10) / 10
        }\n${CommonService.getAllLabelsForToolTip(labels, watchlists)}`,
    getLabelOriginText: (label: Label, watchlists: WatchlistEntry[]) => {
        switch (label.origin) {
            case LabelOrigin.System:
                return i18n.t('common_service.system_label', { ns: 'common' });
            case LabelOrigin.Social:
                return i18n.t(label.metadata.network, { ns: 'networks' });
            case LabelOrigin.Watchlist:
                const watchlist = watchlists
                    ? watchlists.find((watchlist) => watchlist.batchId === label.metadata.batchId)
                    : null;
                return watchlist ? watchlist.name : i18n.t('common_service.unknown_watchlist', { ns: 'common' });
            default:
                return '';
        }
    },
    isNotNumberBetweenRange: (value: number, min: number, max: number) =>
        value && (isNaN(value) || value < min || value > max),
    getDuration: (created: number, finished: number) => {
        const createdDate = moment(created);
        const finishedDate = moment(finished);

        const duration = moment.duration(finishedDate.diff(createdDate));
        return CommonService.formatDuration(duration);
    },
    formatDuration: (duration: Duration) => {
        return `${duration.days() ? duration.days() + 'd ' : ''}${
            duration.hours() ? duration.hours() + 'h ' : ''
        }${duration.minutes()}m ${duration.seconds()}s`;
    },
    getDateByOption: (option: DateOption, isSeconds: boolean) => {
        let momentDate: Moment;
        switch (option) {
            case DateOption.lastTwoMonth:
                momentDate = moment().subtract(2, 'months');
                break;
            case DateOption.lastTwoWeeks:
                momentDate = moment().subtract(2, 'weeks');
                break;
            case DateOption.lastYear:
                momentDate = moment().subtract(1, 'year');
                break;
            case DateOption.all:
                return 1;
        }

        return momentDate ? Math.round(isSeconds ? momentDate.valueOf() / 1000 : momentDate.valueOf()) : 0;
    },
    extractQueryParamList: (searchString: string, paramName: string): string[] => {
        const parsedQuery = queryString.parse(searchString, { arrayFormat: 'none' });
        return (Array.isArray(parsedQuery[paramName])
            ? (parsedQuery[paramName] as string[])
            : ([parsedQuery[paramName]] as string[])
        ).filter((_) => !!_);
    },
    extractQueryParam: (searchString: string, paramName: string) => {
        return new URLSearchParams(searchString).get(paramName);
    },
    extractAllQueryParams: (searchString: string, paramName: string) => {
        return new URLSearchParams(searchString).getAll(paramName);
    },
    getFilesSize: (files: File[]) => {
        return files?.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0) || 0;
    },
    getImageDimensions: (url: string) =>
        new Promise<Dimensions>((resolve, reject) => {
            try {
                const img = document.createElement('img');
                img.addEventListener('load', (e) => {
                    //@ts-ignore
                    const { naturalHeight, naturalWidth } = e.target;

                    resolve([naturalWidth, naturalHeight]);
                });
                img.src = url;
            } catch {
                reject(i18n.t('common_service.failed_to_load_image', { ns: 'common' }) + url);
            }
        })
};

export default CommonService;
