import React, { useEffect, useState } from 'react';
import { InputAdornment, Typography } from '@material-ui/core';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CommonService from '../../../services/commonService';
import GalleryService from '../../../services/galleryService';
import SearchService, { NotFoundReason } from '../../../services/searchService';
import { RootState } from '../../../store';
import {
    changeEntityQuery,
    changeOcrQuery,
    changeSearchQueryImageDescription,
    changeSeed,
    TopologyRouterSelectors
} from '../../../store/router/topologyActions';
import { svgIcons } from '../../common/entities/enums';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import SearchByPhotoInput from '../../common/search/SearchByPhotoInput';
import VirtualizedAutocomplete from '../../common/virtualizedAutocomplete/virtualizedAutocomplete';
import PopupGalleryContainer from '../../popupGallery/popupGalleryContainer';
import VirtualAvatarList from '../virtualAvatarList';
import { PersonDescriptor, SearchResponse } from '../VisionSynapse';
import './searchSectionContainer.less';
import authService from '../../../services/authService';
import { Permissions } from '../../../shared/model/Permissions';
import { useTranslation } from 'react-i18next';

interface SearchSectionContainerProps {
    onDoubleClick: (person: PersonDescriptor) => void;
    doSelectPerson: (person: PersonDescriptor, ctrlKey: boolean) => void;
}

interface SearchByImageState {
    file: File;
    results: SearchResponse;
    isFetching: boolean;
    showGallery: boolean;
    personsFoundByImage: PersonDescriptor[];
}

interface SearchByLabelOrCaptionState {
    isFetching: boolean;
    autoCompleteOptions: AutoCompleteOption[];
    personsFoundByLabel: PersonDescriptor[];
    personsFoundByCaption: PersonDescriptor[];
}

interface AutoCompleteOption {
    label: string;
    id: string;
}

interface ClearSearchOptions {
    clearTextSearch?: boolean;
    clearImageSearch?: boolean;
    clearOcrSearch?: boolean;
}

const SearchSectionContainer: React.FC<SearchSectionContainerProps> = (props) => {
    const { t } = useTranslation(['topologyView', 'errors']);
    const [searchByImageData, setSearchByImageData] = useState<Partial<SearchByImageState>>({});
    const [searchByLabelData, setSearchByLabelData] = useState<Partial<SearchByLabelOrCaptionState>>({});

    const seedIds = useSelector((state: RootState) => TopologyRouterSelectors.getSeedIds(state));
    const batchId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const personsInTopology = useSelector((state: RootState) => state.topology.data.topologyPersons);
    const selectedConnection = useSelector((state: RootState) => state.topology.selectedConnection);
    const searchByTextImageResults = useSelector((state: RootState) => state.search.searchByTextImageResults);
    const serverFilters = useSelector((state: RootState) => state.filters.serverFilters);
    const topology = useSelector((state: RootState) => state.topology.data);
    const commonTopologyIds = useSelector(TopologyRouterSelectors.getCommonTopologies);
    const searchQuery = useSelector(TopologyRouterSelectors.getEntityQuery);
    const currentTopology = useSelector((state: RootState) => state.topologies.currentTopology);
    const dispatch = useDispatch();

    useEffect(() => {
        if (searchQuery) {
            const personsFoundByLabel = getPersonByLabel(searchQuery);

            setSearchByLabelData((searchByLabelOrCaptionData) => ({
                ...searchByLabelOrCaptionData,
                personsFoundByLabel
            }));
        } else if (Object.keys(searchByLabelData).length > 0) {
            setSearchByLabelData({});
        }
    }, [searchQuery, serverFilters, topology.synapses]);

    const getPersonByLabel = (searchValue: string) => {
        const searchTokens = searchValue.split(' ');
        return personsInTopology.filter((person) =>
            person.labels?.some((label) =>
                searchTokens.every((token) => label.label?.toLowerCase().includes(token.toLowerCase()))
            )
        );
    };

    const clearSearch = (options?: ClearSearchOptions) => {
        if (!options || options?.clearTextSearch) {
            setSearchByLabelData({});
            dispatch(changeSearchQueryImageDescription(null));
        }

        if (!options || options?.clearImageSearch) {
            setSearchByImageData({});
        }

        if (!options || options?.clearOcrSearch) {
            dispatch(changeOcrQuery(null));
        }
    };

    const handleSeePhotoClick = () => setSearchByImageData({ ...searchByImageData, showGallery: true });

    const handleFileChange = async (file: File) => {
        clearSearch();
        setSearchByImageData({ isFetching: true });

        try {
            const res = await SearchService.searchPersonByPhoto(
                file,
                [batchId, ...(commonTopologyIds || [])],
                [currentTopology.name]
            );
            try {
                const foundPersons = SearchService.getPersonsFromSearchByPhotoResults(res.data, personsInTopology);
                setSearchByImageData({
                    results: res.data,
                    isFetching: false,
                    showGallery: false,
                    file,
                    personsFoundByImage: foundPersons
                });
            } catch (error) {
                let warning = t('search_section_container.no_results_found');
                switch (error?.message) {
                    case NotFoundReason.OneFaceButNotInCurrentQuery:
                        warning = t('search_section_container.face_not_detected');
                        break;
                    case NotFoundReason.MultipleFacesButNotInCurrentQuery:
                        warning = t('search_section_container.faces_not_detected');
                        break;
                    default:
                        warning = t('search_section_container.no_results_found');
                        break;
                }
                toast.warn(warning);
                setSearchByImageData({ isFetching: false });
            }
        } catch (error) {
            toast.error(t('general_err_msg', { ns: 'errors' }));
            setSearchByImageData({ isFetching: false });
        }
    };

    const handleSearchInputChanged = async () => {
        if (searchByImageData.file) {
            setSearchByImageData({});
        }
    };

    const handleSearch = (
        event: React.ChangeEvent<unknown>,
        value: AutoCompleteOption | string,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === 'clear') {
            dispatch(changeEntityQuery(''));
        } else {
            const searchValue = reason === 'create-option' ? (value as string) : (value as AutoCompleteOption).label;
            dispatch(changeEntityQuery(searchValue));
        }
    };

    const renderParsedQuery = () =>
        authService.hasPermissions(Permissions.show_skynet_parsed_query) && (
            <>
                {searchByTextImageResults?.parsedQuery?.map((query, index) => (
                    <div key={index} className='parsed-query'>
                        {JSON.stringify(query, null, 2)}
                    </div>
                ))}
                {searchByTextImageResults?.parsedQuery?.length === 0 && (
                    <div className='parsed-query'>no parsed query</div>
                )}
            </>
        );

    const renderResults = () => {
        const hasSearchValue = !!searchQuery;
        const hasSearchByImageResults = searchByImageData.personsFoundByImage?.length > 0;
        const hasSearchByLabelResults = searchByLabelData.personsFoundByLabel?.length > 0;
        return (
            <div className='search-results'>
                {renderParsedQuery()}
                {hasSearchByImageResults && (
                    <div className='results-section'>
                        <h6 className='results-header side-panel-header flex-justify-between'>
                            {t('search_section_container.found_by_image')} (
                            {searchByImageData.personsFoundByImage.length})
                            {searchByImageData.results && (
                                <div className='see-image-container' onClick={handleSeePhotoClick}>
                                    <CustomSVGIcon
                                        data-test-id='searched-image'
                                        customClass='see-image-icon'
                                        type={svgIcons.photos}
                                        size={16}
                                    />
                                    <Typography className='see-image-text' color='textPrimary'>
                                        {t('search_section_container.see_image')}
                                    </Typography>
                                </div>
                            )}
                        </h6>
                        {renderVirtualAvatarList(searchByImageData.personsFoundByImage)}
                        {searchByImageData.showGallery && renderSearchedImageGallery()}
                    </div>
                )}

                {hasSearchValue && (
                    <div className='results-section '>
                        <h6 className='results-header side-panel-header'>
                            {t('search_section_container.found_by_label')} (
                            {hasSearchByLabelResults ? searchByLabelData.personsFoundByLabel.length : 0})
                        </h6>
                        {hasSearchByLabelResults && renderVirtualAvatarList(searchByLabelData.personsFoundByLabel)}
                    </div>
                )}
            </div>
        );
    };

    const renderVirtualAvatarList = (persons: PersonDescriptor[]) => (
        <VirtualAvatarList
            maxRows={2}
            rowHeight={46}
            doSelectPerson={props.doSelectPerson}
            onDoubleClick={props.onDoubleClick}
            persons={persons}
        />
    );

    const renderSearchedImageGallery = () => (
        <PopupGalleryContainer
            title='Search by Image'
            images={[
                {
                    id: searchByImageData.results.photoId,
                    url: GalleryService.getPhotoUrl(searchByImageData.results.photoId),
                    name: searchByImageData.file.name,
                    shapes: searchByImageData.results.persons.map(({ personId, coordinates }) => ({
                        id: personId,
                        coordinates: coordinates,
                        type: GalleryService.getShapeType(seedIds, selectedConnection?.personUID, personId),
                        label: CommonService.getHighestPriority(
                            searchByImageData.personsFoundByImage.find((person) => person.personUID === personId).labels
                        )
                    })),
                    faceRecognitionEnabled: true,
                    image2TextEnabled: false,
                    objectDetectionEnabled: false,
                    ocrEnabled: false,
                    explicitContentEnabled: false,
                    landmarksEnabled: false,
                    similarImagesEnabled: false,
                    detectedLandmarks: []
                }
            ]}
            onDoubleClick={(id) => {
                dispatch(changeSeed(id));
                setSearchByImageData({ ...searchByImageData, showGallery: false });
            }}
            onClose={() => setSearchByImageData({ ...searchByImageData, showGallery: false })}
        />
    );

    return (
        <div className='search-container'>
            <VirtualizedAutocomplete
                onInputChange={handleSearchInputChanged}
                freeSolo
                selectOnFocus
                handleHomeEndKeys
                loading={searchByLabelData.isFetching}
                onChange={handleSearch}
                searchValue={searchQuery || ''}
                options={searchByLabelData.autoCompleteOptions || []}
                openOnFocus={false}
                isLoading={searchByLabelData.isFetching || searchByImageData.isFetching}
                inputLabel={t('search_section_container.search_by_label') + '/' + t('search_section_container.image')}
                searchQuery={searchQuery}>
                <InputAdornment position='end'>
                    <SearchByPhotoInput
                        isLoading={searchByLabelData.isFetching || searchByImageData.isFetching}
                        onFileChange={handleFileChange}
                    />
                </InputAdornment>
            </VirtualizedAutocomplete>
            {renderResults()}
        </div>
    );
};

export default SearchSectionContainer;
