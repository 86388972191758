import history from '../../store/history';

const SynapseHistoryService = {
    routesKey: 'routes',

    getRoutes(): string[] {
        return sessionStorage.getItem(this.routesKey) ? JSON.parse(sessionStorage.getItem(this.routesKey)) : [];
    },

    setRoutes(routes: string[]) {
        sessionStorage.setItem(this.routesKey, JSON.stringify(routes));
    },

    getCurrentLocation() {
        return history.location.pathname + history.location.search;
    },

    saveCurrentRoute(url: string) {
        const routes = this.getRoutes();
        routes.push(url);
        this.setRoutes(routes);
    },

    removeLastRoute() {
        const routes: string[] = this.getRoutes();
        const route = routes.pop();
        this.setRoutes(routes);
        return route;
    },

    removeAllRoutes() {
        sessionStorage.removeItem(this.routesKey);
    },

    isNotOnRootTopology() {
        return this.getRoutes()?.length > 0;
    }
};

export default SynapseHistoryService;
