import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CopyToClipboardProps {
    textToCopy: string;
    title: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => {
    const { t } = useTranslation('common');
    const [showTooltip, setShowTooltip] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(props.textToCopy);
        setShowTooltip(true);
    };

    const closeTooltip = () => setShowTooltip(false);

    return (
        <Tooltip
            open={showTooltip}
            title={t('copy_to_clipboard.copied_to_clipboard')}
            leaveDelay={800}
            onClose={closeTooltip}>
            <span onClick={handleClick}>{props.title}</span>
        </Tooltip>
    );
};

export default CopyToClipboard;
