import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './warningDialog.less';
interface WarningDialogProps {
    onClose: () => void;
    title: string;
    description?: string;
}

const WarningDialog: React.FC<WarningDialogProps> = (props) => {
    return (
        <Dialog className='warning-dialog' maxWidth='xs' onClose={props.onClose} open>
            <DialogTitle disableTypography>
                <Typography className='warning-dialog-title' variant='h6'>
                    {props.title}
                </Typography>

                <IconButton aria-label='close' className='warning-dialog-close' onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {props.description && (
                <DialogContent className='warning-dialog-content' dividers>
                    <Typography className='warning-dialog-description' gutterBottom>
                        {props.description}
                    </Typography>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default WarningDialog;
