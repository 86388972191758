import React, { useEffect, useMemo, useRef, useState } from 'react';
import VirtualScrollImage from './virtualScrollImage';
import {
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
    Masonry,
    MasonryCellProps,
    AutoSizer,
    Size,
    IndexRange
} from 'react-virtualized';
import ImageMeasurer from 'react-virtualized-image-measurer';
import './virtualScrollGallery.less';
import { useImperativeHandle } from 'react';
import { ImageToSplit } from '../popupGallery/popupGalleryContainer';

interface OldVirtualScrollGalleryImageWithSize {
    item: OldVirtualScrollGalleryImage;
    size: { height: number; width: number };
}

export interface OldVirtualScrollGalleryImage {
    imageId: string;
    imageUrl: string;
    flagged: boolean;
}

export interface OldVirtualScrollGalleryRef {
    scrollToTopAndResetGallery: () => void;
}
export interface OldVirtualScrollGalleryProps {
    images: OldVirtualScrollGalleryImage[];
    focusedImageId: string;
    multiSelectedImageIds?: ImageToSplit[];
    onMultiSelect?: (imageId: string) => void;
    onClick?: (imageId: string) => void;
    onFlagClick?: (imageId: string) => void;
    columnWidth?: number;
    gutterSize?: number;
    scrollToImageIndex?: number;
    loadMoreImages?: () => void;
    isLoadingMoreImages?: boolean;
    hasMoreToLoad?: boolean;
}

const defaultHeight = 250;
const defaultColumnWidth = 200;
const defaultColumnCount = 3;
const defaultGutterSize = 10;

const pageSize = 20;

const OldVirtualScrollGallery: React.ForwardRefRenderFunction<
    OldVirtualScrollGalleryRef,
    OldVirtualScrollGalleryProps
> = (props, ref) => {
    const hasInternalPagination = !props.loadMoreImages;

    const getInitialLoadedImages = () =>
        hasInternalPagination
            ? props.images.slice(
                  0,
                  (!props.scrollToImageIndex || props.scrollToImageIndex > 1000 ? 0 : props.scrollToImageIndex) +
                      pageSize
              )
            : props.images;
    const [loadedImages, setLoadedImages] = useState(getInitialLoadedImages());
    const [columnCount, setColumnCount] = useState(defaultColumnCount);

    const scrollToIndexDone = useRef<boolean>(!props.scrollToImageIndex);
    const loadedImagesCount = useRef<number>(loadedImages.length);
    const masonryRef = useRef<Masonry>();

    useImperativeHandle(ref, () => ({
        scrollToTopAndResetGallery() {
            (masonryRef.current as any)._scrollingContainer.scrollTop = 0;
            const imagesToLoad = getInitialLoadedImages();
            setLoadedImages(imagesToLoad);
            loadedImagesCount.current = imagesToLoad.length;
        }
    }));

    const columnWidth = props.columnWidth || defaultColumnWidth;
    const gutterSize = props.gutterSize || defaultGutterSize;
    const cache = useMemo(
        () =>
            new CellMeasurerCache({
                defaultHeight,
                defaultWidth: columnWidth,
                fixedWidth: true
            }),
        [columnWidth]
    );

    const cellPositioner = useMemo(
        () =>
            createMasonryCellPositioner({
                cellMeasurerCache: cache,
                columnCount: defaultColumnCount,
                columnWidth: defaultColumnWidth,
                spacer: gutterSize
            }),
        [columnWidth, cache]
    );

    // after remove image measurer try to change to useDidUpdateEffect for better performance
    useEffect(() => {
        resetGallery();

        if (hasInternalPagination) {
            setLoadedImages(props.images.slice(0, loadedImagesCount.current));
        } else {
            setLoadedImages(props.images);
        }
    }, [props.images]);

    useEffect(() => resetCellPositioner(columnCount), [columnCount]);

    const resetGallery = () => {
        cache.clearAll();
        resetCellPositioner(columnCount);
        masonryRef.current.clearCellPositions();
    };

    const onResize = ({ width }: Size) => {
        const newColumnCount = calculateColumnCount(width);
        setColumnCount(newColumnCount);
        resetCellPositioner(newColumnCount);
        masonryRef.current.recomputeCellPositions();
    };

    const calculateColumnCount = (width: number) => Math.floor(width / (columnWidth + gutterSize));

    const resetCellPositioner = (columnCount: number) => {
        cellPositioner.reset({
            columnCount: columnCount,
            columnWidth: columnWidth,
            spacer: gutterSize
        });
    };

    const onCellRender = ({ startIndex, stopIndex }: IndexRange) => {
        const hasMoreToLoad = hasInternalPagination ? loadedImages.length < props.images.length : props.hasMoreToLoad;
        const stopIndexIsNotTooLow = loadedImages.length - stopIndex <= pageSize;
        const notYetLoaded = loadedImagesCount.current <= stopIndex + 1;
        const galleryIsResetting = startIndex === 0 && stopIndex > 50;

        if (
            !props.isLoadingMoreImages &&
            hasMoreToLoad &&
            stopIndexIsNotTooLow &&
            notYetLoaded &&
            !galleryIsResetting
        ) {
            resetGallery();
            if (hasInternalPagination) {
                loadNewImages(stopIndex);
            } else {
                props.loadMoreImages?.();
            }
        }
    };

    const loadNewImages = (stopIndex: number) => {
        console.log(`loading image from ${loadedImages.length} to ${stopIndex + pageSize}`);

        const newImagesArray = [...loadedImages, ...props.images.slice(loadedImages.length, stopIndex + pageSize)];

        loadedImagesCount.current = newImagesArray.length;

        setLoadedImages(newImagesArray);
    };

    const handleMultiSelect = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        image: OldVirtualScrollGalleryImage
    ) => {
        e.stopPropagation();
        props.onMultiSelect(image.imageId);
    };

    const handleFlagClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, image: OldVirtualScrollGalleryImage) => {
        e.stopPropagation();
        props.onFlagClick(image.imageId);
    };

    const autoSizedMasonry = (itemsWithSizes: OldVirtualScrollGalleryImageWithSize[]) => {
        const getImageHeight = (size: OldVirtualScrollGalleryImageWithSize['size']) =>
            Math.round(columnWidth * (size.height / size.width) || defaultHeight);
        const cellRenderer = ({ index, key, parent, style }: MasonryCellProps) => {
            if (!itemsWithSizes[index]) {
                return;
            }

            const { item: image, size } = itemsWithSizes[index];
            const height = getImageHeight(size);

            return (
                <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
                    <div style={style}>
                        <VirtualScrollImage
                            isFocused={image.imageId === props.focusedImageId}
                            isMultiSelected={
                                !!props.multiSelectedImageIds?.find((photo) => photo.imageId === image.imageId)
                            }
                            isFlagged={image.flagged}
                            onClick={props.onClick ? () => props.onClick(image.imageId) : null}
                            onMultiSelect={props.onMultiSelect ? (e) => handleMultiSelect(e, image) : null}
                            onFlagClick={props.onFlagClick ? (e) => handleFlagClick(e, image) : null}
                            width={columnWidth}
                            height={height}
                            imageUrl={image.imageUrl}
                        />
                    </div>
                </CellMeasurer>
            );
        };

        const scrollToImageIndex = () => {
            scrollToIndexDone.current = true;

            const imagesTotalHeight = itemsWithSizes.reduce(
                (totalHeight, item) => totalHeight + getImageHeight(item.size) + gutterSize,
                0
            );

            const buffer = 200;
            //@ts-ignore
            masonryRef.current._scrollingContainer.scrollTop = Math.max(imagesTotalHeight / columnCount - buffer, 0);
        };

        if (itemsWithSizes.length === props.scrollToImageIndex && !scrollToIndexDone.current) {
            scrollToImageIndex();
        }

        return (
            <AutoSizer onResize={onResize}>
                {({ width, height }) => (
                    <Masonry
                        id={Math.random().toString()}
                        overscanByPixels={400}
                        onCellsRendered={onCellRender}
                        ref={masonryRef}
                        autoHeight={false}
                        cellCount={loadedImages.length}
                        cellMeasurerCache={cache}
                        cellPositioner={cellPositioner}
                        cellRenderer={cellRenderer}
                        height={height}
                        width={width}
                        className='masonry'
                    />
                )}
            </AutoSizer>
        );
    };

    return (
        <div className='virtual-scroll-gallery'>
            <ImageMeasurer
                className='image-measurer-container'
                items={loadedImages}
                image={(item: OldVirtualScrollGalleryImage) => item.imageUrl}
                onError={(error: unknown, item: OldVirtualScrollGalleryImage, src: string) =>
                    console.error('Cannot load image', src, 'for item', item, 'error', error)
                }
                timeout={120000}
                defaultHeight={defaultHeight}
                defaultWidth={columnWidth}>
                {({ itemsWithSizes }) => autoSizedMasonry(itemsWithSizes)}
            </ImageMeasurer>
        </div>
    );
};

export default React.forwardRef(OldVirtualScrollGallery);
