import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables } from '../styleVariables';
import { Colors } from '../colors';

export enum newEntityForm {
    NewPersonName = 'NewPersonName'
}

interface ConfirmationDialogProps extends DialogProps {
    open: boolean;
    onClose: (values?: { [newEntityForm.NewPersonName]: string }) => void;
}

const useStyles = makeStyles({
    error: {
        fontSize: 12,
        color: Colors.error,
        marginTop: StyleVariables.gap
    }
});

const NewNameDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const { t } = useTranslation('popupGallery');
    const classes = useStyles();
    const validationSchema = yup.object({
        [newEntityForm.NewPersonName]: yup.string().required(t('name_is_required'))
    });
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth='xs' open={props.open} fullWidth {...props}>
            <DialogTitle>{t('split_to_a_new_individual')}</DialogTitle>
            <Formik
                initialValues={{ [newEntityForm.NewPersonName]: '' }}
                validationSchema={validationSchema}
                onSubmit={props.onClose}>
                {({ handleChange, values, isValid, dirty }) => (
                    <Form>
                        <DialogContent>
                            <TextField
                                name={newEntityForm.NewPersonName}
                                value={values[newEntityForm.NewPersonName] || ''}
                                aria-label={t('new_name_placeholder')}
                                placeholder={t('new_name_placeholder')}
                                onChange={handleChange}
                                type='text'
                                variant='outlined'
                                size='small'
                                fullWidth
                            />
                            <ErrorMessage
                                name={newEntityForm.NewPersonName}
                                className={`advanced-search-panel ${classes.error}`}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => props.onClose()} color='primary'>
                                {t('cancel')}
                            </Button>
                            <Button type='submit' disabled={!(isValid && dirty)} color='primary'>
                                <span>{t('continue')}</span>
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default NewNameDialog;
