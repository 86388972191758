import { Fab, Grid } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { RootState } from '../../store';
import { TopologyStatus } from '../../store/slices/topologiesSlice';
import {
    fetchWatchlists,
    getPendingWatchlists,
    getFinishedWatchlists,
    getProcessingWatchlists,
    WatchlistEntry
} from '../../store/slices/watchlistsSlice';
import BatchDetailsModal from '../common/batchDetailsModal';
import DebouncedSearchInput from '../common/search/debouncedSearchInput';
import { svgIcons } from '../common/entities/enums';
import CustomSvgIcon from '../common/misc/CustomSvgIcon';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import EditNameDialog from '../dialogs/editNameDialog';
import SingleProcessingListItem from '../topologiesView/SingleProcessingListItem';
import SingleTopologyListItem from '../topologiesView/SingleTopologyListItem';
import './watchlists.less';
import ExportQueryModal from '../common/exportQueryModal/exportQueryModal';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../colors';
import { withTranslation, WithTranslation } from 'react-i18next';
import { sortingSingleListItem } from '../topologiesView/sortingSingleListItem';

interface WatchlistsViewContainerProps extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {}

export enum WatchlistModalType {
    Edit = 'Edit',
    Delete = 'Delete',
    Details = 'Details',
    Export = 'Export'
}

interface WatchlistsViewContainerState {
    selectedWatchlist: WatchlistEntry;
    isModalLoading: boolean;
    isSorting: boolean;
    currentModal: WatchlistModalType | null;
    query: string;
}

class WatchlistsViewContainer extends Component<
    WatchlistsViewContainerProps & WithTranslation,
    WatchlistsViewContainerState
> {
    getWatchlistsInterval: NodeJS.Timeout;
    getWatchlistsIntervalMs = 1000 * 10;
    state: WatchlistsViewContainerState = {
        selectedWatchlist: null,
        currentModal: null,
        isSorting: false,
        isModalLoading: false,
        query: ''
    };

    componentDidMount() {
        this.initializeWatchlistsFetchingInterval();
    }

    componentWillUnmount() {
        this.stopWatchlistInterval();
    }

    initializeWatchlistsFetchingInterval = () => {
        this.props.fetchWatchlists(this.state.query);

        this.getWatchlistsInterval = setInterval(
            () => this.props.fetchWatchlists(this.state.query),
            this.getWatchlistsIntervalMs
        );
    };

    stopWatchlistInterval = () => {
        clearInterval(this.getWatchlistsInterval);
    };

    handleDeleteClose = (result: boolean, shouldDeleteQuery: boolean) => {
        if (result) {
            const deleteProcessing =
                this.state.selectedWatchlist.status === TopologyStatus.Processing ||
                this.state.selectedWatchlist.status === TopologyStatus.Pending;

            this.setState({ isModalLoading: true }, () =>
                (deleteProcessing
                    ? ApiService.deleteProcessingWatchlist(this.state.selectedWatchlist.batchId, shouldDeleteQuery)
                    : ApiService.deleteWatchlist(this.state.selectedWatchlist.batchId)
                )
                    .catch(() => toast.error(this.props.t('general_err_msg', { ns: 'errors' })))
                    .finally(() =>
                        this.setState({ isModalLoading: false, selectedWatchlist: null, currentModal: null })
                    )
                    .then(() => this.props.fetchWatchlists(this.state.query))
            );
        } else {
            this.closeModal();
        }
    };

    handleEditName = (name?: string) => {
        if (name) {
            this.setState({ isModalLoading: true }, () =>
                ApiService.editWatchlistName(this.state.selectedWatchlist.batchId, name)
                    .then(() => this.props.fetchWatchlists(this.state.query))
                    .catch(() => toast.error(this.props.t('watchlists_view_container.error_changing_name')))
                    .finally(() =>
                        this.setState({ isModalLoading: false, selectedWatchlist: null, currentModal: null })
                    )
            );
        } else {
            this.closeModal();
        }
    };

    handleMoveQuery = async (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        this.stopWatchlistInterval();

        try {
            this.setState({ isSorting: true });
            await ApiService.sortWaitingExecutions(
                result.destination.index < result.source.index ? true : false,
                this.props.pendingWatchlist[result.source.index].batchId,
                this.props.pendingWatchlist[result.destination.index].batchId
            );
            this.setState({ isSorting: false });
        } catch (err) {
            console.error('Failed to sort pending watchlists', err);
            toast.error(this.props.t('watchlists_view_container.failed_sort_pending_watchlists'));
        }

        this.initializeWatchlistsFetchingInterval();
    };

    showModal = (modalType: WatchlistModalType, selectedWatchlist: WatchlistEntry) =>
        this.setState({ selectedWatchlist, currentModal: modalType });

    closeModal = () => this.setState({ selectedWatchlist: null, currentModal: null });

    renderModals = () => {
        const isCurrentWatchlistRunningAddPhotos =
            this.state.selectedWatchlist?.metadata?.queryRunHistory?.length > 1 &&
            (this.state.selectedWatchlist.status === TopologyStatus.Processing ||
                this.state.selectedWatchlist.status === TopologyStatus.Pending);

        return (
            <>
                {this.state.currentModal === WatchlistModalType.Delete && (
                    <ConfirmationDialog
                        open
                        onClose={(result) => this.handleDeleteClose(result, !isCurrentWatchlistRunningAddPhotos)}
                        title={`${
                            isCurrentWatchlistRunningAddPhotos
                                ? this.props.t('watchlists_view_container.confirm_delete_add_photos_title')
                                : this.props.t('watchlists_view_container.confirm_delete_watchlist')
                        } "${this.state.selectedWatchlist?.name}"`}
                        description={
                            isCurrentWatchlistRunningAddPhotos
                                ? this.props.t('watchlists_view_container.confirm_delete_add_photos_text')
                                : this.props.t('watchlists_view_container.confirm_delete_watchlist_text')
                        }
                        isLoading={this.state.isModalLoading}
                    />
                )}
                {this.state.currentModal === WatchlistModalType.Edit && (
                    <EditNameDialog
                        type='Watchlist'
                        isLoading={this.state.isModalLoading}
                        open
                        name={this.state.selectedWatchlist ? this.state.selectedWatchlist.name : ''}
                        onClose={this.handleEditName}
                    />
                )}
                {this.state.currentModal === WatchlistModalType.Details && (
                    <BatchDetailsModal
                        open
                        onClose={this.closeModal}
                        batch={this.state.selectedWatchlist}
                        isWatchlist
                    />
                )}
                {this.state.currentModal === WatchlistModalType.Export && (
                    <ExportQueryModal isWatchlist onClose={this.closeModal} query={this.state.selectedWatchlist} />
                )}
            </>
        );
    };
    render() {
        return (
            <Grid className='scrollable-container' item style={{ flex: 1 }}>
                {this.renderModals()}
                <div className='watchlist-container'>
                    <Grid
                        className='flex-align-center'
                        item
                        style={{ marginTop: '16px', justifyContent: 'space-between' }}>
                        <Fab
                            color='primary'
                            size='small'
                            aria-label='add'
                            onClick={() => this.props.push(`/createwatchlist`)}>
                            <CustomSvgIcon type={svgIcons.plus} size={24} fillColor='white' />
                        </Fab>
                        <DebouncedSearchInput
                            id='watchlists-search'
                            delay={400}
                            onDebounced={(query) =>
                                this.props.fetchWatchlists(query).then(() => this.setState({ query }))
                            }
                        />
                    </Grid>
                    {(this.props.processingWatchlists?.length > 0 || this.props.pendingWatchlist?.length > 0) && (
                        <h4>
                            {this.props.t('watchlists_view_container.processing_title')} (
                            {this.props.processingWatchlists.length + this.props.pendingWatchlist.length})
                        </h4>
                    )}
                    <div>
                        {this.state.isSorting ? (
                            <>
                                <div
                                    style={{
                                        position: 'relative'
                                    }}>
                                    {this.renderPendingWatchlists()}
                                    {this.renderProcessingWatchlists()}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            top: '0',
                                            left: '0',
                                            right: '0',
                                            bottom: '0',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                        <ClipLoader size={100} color={Colors.white}></ClipLoader>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>
                                {this.renderPendingWatchlists()}
                                {this.renderProcessingWatchlists()}
                            </div>
                        )}
                        {}
                    </div>
                    {this.state.query &&
                    this.props.pendingWatchlist.length === 0 &&
                    this.props.finishedWatchlists.length === 0 &&
                    this.props.processingWatchlists.length === 0 ? (
                        <h4>no watchlists found</h4>
                    ) : (
                        this.renderFinishedWatchlists()
                    )}
                </div>
            </Grid>
        );
    }

    renderPendingWatchlists = () => {
        return this.props.pendingWatchlist?.length > 0 ? (
            <DragDropContext onDragEnd={this.handleMoveQuery}>
                <Droppable droppableId='list'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className='processing-container'>
                            {this.props.pendingWatchlist.map((topology, index) => (
                                <SingleProcessingListItem
                                    query={this.state.query}
                                    data={topology}
                                    key={topology.batchId}
                                    isWatchlist={false}
                                    onDelete={() => this.showModal(WatchlistModalType.Delete, topology)}
                                    onDetailsClick={() => this.showModal(WatchlistModalType.Details, topology)}
                                    index={index}
                                    isDraggable={!this.state.isSorting}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : null;
    };

    renderProcessingWatchlists = () => (
        <>
            {this.props.processingWatchlists.length > 0 &&
                this.props.processingWatchlists.map((watchlist, index) => (
                    <SingleProcessingListItem
                        query={this.state.query}
                        data={watchlist}
                        key={watchlist.batchId}
                        isWatchlist
                        onDelete={() => this.showModal(WatchlistModalType.Delete, watchlist)}
                        onDetailsClick={() => this.showModal(WatchlistModalType.Details, watchlist)}
                        index={index}
                        isDraggable={false}
                    />
                ))}
        </>
    );

    renderFinishedWatchlists = () => {
        const sortedTopologies = this.props.finishedWatchlists.slice().sort(sortingSingleListItem);
        return (
            <>
                <h4>
                    {this.props.t('watchlists_view_container.created_watchlists')} (
                    {this.props.finishedWatchlists.length})
                </h4>
                <Grid item>
                    {this.props.finishedWatchlists &&
                        sortedTopologies.map((watchlist) => (
                            <SingleTopologyListItem
                                data={watchlist as any}
                                query={this.state.query}
                                photosCount={this.props.watchlistIdToPhotosCount[watchlist.batchId]}
                                isWatchlist={true}
                                onSelect={() =>
                                    this.props.push(`/watchlist/${watchlist.batchId}?name=${watchlist.name}`)
                                }
                                onDelete={() => this.showModal(WatchlistModalType.Delete, watchlist)}
                                onExport={() => this.showModal(WatchlistModalType.Export, watchlist)}
                                onDetailsClick={() => this.showModal(WatchlistModalType.Details, watchlist)}
                                onEdit={() => this.showModal(WatchlistModalType.Edit, watchlist)}
                                onAddToExisting={() =>
                                    this.props.push(`/add-images/${watchlist.batchId}?name=${watchlist.name}`)
                                }
                                key={watchlist.batchId}
                            />
                        ))}
                </Grid>
            </>
        );
    };
}

const mapDispatchToProps = { fetchWatchlists, push };

const mapStateToProps = (state: RootState) => ({
    finishedWatchlists: getFinishedWatchlists(state),
    pendingWatchlist: getPendingWatchlists(state),
    processingWatchlists: getProcessingWatchlists(state),
    watchlistIdToPhotosCount: state.watchlists.watchlistIdToPhotosCount
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(['watchlists', 'errors'])(WatchlistsViewContainer));
