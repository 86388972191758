import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WhiteSelect from '../components/common/whiteSelect';
import { makeStyles } from '@material-ui/core/styles';
import LocalStorageService from '../services/localStorageService';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export enum SupportedLanguages {
    en = 'en',
    pt = 'pt',
    es = 'es',
    ca = 'ca',
    fr = 'fr'
}

export const SupportedUserGuideLanguages = {
    en: 'en',
    fr: 'fr'
};

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    }
}));

export const LanguageSelect = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const [language, setLanguage] = useState(SupportedLanguages.en);
    const configLanguages = useSelector((state: RootState) => state.configurations.data.supportedLanguages);
    const [supportedLanguages, setSupportedLanguages] = useState([SupportedLanguages.en]);

    useEffect(() => {
        const lang = LocalStorageService.getLanguage();
        if (lang) {
            setLanguage(lang as SupportedLanguages);
        }
        if (configLanguages?.length) setSupportedLanguages(configLanguages);
    });

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
        LocalStorageService.setLanguage(event.target.value);
    };

    return (
        <>
            {supportedLanguages?.length ? (
                <div className={`language-select ${classes.root}`}>
                    <WhiteSelect
                        value={Object.values(supportedLanguages).includes(language) ? language : SupportedLanguages.en}
                        onChange={handleChange}
                        options={Object.values(supportedLanguages).map((languageName) => ({
                            id: languageName,
                            name: i18n.t(languageName, { ns: 'languages' })
                        }))}
                    />
                </div>
            ) : null}
        </>
    );
};

export default LanguageSelect;
