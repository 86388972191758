import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface EditNameDialogProps extends DialogProps {
    onClose(name?: string);
    name: string;
    open: boolean;
    type: 'Watchlist' | 'Case' | 'Individual';
    isLoading?: boolean;
}

const EditNameDialog: React.FC<EditNameDialogProps> = ({ name, open, type, onClose, isLoading, ...props }) => {
    const { t } = useTranslation('dialogs');

    const [value, setValue] = React.useState('');

    useEffect(() => {
        setValue(name);
    }, [name]);

    return (
        <div>
            <Dialog onClose={() => onClose(null)} maxWidth='xs' open={open} fullWidth {...props}>
                <DialogTitle id='form-dialog-title'>
                    {t('edit_name_dialog.rename')} {t('edit_name_dialog.type.' + type)}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='name'
                        label={t('edit_name_dialog.type.' + type) + ' ' + t('edit_name_dialog.name')}
                        fullWidth
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        error={!value && open}
                        helperText={!value && t('edit_name_dialog.name_required')}
                        inputProps={{ maxLength: 120 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={() => onClose(null)} color='primary'>
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={isLoading}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => (value ? onClose(value) : null)}
                        color='primary'>
                        {isLoading && <CircularProgress style={{ marginRight: '5px' }} size={14} />}
                        <span>{t('ok')}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditNameDialog;
