import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import GroupsManagement from './groups/groupsManagement';
import RolesManagement from './rolesManagement';
import './usersManagementContainer.less';
import ApiService, { UserGroup } from '../../services/apiService';
import UsersManagement from './usersManagement';
import Loader from 'react-spinners/CircleLoader';
import { Colors } from '../colors';
import { Permissions } from '../../shared/model/Permissions';
import SystemManagement from './systemManagment';
import authService from '../../services/authService';
import { useTranslation } from 'react-i18next';
import { AllGroupsUsage } from '../../shared/serviceModels/allCountersGroupsUsage';

export interface UserManagementData {
    groups: UserGroup[];
    roles: UserRole[];
    users: User[];
}

export interface UserRole {
    id: number;
    name: string;
    permissions: Permissions[];
}

export interface User {
    userName: string;
    password: string;
    acceptedTermsOfService: boolean;
    creationDate: number;
    email: string;
    enabled: boolean;
    id: number;
    lastUsedUserGroup: number;
    name: string;
    permissions: null;
    refCode: string;
    userGroups: number[];
    userRoles: number[];
}

export interface FcData {
    version: string;
    registeredPoi: number;
    poiLimit: number;
    facesLimit: number;
    registeredFaces: number;
    modelId: string;
    deploymentId: string;
    licenseExpiration: number;
}

export enum ManagementCategory {
    groups = 'groups',
    users = 'users',
    roles = 'roles',
    system = 'system'
}

const UsersManagementContainer: React.FC = () => {
    const { t } = useTranslation('usersManagement');
    const [managementCategory, setManagementCategory] = useState<ManagementCategory>(ManagementCategory.users);
    const [managementData, setManagementData] = useState<UserManagementData>();
    const [systemData, setSystemData] = useState<FcData>();
    const [allGroupsCountersUsageData, setAllGroupsCountersUsageData] = useState<AllGroupsUsage>();
    const [userGroupNamesMapData, setUserGroupNamesMapData] = useState<{ [key: number]: string }>({});

    const managementCategories: ManagementCategory[] = [ManagementCategory.users];

    if (authService.hasPermissions(Permissions.UM_roles_tab)) {
        managementCategories.push(ManagementCategory.roles);
    }

    if (authService.hasPermissions(Permissions.UM_groups_tab)) {
        managementCategories.push(ManagementCategory.groups);
    }

    if (authService.hasPermissions(Permissions.UM_system_tab)) {
        managementCategories.push(ManagementCategory.system);
    }

    const fetchManagementData = async () => {
        const groupsDataPromise = ApiService.getAllGroups();
        const rolesDataPromise = ApiService.getAllRoles();
        const usersDataPromise = ApiService.getAllUsers();
        const requestsData = await Promise.all([groupsDataPromise, rolesDataPromise, usersDataPromise]);

        setManagementData({
            groups: requestsData[0].data.data,
            roles: requestsData[1].data.data,
            users: requestsData[2].data.data
        });

        const groupIdsAndNames = requestsData[0].data.data;
        let groupNamesMap = {};
        groupIdsAndNames.forEach((item) => {
            groupNamesMap = {
                ...groupNamesMap,
                [item.id]: item.name
            };
        });
        setUserGroupNamesMapData(groupNamesMap);
    };

    const fetchSystemData = async () => {
        const fcData = await ApiService.getSystemData();
        const allCountersGroupsUsage = await ApiService.getAllCountersGroupsUsageData();
        setSystemData(fcData.data);
        setAllGroupsCountersUsageData(allCountersGroupsUsage.data);
    };

    const renderCurrentManagement = (
        managementCategory: ManagementCategory,
        managementData: UserManagementData,
        fetchManagementData: () => Promise<void>,
        systemData: FcData,
        allGroupsCountersUsageData: AllGroupsUsage,
        userGroupNamesMapData: { [key: number]: string }
    ) => {
        const loader = (
            <div className='flex-center' style={{ height: '100%', color: Colors.white }}>
                <Loader size={100} color={Colors.lightBlue} />
            </div>
        );
        if (managementData == null) {
            return loader;
        }

        switch (managementCategory) {
            case ManagementCategory.groups:
                return <GroupsManagement managementData={managementData} refreshManagementData={fetchManagementData} />;
            case ManagementCategory.users:
                return <UsersManagement managementData={managementData} refreshManagementData={fetchManagementData} />;
            case ManagementCategory.roles:
                return <RolesManagement managementData={managementData} refreshManagementData={fetchManagementData} />;
            case ManagementCategory.system:
                return systemData ? (
                    <SystemManagement
                        fcData={systemData}
                        allGroupsCountersUsage={allGroupsCountersUsageData}
                        userGroupMap={userGroupNamesMapData}
                    />
                ) : (
                    loader
                );
            default:
                return t('not_found');
        }
    };
    const renderCategoryNavigation = () => (
        <div className='tabs-wrapper'>
            {managementCategories.map((category) => (
                <div
                    className={'category-tab' + (managementCategory === category ? ' active' : '')}
                    key={category}
                    onClick={() => setManagementCategory(category)}>
                    {t(`categories.${category}`)}
                </div>
            ))}
        </div>
    );

    useEffect(() => {
        fetchManagementData();
        fetchSystemData();
    }, []);

    return (
        <Grid className='scrollable-container' item style={{ flex: 1 }}>
            <div className={'users-management-container'}>
                <h4>{t('users_management_title')}</h4>
                {renderCategoryNavigation()}
                {renderCurrentManagement(
                    managementCategory,
                    managementData,
                    fetchManagementData,
                    systemData,
                    allGroupsCountersUsageData,
                    userGroupNamesMapData
                )}
            </div>
        </Grid>
    );
};

export default UsersManagementContainer;
