import * as tus from 'tus-js-client';
import store from '../store';
import AjaxClient from './ajaxClient';
import { MediaFileDetails } from './batchService';
import CommonService from './commonService';

export interface TusdUploadResults {
    uploadPromise: Promise<string>;
    uploadObject: tus.Upload;
}

const megaByteSize = 1000000;
let interval: NodeJS.Timeout;

const createUploadObj = (
    file: File,
    url: string,
    reject: (reason?: any) => void,
    resolve: (value?: string | PromiseLike<string>) => void,
    onProgressCallback: (progressPercentage: number, bytesUploaded?: number) => void,
    onOnline: () => void,
    customOptions: tus.UploadOptions
) => {
    const upload = new tus.Upload(file, {
        endpoint: url,
        removeFingerprintOnSuccess: true,
        retryDelays: [0, 1000, 3000, 5000, 10000, 20000],
        metadata: {
            filename: file.name,
            filetype: file.type
        },
        chunkSize: megaByteSize * store.getState().configurations.data.fileUploadChunkSizeInMegaBytes,
        onError: (error: Error) => {
            if (navigator.onLine) {
                reject(error);
            } else {
                const offlineTime = new Date().getTime();

                window.addEventListener('online', onOnline);
                interval = setInterval(() => {
                    const fiveMin = 1000 * 60 * 5;
                    const isPast = new Date().getTime() - offlineTime > fiveMin;
                    if (isPast) {
                        window.removeEventListener('online', onOnline);
                        clearInterval(interval);
                        reject('NO_INTERNET_CONNECTION');
                    }
                }, 500);
            }
        },
        onProgress: (bytesUploaded: number, bytesTotal: number) => {
            onProgressCallback(Math.round((bytesUploaded / bytesTotal) * 100), bytesUploaded);
        },
        onSuccess: () => {
            resolve(/[^/]*$/.exec(upload.url)[0]);
        },

        ...customOptions
    });

    return upload;
};

const TusdService = {
    uploadFile: (
        url: string,
        file: File,
        onProgressCallback: (progressPercentage: number, bytesUploaded?: number) => void,
        customOptions?: tus.UploadOptions
    ): TusdUploadResults => {
        let upload: tus.Upload;

        const uploadPromise = new Promise<string>((resolve, reject) => {
            const onOnline = () => {
                window.removeEventListener('online', onOnline);
                clearInterval(interval);
                upload = createUploadObj(file, url, reject, resolve, onProgressCallback, onOnline, customOptions);
                upload.start();
            };

            upload = createUploadObj(file, url, reject, resolve, onProgressCallback, onOnline, customOptions);

            // Start the upload
            upload.start();
        });

        return { uploadObject: upload, uploadPromise: uploadPromise };
    },

    uploadFiles: (
        url: string,
        files: File[],
        onProgressCallback: (progressPercentage: number, uploadedCount: number) => void,
        customOptions?: tus.UploadOptions
    ) => {
        let currentUpload: tus.Upload;
        let stopUpload = false;
        const uploadedMediaFilesDetails: MediaFileDetails[] = [];

        const uploadPromise = new Promise<MediaFileDetails[]>(async (resolve, reject) => {
            try {
                const bytesTotal = CommonService.getFilesSize(files);
                let bytesUploaded = 0;

                const handleProgress = (progressPercentage: number, currentFileByteUploaded: number) => {
                    onProgressCallback(
                        Math.round(((bytesUploaded + currentFileByteUploaded) / bytesTotal) * 100),
                        uploadedMediaFilesDetails.length
                    );
                };
                for (const file of files) {
                    if (stopUpload) {
                        continue;
                    }
                    const uploadResults = TusdService.uploadFile(url, file, handleProgress, customOptions);
                    currentUpload = uploadResults.uploadObject;

                    const tusdId = await uploadResults.uploadPromise;
                    bytesUploaded += file.size;

                    uploadedMediaFilesDetails.push({ name: file.name, size: file.size, tusdId });
                }
                resolve(uploadedMediaFilesDetails);
            } catch (error) {
                stopUpload = true;
                reject(error);
            }
        });
        const abortUpload = () => {
            uploadedMediaFilesDetails.forEach((file) => TusdService.deleteFile('/api/files/' + file.tusdId));
            TusdService.abortUpload(currentUpload);
        };

        return { uploadPromise, abortUpload };
    },
    abortUpload: (fileUpload: tus.Upload) => {
        try {
            fileUpload.abort(true);
        } catch (error) {
            TusdService.deleteFile(fileUpload.url);
        }
    },
    deleteFile: (url: string) => {
        tus.Upload.terminate(url, tus.defaultOptions).catch(() => {
            setTimeout(() => {
                TusdService.deleteFile(url);
            }, 2000);
        });
    },

    getAvailableSpace(): Promise<number | 'IGNORE_ERR'> {
        return AjaxClient.get<TusUsageResponse>('/usage/tus/available-space')
            .then((res) => res.data.data.result[0].value[1])
            .catch(() => 'IGNORE_ERR');
    },

    async throwErrorIfNotEnoughSpace(fileSizeInBytes: number) {
        const availableSpace = await TusdService.getAvailableSpace();
        const buffer = 2;
        if (availableSpace !== 'IGNORE_ERR' && fileSizeInBytes * buffer > availableSpace) {
            throw new Error('ERR_NO_SPACE_IN_TUSD_SERVER');
        }
    }
};

export interface Metric {
    __name__: string;
    endpoint: string;
    instance: string;
    job: string;
    metrics_path: string;
    namespace: string;
    node: string;
    persistentvolumeclaim: string;
    service: string;
}

export interface Result {
    metric: Metric;
    value: any[];
}

export interface Data {
    resultType: string;
    result: Result[];
}

export interface TusUsageResponse {
    status: string;
    data: Data;
}

export default TusdService;
