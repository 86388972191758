import { Avatar, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CommonService from '../../services/commonService';
import { svgIcons } from '../common/entities/enums';
import MenuAction from '../common/menuActions';
import { PersonDescriptor } from '../topologyview/VisionSynapse';
import _ from 'lodash';
import { WatchlistAction } from './WatchlistView';
import HighlightedText from '../common/highlightedText';
import FullSizeRepresentingPhoto from '../common/fullSizeRepresentingPhoto';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { useTranslation } from 'react-i18next';

interface WatchlistIndividualProps {
    searchValue: string;
    queryId: string;
    individual: PersonDescriptor;
    onActionClicked: (individual: PersonDescriptor, action: WatchlistAction) => void;
}

const WatchlistIndividual: React.FC<WatchlistIndividualProps> = (props) => {
    const { t } = useTranslation('watchlists');
    const [showFullSizeRepresentingPhoto, setShowFullSizeRepresentingPhoto] = useState(false);
    const options = [
        {
            label: t('watchlist_individual.details'),
            icon: svgIcons.info,
            onClick: () => props.onActionClicked(props.individual, WatchlistAction.Details)
        },
        {
            label: t('watchlist_individual.rename'),
            onClick: () => props.onActionClicked(props.individual, WatchlistAction.Edit),
            icon: svgIcons.edit
        },
        {
            label: t('watchlist_individual.delete'),
            onClick: () => props.onActionClicked(props.individual, WatchlistAction.Delete),
            icon: svgIcons.trashBin
        }
    ];

    const currentLabel = CommonService.getWatchlistLabel(props.individual.labels, props.queryId);

    const handleAvatarClicked = async () => {
        if (currentLabel.metadata?.source) {
            window.open(currentLabel.metadata.source, '_blank');
        } else {
            setShowFullSizeRepresentingPhoto(true);
        }
    };
    return (
        <div className='flex content-item'>
            <div className='flex' style={{ flex: 1 }}>
                <Avatar
                    onClick={handleAvatarClicked}
                    style={{ cursor: 'pointer' }}
                    className='avatar'
                    imgProps={{}}
                    src={`/api/photo/${props.individual.representingPhotoId}`}
                />
                <div>
                    <Grid container style={{ height: '100%', padding: '0 1em' }}>
                        <Typography color='textPrimary' style={{ margin: 'auto', fontWeight: 'bold' }}>
                            <HighlightedText highlight={props.searchValue}>{currentLabel.label}</HighlightedText>{' '}
                            {authService.hasPermissions(Permissions.watchlist_sort) &&
                                `(${t(
                                    'watchlist_individual.appearances'
                                )}: ${props.individual.batchIds.length.toString()})`}
                        </Typography>
                    </Grid>
                </div>
            </div>
            <MenuAction options={options} />
            {showFullSizeRepresentingPhoto && (
                <FullSizeRepresentingPhoto
                    individual={props.individual}
                    queryId={props.queryId}
                    onClose={() => setShowFullSizeRepresentingPhoto(false)}
                />
            )}
        </div>
    );
};

export default React.memo(
    WatchlistIndividual,
    (prevProps, nextProps) =>
        _.isEqual(prevProps.individual, nextProps.individual) && prevProps.searchValue === nextProps.searchValue
);
