import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { RootState } from '..';
import ApiService from '../../services/apiService';
import CommonService from '../../services/commonService';
import { QueryMetadata, TopologyStatus } from './topologiesSlice';

export interface WatchlistEntry {
    batchId: string;
    username: string;
    name: string;
    created: number;
    status: TopologyStatus;
    orderIndex: number;
    progress: number;
    fcExecutionId: string;
    userGroupId: number;
    error: string;
    zipFileName: string;
    fileSizeKB: number;
    updateUserName: string;
    updatedAt: number;
    metadata: QueryMetadata;
    index: number;
}

export interface WatchlistsState {
    data: WatchlistEntry[];
    watchlistIdToWatchlistName: Record<string, string>;
    watchlistIdToPhotosCount: Record<string, number>;
    isLoading: boolean;
    error: string;
}

const initialState: WatchlistsState = {
    data: [],
    watchlistIdToWatchlistName: {},
    watchlistIdToPhotosCount: {},
    isLoading: false,
    error: ''
};

const watchlistsSlice = createSlice({
    name: 'watchlists',
    initialState,
    reducers: {
        getWatchlistsStart(state) {
            state.isLoading = true;
        },
        getWatchlistsSuccess(state, action: PayloadAction<WatchlistEntry[]>) {
            state.data = action.payload;
            state.watchlistIdToWatchlistName = action.payload.reduce((watchlistIdToTopologyName, watchlist) => {
                watchlistIdToTopologyName[watchlist.batchId] = watchlist.name;
                return watchlistIdToTopologyName;
            }, {});
            state.isLoading = false;
        },
        getWatchlistsFailed(state, action: PayloadAction<AxiosError>) {
            state.error = CommonService.getErrorMessage(action.payload);
            state.isLoading = false;
        },

        getPhotosCountSuccess(state, action: PayloadAction<Record<string, number>>) {
            state.watchlistIdToPhotosCount = action.payload;
        }
    }
});

const watchlistsByStatus = createSelector(
    (state: RootState) => state.watchlists.data,
    (watchlists: WatchlistEntry[]) => {
        const watchlistsByStatus = [];
        const pendingWatchlists = [];
        const processingWatchlists = [];
        const finishedWatchlists = [];

        watchlists.forEach((watchlist) => {
            const isAddMedia = watchlist.metadata?.queryRunHistory?.length > 1;
            if (watchlist.status === TopologyStatus.Pending && !isAddMedia) {
                pendingWatchlists.push(watchlist);
            } else if (
                (watchlist.status === TopologyStatus.Processing && !isAddMedia) ||
                (watchlist.status === TopologyStatus.Error && !isAddMedia)
            ) {
                processingWatchlists.push(watchlist);
            } else if (watchlist.status === TopologyStatus.Finished || isAddMedia) {
                finishedWatchlists.push(watchlist);
            }
        });
        watchlistsByStatus.push(pendingWatchlists, processingWatchlists, finishedWatchlists);
        return watchlistsByStatus;
    }
);

export const getPendingWatchlists = createSelector(watchlistsByStatus, ([pending]) => pending);
export const getProcessingWatchlists = createSelector(watchlistsByStatus, ([, processing]) => processing);
export const getFinishedWatchlists = createSelector(watchlistsByStatus, ([, , finished]) => finished);

export const {
    getWatchlistsStart,
    getWatchlistsSuccess,
    getWatchlistsFailed,
    getPhotosCountSuccess
} = watchlistsSlice.actions;

export default watchlistsSlice.reducer;

export const fetchWatchlists = (query?: string): any => async (dispatch) => {
    try {
        dispatch(getWatchlistsStart());
        const response = await ApiService.fetchAllWatchlists(query);
        dispatch(getWatchlistsSuccess(response.data));
        try {
            const queryIds = response.data.map((topology) => topology.batchId);
            if (queryIds.length > 0) {
                const countResponse = await ApiService.getPersonsCount(queryIds);
                dispatch(getPhotosCountSuccess(countResponse.data.result));
            }
        } catch (err) {
            console.error(err);
        }
    } catch (err) {
        dispatch(getWatchlistsFailed(err));
    }
};
