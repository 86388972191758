import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { TopologyEntry, TopologyStatus } from '../../store/slices/topologiesSlice';
import { WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { Colors } from '../colors';
import { useTranslation } from 'react-i18next';

interface RowDetailsProps {
    data: TopologyEntry | WatchlistEntry;
    photosCount?: number;
}

const RowDetails: React.FC<RowDetailsProps> = ({ data, photosCount }) => {
    const { t } = useTranslation('topologiesView');

    const renderField = (label: string, value: React.ReactNode) => (
        <div className='field'>
            <span className='field-name'>{label} | </span>
            <span className='field-value'>{value}</span>
        </div>
    );

    const isUpdated = data.metadata?.queryRunHistory?.length > 1;

    return (
        <>
            <div className='item-details'>
                {renderField(
                    isUpdated ? t('results.updated') : t('results.created'),
                    t('results.date', { date: new Date((isUpdated ? data.updatedAt : data.created) * 1000) })
                )}
                {renderField(t('results.created_by'), data.updateUserName || data.username)}
                {data.zipFileName && renderField(t('results.file_name'), data.zipFileName)}
                {photosCount > -1 && renderField(t('results.images_count'), photosCount)}
            </div>
            {(data.status === TopologyStatus.Processing ||
                (data.status === TopologyStatus.Error && data.metadata.queryRunHistory?.length === 1)) && (
                <LinearProgress
                    className='query-progress'
                    variant='determinate'
                    style={{ margin: '3px 0' }}
                    value={data.progress}
                />
            )}
            {data.status === TopologyStatus.Pending && (
                <div style={{ fontSize: '11px', marginTop: '4px', color: Colors.lightGray }}>
                    <span style={{ color: Colors.individualsOutlineGrey, fontWeight: 400 }}>
                        {t('results.status')} |
                    </span>{' '}
                    {t('results.pending') + ' (' + t('results.queue_position') + ': ' + data.orderIndex + ')'}
                </div>
            )}
        </>
    );
};
export default RowDetails;
