import React from 'react';
import { withStyles, Tabs, Tab, Theme, createStyles, TabProps } from '@material-ui/core';
import { Colors } from '../colors';

interface StyledTabProps extends TabProps {
    label: string;
}

export const AntTabs = withStyles({
    root: {
        // @ts-ignore
        borderBottom: (props) => (props.borderBottom ? '1px solid rgba(255, 255, 255, 0.32)' : 'none')
    },
    indicator: {
        // @ts-ignore
        backgroundColor: (props) => (props.selectedBorderColor ? props.selectedBorderColor : Colors.clickable),
        height: '3px'
    }
})(({ borderBottom, selectedBorderColor, ...props }: any) => <Tabs {...props} />);

export const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(4),
            color: '#dedede',
            opacity: 0.5,

            '&:hover': {
                color: Colors.white,
                opacity: 0.5
            },
            '&$selected': {
                color: Colors.white,
                fontWeight: 500,
                opacity: 0.9
            },
            '&:focus': {
                color: Colors.white,
                opacity: 0.9
            }
        },
        textColorInherit: {
            '&:disabled': {
                opacity: 0.3
            }
        }
    })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
