import React, { PureComponent } from 'react';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import './aduitExpandedRow.less';

interface AuditExpandedRowProps {
    data?: { metadata: Record<string, any> };
}

class AuditExpandedRow extends PureComponent<AuditExpandedRowProps, null> {
    render() {
        return (
            <div className={'audit-expanded-row'}>
                <table cellPadding={0} cellSpacing={0}>
                    <tbody>
                        {Object.keys(this.props.data.metadata).map((key) => {
                            if (key === 'message' && !authService.hasPermissions(Permissions.audit_admin)) {
                                return null;
                            }

                            if (!this.props.data.metadata[key]) {
                                return null;
                            }

                            return (
                                <tr key={key}>
                                    <td>{key}:</td>
                                    <td>
                                        <pre>{JSON.stringify(this.props.data.metadata[key], undefined, 4)}</pre>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AuditExpandedRow;
