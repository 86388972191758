import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StyleVariables, typographySubtitle } from '../styleVariables';
import { Colors } from '../colors';
import {
    changeSearchQueryCommon,
    changeSimImageQuery,
    navigateSynapse,
    TopologyRouterSelectors
} from '../../store/router/topologyActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { svgIcons } from '../common/entities/enums';
import { changeSearchType, clearSearchResults, serverSearch } from '../../store/slices/searchSlice';
import { useTranslation } from 'react-i18next';
import SearchByPhotoInput from '../common/search/SearchByPhotoInput';
import { SearchSimilarImageData } from '../../services/searchService';
import SimilarImageType from './similarImageType';
import authService from '../../services/authService';
import { AdvancedSearchTypes } from './advancedSearch';
import {
    AdvSearchRequestParams,
    buildAdvSearchRequestData,
    getBase64FromImage,
    getSimilarImageNameAndType
} from '../../shared/helpers/searchHelpers';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import ThumbnailImage from '../thumbnailImge/thumbnailImage';
import { extractBase64EncodedString } from '../../utils/utils';
import { resizeFile } from '../../shared/helpers/imageHelper';
import CancelApiRequestService, { CancelTokenTypes } from '../../services/cancelApiRequestService';

export enum SimilarImageSearchTypes {
    // SIMILAR_OBJECT = 'SIMILAR_OBJECT'
    SIMILAR_BACKGROUND = 'SIMILAR_BACKGROUND',
    SIMILAR_IMAGE = 'SIMILAR_IMAGE'
}

interface SimilarImageFormProps {
    withSimilarImageIcon?: boolean;
    imageName?: string;
    closePopupWhenRadioButtonPressed?: boolean;
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative'
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
        paddingRight: (props: SimilarImageFormProps) => !props.withSimilarImageIcon && StyleVariables.gap * 2,
        paddingLeft: (props: SimilarImageFormProps) => !props.withSimilarImageIcon && StyleVariables.gap * 2,
        '& .MuiFormGroup-root': {
            height: '100%',
            alignItems: 'center'
        },
        border: (props: SimilarImageFormProps) => !props.withSimilarImageIcon && `1px solid ${Colors.contrast}`,
        borderRadius: (props: SimilarImageFormProps) => !props.withSimilarImageIcon && '4px'
    },
    upload: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingRight: StyleVariables.gap * 2,
        borderRight: `1px solid ${Colors.contrast}`
    },
    uploadIcon: {
        '&:hover': {
            fill: Colors.accent
        }
    },
    input: {
        ...typographySubtitle,
        color: Colors.white,
        paddingLeft: StyleVariables.gap * 2,
        flexGrow: 1
    },
    hidden: {
        fontSize: 0
    },
    type: {
        fontWeight: 600,
        marginLeft: StyleVariables.gap / 2
    },
    clear: {
        '&:hover': {
            fill: Colors.accent
        }
    },
    submit: {
        '.similar-image-clear + &': {
            marginLeft: StyleVariables.gap * 2
        }
    }
});

const SimilarImageForm: React.FC<SimilarImageFormProps> = (props: SimilarImageFormProps) => {
    const { t } = useTranslation(['advancedSearch', 'common']);
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const simImageQuery = getSimilarImageNameAndType(
        useSelector(TopologyRouterSelectors.getSimilarImageQuery),
        t('similar_image_form.by'),
        true
    ); // image name
    const batchId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const topologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const groupId = authService.getAccessTokenData().lastUsedUserGroup;
    const defaultType = SimilarImageSearchTypes.SIMILAR_BACKGROUND;
    const [inputValue, setInputValue] = useState(simImageQuery); // image name
    const [uploadedFile, setUploadedFile] = useState<string>(null); // image base64
    const [type, setType] = useState(defaultType);
    const [menuWidth, setMenuWidth] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState<string>(null);
    const [thumbnailWidth, setThumbnailWidth] = useState<number>(0);
    const [thumbnailHeight, setThumbnailHeight] = useState<number>(0);

    useEffect(() => {
        if (!props.withSimilarImageIcon) {
            if (searchType !== AdvancedSearchTypes.similarImage) {
                dispatch(changeSearchType({ searchType: AdvancedSearchTypes.similarImage }));
            }
            Object.keys(AdvancedSearchTypes).forEach((type) => {
                if (type !== AdvancedSearchTypes.similarImage) {
                    dispatch(changeSearchQueryCommon(null, AdvancedSearchTypes[type]));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!props.withSimilarImageIcon) {
            dispatch(clearSearchResults());
            dispatch(changeSearchType({ searchType: AdvancedSearchTypes.similarImage }));
            setInputValue(simImageQuery || '');

            setThumbnailImage(null);
            setUploadedFile(null);

            if (simImageQuery && uploadedFile) {
                const params: AdvSearchRequestParams = {
                    searchType: AdvancedSearchTypes.similarImage,
                    imageBase64: uploadedFile,
                    similarImageSearchType: type,
                    groupId: groupId
                };
                dispatch(
                    serverSearch(
                        buildAdvSearchRequestData(params) as SearchSimilarImageData,
                        AdvancedSearchTypes.similarImage
                    )
                );
            }
        }
    }, [topologyPhotos]);

    useEffect(() => {
        setMenuWidth(ref.current.offsetWidth);
    }, [ref]);

    const clearSearch = () => {
        CancelApiRequestService.cancelApiRequests(CancelTokenTypes.SimilarImage);
        setUploadedFile(null);
        dispatch(clearSearchResults());
        dispatch(changeSearchType({ searchType: AdvancedSearchTypes.similarImage }));
        setInputValue('');
        setType(defaultType);
        setThumbnailImage(null);
        dispatch(changeSimImageQuery(null));
    };

    const on64BaseFileLoad = (res: string) => {
        setUploadedFile(extractBase64EncodedString(res));
    };

    const createThumbnailImage = async (file: File) => {
        const image: File = await resizeFile(file);
        const objectURL = URL.createObjectURL(image);
        const optimizeImage = new Image();
        optimizeImage.src = objectURL;
        optimizeImage.onload = function () {
            setThumbnailWidth(optimizeImage.width);
            setThumbnailHeight(optimizeImage.height);
        };
        setThumbnailImage(objectURL);
    };

    const handleInputChange = (file: File) => {
        if (!file) {
            clearSearch();
        } else {
            getBase64FromImage(file, on64BaseFileLoad);
            setInputValue(file.name);
            createThumbnailImage(file);
        }
    };

    const handleServerSearch = (imgData: string) => {
        if (!imgData) {
            return;
        }
        const imageName = props.withSimilarImageIcon ? props.imageName : inputValue;
        const params: AdvSearchRequestParams = {
            searchType: AdvancedSearchTypes.similarImage,
            imageBase64: imgData ?? uploadedFile,
            similarImageSearchType: type,
            groupId: groupId
        };
        dispatch(
            serverSearch(buildAdvSearchRequestData(params) as SearchSimilarImageData, AdvancedSearchTypes.similarImage)
        );
        dispatch(
            navigateSynapse(
                { batchId, tab: 'gallery' },
                { similarImage: imageName + ' ' + t('similar_image_form.by') + ' ' + type.toLowerCase() }
            )
        );
    };

    const handleSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        handleServerSearch(uploadedFile);
    };

    const handleSelectType = (event) => {
        event.preventDefault();
        if (!!event.target.value) setType(event.target.value as SimilarImageSearchTypes);
    };

    return (
        <div className={`similar-image-form-container ${classes.root}`} ref={ref}>
            <form id='similarImageForm' className={`similar-image-form ${classes.form}`} onSubmit={handleSubmit}>
                {!props.withSimilarImageIcon && (
                    <div className={`similar-image-upload ${classes.upload}`}>
                        <SearchByPhotoInput
                            onFileChange={handleInputChange}
                            iconType={svgIcons.photoPlus}
                            iconSize={24}
                            iconColor={inputValue ? Colors.accent : Colors.white}
                            iconCustomClass={classes.uploadIcon}
                        />
                    </div>
                )}
                {!props.withSimilarImageIcon && (
                    <div className={`similar-image-fake-input ${classes.input}`}>
                        {!!inputValue && (
                            <>
                                <span className={`similar-image-hidden ${classes.hidden}`}>
                                    {t('similar_image_form.uploaded_file_name')}
                                </span>
                                <span className={`similar-image-input-value`}>{inputValue}</span>
                                <span className={`similar-image-type ${classes.type}`}>
                                    {t('similar_image_form.by') + ' ' + t('similar_image_form.types.' + type)}
                                </span>
                            </>
                        )}
                    </div>
                )}

                <ThumbnailImage image={thumbnailImage} hoverWidth={thumbnailWidth} hoverHeight={thumbnailHeight} />

                <SimilarImageType
                    onSelectType={handleSelectType}
                    value={type}
                    formWidth={menuWidth}
                    withSimilarImageIcon={props.withSimilarImageIcon}
                    onSubmit={handleServerSearch}
                    closePopupWhenRadioButtonPressed={props.closePopupWhenRadioButtonPressed}
                />

                {!props.withSimilarImageIcon && inputValue && (
                    <IconButton
                        data-test-id='similar-image-clear'
                        aria-label={t('similar_image_form.clear')}
                        onClick={clearSearch}
                        className='similar-image-clear'>
                        <CustomSVGIcon
                            type={svgIcons.close}
                            fillColor={Colors.white}
                            customClass={`similar-image-form-clear ${classes.clear}`}
                            size={22}
                        />
                    </IconButton>
                )}

                {!props.withSimilarImageIcon && (
                    <Button
                        type='submit'
                        className={`similar-image-submit ${classes.submit}`}
                        size='small'
                        variant='contained'
                        color='primary'>
                        {t('similar_image_form.submit')}
                    </Button>
                )}
            </form>
        </div>
    );
};

export default SimilarImageForm;
