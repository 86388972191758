import AjaxClient from './ajaxClient';
import { ResourceType } from './apiService';

const baseUrl = '/audit';

export enum AuditCategory {
    userSecurity = 'USER_SECURITY',
    topologyActions = 'TOPOLOGY_ACTIONS',
    imageActions = 'IMAGE_ACTIONS',
    poiActions = 'POI_ACTIONS'
}

export interface AuditGetResponse<T> {
    page: number;
    pageSize: number;
    content: T[];
    totalElements: number;
    totalPages: number;
}

export interface AuditEvent {
    customerId: number;
    eventDate: number;
    eventType: string;
    metadata: Record<string, any>;
    userGroupId: number;
    userId: number;
}

export interface AuditUserSecurityEvents extends AuditEvent {}

export interface AuditTopologyEvents extends AuditEvent {
    flowId: string;
    queryId: string;
    topologyName: string;
}

export interface AuditImageEvents extends AuditEvent {
    flowId: string;
    imageName: string;
    queryId: string;
    queryName: string;
}

export interface AuditPoiEvents extends AuditEvent {
    flowId: string;
    poiName: string;
    queryName: string;
}

type Order = 'asc' | 'desc';

const AuditService = {
    fetchUserSecurityEvents(
        sort: string,
        order: Order,
        page: number,
        pageSize: number,
        showAllUserGroups: boolean,
        columnsToHide: string[] | null
    ) {
        const hiddenColumns = columnsToHide?.length ? columnsToHide.join(',') : columnsToHide;
        return AjaxClient.get<AuditGetResponse<AuditUserSecurityEvents>>(`${baseUrl}/userSecurity/events`, {
            params: {
                sort,
                order,
                page,
                pageSize,
                showAllUserGroups,
                hiddenColumns
            }
        });
    },
    fetchTopologyActionsEvents(
        sort: string,
        order: Order,
        page: number,
        pageSize: number,
        showAllUserGroups: boolean,
        columnsToHide: string[] | null
    ) {
        const hiddenColumns = columnsToHide?.length ? columnsToHide.join(',') : columnsToHide;
        return AjaxClient.get<AuditGetResponse<AuditTopologyEvents>>(`${baseUrl}/topologyActions/events`, {
            params: {
                sort,
                order,
                page,
                pageSize,
                showAllUserGroups,
                hiddenColumns
            }
        });
    },
    fetchImageActionsEvents(
        sort: string,
        order: Order,
        page: number,
        pageSize: number,
        showAllUserGroups: boolean,
        columnsToHide: string[] | null
    ) {
        const hiddenColumns = columnsToHide?.length ? columnsToHide.join(',') : columnsToHide;
        return AjaxClient.get<AuditGetResponse<AuditImageEvents>>(`${baseUrl}/imageActions/events`, {
            params: {
                sort,
                order,
                page,
                pageSize,
                showAllUserGroups,
                hiddenColumns
            }
        });
    },
    fetchPoiActionsEvents(
        sort: string,
        order: Order,
        page: number,
        pageSize: number,
        showAllUserGroups: boolean,
        columnsToHide: string[] | null
    ) {
        const hiddenColumns = columnsToHide?.length ? columnsToHide.join(',') : columnsToHide;
        return AjaxClient.get<AuditGetResponse<AuditImageEvents>>(`${baseUrl}/poiActions/events`, {
            params: {
                sort,
                order,
                page,
                pageSize,
                showAllUserGroups,
                hiddenColumns
            }
        });
    },
    logViewEvent(queryId: string, queryName: string, type: ResourceType) {
        return AjaxClient.post(`${baseUrl}/topologyActions/view`, {
            queryId,
            queryName,
            type
        });
    }
};

export default AuditService;
