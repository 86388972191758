import React from 'react';

interface ProgressBarCounterProps {
    text: string;
    currentText?: string;
    current: number;
    max: number;
}

export const ProgressBarCounter = (props: ProgressBarCounterProps) => {
    return (
        <div className='vv-progress-bar-counter'>
            <div className='counter-top'>
                <div className='vv-label'>{props.text}</div>
                <div className='current-total-container'>
                    <div className='current'>{`${Number(props.current).toLocaleString()} ${
                        props.currentText ? props.currentText : ''
                    }`}</div>
                    <div>/{Number(props.max).toLocaleString()}</div>
                </div>
            </div>
            <div className='counter-bottom'>
                <div className='bar-container'>
                    <div
                        className='bar'
                        style={{ width: `${Math.min(Math.floor((props.current / props.max) * 100), 100)}%` }}
                    />
                </div>
            </div>
        </div>
    );
};
