import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import AllImagesGallery from './allImagesGallery';
import SearchResultsGallery from './searchResultsGallery';
import './galleryTab.less';
import { changeSeed, navigateSynapse, TopologyRouterSelectors } from '../../../store/router/topologyActions';
import TopologyPopupGalleryWrapper from '../../popupGallery/topologyPopupGalleryWrapper';
import GalleryService from '../../../services/galleryService';
import { AdvancedSearchTypes } from '../../advancedSearch/advancedSearch';
import { useTranslation } from 'react-i18next';
import {
    AdvSearchRequestParams,
    buildAdvSearchRequestData,
    getSimilarImageNameAndType
} from '../../../shared/helpers/searchHelpers';
import { SearchByTextRequestData, SearchSimilarImageData } from '../../../services/searchService';
import { SimilarImageSearchTypes } from '../../advancedSearch/similarImageForm';
import {
    deleteImageFromUISearchGallery,
    serverSearch,
    setDisplaySearchGallery
} from '../../../store/slices/searchSlice';
import authService from '../../../services/authService';

export interface GalleryProps {
    onImageClicked: (e) => void;
    title: string;
}

const GalleryTab: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['topologyView', 'common', 'advancedSearch']);
    const searchError = useSelector((state: RootState) => state.search.error);
    const [currentImageId, setCurrentImageId] = useState<string | null>(null);
    const topologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const topologyMetadata = useSelector((state: RootState) => state.topology.topologyMetadata);
    const selectedConnection = useSelector((state: RootState) => state.topology.selectedConnection);
    const seedIds = useSelector(TopologyRouterSelectors.getSeedIds);
    const batchId = useSelector(TopologyRouterSelectors.getBatchId);
    const searchByTextResults = useSelector((state: RootState) => state.search.searchByTextImageResults);
    const searchResultsPhotos = searchByTextResults?.data.photos || [];
    const displaySearchGallery = useSelector((state: RootState) => state.search.displaySearchGallery);
    const groupId = authService.getAccessTokenData().lastUsedUserGroup;
    const isShowingLoader = useSelector((state: RootState) => state.search.isShowingLoader);
    const searchType = useSelector((state: RootState) => state.search.searchType);
    const similarImageRawQuery = useSelector(TopologyRouterSelectors.getSimilarImageQuery);
    const queries = {
        imageDescriptionQueryObj: useSelector(TopologyRouterSelectors.getImage2TextQuery),
        ocr: useSelector(TopologyRouterSelectors.getOcrQuery),
        similarImage: getSimilarImageNameAndType(
            similarImageRawQuery,
            t('similar_image_form.by', { ns: 'advancedSearch' }),
            true
        )
    };

    const handleOnClose = useCallback(() => {
        setCurrentImageId(null);
        if (searchType !== AdvancedSearchTypes.similarImage && searchType !== AdvancedSearchTypes.imageDescription) {
            dispatch(setDisplaySearchGallery(false));
        }
    }, [searchType]);

    const handleDeleteImageFromSimilarImageGallery = useCallback((photoId) => {
        dispatch(deleteImageFromUISearchGallery({ photoId }));
    }, []);

    const popupGalleryPhotos = useMemo(
        () =>
            GalleryService.convertToPopupGalleryPhotos(
                displaySearchGallery ? searchResultsPhotos : topologyPhotos,
                topologyMetadata,
                seedIds,
                selectedConnection?.personUID
            ),
        [displaySearchGallery, searchResultsPhotos, topologyPhotos, topologyMetadata, seedIds, selectedConnection]
    );

    if (searchError) {
        return <div className='flex-center'>{searchError}</div>;
    }

    const handleGalleryClick = (id: string) => {
        setCurrentImageId(id);
    };

    const handleGalleryDoubleClick = (id: string) => {
        setCurrentImageId(null);

        if (seedIds.length === 1 && seedIds[0] === id) {
            dispatch(navigateSynapse({ batchId, tab: 'relationships' }));
        } else {
            dispatch(changeSeed(id));
        }
    };

    const renderSearchType = () => {
        const similarImageSearchType = getSimilarImageNameAndType(
            similarImageRawQuery,
            t('similar_image_form.by', { ns: 'advancedSearch' }),
            false
        )?.toUpperCase();

        return similarImageSearchType
            ? t('similar_image_form.types.' + similarImageSearchType, { ns: 'advancedSearch' })
            : t(`advancedSettings.${searchType}`, { ns: 'common' });
    };

    const getPopupImageTitle = () => {
        if (displaySearchGallery) {
            return (
                t(`search_results_header.${searchType}_title`) + ' "' + queries[searchType] + '" ' + renderSearchType()
            );
        } else if (searchType === AdvancedSearchTypes.ocr && queries[AdvancedSearchTypes.ocr]) {
            return t(`search_results_header.${searchType}_title`) + ' "' + queries[searchType] + '"';
        } else {
            return t('all_images_gallery.all_photos');
        }
    };

    const handleLoadMoreImages = () => {
        const params: AdvSearchRequestParams = {
            searchType,
            query: queries.imageDescriptionQueryObj.imageDescriptions ? queries.imageDescriptionQueryObj : null,
            similarImageSearchType: getSimilarImageNameAndType(
                similarImageRawQuery,
                t('similar_image_form.by', { ns: 'advancedSearch' }),
                false
            )?.toUpperCase() as SimilarImageSearchTypes,
            groupId: queries.similarImage ? groupId : null
        };
        const data: SearchByTextRequestData | SearchSimilarImageData = buildAdvSearchRequestData(params);
        dispatch(serverSearch({ ...data, bookmark: searchByTextResults.nextBookmark }, searchType));
    };

    const getGalleryComponent = () => {
        if (displaySearchGallery && !currentImageId) {
            return <SearchResultsGallery onImageClicked={handleGalleryClick} title={getPopupImageTitle()} />;
        } else if (!displaySearchGallery && !currentImageId) {
            return <AllImagesGallery onImageClicked={handleGalleryClick} title={getPopupImageTitle()} />;
        } else if (currentImageId !== null) {
            return (
                <TopologyPopupGalleryWrapper
                    title={getPopupImageTitle()}
                    onClose={handleOnClose}
                    images={popupGalleryPhotos}
                    currentImageId={currentImageId}
                    onDoubleClick={handleGalleryDoubleClick}
                    virtualScrollGalleryProps={
                        displaySearchGallery && {
                            loadMoreImages: handleLoadMoreImages,
                            isLoadingMoreImages: isShowingLoader,
                            hasMoreToLoad: !!searchByTextResults?.nextBookmark
                        }
                    }
                    hideSearchByImageName={displaySearchGallery}
                    imageCount={displaySearchGallery && searchByTextResults?.numberOfMatchedImages}
                    handleDeleteImage={displaySearchGallery && handleDeleteImageFromSimilarImageGallery}
                />
            );
        }
    };

    return <div className='gallery-tab-container'>{getGalleryComponent()}</div>;
};

export default React.memo(GalleryTab);
