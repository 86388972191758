import { useEffect, useRef } from 'react';

const useInterval = (callback: Noop, delay: number) => {
    const savedCallback = useRef<Noop>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
};

export default useInterval;
