export const Colors = {
    // common
    dividers: '#2a2c2f',
    black: '#020606',
    clickable: '#5657fb',
    clickableHover: '#2C2E80',
    darkGray: '#28282a',
    lightGray: '#dedede',
    hoverOnIcon: 'rgba(86, 87, 251, 0.4)',
    white: '#ffffff',
    lightBlue: '#26b3ff',
    individualsOutlineGrey: '#74787d',
    confirmation: '#72ef96',
    error: '#f03b16',
    textSecondary: '#a8a8a8',
    textPrimary: '#dedfe0',
    secondary: '#00ddc5',
    seed: '#f38657',
    contrast: '#979797',
    transparentContrast: '#3a403b',

    // components
    recentSearchBg: 'linear-gradient(89.24deg, #242B35 -71.72%, #121213 10.01%, #111113 82.9%, #242B35 128.93%)',

    // new Vision
    accent: '#01E49E'
};
