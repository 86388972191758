import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Network } from '../../components/topologyview/VisionSynapse';
import ApiService from '../../services/apiService';
import CommonService from '../../services/commonService';
import { TopologyRouterSelectors } from '../router/topologyActions';
import { topologyExit } from './sharedActions';
import { AdvancedSettings } from './configurationsSlice';

export interface TopologyEntry {
    batchId: string;
    status: TopologyStatus;
    orderIndex: number;
    progress: number;
    fcExecutionId: string;
    username: string;
    zipFileName: string;
    userGroupId: string;
    name: string;
    created: number;
    finished: number;
    error: string;
    origins: string[];
    metadata: QueryMetadata;
    updatedAt: number;
    updateUserName: string;
}

export interface TopologySpecializer {
    topologyType: TopologyType;
    seedPersonUID: string | null;
}

export type TopologyType = 'Seed' | 'NoSeed' | 'Group';

export enum TopologyStatus {
    Pending = 'PENDING',
    Error = 'ERROR',
    Stopped = 'STOPPED',
    Processing = 'PROCESSING',
    Finished = 'FINISHED'
}

export interface QueryMetadata {
    queryRunHistory: QueryRunHistory[];
}

export interface QueryRunHistory {
    fileName: string;
    startUpdateDate: number;
    finishedUpdateDate: number;
    updateBy: string;
    status: TopologyStatus;
    errors: string[];
    withVideo: boolean;
    videoFramesPerMinute: number;
    automaticSlicing: number;
    unsupportedFiles: UnsupportedFiles[];
    failedFiles: FailedFiles[];
    videos: VideoMetadata[];
    networksMetadata: Record<Network, NetworkMetadata>;
    fcProcessingData: FCProcessingData;
    aggregatedPhotosCount: number;
    uploadedPhotosCount: number;
    photosFromVideosCount: number;
    currentlyAggregatedPhotosCount: number;
    currentlyAggregatedVideosCount: number;
    currentlyAggregatedProfilesCount: number;
    ageAndGenderCompleted: boolean;
    comparePoiCompleted: boolean;
    fileSizeKB: number;
    photosProcessedByFileTransformerCount: number;
    duplicatePhotosCount: number;
    recentPhotosOnly: boolean;
    imageAnalysisCsvJfileIds: string[];
    possibleComparePoiCount: number;
    currentlyComparedPoiCount: number;
    classifyMode: string;
    includeFR: boolean;
    includeOR: boolean;
    includeOCR: boolean;
    includeExplicitContent: boolean;
    includeImage2Text: boolean;
    includeLandmarks: boolean;
    includeSimilarImages: boolean;
    advancedSettings: AdvancedSettings;
    framesWithIndividualsOnly: boolean;
    image2TextAnalyseStatus: RunStatus;
    image2TextPostProcessingStatus: RunStatus;
}

export interface NetworkMetadata {
    photosWithIndividualsOnly: boolean;
    withFriendsAggregation: boolean;
    socialIdentifiersList: SocialIdentifiers[];
    /**
     * @deprecated From version 1.10.0 videos ids are saved in socialIdentifiersList
     */
    videoIds: string[];
    filters: Filters;
    aggregateImages: boolean;
    aggregateVideos: boolean;
}

interface Filters {
    usernames?: string[];
    gender?: string;
}

export interface FCProcessingData {
    photosProcessedByFcCount: number;
    friendsPhotosUploadedToFcCount: number;
    friendsPhotosProcessedByFcCount: number;

    fcPhotosCompleted: boolean;
    fcFriendsCompleted: boolean;
}

export enum RunStatus {
    Pending = 'Pending',
    Started = 'Started',
    Finished = 'Finished',
    Stopped = 'Stopped',
    Error = 'Error'
}

export interface SocialIdentifiers {
    id: string;
    username: string;
    displayName: string;
    type: SocialEntityType;
}

export enum SocialEntityType {
    User = 'User',
    Video = 'Video',
    Page = 'Page',
    Group = 'Group',
    Channel = 'Channel'
}

export interface UnsupportedFiles {
    name: string;
    reason: UnsupportedFileReason;
}

export interface FailedFiles {
    name: string;
}

export type UnsupportedFileReason =
    | 'UNSUPPORTED_FILE_TYPE'
    | 'LOW_QUALITY_VIDEO'
    | 'LOW_QUALITY_IMAGE'
    | 'PHOTO_CONTAIN_MORE_THEN_ONE_PEOPLE'
    | 'PERSON_ALREADY_EXISTS'
    | 'VIDEO_CORRUPTED_METADATA';

export interface VideoMetadata {
    duration: string;
    name: string;
    resolution: string;
    valid: boolean;
}

export interface TopologiesState {
    data: TopologyEntry[];
    topologyIdToPhotosCount: Record<string, number>;
    currentTopology: TopologyEntry;
    isLoading: boolean;
    error: string;
}

const initialState: TopologiesState = {
    data: [],
    currentTopology: null,
    topologyIdToPhotosCount: {},
    isLoading: false,
    error: ''
};

const topologiesSlice = createSlice({
    name: 'topologies',
    initialState,
    reducers: {
        getTopologiesStart(state) {
            state.isLoading = true;
        },
        getTopologiesSuccess(state, action: PayloadAction<TopologyEntry[]>) {
            state.data = action.payload;
            state.isLoading = false;
        },
        getTopologiesFailed(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isLoading = false;
        },

        setCurrentTopology(state, action: PayloadAction<string | TopologyEntry>) {
            if (typeof action.payload === 'string') {
                state.currentTopology = state.data.find((topology) => topology.batchId === action.payload);
            } else {
                state.currentTopology = action.payload;
            }
        },

        getPhotosCountSuccess(state, action: PayloadAction<Record<string, number>>) {
            state.topologyIdToPhotosCount = action.payload;
        },

        clearTopologies(state) {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(topologyExit.type, (state, action) => {
            state.currentTopology = null;
        });
    }
});

export const isAtLeastOneTopologyIncludeImage2TextSelector = createSelector(
    (state: RootState) => {
        const allQueryIds = new Set(TopologyRouterSelectors.getAllQueryIds(state));
        return state.topologies.data.filter((topology) => allQueryIds.has(topology.batchId));
    },
    (currentTopologies) =>
        currentTopologies.some((topology) =>
            topology.metadata.queryRunHistory.some((runHistory) => runHistory.includeImage2Text)
        )
);

export const isAtLeastOneTopologyIncludeOcrSelector = createSelector(
    (state: RootState) => {
        const allQueryIds = new Set(TopologyRouterSelectors.getAllQueryIds(state));
        return state.topologies.data.filter((topology) => allQueryIds.has(topology.batchId));
    },
    (currentTopologies) =>
        currentTopologies.some((topology) =>
            topology.metadata.queryRunHistory.some((runHistory) => runHistory.includeOCR)
        )
);

export const isAtLeastOneTopologyIncludeSimilarImageSelector = createSelector(
    (state: RootState) => {
        const allQueryIds = new Set(TopologyRouterSelectors.getAllQueryIds(state));
        return state.topologies.data.filter((topology) => allQueryIds.has(topology.batchId));
    },
    (currentTopologies) =>
        currentTopologies.some((topology) =>
            topology.metadata.queryRunHistory.some((runHistory) => runHistory.includeSimilarImages)
        )
);

const topologiesByStatus = createSelector(
    (state: RootState) => state.topologies.data,
    (topologies: TopologyEntry[]) => {
        const topologiesByStatus = [];
        const pendingTopologies = [];
        const processingtopologies = [];
        const finishedtopologies = [];

        topologies.forEach((topology) => {
            const isAddMedia = topology.metadata?.queryRunHistory?.length > 1;
            if (topology.status === TopologyStatus.Pending && !isAddMedia) {
                pendingTopologies.push(topology);
            } else if (
                (topology.status === TopologyStatus.Processing && !isAddMedia) ||
                (topology.status === TopologyStatus.Error && !isAddMedia)
            ) {
                processingtopologies.push(topology);
            } else if (topology.status === TopologyStatus.Finished || isAddMedia) {
                finishedtopologies.push(topology);
            }
        });
        topologiesByStatus.push(pendingTopologies, processingtopologies, finishedtopologies);
        return topologiesByStatus;
    }
);

export const getPendingTopologies = createSelector(topologiesByStatus, ([pending]) => pending);
export const getProcessingTopologies = createSelector(topologiesByStatus, ([, processing]) => processing);
export const getFinishedTopologies = createSelector(topologiesByStatus, ([, , finished]) => finished);

export const {
    getTopologiesStart,
    getTopologiesSuccess,
    getTopologiesFailed,
    setCurrentTopology,
    clearTopologies,
    getPhotosCountSuccess
} = topologiesSlice.actions;

export default topologiesSlice.reducer;

export const fetchTopologies = (query?: string): any => async (dispatch) => {
    try {
        dispatch(getTopologiesStart());
        const response = await ApiService.fetchAllTopologies(query);
        dispatch(getTopologiesSuccess(response.data));
        try {
            const queryIds = response.data.map((topology) => topology.batchId);
            if (queryIds.length > 0) {
                const countResponse = await ApiService.getPhotosCount(queryIds);
                dispatch(getPhotosCountSuccess(countResponse.data.result));
            }
        } catch (err) {
            console.error(err);
        }
    } catch (err) {
        dispatch(getTopologiesFailed(CommonService.getErrorMessage(err)));
    }
};
