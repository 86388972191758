import { makeStyles } from '@material-ui/core';
import { Colors } from '../colors';
import { StyleVariables, typographySmall, typographySubtitle } from '../styleVariables';

export const useAdvancedSearchStyles = makeStyles({
    advancedSearchFormWrapper: {
        position: 'absolute',
        top: '0px',
        width: '100%',
        minWidth: '600px',
        height: '450px',
        zIndex: 1,
        backgroundColor: Colors.black,
        border: `1px solid ${Colors.contrast}`
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: StyleVariables.gap * 3,
        paddingTop: StyleVariables.gap * 3,
        height: '50px'
    },
    closeButton: {
        position: 'absolute',
        right: StyleVariables.gap * 3,
        top: StyleVariables.gap * 3
    },
    infoButton: {
        marginLeft: StyleVariables.gap * 2
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        ...typographySubtitle,
        color: Colors.white,
        height: '100%',
        width: '500px',
        lineHeight: '100%',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 5px center',
        outline: 'none',
        flexGrow: 1,
        '&::-webkit-input-placeholder': {
            color: Colors.contrast,
            paddingLeft: StyleVariables.gap * 5
        },
        border: `1px solid ${Colors.contrast}`,
        alignItems: 'center',
        padding: StyleVariables.gap,
        marginTop: StyleVariables.gap * 3
    },
    dropdownWrapper: {
        color: Colors.white,
        backgroundColor: Colors.black,
        width: StyleVariables.gap * 12,
        height: StyleVariables.gap * 5,
        marginTop: StyleVariables.gap * 3,
        textAlign: 'center',
        padding: StyleVariables.gap,
        fontSize: typographySmall.fontSize,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${Colors.contrast}`,
        '&:focus': {
            outline: 'none'
        }
    },
    dropdownItem: {
        justifyContent: 'center'
    },
    noToperatorText: {
        marginTop: StyleVariables.gap * 3,
        fontSize: typographySmall.fontSize,
        width: StyleVariables.gap * 12,
        height: StyleVariables.gap * 5,
        padding: StyleVariables.gap,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${Colors.contrast}`
    },
    searchButtonWrapper: {
        position: 'absolute',
        bottom: StyleVariables.gap * 3,
        right: StyleVariables.gap * 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: StyleVariables.gap * 19
    },
    clearButton: {
        color: Colors.white
    },
    tips: {
        position: 'absolute',
        top: StyleVariables.gap * 55,
        background: '#020606',
        border: `1px solid ${Colors.contrast}`,
        width: '100.1%'
    },
    recentSearchList: {
        width: '500px',
        position: 'absolute',
        border: `1px solid ${Colors.contrast}`,
        background: Colors.black,
        zIndex: 3
    },
    expression1RecentSearchList: {
        top: '108px'
    },
    expression2RecentSearchList: {
        top: '226px'
    },
    expression3RecentSearchList: {
        top: '335px'
    }
});
