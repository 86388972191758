import React, { useRef } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { Colors } from '../../colors';
import { svgIcons } from '../entities/enums';
import CustomSVGIcon from '../misc/CustomSvgIcon';

interface SearchByPhotoProps {
    isLoading?: boolean;
    onFileChange: (file: File) => void;
    iconType?: svgIcons;
    iconSize?: number;
    iconColor?: string;
    iconCustomClass?: string;
}

const SearchByPhotoInput: React.FC<SearchByPhotoProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>();

    return (
        <div className='flex-center'>
            {props.isLoading ? (
                <Loader size={10} color={Colors.white} />
            ) : (
                <CustomSVGIcon
                    data-test-id='search-by-image'
                    onClick={() => inputRef.current.click()}
                    size={props.iconSize ? props.iconSize : 20}
                    type={props.iconType ? props.iconType : svgIcons.imageSearch}
                    customClass={`topology-search-by-image-icon ${props.iconCustomClass}`}
                    fillColor={props.iconColor}
                />
            )}
            <input
                type='file'
                ref={inputRef}
                style={{ display: 'none' }}
                accept='.jpg,.jpeg,.png'
                onChange={(e) => {
                    if (e.target.files[0]) {
                        props.onFileChange(e.target.files[0]);
                    }
                    e.target.value = null;
                }}
            />
        </div>
    );
};
export default SearchByPhotoInput;
