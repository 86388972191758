import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { SynapseMetaData } from '../../store/slices/topologySlice';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { PersonDescriptor } from './VisionSynapse';

interface SidePanelGalleryButtonProps {
    selectedPerson: PersonDescriptor;
    synapseMetadata: SynapseMetaData;
    handleSeeSelectedPhotosClick: () => void;
    seedIds: string[];
}

const SidePanelGalleryButton: React.FC<SidePanelGalleryButtonProps> = (props) => {
    const { t } = useTranslation(['topologyView']);

    const getGalleryButtonText = () =>
        props.seedIds.includes(props.selectedPerson?.personUID)
            ? t('select_connection_actions.actions.seed_gallery')
            : t('select_connection_actions.actions.mutual_gallery');

    const getTooltipText = () => {
        if (props.seedIds.includes(props.selectedPerson?.personUID)) {
            return t('select_connection_actions.actions.show_seed_images');
        }

        if (props.synapseMetadata.isGroupTopology) {
            return t('select_connection_actions.actions.show_mutual_presence_with_Seeds');
        }

        return t('select_connection_actions.actions.show_mutual_presence_with_Seed');
    };

    return (
        <div className='gallery-button-wrapper'>
            <div className='gallery-button' onClick={props.handleSeeSelectedPhotosClick}>
                <CustomSVGIcon type={svgIcons.photos} size={18} tooltip={getTooltipText()} />
                {getGalleryButtonText()}
                <span>{`(${props.synapseMetadata.filteredSelectedPhotoUIDs.length})`}</span>
            </div>
        </div>
    );
};

export default SidePanelGalleryButton;
