import React from 'react';
import { PhotoSourceNetwork } from '../topologyview/VisionSynapse';
import { svgIcons } from './entities/enums';
import CustomSVGIcon from './misc/CustomSvgIcon';

interface NetworkLinkProps {
    url: string;
    network: PhotoSourceNetwork;
    className?: string;
}

const NetworkLink: React.FC<NetworkLinkProps> = ({ url, network, className }) => {
    const goToNetwork = () => {
        window.open(url);
    };

    return (
        <CustomSVGIcon
            customClass={className}
            type={svgIcons[network?.toLowerCase()]}
            size={25}
            customStyle={{ margin: 'auto' }}
            onClick={goToNetwork}
        />
    );
};

export default NetworkLink;
