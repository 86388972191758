import { Checkbox, Chip, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteChangeReason, createFilterOptions } from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useTranslation } from 'react-i18next';

type VVAutocompleteChangeReason = AutocompleteChangeReason | 'delete-chip';
interface Option {
    name: string;
    [key: string]: any;
}

interface SidePanelMultiSelectProps {
    onClose: () => void;
    onAutoCompleteChange: (event: Record<string, any>, value: any[], reason: VVAutocompleteChangeReason) => void;
    options: Option[] | string[];
    value: any[];
    label: string;
    dataTestId?: string;
}

const filterOptions = createFilterOptions<Option | string>({
    matchFrom: 'any',
    stringify: (option) => (typeof option === 'string' ? option : option.name),
    trim: true
});

const SidePanelMultiSelect: React.FC<SidePanelMultiSelectProps> = (props) => {
    const { t } = useTranslation('common');
    return (
        <Autocomplete
            multiple
            className='auto-complete'
            options={props.options}
            disableCloseOnSelect
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            onChange={props.onAutoCompleteChange}
            size='small'
            limitTags={4}
            onClose={props.onClose}
            value={props.value}
            noOptionsText={t('no_options')}
            renderTags={(options, getTagProps) => {
                return options.map((option, index) => (
                    <Chip
                        label={(option as Option)?.name || option}
                        size='small'
                        {...getTagProps({ index })}
                        onDelete={() => {
                            const cloneValues = [...props.value];
                            cloneValues.splice(props.value.indexOf(option), 1);

                            props.onAutoCompleteChange(null, cloneValues, 'delete-chip');
                        }}
                    />
                ));
            }}
            style={{ width: '100%' }}
            filterOptions={filterOptions}
            renderOption={(option, { selected }) => (
                <div className='flex-align-center' style={{ wordBreak: 'break-word' }}>
                    <Checkbox color='primary' style={{ marginRight: 8, fontSize: '13px' }} checked={selected} />
                    {(option as Option)?.name || option}
                </div>
            )}
            renderInput={(params) => <TextField {...params} variant='outlined' label={props.label} fullWidth />}
            data-test-id={props.dataTestId}
        />
    );
};

export default SidePanelMultiSelect;
