import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import authService from '../../services/authService';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { Colors } from '../colors';
import { svgIcons } from '../common/entities/enums';
import { StyleVariables } from '../styleVariables';
import Loader from 'react-spinners/CircleLoader';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Permissions } from '../../shared/model/Permissions';

const useStyles = makeStyles({
    defaultGroup: {
        fontSize: 40,
        textAlign: 'center',
        lineHeight: 1.5,
        width: `calc(100% - ${StyleVariables.sidebarWidth})`
    },
    icon: {
        display: 'block',
        margin: '120px auto 16px auto',
        opacity: 0.8
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:first-child': {
            height: '100%'
        },
        '&:not(:first-child)': {
            width: `calc(100% - ${StyleVariables.sidebarWidth})`
        }
    },
    text: {
        width: '800px',
        margin: '0 auto'
    }
});

const DefaultGroup = () => {
    const { t } = useTranslation('login');
    const classes = useStyles();
    const ssoConfigClient = useSelector((state: RootState) => state.configurations.data.ssoConfigClient);
    const accessToken = authService.getAccessTokenData();
    const [isDefault, setIsDefault] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (ssoConfigClient.sso_enabled) {
            if (accessToken) {
                getIsDefault();
            } else {
                requestUser();
            }
        } else setIsLoading(true);
    }, [ssoConfigClient]);

    const getIsDefault = () => {
        const { groups } = authService.getAccessTokenData();
        if (groups?.length === 1 && parseInt(groups[0]) === ssoConfigClient.default_group) {
            setIsDefault(true);
        } else {
            setIsDefault(false);
        }
        setIsLoading(false);
    };

    const requestUser = async () => {
        setIsLoading(true);
        try {
            const ssoUser = (await authService.getUser()) as any;
            const res = await authService.getUserByName(ssoUser.data[ssoConfigClient.oidc_details_userNameKey]);
            authService.storeTokens(res.data);
            getIsDefault();
            window.location.reload();
        } catch (error) {
            console.log('Error logging in ', error);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className={`default-group ${classes.loader}`}>
                    <Loader size={100} color={Colors.lightBlue} />
                </div>
            ) : isDefault ? (
                <div className={`default-group ${classes.defaultGroup}`}>
                    <CustomSVGIcon
                        customClass={`default-group-icon ${classes.icon}`}
                        fillColor={Colors.white}
                        type={svgIcons.group}
                        size={120}
                    />
                    <p className={`default-group ${classes.text}`}>{t('default_group')}</p>
                </div>
            ) : authService.hasPermissions(Permissions.hide_cases_tab) ? (
                <Redirect to='/usersManagement' />
            ) : (
                <Redirect to='/cases' />
            )}
        </>
    );
};

export default DefaultGroup;
