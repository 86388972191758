import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { RootState } from '../../store';
import Logo from '../common/logo/logo';
import { ProgressBarCounter } from '../common/ProgressBarCounter';
import './topbar.less';
import { useTranslation } from 'react-i18next';

interface TopbarProps extends ReturnType<typeof mapStateToProps> {}

const Topbar: React.FC<TopbarProps> = (props) => {
    const { t } = useTranslation('topbar');
    return (
        <header className='navbar'>
            <Link to='/cases' className='logo-container' style={{ textDecoration: 'none' }}>
                {!authService.hasPermissions(Permissions.show_white_label) && <Logo />}
            </Link>

            {props.counter && (
                <div className='progress-bar-container box'>
                    <ProgressBarCounter
                        text={t('total_plan_usage')}
                        currentText={t('images')}
                        max={props.counter.limit}
                        current={props.counter.usage}
                    />
                </div>
            )}
        </header>
    );
};

const mapStateToProps = (state: RootState) => ({
    counter: state.usage.imageCounter
});

export default connect(mapStateToProps, null)(Topbar);
