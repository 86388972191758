import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import ApiService, { UserGroup } from '../../services/apiService';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { Colors } from '../colors';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import WhiteSelect from '../common/whiteSelect';
import CustomTooltip from '../common/misc/CustomTooltip';
import './sidebar.less';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../translations/languageSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import LocalStorageService from '../../services/localStorageService';
import { SupportedUserGuideLanguages, SupportedLanguages } from '../../translations/languageSelect';

export const SideBar: React.FC = () => {
    const history = useHistory();
    const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
    const [isFetchingGroups, setIsFetchingGroups] = useState(false);
    const [isGroupNameSelectOpen, setIsGroupNameSelectOpen] = useState(false);
    const [isGroupNameTooltipOpen, setIsGroupNameTooltipOpen] = useState(false);
    const { sso_enabled } = useSelector((state: RootState) => state.configurations.data.ssoConfigClient);
    const { t } = useTranslation('sidebar');

    useEffect(() => {
        fetchUserGroups();
    }, []);

    const fetchUserGroups = async () => {
        const res = await ApiService.getUserGroups();

        setUserGroups(
            (res.data.data as UserGroup[]).sort((userGroupA, userGroupB) =>
                userGroupA.name.localeCompare(userGroupB.name)
            )
        );
    };

    const handleGroupChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            setIsFetchingGroups(true);
            await ApiService.changeCurrentUserGroup(event.target.value);
            const res = await authService.refreshToken(authService.getRefreshToken());
            authService.storeTokens(res.data);
            window.location.replace('/');
        } catch (error) {
            toast.error('Unable to change user group.');
        }
    };
    const accessTokenData = authService.getAccessTokenData();

    const getLastUsedGroupName = () => {
        if (userGroups?.length) {
            const lastUsedGroup = userGroups.find(
                (group) => group.id === parseFloat(accessTokenData?.lastUsedUserGroup)
            );
            return lastUsedGroup ? lastUsedGroup.name : userGroups[0].name;
        } else {
            return '';
        }
    };

    const handleLogout = () => {
        if (sso_enabled) {
            authService.logout(true, true);
        } else {
            authService.logout(true);
        }
        history.push('/login');
    };

    const getUserGuideFolderName = () => {
        const currentLanguage = LocalStorageService.getLanguage();

        if (SupportedUserGuideLanguages[currentLanguage]) {
            return currentLanguage;
        }

        return SupportedLanguages.en;
    };

    return (
        <nav className='sidebar'>
            <div className='sidebar-navigation'>
                {!authService.hasPermissions(Permissions.hide_cases_tab) && (
                    <>
                        <NavLink to='/cases'>
                            <div className='sidebar-navigation-item'>{t('menu.cases')}</div>
                        </NavLink>
                        <NavLink to='/watchlists'>
                            <div className='sidebar-navigation-item'>{t('menu.watchlists')}</div>
                        </NavLink>
                    </>
                )}
                {authService.hasPermissions(Permissions.show_audit) && (
                    <NavLink to='/audit'>
                        <div className='sidebar-navigation-item'>{t('menu.audit')}</div>
                    </NavLink>
                )}
                {authService.hasAtLeastOnePermission(
                    Permissions.UM_users_tab,
                    Permissions.UM_roles_tab,
                    Permissions.UM_groups_tab,
                    Permissions.UM_system_tab
                ) && (
                    <NavLink to='/usersManagement'>
                        <div className='sidebar-navigation-item'>{t('menu.users_management')}</div>
                    </NavLink>
                )}
            </div>
            {authService.hasPermissions(Permissions.show_user_guide) && (
                <div className='help'>
                    <Link className='link' to={`/user-guide/${getUserGuideFolderName()}/index.html`} target='_blank'>
                        <CustomSVGIcon customClass='icon' type={svgIcons.help} size={19} />
                        {t('help')}
                    </Link>
                </div>
            )}
            <div className='settings-container'>
                <ul className='settings-list'>
                    <li>
                        <CustomSVGIcon
                            customClass='settings-list-icon'
                            fillColor={Colors.individualsOutlineGrey}
                            type={svgIcons.profile}
                            size={19}
                        />
                        <div className='text-container'>
                            <span className='text-after-icon'>{accessTokenData ? accessTokenData.userName : ''}</span>
                        </div>
                    </li>
                    <li>
                        <CustomSVGIcon
                            customClass='settings-list-icon'
                            fillColor={Colors.individualsOutlineGrey}
                            type={svgIcons.group}
                            size={19}
                        />
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-start' }}
                            className='text-container flex-center'>
                            <span style={{ marginRight: '10px' }} className='text-after-icon'>
                                {t('group')}
                            </span>
                            {isFetchingGroups ? (
                                <Loader size={10} color={Colors.white} />
                            ) : (
                                <CustomTooltip
                                    open={!isGroupNameSelectOpen && isGroupNameTooltipOpen}
                                    onOpen={() => setIsGroupNameTooltipOpen(true)}
                                    onClose={() => setIsGroupNameTooltipOpen(false)}
                                    title={getLastUsedGroupName()}>
                                    <span className='settings-list-select group-name-container'>
                                        <WhiteSelect
                                            onChange={handleGroupChange}
                                            onOpen={() => setIsGroupNameSelectOpen(true)}
                                            onClose={() => setIsGroupNameSelectOpen(false)}
                                            options={userGroups}
                                            value={accessTokenData?.lastUsedUserGroup}
                                        />
                                    </span>
                                </CustomTooltip>
                            )}
                        </div>
                    </li>
                    <li>
                        <CustomSVGIcon
                            customClass='settings-list-icon'
                            fillColor={Colors.individualsOutlineGrey}
                            type={svgIcons.language}
                            size={19}
                        />

                        <div className='text-container'>
                            <LanguageSelect />
                        </div>
                    </li>
                    {!authService.hasPermissions(Permissions.hide_logout) && (
                        <li className='action' onClick={handleLogout}>
                            <CustomSVGIcon
                                customClass='settings-list-icon'
                                fillColor='unset'
                                type={svgIcons.logout}
                                size={19}
                            />
                            <div className='text-container'>
                                <span className='text-after-icon'>{t('logout')}</span>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default React.memo(SideBar);
