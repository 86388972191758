import { Button, Typography } from '@material-ui/core';
import classnames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import { RootState } from '../../store';
import { TopologyRouterSelectors } from '../../store/router/topologyActions';
import { getServerSideFilter } from '../../store/slices/topologySlice';
import { Colors } from '../colors';
import BottomDrawer from '../common/bottomDrawer';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import './autoGroup.less';
import { useTranslation } from 'react-i18next';

interface AutoGroupProps {
    onClose: () => unknown;
    onGroupClick: (seedIds: string[]) => void;
}

export interface AutoGroupData {
    autoGroups: string[][];
    mediators: string[];
}

const AutoGroup: React.FC<AutoGroupProps> = (props) => {
    const { t } = useTranslation('group');
    const [data, setData] = useState<AutoGroupData>(null);
    const [showAll, setShowAll] = useState(false);

    const currentSeeds = useSelector((state: RootState) => TopologyRouterSelectors.getSeedIds(state));
    const crossTopologies = useSelector((state: RootState) => TopologyRouterSelectors.getCommonTopologies(state));
    const batchId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const serverSideFilters = useSelector((state: RootState) => state.filters.serverFilters);

    const minGroups = 5;
    useEffect(() => {
        ApiService.fetchAutoGroups(batchId, crossTopologies, getServerSideFilter(serverSideFilters))
            .then((res) => setData(res.data.result))
            .catch(() => toast.error(t('auto_group.error_fetch_groups')));
    }, []);

    const mediatorGroupsCount = data?.mediators?.length > 0 ? 1 : 0;
    const suggestedGroupsCount = data?.autoGroups.length + mediatorGroupsCount;
    return (
        <BottomDrawer>
            <div className='auto-group-container'>
                {data ? (
                    <>
                        <div className='suggested-count flex-align-center'>
                            {suggestedGroupsCount
                                ? `${suggestedGroupsCount} ${t('auto_group.suggested_groups')}:`
                                : t('auto_group.no_suggested_groups')}
                        </div>
                        <div className='auto-group-buttons'>
                            {mediatorGroupsCount ? (
                                <Button
                                    className={classnames('auto-group-button', {
                                        selected: _.isEqual(currentSeeds, data.mediators)
                                    })}
                                    onClick={() => props.onGroupClick(data.mediators)}
                                    color='primary'
                                    size='small'
                                    disabled={_.isEqual(currentSeeds, data.mediators)}
                                    variant='contained'>
                                    {t('auto_group.mediators')}
                                </Button>
                            ) : null}
                            {data.autoGroups
                                .slice(0, showAll ? suggestedGroupsCount : minGroups - mediatorGroupsCount)
                                .map((autoGroup, index) => {
                                    const isCurrentGroup = _.isEqual(currentSeeds, autoGroup);
                                    return (
                                        <Button
                                            className={classnames('auto-group-button', { selected: isCurrentGroup })}
                                            onClick={() => props.onGroupClick(autoGroup)}
                                            color='primary'
                                            size='small'
                                            disabled={isCurrentGroup}
                                            variant='contained'>
                                            {`#${index + 1} (${autoGroup.length} ${t('auto_group.seeds')})`}
                                        </Button>
                                    );
                                })}
                            {suggestedGroupsCount > minGroups && (
                                <Typography
                                    style={{
                                        alignSelf: 'flex-end',
                                        marginBottom: '2px'
                                    }}
                                    className='text-button'
                                    color='primary'
                                    onClick={() => setShowAll(!showAll)}>
                                    {showAll ? t('auto_group.show_less') : t('auto_group.show_all')}
                                </Typography>
                            )}
                        </div>
                    </>
                ) : (
                    <Loader color={Colors.white} size={18}></Loader>
                )}
                <CustomSVGIcon customClass='close-icon' onClick={props.onClose} type={svgIcons.close} size={20} />
            </div>
        </BottomDrawer>
    );
};

export default React.memo(AutoGroup);
