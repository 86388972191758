import React from 'react';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { svgIcons } from '../common/entities/enums';
import MenuActions, { MenuOption } from '../common/menuActions';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface SelectedConnectionActionsProps {
    isSeed: boolean;
    isGroup: boolean;
    onDeleteClick: () => void;
    onDetailsClick?: () => void;
    onMergeClick: () => void;
    onSplitClick?: () => void;
    onAddLabelClick?: () => void;
    onAddPersonToWatchlistClick: () => void;
    onEditPersonProperties?: () => void;
    onExportToZip: () => void;
    selectedPersonFilteredPhotosCount: number;
}

const SelectedConnectionActions: React.FC<SelectedConnectionActionsProps> = (props) => {
    const { t } = useTranslation('topologyView');
    const isMultipleSelectedConnectionsMode: boolean = useSelector(
        (state: RootState) => state.topology.isMultipleSelectedConnectionsMode
    );

    const actions = [
        {
            icon: svgIcons.tag,
            tooltip: t('select_connection_actions.actions.add_label'),
            onClick: props.onAddLabelClick,
            visible: !isMultipleSelectedConnectionsMode
        },
        {
            icon: svgIcons.binoculars,
            tooltip: t('select_connection_actions.actions.add_individual_to_watchlist'),
            onClick: props.onAddPersonToWatchlistClick,
            visible: true
        },
        {
            icon: svgIcons.export,
            tooltip: t('select_connection_actions.menu_actions.export_to_zip'),
            onClick: props.onExportToZip,
            visible: authService.hasPermissions(Permissions.export_person_to_zip)
        },
        {
            icon: svgIcons.edit,
            tooltip: t('select_connection_actions.menu_actions.edit_properties'),
            onClick: props.onEditPersonProperties,
            visible:
                authService.hasAtLeastOnePermission(Permissions.show_age, Permissions.show_gender) &&
                !isMultipleSelectedConnectionsMode
        },
        {
            icon: svgIcons.merge,
            tooltip: t('select_connection_actions.menu_actions.merge'),
            onClick: props.onMergeClick,
            visible: authService.hasPermissions(Permissions.merge_individual) && !isMultipleSelectedConnectionsMode
        },
        {
            icon: svgIcons.split,
            tooltip: t('select_connection_actions.menu_actions.split'),
            onClick: props.onSplitClick,
            visible: authService.hasPermissions(Permissions.split_individual) && !isMultipleSelectedConnectionsMode
        },
        {
            icon: svgIcons.info,
            tooltip: t('select_connection_actions.menu_actions.details'),
            onClick: props.onDetailsClick,
            visible: !isMultipleSelectedConnectionsMode
        },
        {
            icon: svgIcons.trashBin,
            tooltip: t('select_connection_actions.menu_actions.delete'),
            onClick: props.onDeleteClick,
            visible: authService.hasPermissions(Permissions.delete_individual)
        }
    ];

    return (
        <div className='actions-icons-wrapper'>
            {actions.map(
                (action) =>
                    action.visible && (
                        <div
                            className='action-icon'
                            key={action.icon}
                            data-test-id={`selected-relationship-${svgIcons[action.icon]}`}
                            onClick={() => action.onClick?.()}>
                            <CustomSVGIcon type={action.icon} size={25} tooltip={action.tooltip} />
                        </div>
                    )
            )}
        </div>
    );
};

export default React.memo(SelectedConnectionActions);
