import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { useField } from 'formik';
import React from 'react';
import { svgIcons } from '../entities/enums';
import CustomSvgIcon from '../misc/CustomSvgIcon';

interface RadioGroupFieldProps extends RadioGroupProps {
    name: string;
    disabled?: boolean;
    options: { label: string; tooltip?: React.ReactNode; value: string }[];
}

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({ options, ...props }) => {
    const [field] = useField(props.name);

    return (
        <RadioGroup row aria-label='select' {...field} {...props}>
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    disabled={props.disabled}
                    control={<Radio />}
                    label={
                        option.tooltip ? (
                            <div className='flex-center'>
                                {option.label}
                                <CustomSvgIcon
                                    type={svgIcons.info}
                                    size={20}
                                    customStyle={{ margin: '0 4px' }}
                                    tooltip={option.tooltip}
                                    tooltipProps={{ placement: 'top' }}
                                />
                            </div>
                        ) : (
                            option.label
                        )
                    }
                />
            ))}
        </RadioGroup>
    );
};

export default RadioGroupField;
