import { ClickAwayListener, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { svgIcons } from '../common/entities/enums';
import MenuAction, { MenuOption } from '../common/menuActions';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import CustomSvgIcon from '../common/misc/CustomSvgIcon';
import NetworkLink from '../common/networkLink';
import TreeSelect, { TreeSelectOption } from '../common/treeSelect/treeSelect';
import { PhotoSourceNetwork } from '../topologyview/VisionSynapse';
import { Image } from './popupImageSectionContainer';

import './popupImageHeader.less';
import { useTranslation } from 'react-i18next';
import SimilarImageForm from '../advancedSearch/similarImageForm';
import { useSelector } from 'react-redux';
import { isAtLeastOneTopologyIncludeSimilarImageSelector } from '../../store/slices/topologiesSlice';

interface PopupImageHeaderProps {
    image: Image;
    imagesCount: number;
    currentImageIndex: number;
    onNext: () => void;
    onPrev: () => void;
    actions?: MenuOption[];
    displayOptions: TreeSelectOption<string>[];
    displayValues: string[];
    onFilterShapesChange: (selectedItems: string[]) => void;
    onFlagClick?: () => void;
    disableSimilarImage?: boolean;
}

const PopupImageHeader: React.FC<PopupImageHeaderProps> = (props) => {
    const { t } = useTranslation('popupImage');
    const isAtLeastOneTopologyIncludeSimilarImage = useSelector(isAtLeastOneTopologyIncludeSimilarImageSelector);
    const [showDisplayOptions, setShowDisplayOptions] = useState(false);

    const toggleShowDisplayOption = () => setShowDisplayOptions(!showDisplayOptions);

    return (
        <div className='popup-image-header'>
            <div className='left'>
                {props.image.imageSourceUrl && (
                    <NetworkLink
                        className='network-icon'
                        network={props.image.source as PhotoSourceNetwork}
                        url={props.image.imageSourceUrl}
                    />
                )}
                <div className='image-position-indicator'>
                    {props.currentImageIndex + 1 + ' ' + t('of') + ' ' + props.imagesCount}
                </div>
            </div>
            {props.image.name && (
                <div className='middle'>
                    <CustomSvgIcon
                        disabled={props.currentImageIndex === 0}
                        type={svgIcons.left}
                        size={30}
                        tooltip={t('prev')}
                        onClick={props.onPrev}
                        data-test-id='prev-image'
                    />
                    <div title={props.image.name} className='image-name'>
                        {props.image.name}
                    </div>
                    <CustomSvgIcon
                        disabled={props.currentImageIndex + 1 === props.imagesCount}
                        type={svgIcons.right}
                        size={30}
                        tooltip={t('next')}
                        onClick={props.onNext}
                        data-test-id='next-image'
                    />
                </div>
            )}

            <div className='right'>
                {showDisplayOptions && (
                    <ClickAwayListener onClickAway={() => setShowDisplayOptions(false)}>
                        <div className='tree-select-container'>
                            <TreeSelect
                                options={props.displayOptions}
                                selectedOptions={props.displayValues}
                                onChange={props.onFilterShapesChange}
                            />
                        </div>
                    </ClickAwayListener>
                )}

                {isAtLeastOneTopologyIncludeSimilarImage && !props.disableSimilarImage && (
                    <SimilarImageForm withSimilarImageIcon={true} imageName={props.image.name} />
                )}

                {props.onFlagClick && (
                    <IconButton data-test-id='flag-image' onClick={props.onFlagClick}>
                        <CustomSVGIcon
                            tooltip={props.image.flagged ? t('unflag_image') : t('flag_image')}
                            type={props.image.flagged ? svgIcons.flag : svgIcons.flagOutline}
                            fillColor={props.image.flagged ? 'red' : 'currentColor'}
                            size={25}
                        />
                    </IconButton>
                )}

                <IconButton data-test-id='display-options' onClick={toggleShowDisplayOption}>
                    <CustomSVGIcon tooltip={t('display_options')} type={svgIcons.playlistEdit} size={25} />
                </IconButton>

                <MenuAction options={props.actions} />
            </div>
        </div>
    );
};

export default PopupImageHeader;
