import { ImageTypes } from './entities/enums';

export const Consts = {
    UNDERSCORE_DATE_TIME_FORMAT: 'YYYY_MM_DD_HH_MM',
    Brand: {
        title: 'Scorpio',
        path: '#'
    },
    AdminBrand: {
        title: 'Admin',
        path: '#'
    },
    Status: {
        Completed: 'COMPLETE',
        Processing: 'processing',
        Error: 'error',
        Unknown: 'unknown'
    },
    UserRoles: {
        Admin: 'ADMIN',
        User: 'USER',
        SuperAdmin: 'SUPER_ADMIN',
        Scorpio: 'scorpio'
    },
    regex: {
        fileExtension: /\.[^/.]+$/
    },
    defaultResizeImageFileParams: {
        maxWidth: 250,
        maxHeight: 200,
        quality: 100,
        rotation: 0,
        returnType: 'file',
        imageType: ImageTypes.jpeg
    }
};
