import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React, { useEffect } from 'react';
import authService from '../../services/authService';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import LocalStorageService from '../../services/localStorageService';

interface DroneButtonProps {
    isConnected: boolean;
    droneLink: string;
    disabled?: boolean;
}

const DroneButton: React.FC<DroneButtonProps> = (props) => {
    const { t } = useTranslation('createTopology');
    const language = LocalStorageService.getLanguage();

    const messageData = {
        tokens: authService.getTokens(),
        language: language ? language : ''
    };

    const sendMessagesToDrone = (droneWindowInstance: Window) => {
        droneWindowInstance.postMessage(messageData, props.droneLink);
    };

    let interval: NodeJS.Timeout;

    useEffect(() => {
        if (props.isConnected) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [props.isConnected]);

    return (
        <Button
            disabled={props.disabled}
            variant='outlined'
            fullWidth
            className={classnames('v-drone-button', { connected: props.isConnected, disabled: props.disabled })}
            onClick={() => {
                const droneWindowInstance = window.open(props.droneLink);
                interval = setInterval(() => sendMessagesToDrone(droneWindowInstance), 500);
            }}>
            <div className='flex-align-center'>
                <span className='drone-button-text'>FVI</span>
                <CustomSVGIcon fillColor='#0f95fc' customStyle={{ margin: '0 5px' }} type={svgIcons.falcon} size={14} />
                {props.isConnected && <span className='connected-text'>{t('drone_button.connected')}</span>}
            </div>
            {props.isConnected && <CheckIcon style={{ color: 'green' }} />}
            <CustomSVGIcon
                type={svgIcons.info}
                size={18}
                customStyle={{ margin: '0 6px' }}
                tooltip={t('drone_button.tooltip')}
            />
        </Button>
    );
};

export default DroneButton;
