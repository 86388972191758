import { SnackbarContent, Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import { push } from 'connected-react-router';
import { Field, Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import authService from '../../services/authService';
import { DroneData, ExecutionType, MediaFileDetails } from '../../services/batchService';
import CommonService from '../../services/commonService';
import PaymentService from '../../services/paymentService';
import TusdService from '../../services/tusdService';
import { Permissions } from '../../shared/model/Permissions';
import { RootState } from '../../store';
import { getFormattedImageTypes } from '../../store/slices/configurationsSlice';
import { Consts } from '../common/consts';
import { svgIcons } from '../common/entities/enums';
import TextFormField from '../common/formFields/TextFormField';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import TusdPrompt from '../common/tusdPrompt';
import FileAndFolderUpload from '../common/fileAndFolderUpload';
import './createTopology.less';
import CreateTopologyService, { BatchDescriptor } from './createTopologyService';
import DroneButton from './droneButton';
import UploadMediaTooltip from './uploadMediaTooltip';
import AdvancedSettingsFromikSection from './advancedSettings/advancedSettingsFormikSection';
import { RouteComponentProps } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { setCurrentTopology } from '../../store/slices/topologiesSlice';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {
    currentType: ExecutionType;
    formError: string;
    uploadProgress: number;
    initialValues: BatchDescriptor;
    networksFromDrone: DroneData;
    isSubmitting: boolean;
    showAdvancedSettings: boolean;
    valuesBeforeAdvancedSettings: BatchDescriptor;
}

interface RouteParams {
    batchId?: string;
}

interface Props
    extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps>,
        RouteComponentProps<RouteParams> {}

class CreateTopologyContainer extends Component<Props & WithTranslation, State> {
    private scrollToTopRef: React.RefObject<HTMLDivElement>;
    private formikRef: React.RefObject<FormikProps<BatchDescriptor>>;
    private abortFilesUpload: () => void;

    constructor(props) {
        super(props);
        const initValues = new BatchDescriptor(ExecutionType.NoSeed, this.getAddMediaQueryId());
        initValues.batchData.videoFramesPerMinute = this.props.videoDefaultFramesPerMinute || 1;
        initValues.batchData.advancedSettings = this.props.advancedSettings;
        this.state = {
            currentType: ExecutionType.NoSeed,
            formError: null,
            uploadProgress: null,
            initialValues: initValues,
            networksFromDrone: null,
            isSubmitting: false,
            showAdvancedSettings: false,
            valuesBeforeAdvancedSettings: null
        };

        this.scrollToTopRef = React.createRef();
        this.formikRef = React.createRef();
    }

    isAddMedia() {
        return !!this.props.match.params.batchId;
    }

    getAddMediaQueryId() {
        return this.props.match.params.batchId;
    }

    componentDidMount() {
        window.addEventListener('message', this.handleMessageReceived, false);

        if (this.isAddMedia()) {
            ApiService.fetchTopologyMetadata(this.getAddMediaQueryId()).then((res) => {
                this.props.setCurrentTopology(res.data);
            });
        }
    }

    handleMessageReceived = (event: MessageEvent) => {
        if (event.origin === this.props.droneLink) {
            this.setState({ networksFromDrone: event.data });
            if (this.formikRef.current) {
                this.formikRef.current.setFieldError('files', null);
            }
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (
            prevProps.videoDefaultFramesPerMinute !== this.props.videoDefaultFramesPerMinute ||
            prevProps.advancedSettings !== this.props.advancedSettings ||
            (!prevProps.currentTopology && !!this.props.currentTopology)
        ) {
            const initialValues = { ...this.state.initialValues };

            if (this.isAddMedia()) {
                const lastRunHistory = this.getLastRunHistory();
                if (lastRunHistory && lastRunHistory.includeFR !== null) {
                    Object.assign(initialValues.batchData.advancedSettings.entityMatching, lastRunHistory.includeFR);
                }
            }

            initialValues.batchData.videoFramesPerMinute = this.props.videoDefaultFramesPerMinute || 0;
            initialValues.batchData.advancedSettings = this.props.advancedSettings;

            this.setState({ initialValues });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessageReceived);
        if (this.state.isSubmitting) {
            this.cancelUpload();
        }

        if (this.props.currentTopology) {
            this.props.setCurrentTopology(null);
        }
    }

    scrollToTop = () => {
        this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    validate = (values: BatchDescriptor) => {
        const errors: FormikErrors<BatchDescriptor> = {};

        if (!this.isAddMedia() && !values.batchData.batchName) {
            errors.batchData = {
                ...errors.batchData,
                batchName: this.props.t('create_topology_container.topology_name_required')
            };
        }

        if (!this.state.networksFromDrone && !values.files) {
            errors.files = CreateTopologyService.getBoxError();
        }

        if (values.batchData.videoFramesPerMinute > 120 || values.batchData.videoFramesPerMinute <= 0) {
            errors.batchData = {
                ...errors.batchData,
                videoFramesPerMinute: this.props.t('create_topology_container.frames_per_minute')
            };
        }

        return errors;
    };

    onFormSubmit = async (formValues: BatchDescriptor) => {
        if (this.state.isSubmitting) {
            return;
        }
        this.setState({ formError: null, isSubmitting: true });
        this.scrollToTop();

        try {
            const isRateLimitExceededResponsePromise = PaymentService.isRateLimitExceeded();
            const topologyPhotosResponsePromise = this.getAddMediaQueryId()
                ? ApiService.getPhotosCount([this.getAddMediaQueryId()])
                : null;

            const [isRateLimitExceededResponse, topologyPhotosResponse] = await Promise.all([
                isRateLimitExceededResponsePromise,
                topologyPhotosResponsePromise
            ]);

            if (isRateLimitExceededResponse) {
                this.setState({
                    uploadProgress: null,
                    formError: this.props.t('ERR_LIMIT_EXCEEDED', { ns: 'errors' })
                });
            } else if (
                topologyPhotosResponse?.data.result[this.getAddMediaQueryId()] >= this.props.maxTopologySizeInImages
            ) {
                this.setState({
                    uploadProgress: null,
                    formError: this.props.t('create_topology_container.error_images_limit', {
                        currentTopologyName: this.props.currentTopology?.name,
                        maxTopologySizeInImages: this.props.maxTopologySizeInImages.toLocaleString()
                    })
                });
            } else {
                if (formValues.files?.length > 0) {
                    await TusdService.throwErrorIfNotEnoughSpace(CommonService.getFilesSize(formValues.files));
                }
                const filesDetails: MediaFileDetails[] = await this.uploadAnyFilesToTusd(formValues);

                if (this.isAddMedia()) {
                    await CreateTopologyService.addImages(formValues, this.state.networksFromDrone, filesDetails);
                } else {
                    await CreateTopologyService.create(formValues, this.state.networksFromDrone, filesDetails);
                }
                this.setState({ isSubmitting: false }, () => this.props.push('/cases'));
            }
        } catch (error) {
            console.error(error);

            if (!axios.isCancel(error)) {
                this.setState({
                    formError: CommonService.getErrorMessage(error)
                });
            }
        } finally {
            this.setState({ isSubmitting: false, uploadProgress: null });
        }
    };

    handleCancelClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.cancelUpload();
        this.setState({ uploadProgress: null, isSubmitting: false });
        e.preventDefault();
    };

    cancelUpload = () => this.abortFilesUpload?.();

    handleUploadProgress = (progressPercentage: number) => {
        this.setState({ uploadProgress: progressPercentage });
    };

    uploadAnyFilesToTusd = (formValues: BatchDescriptor): Promise<MediaFileDetails[]> => {
        if (formValues.files) {
            const uploadResults = CreateTopologyService.uploadTopologyMediaZip(
                formValues.files,
                this.handleUploadProgress
            );
            try {
                this.abortFilesUpload = uploadResults.abortUpload;
                return uploadResults.uploadPromise;
            } catch (error) {
                console.error(this.props.t('create_topology_container.failed_to_upload_to_tusd') + ': ' + error);
                throw error;
            }
        }
    };

    handleAdvancedSettingsClosed = (isCancel: boolean) => {
        if (isCancel) {
            this.setState({
                initialValues: this.state.valuesBeforeAdvancedSettings,
                showAdvancedSettings: false,
                valuesBeforeAdvancedSettings: null
            });
        } else {
            this.setState({
                showAdvancedSettings: false,
                valuesBeforeAdvancedSettings: null
            });
        }
    };

    getLastRunHistory = () =>
        this.props.currentTopology?.metadata.queryRunHistory[
            this.props.currentTopology.metadata.queryRunHistory.length - 1
        ];

    renderHeader = () => {
        const header = this.isAddMedia()
            ? this.props.t('create_topology_container.add_media_to_case')
            : this.props.t('create_topology_container.create_case');
        return (
            <>
                <h4 className='flex-align-center'>
                    <span>
                        {this.state.isSubmitting
                            ? `${this.props.t('create_topology_container.uploading_media')} (${
                                  this.state.uploadProgress || 0
                              }%) ...`
                            : header}
                    </span>
                    <CustomSVGIcon
                        type={svgIcons.info}
                        size={25}
                        customStyle={{ margin: '0 10px' }}
                        tooltip={CreateTopologyService.getCreateCaseTooltip()}
                        tooltipProps={{ placement: 'right-start' }}
                    />
                </h4>
                {this.isAddMedia() && (
                    <b>
                        {this.props.t('create_topology_container.case_name')}: {this.props.currentTopology?.name}
                    </b>
                )}
            </>
        );
    };

    renderProgressIndicator = () => (
        <div style={{ margin: '10px', display: this.state.isSubmitting ? 'block' : 'none' }}>
            <LinearProgress className='query-progress' variant='determinate' value={this.state.uploadProgress} />
        </div>
    );

    renderError = () =>
        this.state.formError ? (
            <SnackbarContent style={{ marginTop: '1em', backgroundColor: '#d32f2f' }} message={this.state.formError} />
        ) : null;

    renderNameInput = () =>
        this.isAddMedia() ? null : (
            <div style={{ width: 500, margin: '0 auto' }}>
                <Field
                    inputProps={{ maxLength: 120 }}
                    style={{ width: '100%', marginTop: '0' }}
                    name='batchData.batchName'
                    label={this.props.t('create_topology_container.case_name')}
                    disabled={this.state.isSubmitting}
                    component={TextFormField}
                />
            </div>
        );

    renderUploadMediaButton = (values: BatchDescriptor, setFieldValue: any) => (
        <div style={{ width: 500, marginBottom: '20px' }}>
            <FileAndFolderUpload
                withVideo
                multiple
                maxSizeInMegaBytes={this.props.fileUploadMaxSizeInMegaBytes}
                files={values.files}
                title={this.props.t('create_topology_container.upload_media')}
                infoTooltipText={<UploadMediaTooltip />}
                onChange={(file) => {
                    setFieldValue('files', file);
                    if (!values.batchData.batchName) {
                        setFieldValue('batchData.batchName', file[0].name.replace(Consts.regex.fileExtension, ''));
                    }
                }}
                onRemove={() => setFieldValue('files', null)}
                disabled={this.state.isSubmitting}
            />
        </div>
    );

    renderDroneButton = () =>
        authService.hasPermissions(Permissions.case_show_falcon) ? (
            <div style={{ width: 500, marginBottom: '20px' }}>
                <div className='and-or'>{this.props.t('create_topology_container.and_or')}</div>
                <DroneButton
                    isConnected={!!this.state.networksFromDrone}
                    droneLink={this.props.droneLink + '/create'}
                />
            </div>
        ) : null;

    renderAdvancedSettingsButton = (values: BatchDescriptor) => (
        <Button
            onClick={(e) => {
                this.setState({ showAdvancedSettings: true, valuesBeforeAdvancedSettings: values });
            }}
            style={{ fontSize: '16px', marginBottom: '16px' }}
            variant='text'
            color='primary'>
            <CustomSVGIcon customStyle={{ marginRight: '8px' }} type={svgIcons.settings} size={26} />
            {this.props.t('create_topology_container.advanced_settings')}
        </Button>
    );

    renderSubmitAndCancelButton = (values: BatchDescriptor) => (
        <div style={{ width: 500, margin: '32px auto' }}>
            {this.state.uploadProgress !== null && values.files ? (
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={this.handleCancelClicked}
                    disabled={!this.state.isSubmitting}>
                    {this.props.t('create_topology_container.cancel')}
                </Button>
            ) : (
                <Button disabled={this.state.isSubmitting} fullWidth variant='contained' type='submit' color='primary'>
                    {this.props.t('create_topology_container.submit')}
                </Button>
            )}
        </div>
    );

    renderForm = (
        values: BatchDescriptor,
        errors: FormikErrors<BatchDescriptor>,
        touched: FormikTouched<BatchDescriptor>,
        setFieldValue: any
    ) => (
        <>
            {this.renderHeader()}
            {this.renderProgressIndicator()}
            {this.renderError()}
            <div style={{ display: 'inline-flex', flexDirection: 'column', overflowY: 'auto' }}>
                <div ref={this.scrollToTopRef} />

                {this.renderNameInput()}

                <div className={`upload-card${errors.files && touched.files ? ' has-error' : ''}`}>
                    {this.renderUploadMediaButton(values, setFieldValue)}
                    {this.renderDroneButton()}

                    {this.renderAdvancedSettingsButton(values)}
                    {touched.files && <small className='text-red create-topology-box-error'>{errors.files}</small>}
                </div>
            </div>
            {this.renderSubmitAndCancelButton(values)}
        </>
    );

    render() {
        return (
            <div className='create-topology-container'>
                <TusdPrompt isUploading={this.state.isSubmitting} />
                <div className='form-container'>
                    <Formik<BatchDescriptor>
                        initialValues={this.state.initialValues}
                        enableReinitialize={true}
                        //@ts-ignore
                        innerRef={this.formikRef}
                        onSubmit={this.onFormSubmit}
                        validate={this.validate}>
                        {({ values, errors, touched, setFieldValue }) => (
                            <Form className='create-topology-form'>
                                {this.state.showAdvancedSettings ? (
                                    <AdvancedSettingsFromikSection
                                        disabledOptions={{ entityMatching: this.isAddMedia() }}
                                        onClose={this.handleAdvancedSettingsClosed}
                                    />
                                ) : (
                                    this.renderForm(values, errors, touched, setFieldValue)
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = { push, setCurrentTopology };

const mapStateToProps = (state: RootState) => ({
    ...state.configurations.data,
    formattedImageTypes: getFormattedImageTypes(state),
    currentTopology: state.topologies.currentTopology
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(['createTopology', 'errors'])(CreateTopologyContainer));
