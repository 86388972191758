export interface PaymentCounter {
    counterGroup: number;
    counterName: PaymentCounterType;
    limit: number;
    startCountUnixTime: number;
    timeAmount: number;
    timeUnit: string;
    usage: number;
}

export enum PaymentCounterType {
    falconShort = 'falconShort',
    falconLong = 'falconLong',
    visionLong = 'visionLong'
}
