import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { Age, Gender } from '../../store/slices/filtersSlice';
import { SynapseMetaData } from '../../store/slices/topologySlice';
import { getFinishedWatchlists, WatchlistEntry } from '../../store/slices/watchlistsSlice';
import ExportToZipDialog, { ExportDialogMode } from '../dialogs/exportToZipDialog';
import SelectedConnectionActions from './selectedConnectionActions';
import { PersonDescriptor } from './VisionSynapse';
import './styles/sidePanel.less';
import { useTranslation } from 'react-i18next';
import { TopologyRouterSelectors } from '../../store/router/topologyActions';
import { RootState } from '../../store';
import AddMultiplePersonsToWatchlist from './addMultiplePersonsToWatchlist';

interface SidePanelMultipleSelectProps {
    onDeleteClick: (persons: PersonDescriptor[]) => void;
    onMergeClick: () => void;
    onAddPersonToWatchlistClick: () => void;
    onExportToZip: () => void;
    showAddPersonToWatchlist: boolean;
    showExportToZip: boolean;
    handleExportToZipProcessing: (handleExportToZipProcessing: ExportDialogMode) => void;
    handleExportToZipCancel: () => void;
    isExportToZipLoading: boolean;
    scrollToBottomRef: React.RefObject<HTMLDivElement>;
    onSaveOrCancelWatchlist: () => void;
    caseName: string;
}

const SidePanelMultipleSelect: React.FC<SidePanelMultipleSelectProps> = (props) => {
    const { t } = useTranslation(['topologyView', 'popupImage']);
    const seedIds: string[] = useSelector((state: RootState) => TopologyRouterSelectors.getSeedIds(state) || []);
    const selectedPerson: PersonDescriptor = useSelector((state: RootState) => state.topology.selectedConnection);
    const synapseMetadata: SynapseMetaData = useSelector((state: RootState) => state.topology.synapseMetadata);
    const batchId: string = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const watchlistEntries: WatchlistEntry[] = useSelector((state: RootState) => getFinishedWatchlists(state));
    const selectedMultipleConnections: PersonDescriptor[] = useSelector(
        (state: RootState) => state.topology.selectedMultipleConnections
    );
    const numOfConnections: number = useSelector((state: RootState) => state.topology.data.topologyPersons.length);

    const handleDeleteClick = () => props.onDeleteClick(selectedMultipleConnections);

    return (
        <div className='side-panel-section selected-connection-container'>
            <Grid item style={{ flex: 1, marginTop: '4px' }}>
                <div style={{ width: 'fit-content', margin: '0 auto' }}>
                    {authService.hasPermissions(Permissions.show_gender) && selectedPerson?.gender !== Gender.Unknown && (
                        <Typography>
                            {t('side_panel.gender')}:{' '}
                            {t(`gender_filter.${selectedPerson?.gender}`, {
                                ns: 'filters'
                            })}
                        </Typography>
                    )}
                    {authService.hasPermissions(Permissions.show_age) && selectedPerson?.age !== Age.Unknown && (
                        <Typography>
                            {t('side_panel.age')}:{' '}
                            {t(`age_filter.${selectedPerson?.age}`, {
                                ns: 'filters'
                            })}
                        </Typography>
                    )}
                </div>
            </Grid>

            <div className='selected-images-counter'>{`${t('side_panel.selected')} ${
                selectedMultipleConnections.length
            }/${numOfConnections}`}</div>

            {selectedMultipleConnections.length > 0 && (
                <SelectedConnectionActions
                    onDeleteClick={handleDeleteClick}
                    onMergeClick={props.onMergeClick}
                    onAddPersonToWatchlistClick={props.onAddPersonToWatchlistClick}
                    onExportToZip={props.onExportToZip}
                    isSeed={seedIds.includes(selectedPerson?.personUID)}
                    isGroup={synapseMetadata.isGroupTopology}
                    selectedPersonFilteredPhotosCount={synapseMetadata.filteredSelectedPhotoUIDs.length}
                />
            )}

            {!!props.showAddPersonToWatchlist && (
                <AddMultiplePersonsToWatchlist
                    selectedPersons={selectedMultipleConnections}
                    watchlists={watchlistEntries}
                    onSaveOrCancel={props.onSaveOrCancelWatchlist}
                    caseName={props.caseName}
                />
            )}

            {!!props.showExportToZip && (
                <ExportToZipDialog
                    onClose={props.handleExportToZipProcessing}
                    onCancel={props.handleExportToZipCancel}
                    isLoading={props.isExportToZipLoading}
                />
            )}

            <div ref={props.scrollToBottomRef} />
        </div>
    );
};

export default SidePanelMultipleSelect;
