import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format as formatDate, isDate } from 'date-fns';
import { enUS, es, pt, ca, fr } from 'date-fns/locale';

import languages_en from './en/languages.json';
import networks_en from './en/networks.json';
import networks_pt from './pt/networks.json';
import networks_es from './es/networks.json';
import networks_ca from './ca/networks.json';
import networks_fr from './fr/networks.json';
import advancedSearch_en from './en/advancedSearch.json';
import advancedSearch_pt from './pt/advancedSearch.json';
import advancedSearch_es from './es/advancedSearch.json';
import advancedSearch_ca from './ca/advancedSearch.json';
import advancedSearch_fr from './fr/advancedSearch.json';
import common_en from './en/common.json';
import common_pt from './pt/common.json';
import common_es from './es/common.json';
import common_ca from './ca/common.json';
import common_fr from './fr/common.json';
import filters_en from './en/filters.json';
import filters_pt from './pt/filters.json';
import filters_es from './es/filters.json';
import filters_ca from './ca/filters.json';
import filters_fr from './fr/filters.json';
import compareApi_en from './en/compareApi.json';
import compareApi_pt from './pt/compareApi.json';
import compareApi_es from './es/compareApi.json';
import compareApi_ca from './ca/compareApi.json';
import compareApi_fr from './fr/compareApi.json';
import createTopology_en from './en/createTopology.json';
import createTopology_pt from './pt/createTopology.json';
import createTopology_es from './es/createTopology.json';
import createTopology_ca from './ca/createTopology.json';
import createTopology_fr from './fr/createTopology.json';
import login_en from './en/login.json';
import login_pt from './pt/login.json';
import login_es from './es/login.json';
import login_ca from './ca/login.json';
import login_fr from './fr/login.json';
import sidebar_en from './en/sidebar.json';
import sidebar_pt from './pt/sidebar.json';
import sidebar_es from './es/sidebar.json';
import sidebar_ca from './ca/sidebar.json';
import sidebar_fr from './fr/sidebar.json';
import topbar_en from './en/topbar.json';
import topbar_pt from './pt/topbar.json';
import topbar_es from './es/topbar.json';
import topbar_ca from './ca/topbar.json';
import topbar_fr from './fr/topbar.json';
import topologiesView_en from './en/topologiesView.json';
import topologiesView_pt from './pt/topologiesView.json';
import topologiesView_es from './es/topologiesView.json';
import topologiesView_ca from './ca/topologiesView.json';
import topologiesView_fr from './fr/topologiesView.json';
import topologyView_en from './en/topologyView.json';
import topologyView_pt from './pt/topologyView.json';
import topologyView_es from './es/topologyView.json';
import topologyView_ca from './ca/topologyView.json';
import topologyView_fr from './fr/topologyView.json';
import errors_en from './en/errors.json';
import errors_pt from './pt/errors.json';
import errors_es from './es/errors.json';
import errors_ca from './ca/errors.json';
import errors_fr from './fr/errors.json';
import usersManagement_en from './en/usersManagement.json';
import usersManagement_pt from './pt/usersManagement.json';
import usersManagement_es from './es/usersManagement.json';
import usersManagement_ca from './ca/usersManagement.json';
import usersManagement_fr from './fr/usersManagement.json';
import audit_en from './en/audit.json';
import audit_pt from './pt/audit.json';
import audit_es from './es/audit.json';
import audit_ca from './ca/audit.json';
import audit_fr from './fr/audit.json';
import popupGallery_en from './en/popupGallery.json';
import popupGallery_pt from './pt/popupGallery.json';
import popupGallery_es from './es/popupGallery.json';
import popupGallery_ca from './ca/popupGallery.json';
import popupGallery_fr from './fr/popupGallery.json';
import popupImage_en from './en/popupImage.json';
import popupImage_pt from './pt/popupImage.json';
import popupImage_es from './es/popupImage.json';
import popupImage_ca from './ca/popupImage.json';
import popupImage_fr from './fr/popupImage.json';
import watchlists_en from './en/watchlists.json';
import watchlists_pt from './pt/watchlists.json';
import watchlists_es from './es/watchlists.json';
import watchlists_ca from './ca/watchlists.json';
import watchlists_fr from './fr/watchlists.json';
import dialogs_en from './en/dialogs.json';
import dialogs_pt from './pt/dialogs.json';
import dialogs_es from './es/dialogs.json';
import dialogs_ca from './ca/dialogs.json';
import dialogs_fr from './fr/dialogs.json';
import group_en from './en/group.json';
import group_pt from './pt/group.json';
import group_es from './es/group.json';
import group_ca from './ca/group.json';
import group_fr from './fr/group.json';

const locales = { enUS, es, pt, ca, fr };

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        detection: { order: ['localStorage', 'navigator'] },
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (isDate(value)) {
                    const locale = locales[lng];
                    if (format === 'dateOnly') return formatDate(value, 'PP', { locale });
                    if (format === 'dateTime') return formatDate(value, 'PPp', { locale });
                    return formatDate(value, format, { locale });
                }
            }
        },
        defaultNS: 'common',
        resources: {
            en: {
                languages: languages_en,
                networks: networks_en,
                advancedSearch: advancedSearch_en,
                common: common_en,
                filters: filters_en,
                compareApi: compareApi_en,
                createTopology: createTopology_en,
                login: login_en,
                sidebar: sidebar_en,
                topbar: topbar_en,
                topologiesView: topologiesView_en,
                topologyView: topologyView_en,
                usersManagement: usersManagement_en,
                errors: errors_en,
                audit: audit_en,
                popupGallery: popupGallery_en,
                popupImage: popupImage_en,
                watchlists: watchlists_en,
                dialogs: dialogs_en,
                group: group_en
            },
            pt: {
                networks: networks_pt,
                advancedSearch: advancedSearch_pt,
                common: common_pt,
                filters: filters_pt,
                compareApi: compareApi_pt,
                createTopology: createTopology_pt,
                login: login_pt,
                sidebar: sidebar_pt,
                topbar: topbar_pt,
                topologiesView: topologiesView_pt,
                topologyView: topologyView_pt,
                usersManagement: usersManagement_pt,
                errors: errors_pt,
                audit: audit_pt,
                popupGallery: popupGallery_pt,
                popupImage: popupImage_pt,
                watchlists: watchlists_pt,
                dialogs: dialogs_pt,
                group: group_pt
            },
            es: {
                networks: networks_es,
                advancedSearch: advancedSearch_es,
                common: common_es,
                filters: filters_es,
                compareApi: compareApi_es,
                createTopology: createTopology_es,
                login: login_es,
                sidebar: sidebar_es,
                topbar: topbar_es,
                topologiesView: topologiesView_es,
                topologyView: topologyView_es,
                usersManagement: usersManagement_es,
                errors: errors_es,
                audit: audit_es,
                popupGallery: popupGallery_es,
                popupImage: popupImage_es,
                watchlists: watchlists_es,
                dialogs: dialogs_es,
                group: group_es
            },
            ca: {
                networks: networks_ca,
                advancedSearch: advancedSearch_ca,
                common: common_ca,
                filters: filters_ca,
                compareApi: compareApi_ca,
                createTopology: createTopology_ca,
                login: login_ca,
                sidebar: sidebar_ca,
                topbar: topbar_ca,
                topologiesView: topologiesView_ca,
                topologyView: topologyView_ca,
                usersManagement: usersManagement_ca,
                errors: errors_ca,
                audit: audit_ca,
                popupGallery: popupGallery_ca,
                popupImage: popupImage_ca,
                watchlists: watchlists_ca,
                dialogs: dialogs_ca,
                group: group_ca
            },
            fr: {
                networks: networks_fr,
                advancedSearch: advancedSearch_fr,
                common: common_fr,
                filters: filters_fr,
                compareApi: compareApi_fr,
                createTopology: createTopology_fr,
                login: login_fr,
                sidebar: sidebar_fr,
                topbar: topbar_fr,
                topologiesView: topologiesView_fr,
                topologyView: topologyView_fr,
                usersManagement: usersManagement_fr,
                errors: errors_fr,
                audit: audit_fr,
                popupGallery: popupGallery_fr,
                popupImage: popupImage_fr,
                watchlists: watchlists_fr,
                dialogs: dialogs_fr,
                group: group_fr
            }
        }
    });

export default i18n;
