import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Loader from 'react-spinners/CircleLoader';
import { toast } from 'react-toastify';
import TopBarProgress from 'react-topbar-progress-indicator';
import { bindActionCreators } from 'redux';
import ApiService, { ResourceType } from '../../services/apiService';
import AuditService from '../../services/auditService';
import { AppDispatch, RootState } from '../../store';

import {
    changeSeed,
    changeTnt,
    CurrentTab,
    doNavigateTopologyRoot,
    navigateSynapse,
    replaceSeed,
    TopologyRouterSelectors
} from '../../store/router/topologyActions';
import { resetClientFilters, updateClientFilters } from '../../store/slices/filtersSlice';
import { topologyExit } from '../../store/slices/sharedActions';
import {
    fetchTopologies,
    getFinishedTopologies,
    setCurrentTopology,
    isAtLeastOneTopologyIncludeImage2TextSelector,
    isAtLeastOneTopologyIncludeOcrSelector,
    isAtLeastOneTopologyIncludeSimilarImageSelector
} from '../../store/slices/topologiesSlice';
import {
    fetchSynapses,
    fetchTopology,
    onClientFilterDifferent,
    onSelectedConnectionChange,
    onTntChange,
    updatePersons,
    resetSelectedConnection,
    resetMultipleSelectedConnections
} from '../../store/slices/topologySlice';
import { fetchWatchlists } from '../../store/slices/watchlistsSlice';
import { Colors } from '../colors';
import { AntTab, AntTabs } from '../common/antTabs';
import { svgIcons, TabsNames } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import DeletePersonDialog, { DeleteDialogResult } from '../dialogs/deletePersonDialog';
import '../sidebar/sidebar.less';
import ConnectionsMapTab from './connectionsMapTab/connectionsMapTab';
import GalleryTab from './galleryTab/galleryTab';
import SidePanel, { SidePanel as SidePanelType } from './SidePanel';
import './styles/topologyview.less';
import TopologyViewService from './topologyViewService';
import { PersonDescriptor, Synapse } from './VisionSynapse';
import AdvancedSearch from '../advancedSearch/advancedSearch';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { withTranslation, WithTranslation } from 'react-i18next';

TopBarProgress.config({
    barColors: {
        '0': Colors.lightBlue,
        '1.0': Colors.clickable
    },
    barThickness: 4,
    shadowBlur: 5
});

export interface PersonSelectionMode {}

export class ViewMode implements PersonSelectionMode {}

export class MergingMode implements PersonSelectionMode {
    merging: PersonDescriptor[] = [];
    mergeInto: PersonDescriptor;

    constructor(mergeInto: PersonDescriptor, merging: PersonDescriptor[] = []) {
        this.merging = merging;
        this.mergeInto = mergeInto;
    }
}

export class DeletingPersonMode implements PersonSelectionMode {
    deletingPersons: PersonDescriptor[];
    isDeletingPerson: boolean;

    constructor(deletingPersons: PersonDescriptor[], isDeletingPerson?: boolean) {
        this.deletingPersons = deletingPersons;
        this.isDeletingPerson = isDeletingPerson;
    }
}

export class GroupingMode implements PersonSelectionMode {
    grouping: PersonDescriptor[] = [];

    constructor(grouping: PersonDescriptor[] = []) {
        this.grouping = grouping;
    }
}

export class MultiSelectMode implements PersonSelectionMode {
    selected: PersonDescriptor[] = [];
    sharedPhotoIds: Set<string> = new Set();

    constructor(selected: PersonDescriptor[] = [], sharedPhotoIds: string[]) {
        this.selected = selected;
        this.sharedPhotoIds = new Set(sharedPhotoIds);
    }
}

export class AutoGroupMode implements PersonSelectionMode {}

interface State {
    personSelectionMode: PersonSelectionMode;
}

interface TParams {
    batchId: string;
}

interface Props extends RouteComponentProps<TParams>, ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {}

let shouldForceRefreshTopology: boolean;

class TopologyView extends Component<Props & WithTranslation, State> {
    sidePanelRef = createRef<SidePanelType>();
    state = {
        personSelectionMode: new ViewMode()
    };

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        this.loadTopology();
        this.props.fetchWatchlists();
        this.props.fetchTopologies().then(() => {
            this.props.setCurrentTopology(batchId);

            AuditService.logViewEvent(batchId, this.props.currentTopology?.name, ResourceType.Topology);
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        this.props.topologyExit();
    }

    handleKeyDown = (event: KeyboardEvent) => {
        const ESCAPE_KEY = 'Escape';
        if (event.key === ESCAPE_KEY && !(this.state.personSelectionMode instanceof ViewMode)) {
            this.setState({ personSelectionMode: new ViewMode() });
        }
    };

    isTopologyDifferent = (prevProps: Props, prevState: State) => {
        return (
            !_.isEqual(this.props.watchlists, prevProps.watchlists) ||
            !_.isEqual(this.props.commonTopologies, prevProps.commonTopologies) ||
            !_.isEqual(this.props.serverFilters, prevProps.serverFilters) ||
            !_.isEqual(this.props.searchByOcrQuery, prevProps.searchByOcrQuery) ||
            this.forceRefreshTopology()
        );
    };

    forceRefreshTopology = () => {
        const originalShouldForceRefreshTopology = shouldForceRefreshTopology;
        shouldForceRefreshTopology = false;
        return originalShouldForceRefreshTopology;
    };

    isSynapseDifferent = (prevProps: Props) =>
        !_.isEqual(this.props.seedIds, prevProps.seedIds) && prevProps.seedIds.length > 0;

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (this.props.history.location.pathname === '/cases') {
            return;
        }
        if (
            this.props.seedIds.length === 0 &&
            this.props.synapse &&
            !!TopologyViewService.getDefaultPerson(
                this.props.topology.topologyPersons,
                this.props.commonTopologies.concat(this.props.topology.batchId)
            )
        ) {
            this.props.replaceSeed(...this.props.synapse.seedIds);
            return;
        }
        if (this.isTopologyDifferent(prevProps, prevState)) {
            this.loadTopology();
        } else if (this.isSynapseDifferent(prevProps)) {
            this.props.seedIds?.length > 0 ? this.loadSynapse() : this.loadTopology();
        } else if (this.props.isTnt !== prevProps.isTnt) {
            this.handleTntChange();
        } else {
            const isFilterDifferent = !_.isEqual(prevProps.clientFilters, this.props.clientFilters);
            if (isFilterDifferent) {
                this.props.onClientFilterDifferent({
                    clientFilters: this.props.clientFilters,
                    isTnt: this.props.isTnt
                });
            }
        }
    }

    resetViewMode = () => this.setState({ personSelectionMode: new ViewMode() });

    setMergeMode = (person: PersonDescriptor) => {
        this.props.changeCurrentTab(TabsNames.Relationships);
        this.setState({ personSelectionMode: new MergingMode(person) });
    };

    setDeleteMode = (persons: PersonDescriptor[]) => {
        this.setState({ personSelectionMode: new DeletingPersonMode(persons) });
    };

    optimisticSetLabel = (personWithNewLabels: PersonDescriptor) => {
        const topologyPersons = this.getNewPersonArrayWithUpdatedSystemLabel(
            this.props.topology.topologyPersons,
            personWithNewLabels
        );
        const personsInSynapse = this.getNewPersonArrayWithUpdatedSystemLabel(
            this.props.synapse.personsInSynapse,
            personWithNewLabels
        );

        this.props.updatePersons({ personsInSynapse, topologyPersons });
    };

    optimisticUpdatePerson = (updatedPerson: PersonDescriptor) => {
        const topologyPersons = this.getNewPersonArrayWithUpdatedPerson(
            this.props.topology.topologyPersons,
            updatedPerson
        );
        const personsInSynapse = this.getNewPersonArrayWithUpdatedPerson(
            this.props.synapse.personsInSynapse,
            updatedPerson
        );

        this.props.updatePersons({ personsInSynapse, topologyPersons });
    };

    handleTntChange = () => {
        this.props.onTntChange({ isTnt: this.props.isTnt, configuration: this.props.configuration });
        this.props.resetClientFilters();
    };

    getNewPersonArrayWithUpdatedPerson = (personArray: PersonDescriptor[], updatedPerson: PersonDescriptor) => {
        const personArrayCopy = [...personArray];
        const updatedPersonIndex = personArrayCopy.findIndex((person) => person.personUID === updatedPerson.personUID);

        if (updatedPersonIndex !== -1) {
            personArrayCopy[updatedPersonIndex] = updatedPerson;
        }

        return personArrayCopy;
    };

    getNewPersonArrayWithUpdatedSystemLabel = (
        personArray: PersonDescriptor[],
        personWithNewLabels: PersonDescriptor
    ) => {
        const personArrayCopy = [...personArray];

        const editedPersonIndex = personArrayCopy.findIndex(
            (person) => person.personUID === personWithNewLabels.personUID
        );

        if (editedPersonIndex !== -1) {
            personArrayCopy[editedPersonIndex] = personWithNewLabels;
        }

        return personArrayCopy;
    };

    handelSelectPersonInGraph = (person: PersonDescriptor, ctrlKey: boolean) => {
        setTimeout(this.sidePanelRef.current.scrollToBottom);
        this.selectPerson(person, ctrlKey);
    };

    selectPerson = (person: PersonDescriptor, ctrlKey: boolean) => {
        if (
            (ctrlKey && !(this.state.personSelectionMode instanceof MultiSelectMode)) ||
            (this.state.personSelectionMode as MultiSelectMode).selected?.every(
                (selected) => selected.personUID !== person.personUID
            )
        ) {
            this.handlePersonMultiSelectionMode(person);
        } else if (this.state.personSelectionMode instanceof GroupingMode) {
            this.handleCreateGroupMode(person);
        } else if (this.state.personSelectionMode instanceof MergingMode) {
            const mergingMode = this.state.personSelectionMode as MergingMode;
            this.props.changeCurrentTab(TabsNames.Relationships);

            const isMergingWithHimself = person.personUID === mergingMode.mergeInto.personUID;
            if (isMergingWithHimself) {
                return;
            } else if (mergingMode.merging.every((_) => _.personUID !== person.personUID)) {
                this.setState({
                    personSelectionMode: new MergingMode(mergingMode.mergeInto, [...mergingMode.merging, person])
                });
            }
        }
        this.props.onSelectedConnectionChange({
            selectedConnectionId: person.personUID,
            isTnt: this.props.isTnt
        });
    };

    handlePersonMultiSelectionMode(person: PersonDescriptor) {
        const selectedPersons = [...((this.state.personSelectionMode as MultiSelectMode).selected || []), person];
        if (selectedPersons.length === 1 && this.props.selectedPerson) {
            const selectedPerson = this.props.topology.topologyPersons.find(
                (person) => person.personUID === this.props.selectedPerson.personUID
            );
            if (selectedPersons.every((person) => person.personUID !== selectedPerson.personUID)) {
                selectedPersons.unshift(selectedPerson);
            }
        }

        this.setState({
            personSelectionMode: new MultiSelectMode(selectedPersons, this.getSharedPhotoIds(selectedPersons))
        });
    }

    getSharedPhotoIds = (selectedPersons: PersonDescriptor[]): string[] => {
        if (selectedPersons.length <= 1) {
            return [];
        }
        const selectedPersonsPhotos = selectedPersons.map((person) => [
            ...this.props.topologyMetadata.photosByPersonId[person.personUID]
        ]);
        return _.intersection(...selectedPersonsPhotos);
    };

    handleCreateGroupMode(person: PersonDescriptor) {
        if ((this.state.personSelectionMode as GroupingMode).grouping.every((_) => _.personUID !== person.personUID)) {
            const persons = [...(this.state.personSelectionMode as GroupingMode).grouping, person];
            this.setState({ personSelectionMode: new GroupingMode(persons) });
        }
    }

    refreshSynapseAfterDelete = async (deletedPersons: PersonDescriptor[]) => {
        const { topology } = this.props;

        // Find person in topology that is not one of the deleted persons for mark as the next seed (relevant only if one of the deleted persons is seed)
        const newSeed = topology.topologyPersons.find((person) => {
            return deletedPersons.every((deletedPerson) => deletedPerson.personUID !== person.personUID);
        });

        // If there is no newSeed, it means that there is no person in topology that is not one of the deleted persons, which mean that topology is empty
        const emptyPersonsLeftInTopology = !newSeed;

        const oneOfDeletedPersonsIsSeed = deletedPersons.some((deletedPerson) =>
            this.props.seedIds.includes(deletedPerson.personUID)
        );

        if (emptyPersonsLeftInTopology) {
            this.props.doNavigateTopologyRoot(topology.batchId);
        } else if (oneOfDeletedPersonsIsSeed) {
            shouldForceRefreshTopology = true;
            this.props.replaceSeed(newSeed.personUID);
        } else {
            this.loadTopology();
        }
    };

    doMerge = async (mergeInto: PersonDescriptor, merging: PersonDescriptor[]) => {
        const { topology, synapse } = this.props;
        const poiLabels = mergeInto.labels?.map((label) => label.label);
        try {
            await ApiService.mergePersons(
                topology.batchId,
                topology.batchId,
                this.props.currentTopology.name,
                mergeInto.personUID,
                poiLabels,
                merging.map((v) => v.personUID)
            );
        } catch (e) {
            toast.error(this.props.t('general_err_msg', { ns: 'errors' }));
            return;
        }

        const newSeed = topology.topologyPersons.find((p) =>
            merging.every((person) => person.personUID !== p.personUID)
        );
        if (!newSeed) {
            this.props.doNavigateTopologyRoot(topology.batchId);
        }
        // If merged person is seed
        if (
            synapse.seedIds.find((seedId) => merging.some((person) => person.personUID === seedId)) ||
            this.props.seedIds.find((seedId) => merging.some((person) => person.personUID === seedId))
        ) {
            this.props.changeSeed(newSeed.personUID);
        } else {
            await this.loadTopology();
        }
    };

    handleDeletePersonDialogClosed = async (deleteMode: DeleteDialogResult, batchId: string) => {
        if (!deleteMode) {
            this.resetViewMode();
            return;
        }

        const persons = (this.state.personSelectionMode as DeletingPersonMode).deletingPersons;
        this.setState({ personSelectionMode: new DeletingPersonMode(persons, true) });
        const isHardDelete = deleteMode === DeleteDialogResult.Hard;
        const res = await ApiService.deletePerson(
            batchId,
            batchId,
            this.props.currentTopology.name,
            persons,
            isHardDelete
        );
        if (res.status !== 200) {
            toast.error(this.props.t('failed_to_delete_individual'));
        }
        this.refreshSynapseAfterDelete(persons);
        this.props.resetSelectedConnection();
        this.props.resetMultipleSelectedConnections();
    };

    async loadTopology() {
        await this.props.fetchTopology();

        if (!this.props.error) {
            if (this.props.topology?.synapses) {
                this.cancelTntIfNoTntSynapse(this.props.topology.synapses.tnt);
                this.props.updateClientFilters({
                    isLabeledOnly: false,
                    strength: this.props.synapseMetadata?.defaultConnectionStrengthValue
                });
            }
            this.setState({
                personSelectionMode:
                    this.state.personSelectionMode instanceof DeletingPersonMode
                        ? new ViewMode()
                        : this.state.personSelectionMode
            });
        }
    }

    async loadSynapse() {
        await this.props.fetchSynapses();
        if (!this.props.error) {
            if (this.props.currentTab !== TabsNames.Relationships) {
                this.props.changeCurrentTab(TabsNames.Relationships);
            }
            this.cancelTntIfNoTntSynapse(this.props.topology?.synapses.tnt);
            this.props.updateClientFilters({
                isLabeledOnly: false,
                strength: this.props.synapseMetadata?.defaultConnectionStrengthValue
            });

            this.setState({
                personSelectionMode:
                    this.state.personSelectionMode instanceof DeletingPersonMode
                        ? new ViewMode()
                        : this.state.personSelectionMode
            });
        }
    }

    cancelTntIfNoTntSynapse = (tntSynapse: Synapse) => {
        if (tntSynapse?.personsInSynapse.length <= tntSynapse?.seedIds.length && this.props.isTnt) {
            this.props.changeTnt(false);
        }
    };

    getGalleryTabLabel = () => {
        const currentPhotosCount =
            this.props.search != null
                ? this.props.search.numberOfMatchedImages
                : this.props.topology.topologyPhotos.length;
        const allPhotosCount = this.props.originalTopology.topologyPhotos.length;
        return `${this.props.t('tabs.gallery')} (${
            currentPhotosCount === allPhotosCount || this.props.isShowingLoader
                ? allPhotosCount
                : currentPhotosCount + '/' + allPhotosCount
        })`;
    };

    render() {
        const batchId = this.props.match.params.batchId;
        const imageDescriptionActivated = this.props.showImage2text && this.props.isAtLeastOneTopologyIncludeImage2Text;
        const advancedSearchActivated =
            imageDescriptionActivated ||
            this.props.isAtLeastOneTopologyIncludeSimImage ||
            this.props.isAtLeastOneTopologyIncludeOcr;

        if (this.props.error) {
            return (
                <Typography style={{ textAlign: 'center' }} variant={'h2'}>
                    {this.props.error}
                </Typography>
            );
        }

        if (!this.props.topology || !this.props.currentTopology) {
            return (
                <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100%' }}>
                    <Loader size={100} color={Colors.lightBlue} />
                </Grid>
            );
        }

        return (
            <Grid container direction='row' style={{ height: '100%', flexWrap: 'nowrap', overflow: 'hidden' }}>
                {this.props.isFetchingSynapse && <TopBarProgress />}
                {this.state.personSelectionMode instanceof DeletingPersonMode && (
                    <DeletePersonDialog
                        open
                        isLoading={this.state.personSelectionMode.isDeletingPerson}
                        onClose={(deleteMode) => this.handleDeletePersonDialogClosed(deleteMode, batchId)}
                    />
                )}

                <Grid item style={{ width: 320, height: '100%' }}>
                    <SidePanel
                        doSelectPerson={this.selectPerson}
                        setMergingMode={this.setMergeMode}
                        onDeleteMultiplePersons={this.setDeleteMode}
                        optimisticSetLabelToAfterEdit={this.optimisticSetLabel}
                        optimisticUpdatePerson={this.optimisticUpdatePerson}
                        filtersButtonHidden={advancedSearchActivated}
                        ref={this.sidePanelRef}
                        resetViewMode={this.resetViewMode}
                    />
                </Grid>

                <Grid
                    item
                    container
                    direction='column'
                    wrap='nowrap'
                    className='topology-view'
                    style={{
                        backgroundImage: 'linear-gradient(to left, #051f45, #07090e, #07090e, #051f45)',
                        width: 'calc(100vw - 320px)',
                        height: '100%'
                    }}>
                    <Grid container direction='row' className='synapse-header'>
                        <Grid container item style={{ width: 180 }}>
                            <Link className='topologies-link' to='/cases'>
                                <CustomSVGIcon fillColor={Colors.clickable} type={svgIcons.back} size={20} />
                                <span> {this.props.t('cases')}</span>
                            </Link>
                        </Grid>
                        <Grid container item style={{ flex: 1 }}>
                            <h3
                                className='topology-name'
                                onClick={() => this.props.doNavigateTopologyRoot(this.props.match.params.batchId)}>
                                {this.props.currentTopology.name}
                            </h3>
                        </Grid>
                        <Grid container item style={{ width: 150 }} />
                    </Grid>
                    {advancedSearchActivated && (
                        <AdvancedSearch
                            imageDescriptionActivated={imageDescriptionActivated}
                            similarImageActivated={this.props.isAtLeastOneTopologyIncludeSimImage}
                            ocrActivated={this.props.isAtLeastOneTopologyIncludeOcr}
                        />
                    )}
                    <AntTabs
                        style={{ margin: '0 30px 16px' }}
                        value={this.props.currentTab}
                        onChange={(e, value) => this.props.changeCurrentTab(value)}
                        borderBottom={true}>
                        <AntTab value={TabsNames.Gallery} label={this.getGalleryTabLabel()} />
                        <AntTab
                            disabled={!this.props.synapse}
                            value={TabsNames.Relationships}
                            label={this.props.t('tabs.relationships_map')}
                        />
                    </AntTabs>
                    {this.props.currentTab === TabsNames.Gallery && <GalleryTab />}
                    {this.props.currentTab === TabsNames.Relationships && (
                        <ConnectionsMapTab
                            onMerge={this.doMerge}
                            personSelectionMode={this.state.personSelectionMode}
                            onSelectPerson={this.handelSelectPersonInGraph}
                            setPersonSelectionMode={(personSelectionMode) => this.setState({ personSelectionMode })}
                            getSharedPhotoIds={this.getSharedPhotoIds}
                        />
                    )}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    topology: state.topology.data,
    originalTopology: state.topology.originalTopology,
    topologyMetadata: state.topology.topologyMetadata,
    synapseMetadata: state.topology.synapseMetadata,
    synapse: state.topology.currentSynapse,
    isFetchingSynapse: state.topology.isFetchingSynapse,
    error: state.topology.error,
    selectedPerson: state.topology.selectedConnection,
    serverFilters: state.filters.serverFilters,
    clientFilters: state.filters.clientFilters,
    currentTopology: state.topologies.currentTopology,
    configuration: state.configurations.data,
    topologies: getFinishedTopologies(state),
    currentTab: TopologyRouterSelectors.getCurrentTab(state),
    seedIds: TopologyRouterSelectors.getSeedIds(state),
    watchlists: TopologyRouterSelectors.getWatchlistIds(state),
    commonTopologies: TopologyRouterSelectors.getCommonTopologies(state),
    isTnt: TopologyRouterSelectors.isTnt(state),
    search: state.search.searchByTextImageResults,
    searchQuery: TopologyRouterSelectors.getSearchQuery(state),
    searchByOcrQuery: TopologyRouterSelectors.getOcrQuery(state),
    isShowingLoader: state.search.isShowingLoader,
    showImage2text: authService.hasPermissions(Permissions.show_skynet_activation_button),
    isAtLeastOneTopologyIncludeImage2Text: isAtLeastOneTopologyIncludeImage2TextSelector(state),
    isAtLeastOneTopologyIncludeOcr: isAtLeastOneTopologyIncludeOcrSelector(state),
    isAtLeastOneTopologyIncludeSimImage: isAtLeastOneTopologyIncludeSimilarImageSelector(state),
    isMultipleSelectedConnectionsMode: state.topology.isMultipleSelectedConnectionsMode,
    selectedConnection: state.topology.selectedConnection,
    selectedMultipleConnections: state.topology.selectedMultipleConnections
});

const mapDispatchToProps = (dispatch: AppDispatch, ownProps) => ({
    ...bindActionCreators(
        {
            doNavigateTopologyRoot,
            changeSeed,
            replaceSeed,
            changeTnt,
            fetchWatchlists,
            fetchTopologies,
            fetchTopology,
            fetchSynapses,
            onClientFilterDifferent,
            resetClientFilters,
            updateClientFilters,
            onSelectedConnectionChange,
            updatePersons,
            onTntChange,
            setCurrentTopology,
            topologyExit,
            resetSelectedConnection,
            resetMultipleSelectedConnections
        },
        dispatch
    ),
    changeCurrentTab: (tab: CurrentTab) => {
        dispatch(navigateSynapse({ batchId: ownProps.match.params.batchId, tab }, null, false));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['topologyView', 'errors'])(TopologyView));
