import React from 'react';

interface HighlightedTextProps {
    highlight: string;
    children: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = (props) => {
    if (!props.highlight) {
        return <span>{props.children}</span>;
    }

    const specialCharacters = /([.?*+^$[\]\\(){}|-])/g;

    const parts = props.children.split(new RegExp(`(${props.highlight.replace(specialCharacters, '\\$1')})`, 'gi'));

    const getHighlightStyle = (): React.CSSProperties => ({
        fontWeight: 'bold',
        backgroundColor: '#ffd54f',
        color: 'black'
    });

    return (
        <span>
            {parts.map((part, index) => (
                <span
                    key={index}
                    style={part.toLowerCase() === props.highlight.toLowerCase() ? getHighlightStyle() : {}}>
                    {part}
                </span>
            ))}
        </span>
    );
};

export default HighlightedText;
