import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CommonService from '../../services/commonService';
import { Label, LabelNetwork, LabelOrigin, PersonDescriptor } from './VisionSynapse';
import { useTranslation } from 'react-i18next';

interface AddLabelProps {
    person: PersonDescriptor;
    onSaveOrCancel: (label?: Label) => void;
}

const AddLabel: React.FC<AddLabelProps> = (props) => {
    const [label, setLabel] = useState(CommonService.getSystemLabel(props.person?.labels)?.label || '');
    const [labelOrigin, setLabelOrigin] = useState(LabelOrigin.System);
    const [network, setNetwork] = useState(LabelNetwork.Facebook);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(['topologyView', 'networks']);

    useEffect(() => {
        setLabel(
            props.person.labels.find(
                (label) =>
                    label.origin === labelOrigin &&
                    (label.origin !== LabelOrigin.Social || label.metadata?.network === network)
            )?.label ||
                label ||
                ''
        );
    }, [labelOrigin, network]);

    const renderSelectLabelOrigin = () => (
        <Grid item style={{ flex: 1, marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor='select-label-type'>{t('add_label.select_label_type')}</InputLabel>
                <Select value={labelOrigin} onChange={(e) => setLabelOrigin(e.target.value as LabelOrigin)}>
                    <MenuItem key={LabelOrigin.System} value={LabelOrigin.System}>
                        {LabelOrigin.System}
                    </MenuItem>
                    <MenuItem key={LabelOrigin.Social} value={LabelOrigin.Social}>
                        {LabelOrigin.Social}
                    </MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );

    const renderSelectNetwork = () => (
        <Grid item style={{ flex: 1, marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor='select-network'>{t('add_label.select_network')}</InputLabel>
                <Select value={network} onChange={(e) => setNetwork(e.target.value as LabelNetwork)}>
                    {Object.values(LabelNetwork)
                        .filter((network) => network !== LabelNetwork.Mugshots)
                        .map((network) => (
                            <MenuItem key={network} value={network}>
                                {t(network, { ns: 'networks' })}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Grid>
    );

    return (
        <Grid item style={{ padding: '7px 28px' }}>
            <Grid container direction='column'>
                <Grid item>
                    <TextField
                        inputProps={{ maxLength: 100 }}
                        autoFocus
                        InputLabelProps={{ shrink: label ? true : false }}
                        id='person-label'
                        label={t('add_label.individual_label')}
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        margin='normal'
                        style={{ width: '100%' }}
                    />
                </Grid>
                {renderSelectLabelOrigin()}

                {labelOrigin === LabelOrigin.Social && renderSelectNetwork()}

                <Grid item container justify='space-between'>
                    <Grid item>
                        <Button
                            color='primary'
                            variant='contained'
                            disabled={isLoading}
                            onClick={() => {
                                setIsLoading(true);
                                props.onSaveOrCancel({
                                    label,
                                    origin: labelOrigin,
                                    metadata: { network }
                                });
                            }}>
                            {isLoading ? t('add_label.saving') : t('add_label.save')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isLoading}
                            color='primary'
                            variant='outlined'
                            onClick={() => props.onSaveOrCancel()}>
                            {t('add_label.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddLabel;
