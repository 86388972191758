import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React from 'react';
import { Colors } from '../colors';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: 40,
                right: 40
            }
        },
        staticTooltipLabel: {
            color: Colors.white,
            whiteSpace: 'nowrap'
        },
        tooltipPlacementLeft: {
            marginRight: 0
        },
        fab: {
            width: '56px',
            height: '56px',
            backgroundColor: Colors.white,
            '&:hover': {
                backgroundColor: Colors.white
            }
        }
    })
);

export enum GroupActionTypes {
    // GroupAnalysis = 'group',
    CreateGroup = 'createGroup',
    AutoGroup = 'autoGroup'
}

interface GroupActionsProps {
    onActionClick: (actionType: GroupActionTypes) => void;
}

const GroupActions: React.FC<GroupActionsProps> = (props) => {
    const { t } = useTranslation('group');
    const classes = useStyles({});
    const [open, setOpen] = React.useState(false);

    const actions = Object.keys(GroupActionTypes).map((key) => ({
        icon: <CustomSVGIcon type={svgIcons[GroupActionTypes[key]] as any} fillColor={Colors.black} size={24} />,
        name: t('group_actions.' + key),
        type: GroupActionTypes[key]
    }));

    const handleActionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, actionType: GroupActionTypes) => {
        setOpen(false);
        props.onActionClick(actionType);
        e.stopPropagation();
    };

    const handleSpeedDialClick = () => setOpen((open) => !open);

    return (
        <SpeedDial
            ariaLabel={t('group_actions.label')}
            classes={{ root: classes.root, fab: classes.fab }}
            icon={
                <SpeedDialIcon
                    icon={<CustomSVGIcon type={svgIcons.groupActions} fillColor={Colors.black} size={24} />}
                />
            }
            onClick={handleSpeedDialClick}
            open={open}
            direction='up'>
            {actions.map((action) => (
                <SpeedDialAction
                    classes={{
                        staticTooltipLabel: classes.staticTooltipLabel,
                        tooltipPlacementLeft: classes.tooltipPlacementLeft,
                        fab: classes.fab
                    }}
                    tooltipOpen
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={(e) => handleActionClick(e, action.type)}
                />
            ))}
        </SpeedDial>
    );
};

export default GroupActions;
