import React from 'react';
import { QueryRunHistory, RunStatus, TopologyStatus } from '../../store/slices/topologiesSlice';
import { useTranslation } from 'react-i18next';

interface TopologyProgressProps {
    status: TopologyStatus;
    queryRunHistory: QueryRunHistory;
    isWatchlist?: boolean;
}

const TopologyProgress: React.FC<TopologyProgressProps> = (props) => {
    const { t } = useTranslation('topologiesView');

    if (!props.queryRunHistory) {
        return null;
    }

    const {
        currentlyAggregatedPhotosCount,
        currentlyAggregatedVideosCount,
        currentlyAggregatedProfilesCount,
        fcProcessingData,
        ageAndGenderCompleted,
        comparePoiCompleted,
        photosProcessedByFileTransformerCount,
        possibleComparePoiCount,
        currentlyComparedPoiCount,
        includeImage2Text,
        image2TextPostProcessingStatus
    } = props.queryRunHistory;

    const phtotosAggregationProgress = currentlyAggregatedPhotosCount
        ? `${currentlyAggregatedPhotosCount} ${t('progress.photos')}`
        : '';
    const videosAggregationProgress = currentlyAggregatedVideosCount
        ? `${currentlyAggregatedPhotosCount ? ' and' : ''} ${currentlyAggregatedVideosCount} ${t('progress.videos')}`
        : '';
    const profilesAggregationProgress = currentlyAggregatedProfilesCount
        ? `${
              currentlyAggregatedPhotosCount || currentlyAggregatedVideosCount ? ' and' : ''
          } ${currentlyAggregatedProfilesCount} ${t('progress.profiles')}`
        : '';

    const isAggregationProgress =
        currentlyAggregatedPhotosCount || currentlyAggregatedProfilesCount || currentlyAggregatedVideosCount;

    const aggregationProgressText = isAggregationProgress
        ? `${t(
              'progress.aggregated'
          )} - ${phtotosAggregationProgress} ${videosAggregationProgress} ${profilesAggregationProgress}`
        : '';

    const fileTransformerProgress = photosProcessedByFileTransformerCount
        ? `${t('progress.pre-processed')} - ${photosProcessedByFileTransformerCount}`
        : '';

    const fcProgress = fcProcessingData?.photosProcessedByFcCount
        ? `${t('progress.processed')} - ${fcProcessingData.photosProcessedByFcCount}`
        : '';

    const isFcCompleted = props.isWatchlist
        ? fcProcessingData?.fcFriendsCompleted && fcProcessingData.fcPhotosCompleted
        : fcProcessingData?.fcPhotosCompleted;

    function getWatchlistProgressText() {
        const isAggregation = currentlyAggregatedProfilesCount > 0;

        if (!comparePoiCompleted && isAggregation) {
            let comparePoiProgressInPercentage = Math.round(
                (currentlyComparedPoiCount * 100) / possibleComparePoiCount || 0
            );

            comparePoiProgressInPercentage = Math.min(comparePoiProgressInPercentage, 100);
            return `${t('progress.post_processing_1_1')} ${comparePoiProgressInPercentage}%`;
        }

        return t('progress.finalizing');
    }

    function getCaseProgressText() {
        const isAggregation = currentlyAggregatedPhotosCount > 0;
        let postProcessingCount = isAggregation ? 3 : 1;
        postProcessingCount = includeImage2Text ? postProcessingCount + 1 : postProcessingCount;

        if (!ageAndGenderCompleted) {
            return `${t('progress.post_processing_1')}/${postProcessingCount}`;
        }

        if (includeImage2Text && image2TextPostProcessingStatus !== RunStatus.Finished && !isAggregation) {
            return `${t('progress.post_processing_2')}/${postProcessingCount}`;
        }
        if (!fcProcessingData?.fcFriendsCompleted && isAggregation) {
            if (!fcProcessingData?.friendsPhotosProcessedByFcCount) {
                return `${t('progress.post_processing_1')}/${postProcessingCount}`;
            }
            return `${t('progress.expanded_search')} ${fcProcessingData.friendsPhotosProcessedByFcCount}/${
                fcProcessingData.friendsPhotosUploadedToFcCount
            }`;
        }
        if (!comparePoiCompleted && isAggregation) {
            let comparePoiProgressInPercentage = Math.round(
                (currentlyComparedPoiCount * 100) / possibleComparePoiCount || 0
            );

            comparePoiProgressInPercentage = Math.min(comparePoiProgressInPercentage, 100);
            return `${t('progress.post_processing_3')}/${postProcessingCount} ${comparePoiProgressInPercentage}%`;
        }

        if (includeImage2Text && image2TextPostProcessingStatus !== RunStatus.Finished && isAggregation) {
            return `${t('progress.post_processing_4')}/${postProcessingCount}`;
        }

        return t('progress.finalizing');
    }

    const getPostPrecessingText = () => {
        if (props.isWatchlist) {
            return getWatchlistProgressText();
        } else {
            return getCaseProgressText();
        }
    };

    const imageProgress = [aggregationProgressText, fileTransformerProgress, fcProgress]
        .filter((progress) => !!progress)
        .join(', ');

    return props.status === TopologyStatus.Processing && imageProgress ? (
        <span style={{ marginLeft: '8px', fontSize: '10px' }}>
            ({isFcCompleted ? getPostPrecessingText() : t('progress.media_progress') + ' ' + imageProgress})
        </span>
    ) : null;
};

export default TopologyProgress;
