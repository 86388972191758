import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import _ from 'lodash';
import React, { useState } from 'react';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import { Age, Gender } from '../../store/slices/filtersSlice';
import { PersonDescriptor } from './VisionSynapse';
import { useTranslation } from 'react-i18next';

interface EditPersonProps {
    selectedPerson: PersonDescriptor;
    batchId: string;
    onSaveOrCancel: (person?: PersonDescriptor) => void;
}

const EditPerson: React.FC<EditPersonProps> = (props) => {
    const { t } = useTranslation(['topologyView', 'filters']);
    const [person, setPerson] = useState<PersonDescriptor>(props.selectedPerson);

    const handleSave = async () => props.onSaveOrCancel(_.isEqual(props.selectedPerson, person) ? null : person);

    const renderSelectAge = () => (
        <Grid item style={{ flex: 1, marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor='select-age'>Select Age</InputLabel>
                <Select
                    value={person.age}
                    onChange={(e) => {
                        setPerson({ ...person, age: e.target.value as Age });
                    }}>
                    {Object.values(Age).map((age) => (
                        <MenuItem key={age} value={age}>
                            {t(`age_filter.${age}`, { ns: 'filters' })}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );

    const renderSelectGender = () => (
        <Grid item style={{ flex: 1, marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor='select-gender'>Select Gender</InputLabel>
                <Select
                    value={person.gender}
                    onChange={(e) => {
                        setPerson({ ...person, gender: e.target.value as Gender });
                    }}>
                    {Object.values(Gender).map((gender) => (
                        <MenuItem key={gender} value={gender}>
                            {t(`gender_filter.${gender}`, { ns: 'filters' })}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );

    return (
        <Grid item style={{ padding: '7px 28px' }}>
            <Grid container direction='column'>
                {authService.hasPermissions(Permissions.show_age) && renderSelectAge()}
                {authService.hasPermissions(Permissions.show_gender) && renderSelectGender()}
                <Grid item container justify='space-between'>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleSave}>
                            {t('edit_person.save')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' color='primary' onClick={() => props.onSaveOrCancel()}>
                            {t('edit_person.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditPerson;
