import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { svgIcons } from '../common/entities/enums';
import { Colors } from '../colors';
import { useTranslation } from 'react-i18next';
import MagnifyingGlass from '../../images/magnifyingGlass.svg';
import SearchTips from './searchTips';
import AdvanceSearchTips from './advancedSearchTips';
import RecentSearchList from './recentSearchList';
import { useAdvancedSearchStyles } from './image2textAdvancedSearchFormStyle';
import { useDispatch, useSelector } from 'react-redux';
import { navigateSynapse, TopologyRouterSelectors } from '../../store/router/topologyActions';
import { RootState } from '../../store';
import {
    AdvancedSearchImage2TextInputs,
    DropdownImage2TextOperators,
    emptyAdvancedFormInputs,
    ExpressionInput
} from './image2TextForm';

type setFormInputsPrevState = (prevState: Record<string, string>) => Record<string, string>;

interface Image2TextAdvancedSearchFormProps {
    onCloseButtonClickedCallback?: () => void;
    onSubmitEndCallback?: () => void;
    formInputs: { [x: string]: string };
    setFormInputs: (prevState: setFormInputsPrevState | Record<string, string>) => void;
    operatorClassName?: string;
}

const defaultRecentSearchExpanded = {
    expression1: false,
    expression2: false,
    expression3: false
};

const Image2TextAdvancedSearchForm: React.FC<Image2TextAdvancedSearchFormProps> = (props) => {
    const [displayForm, setDisplayForm] = useState(true);
    const [displayImage2TextTips, setDisplayImage2TextTips] = useState(false);
    const [displayImage2TextAdvancedSearchTips, setImage2TextAdvancedSearchTips] = useState(false);
    const [recentSearchExpanded, setRecentSearchExpanded] = useState(defaultRecentSearchExpanded);
    const batchId = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const classes = useAdvancedSearchStyles();
    const { t } = useTranslation('advancedSearch');
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const { formInputs, setFormInputs } = props;

    const handleOutsideRecentSearchClick = (event) => {
        if (wrapperRef?.current && !event.target.getAttribute('name')) {
            setRecentSearchExpanded(defaultRecentSearchExpanded);
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleOutsideRecentSearchClick);

        return () => {
            window.removeEventListener('click', handleOutsideRecentSearchClick);
        };
    }, []);

    const handleCloseButtonPressed = useCallback(() => {
        setDisplayForm(false);
        props.onCloseButtonClickedCallback?.();
    }, []);

    const handleFormChange = useCallback(
        (key: string, value: string) => {
            setFormInputs((prevState) => ({
                ...prevState,
                [key]: value
            }));
        },
        [formInputs]
    );

    const checkAdvancedFormEmpty = () => {
        const expressionsKeysArray = Object.keys(formInputs).filter((input) => input.startsWith(ExpressionInput));
        return expressionsKeysArray.every((key) => formInputs[key] === '');
    };

    const handleAdvancedFormSubmit = useCallback(() => {
        const isAdvancedFormEmpty = checkAdvancedFormEmpty();
        if (!isAdvancedFormEmpty) {
            dispatch(
                navigateSynapse(
                    { batchId, tab: 'gallery' },
                    {
                        operators: [formInputs.operator1, formInputs.operator2],
                        imageDescription: [formInputs.expression1, formInputs.expression2, formInputs.expression3]
                    }
                )
            );
            props.onSubmitEndCallback?.();
        }
    }, [formInputs]);

    const handleClearButtonClicked = useCallback(() => {
        setFormInputs(emptyAdvancedFormInputs);
    }, []);

    const handleInputFocus = useCallback(
        (key: string) => {
            setRecentSearchExpanded({
                ...recentSearchExpanded,
                [key]: !recentSearchExpanded[key]
            });
        },
        [formInputs, recentSearchExpanded]
    );

    const updateOpenInputsStateObj = useCallback(
        (key: string, value: boolean) => {
            setRecentSearchExpanded({
                ...recentSearchExpanded,
                [key]: value
            });
        },
        [formInputs, recentSearchExpanded]
    );

    const handleSelectItemFromDropdown = (key: string, value: string) => {
        handleFormChange(key, value);
        handleInputFocus(key);
    };

    const createExpressionInput = (expressionName: string) => {
        return (
            <>
                <input
                    type='text'
                    name={expressionName}
                    value={formInputs[expressionName]}
                    placeholder={t('image2text_form.placeholder')}
                    autoComplete='off'
                    maxLength={50}
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                    onClick={(e) => handleInputFocus(expressionName)}
                    className={`image2text-input ${classes.input}`}
                    style={!formInputs[expressionName] ? { backgroundImage: `url(${MagnifyingGlass})` } : null}
                />
                {/* the recent search feature is in comment because it is not supported by server with api */}
                {/* {recentSearchExpanded[expressionName] && (
                    <div className={`${classes.recentSearchList} ${classes[`${expressionName}RecentSearchList`]}`}>
                        <RecentSearchList
                            inputValue={formInputs[expressionName]}
                            onSelected={(value: string) => handleSelectItemFromDropdown(expressionName, value)}
                            onClose={() => updateOpenInputsStateObj(expressionName, false)}
                        />
                    </div>
                )} */}
            </>
        );
    };

    return displayForm ? (
        <div className={classes.advancedSearchFormWrapper} ref={wrapperRef}>
            <div className={classes.titleWrapper}>
                <Typography>Advanced Search</Typography>
                <CustomSVGIcon
                    onClick={() => setImage2TextAdvancedSearchTips(!displayImage2TextAdvancedSearchTips)}
                    type={svgIcons.info}
                    size={22}
                    fillColor={Colors.white}
                    customClass={classes.infoButton}
                />
                <CustomSVGIcon
                    onClick={() => setDisplayImage2TextTips(!displayImage2TextTips)}
                    type={svgIcons.help}
                    size={22}
                    fillColor={Colors.white}
                    customClass={classes.infoButton}
                />
            </div>
            {displayImage2TextAdvancedSearchTips && <AdvanceSearchTips />}
            <CustomSVGIcon
                onClick={handleCloseButtonPressed}
                type={svgIcons.close}
                size={22}
                fillColor={Colors.white}
                customClass={classes.closeButton}
            />
            <form className={classes.formWrapper}>
                {createExpressionInput(AdvancedSearchImage2TextInputs.expression1)}

                <select
                    className={classes.dropdownWrapper}
                    value={formInputs[AdvancedSearchImage2TextInputs.operator1]}
                    onChange={(e) => handleFormChange(e.target.name, e.target.value as string)}
                    name={AdvancedSearchImage2TextInputs.operator1}>
                    <option
                        value={DropdownImage2TextOperators.OR}
                        className={`${classes.dropdownItem} ${props.operatorClassName}`}>
                        {DropdownImage2TextOperators.OR}
                    </option>
                    <option
                        value={DropdownImage2TextOperators.AND}
                        className={`${classes.dropdownItem} ${props.operatorClassName}`}>
                        {DropdownImage2TextOperators.AND}
                    </option>
                </select>

                {createExpressionInput(AdvancedSearchImage2TextInputs.expression2)}
                <Typography className={classes.noToperatorText}>{DropdownImage2TextOperators.NOT}</Typography>
                {createExpressionInput(AdvancedSearchImage2TextInputs.expression3)}
                <div className={classes.searchButtonWrapper}>
                    <Button
                        className={classes.clearButton}
                        onClick={handleClearButtonClicked}
                        size='small'
                        variant='contained'
                        color='secondary'>
                        {t('image2text_form.clear')}
                    </Button>
                    <Button onClick={handleAdvancedFormSubmit} size='small' variant='contained' color='primary'>
                        {t('image2text_form.submit')}
                    </Button>
                </div>
            </form>
            {displayImage2TextTips && (
                <div className={classes.tips}>
                    <SearchTips onClose={() => setDisplayImage2TextTips(false)} />
                </div>
            )}
        </div>
    ) : null;
};

export default Image2TextAdvancedSearchForm;
