import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TopologyEntry, TopologyStatus } from '../../store/slices/topologiesSlice';
import { Colors } from '../colors';
import { svgIcons } from '../common/entities/enums';
import HighlightedText from '../common/highlightedText';
import MenuAction from '../common/menuActions';
import CustomSvgIcon from '../common/misc/CustomSvgIcon';
import CreationErrorTooltipInfo, { shouldDisplayInfoTooltip } from './creationErrorTooltipInfo';
import RowDetails from './rowDetails';
import TopologyProgress from './topologyProgress';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

interface SingleTopologyListItemProps {
    data: TopologyEntry;
    query?: string;
    photosCount: number;
    isWatchlist: boolean;
    onSelect?(data?: TopologyEntry);
    onDelete?(data?: TopologyEntry);
    onExport?(data?: TopologyEntry);
    onEdit?(data?: TopologyEntry);
    onDetailsClick?(data?: TopologyEntry);
    onAddToExisting?(data?: TopologyEntry);
}

const SingleTopologyListItem: React.FC<SingleTopologyListItemProps> = ({
    data,
    query,
    isWatchlist,
    onSelect,
    onDelete,
    onExport,
    onEdit,
    onDetailsClick,
    onAddToExisting,
    photosCount
}) => {
    const { t } = useTranslation(['topologiesView', 'errors']);
    const { fileUploadMaxMediaFilesPerZip, recommendedVideoResolution } = useSelector(
        (state: RootState) => state.configurations.data
    );

    const getAvatar = () => <CustomSvgIcon fillColor='none' type={svgIcons.noSeed} size={46} />;

    const getErrorMessage = (errors: string[]) => {
        let errorMessage = '';
        for (const error of errors) {
            const err = t(error?.trim(), {
                ns: 'errors',
                maxFilesInZip: fileUploadMaxMediaFilesPerZip,
                defaultValue: null
            });
            if (err) {
                errorMessage = errorMessage + err + ', ';
            } else {
                return t('general_err_msg', { ns: 'errors' });
            }
        }

        // remove the " ," in the end
        return errorMessage.slice(0, -2);
    };

    const getOptions = () => {
        const options = [];
        if (onDetailsClick) {
            options.push({
                label: t('single_topology_list_item.details'),
                icon: svgIcons.info,
                onClick: () => onDetailsClick(data)
            });
        }
        if (onEdit) {
            options.push({
                label: t('single_topology_list_item.rename'),
                onClick: () => onEdit(data),
                icon: svgIcons.edit
            });
        }
        if (onExport) {
            options.push({
                label: t('single_topology_list_item.export'),
                onClick: () => onExport(data),
                icon: svgIcons.export
            });
        }
        if (onDelete) {
            const isCancel = data.status === TopologyStatus.Processing && data.metadata?.queryRunHistory?.length > 1;
            options.push({
                label: isCancel ? t('single_topology_list_item.cancel') : t('single_topology_list_item.delete'),
                onClick: () => onDelete(data),
                icon: isCancel ? svgIcons.stop : svgIcons.trashBin
            });
        }

        return options;
    };

    const renderErrorIcon = (data: TopologyEntry) => {
        const errors = data.error.substring(1, data.error.length - 1).split(',') as string[];
        return (
            <CustomSvgIcon
                customStyle={{ margin: '0 5px', fill: '#ed1000' }}
                type={svgIcons.warn}
                size={16}
                key={origin}
                tooltip={
                    <>
                        <div>{t('single_topology_list_item.update_failed') + ': ' + getErrorMessage(errors)} </div>
                        {shouldDisplayInfoTooltip(errors) && (
                            <CreationErrorTooltipInfo isWatchlist={isWatchlist} errors={errors} />
                        )}
                    </>
                }
                tooltipProps={{ placement: 'top' }}
            />
        );
    };

    const lastRunHistory = data.metadata?.queryRunHistory?.[data.metadata.queryRunHistory.length - 1];

    return (
        <div className='content-item clickable flex' onClick={() => onSelect(data)}>
            {!isWatchlist && <div style={{ width: 100, display: 'flex' }}>{getAvatar()}</div>}
            <div style={{ flex: 1 }}>
                <div className='item-title flex-align-center'>
                    <HighlightedText highlight={query}>{data.name}</HighlightedText>
                    <TopologyProgress isWatchlist={isWatchlist} status={data.status} queryRunHistory={lastRunHistory} />
                    {(data.origins?.some((origin) => typeof origin === 'string') ||
                        data.metadata?.queryRunHistory.some((queryRunHistory) => queryRunHistory.networksMetadata)) && (
                        <CustomSvgIcon
                            customStyle={{ margin: '0 5px' }}
                            type={svgIcons.falcon}
                            size={16}
                            tooltip={
                                isWatchlist
                                    ? t('single_topology_list_item.watchlist_includes_import_from_falcon')
                                    : t('single_topology_list_item.case_includes_import_from_falcon')
                            }
                        />
                    )}
                    {data.metadata?.queryRunHistory.some((queryRunHistory) => queryRunHistory.withVideo) && (
                        <CustomSvgIcon
                            customStyle={{ margin: '0 5px' }}
                            type={svgIcons.video}
                            fillColor={Colors.lightBlue}
                            size={16}
                            tooltip={t('single_topology_list_item.includes_images_from_video')}
                        />
                    )}
                    {lastRunHistory.videos?.some((video) =>
                        video.resolution
                            .split('x')
                            ?.some((heightOrWidth) => +heightOrWidth < recommendedVideoResolution)
                    ) && (
                        <CustomSvgIcon
                            customStyle={{ margin: '0 5px' }}
                            type={svgIcons.alert}
                            fillColor='#C7A206'
                            size={16}
                            tooltip={t('single_topology_list_item.below_recommended_resolution', {
                                recommendedVideoResolution
                            })}
                        />
                    )}
                    {data.status === TopologyStatus.Error && renderErrorIcon(data)}
                </div>
                <RowDetails photosCount={photosCount} data={data} />
            </div>
            {onAddToExisting && (
                <Button
                    className='item-second-line-btn'
                    onClick={(e) => {
                        e.stopPropagation();
                        onAddToExisting(data);
                    }}
                    disabled={data.status === TopologyStatus.Processing}
                    variant='outlined'
                    size='small'
                    startIcon={<CustomSvgIcon type={svgIcons.plus} size={24} />}>
                    {isWatchlist ? t('single_topology_list_item.add_images') : t('single_topology_list_item.add_media')}
                </Button>
            )}
            <MenuAction options={getOptions()} />
        </div>
    );
};

export default React.memo(
    SingleTopologyListItem,
    (prevProps, nextProps) =>
        _.isEqual(prevProps.data, nextProps.data) &&
        prevProps.query === nextProps.query &&
        prevProps.photosCount === nextProps.photosCount
);
