import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import history from '../store/history';
import rootReducer from './rootReducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        routerMiddleware(history)
    ]
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<any>>;

export default store;
