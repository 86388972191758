import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AuditViewContainer from '../auditViewContainer/auditViewContainer';
import ProtectedRoute from '../common/protectedRoute';
import CompareApiContainer from '../compareApi/compareApiContainer';
import CreateTopologyContainer from '../createTopologyPage/createTopologyContainer';
import LoginPage from '../login/LoginPage';
import { NotFound } from '../notFound/notFound';
import TopologiesViewContainer from '../topologiesView/TopologiesViewContainer';
import TopologyView from '../topologyview/TopologyView';
import UploadWatchlistContainer from '../watchlists/uploadWatchlist/uploadWatchlistContainer';
import WatchlistsViewContainer from '../watchlists/WatchlistsViewContainer';
import WatchlistView from '../watchlists/WatchlistView';
import UsersManagementContainer from '../usersManagement/usersManagmentContainer';
import authService from '../../services/authService';
import { Permissions } from '../../shared/model/Permissions';
import DefaultGroup from '../topologiesView/defaultGroup';

const routes = !authService.hasPermissions(Permissions.hide_cases_tab) ? (
    <Switch>
        <Route exact path='/login' component={LoginPage} />
        <Route exact path='/default' component={DefaultGroup} />
        <ProtectedRoute exact path='/create' component={CreateTopologyContainer} />
        <ProtectedRoute exact path='/add-media/:batchId' component={CreateTopologyContainer} />
        <ProtectedRoute exact path='/cases' component={TopologiesViewContainer} />
        <ProtectedRoute exact path='/watchlists' component={WatchlistsViewContainer} />
        <ProtectedRoute exact path='/createwatchlist' component={UploadWatchlistContainer} />
        <ProtectedRoute exact path='/add-images/:batchId' component={UploadWatchlistContainer} />
        <ProtectedRoute exact path='/watchlist/:batchId' component={WatchlistView} />
        <Redirect exact from='/case/:batchId' to='/case/:batchId/gallery' />
        <ProtectedRoute exact path='/case/:batchId/:tab' component={TopologyView} />
        <ProtectedRoute exact path='/compare' component={CompareApiContainer} />
        <ProtectedRoute exact path='/audit' component={AuditViewContainer} />
        <ProtectedRoute exact path='/usersManagement' component={UsersManagementContainer} />
        <Redirect exact from='/' to='/cases' />
        <Route component={NotFound} />
    </Switch>
) : (
    <Switch>
        <Route exact path='/login' component={LoginPage} />
        <ProtectedRoute exact path='/audit' component={AuditViewContainer} />
        <ProtectedRoute exact path='/usersManagement' component={UsersManagementContainer} />
        <Redirect exact from='/' to='/usersManagement' />
        <Route component={NotFound} />
    </Switch>
);

export default routes;
