import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import CommonService from '../../services/commonService';
import { useTranslation } from 'react-i18next';

interface DateRangeSelectProps {
    value: DateOption;
    onChange: (option: DateOption) => void;
    disabled?: boolean;
    isOptionDisabled?: Record<DateOption, boolean>;
    label: string;
}

export enum DateOption {
    all = 'All (with dates)',
    lastTwoWeeks = 'Last Two Weeks',
    lastTwoMonth = 'Last Two Months',
    lastYear = 'Last Year'
}

const DateRangeSelect: React.FC<DateRangeSelectProps> = (props) => {
    const { t } = useTranslation('common');
    return (
        <FormControl style={{ width: '100%' }} disabled={props.disabled}>
            <InputLabel htmlFor='date-select-label'>{props.label}</InputLabel>
            <Select
                placeholder={props.label}
                displayEmpty
                labelId='date-select-label'
                id='date-select'
                value={props.value || ''}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.onChange(e.target.value as DateOption)}>
                {Object.entries(DateOption).map((option) => (
                    <MenuItem disabled={props.isOptionDisabled?.[option[1]]} key={option[0]} value={option[1]}>
                        {t('date_range_select.date_range_select.' + option[0])}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const isDateOptionsDisabled = (dates: number[], isSeconds: boolean): Record<DateOption, boolean> => {
    const lastYearDate = CommonService.getDateByOption(DateOption.lastYear, isSeconds);
    const lastTwoMonthDate = CommonService.getDateByOption(DateOption.lastTwoMonth, isSeconds);
    const lastTwoWeeksDate = CommonService.getDateByOption(DateOption.lastTwoWeeks, isSeconds);
    const allDate = CommonService.getDateByOption(DateOption.all, isSeconds);

    let isLastYearDisabled = true;
    let isLastTwoMonthDisabled = true;
    let isLastTwoWeeksDisabled = true;
    let isAllDisabled = true;

    dates.forEach((date) => {
        if (date > lastTwoWeeksDate) {
            isLastTwoWeeksDisabled = false;
        }
        if (date > lastTwoMonthDate) {
            isLastTwoMonthDisabled = false;
        }
        if (date > lastYearDate) {
            isLastYearDisabled = false;
        }
        if (date > allDate) {
            isAllDisabled = false;
        }
    });

    return {
        [DateOption.lastTwoWeeks]: isLastTwoWeeksDisabled,
        [DateOption.lastTwoMonth]: isLastTwoMonthDisabled,
        [DateOption.lastYear]: isLastYearDisabled,
        [DateOption.all]: isAllDisabled
    };
};

export default DateRangeSelect;
