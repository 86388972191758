import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { svgIcons } from './entities/enums';
import CustomSvgIcon from './misc/CustomSvgIcon';

export interface MenuOption {
    label: string;
    icon?: svgIcons;
    disabled?: boolean;
    hide?: boolean;
    onClick: (...args: any[]) => void;
}

interface MenuActionProps {
    options: MenuOption[];
}

const MenuAction: React.FC<MenuActionProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton data-test-id='menu' onClick={handleClick}>
                <CustomSvgIcon type={svgIcons.moreVert} size={25} />
            </IconButton>
            {open && (
                <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}>
                    {props.options
                        .filter((option) => !option.hide)
                        .map((option) => (
                            <MenuItem
                                disabled={option.disabled}
                                key={option.label}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    option.onClick();
                                    setAnchorEl(null);
                                }}>
                                <CustomSvgIcon type={option.icon} size={24} />
                                <span style={{ marginLeft: '10px' }}>{option.label}</span>
                            </MenuItem>
                        ))}
                </Menu>
            )}
        </>
    );
};

export default React.memo(MenuAction);
