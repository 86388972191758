import React from 'react';
import { connect } from 'react-redux';
import AuthService from '../../services/authService';
import { fetchConfigurations } from '../../store/slices/configurationsSlice';
import { fetchUsages } from '../../store/slices/usageSlice';

// HOC that send requests to server (after login or refresh) to initialize global data
function withGlobalInitialization<T>(WrappedComponent: React.ComponentType<T>): typeof React.Component {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    type ComponentWithGlobalInitializationProps<P extends Record<string, unknown>> = P &
        ReduxProps<typeof mapDispatchToProps, null> &
        Record<string, unknown>;

    //@ts-ignore
    class ComponentWithGlobalInitialization extends React.Component<ComponentWithGlobalInitializationProps<T>> {
        public static displayName = `withGlobalInitialization(${displayName})`;
        usageRequestIntervalMs = 1000 * 20;
        usageRequestInterval: NodeJS.Timeout;

        componentDidMount() {
            if (AuthService.isAuthorized()) {
                this.props.fetchConfigurations();
                this.updateCounter();
                this.usageRequestInterval = setInterval(this.updateCounter, this.usageRequestIntervalMs);
            }
        }

        componentWillUnmount() {
            clearInterval(this.usageRequestInterval);
        }

        updateCounter = () => {
            if (AuthService.isAuthorized()) {
                this.props.fetchUsages();
            }
        };

        public render() {
            return <WrappedComponent {...(this.props as T)} />;
        }
    }

    const mapDispatchToProps = { fetchUsages, fetchConfigurations };

    //@ts-ignore
    return connect(null, mapDispatchToProps)(ComponentWithGlobalInitialization as any);
}

export default withGlobalInitialization;
