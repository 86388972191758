import React from 'react';

import './popupImageFooter.less';
import { Image } from './popupImageSectionContainer';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { svgIcons } from '../common/entities/enums';
import { useTranslation } from 'react-i18next';
import { Colors } from '../colors';

interface PopupImageFooterProps {
    image: Image;
    isMetadataExpanded: boolean;
    toggleIsMetadataExpanded: () => void;
}

const PopupImageFooter: React.FC<PopupImageFooterProps> = (props) => {
    const { t } = useTranslation(['popupImage', 'common']);

    const renderImageMetadata = (metaData: Record<string, string>) => {
        return (
            <div className='popup-image-footer-metadata-content'>
                {Object.entries(metaData).map(([key, value]) => {
                    return (
                        <div className={`popup-image-footer-metadata-item ${key}`} key={key}>
                            <div className={'primary-text'}>{key}</div>
                            <div className={'secondary-text'}>{value}</div>
                        </div>
                    );
                })}
            </div>
        );
    };
    return (
        <div className='popup-image-footer-wrapper'>
            {(!!props.image.extraText ||
                props.image.faceRecognitionEnabled ||
                props.image.image2TextEnabled ||
                props.image.ocrEnabled ||
                props.image.objectDetectionEnabled ||
                props.image.explicitContentEnabled ||
                props.image.landmarksEnabled ||
                props.image.similarImagesEnabled) && (
                <div className='popup-image-footer-general'>
                    {!!props.image.extraText && (
                        <div className='primary-text'>
                            <CustomSVGIcon
                                size={16}
                                type={svgIcons.clock}
                                fillColor={Colors.white}
                                customClass={'popup-image-footer-icon'}
                            />
                            <span>{props.image.extraText}</span>
                        </div>
                    )}
                    {!!props.image.detectedLandmarks?.length && (
                        <div className='primary-text'>
                            <CustomSVGIcon
                                size={16}
                                type={svgIcons.location}
                                fillColor={Colors.white}
                                customClass={'popup-image-footer-icon'}
                            />
                            <span>
                                {`${props.image.detectedLandmarks[0].description} (${Math.round(
                                    props.image.detectedLandmarks[0].score * 100
                                )}%)`}
                            </span>
                        </div>
                    )}
                    <div className='photo-icons'>
                        {props.image.faceRecognitionEnabled && (
                            <CustomSVGIcon
                                type={svgIcons.face}
                                size={18}
                                fillColor={'white'}
                                customClass={'popup-image-footer-icon'}
                                tooltip={t('image_was_processed_with_entity_matching')}
                            />
                        )}
                        {(props.image.objectDetectionEnabled ||
                            props.image.ocrEnabled ||
                            props.image.image2TextEnabled ||
                            props.image.explicitContentEnabled ||
                            props.image.landmarksEnabled ||
                            props.image.similarImagesEnabled) && (
                            <CustomSVGIcon
                                type={svgIcons.info}
                                size={22}
                                fillColor={'white'}
                                customClass={'popup-image-footer-icon'}
                                tooltip={
                                    <div className='popup-image-footer-tooltip'>
                                        {t('image_was_processed_with')}:
                                        <ul className='popup-image-footer-tooltip-list'>
                                            <li>
                                                {t('advancedSettings.imageDescription', { ns: 'common' })}:{' '}
                                                {props.image.image2TextEnabled ? t('enabled') : t('disabled')}
                                            </li>
                                            <li>
                                                {t('advancedSettings.or', { ns: 'common' })}:{' '}
                                                {props.image.objectDetectionEnabled ? t('enabled') : t('disabled')}
                                            </li>
                                            <li>
                                                {t('advancedSettings.ocr', { ns: 'common' })}:{' '}
                                                {props.image.ocrEnabled ? t('enabled') : t('disabled')}
                                            </li>
                                            <li>
                                                {t('advancedSettings.explicitContent', { ns: 'common' })}:{' '}
                                                {props.image.explicitContentEnabled ? t('enabled') : t('disabled')}
                                            </li>
                                            <li>
                                                {t('advancedSettings.landmarks', { ns: 'common' })}:{' '}
                                                {props.image.landmarksEnabled ? t('enabled') : t('disabled')}
                                            </li>
                                            <li>
                                                {t('advancedSettings.similarImage', { ns: 'common' })}:{' '}
                                                {props.image.similarImagesEnabled ? t('enabled') : t('disabled')}
                                            </li>
                                        </ul>
                                    </div>
                                }
                            />
                        )}
                    </div>
                </div>
            )}
            {props.image.metadata && Object.keys(props.image.metadata).length > 0 && (
                <div>
                    <div
                        className='popup-image-footer-metadata-header'
                        style={{ marginTop: !!props.image.extraText ? '6px' : 0 }}
                        onClick={props.toggleIsMetadataExpanded}>
                        <div className={'title'}>
                            <CustomSVGIcon
                                size={10}
                                type={svgIcons.document}
                                fillColor={'none'}
                                customClass={'popup-image-footer-icon'}
                            />
                            <span className={'primary-text'}>{t('image_metadata')}</span>
                        </div>
                        <CustomSVGIcon
                            size={20}
                            type={props.isMetadataExpanded ? svgIcons.menuUp : svgIcons.menuDown}
                        />
                    </div>
                    {props.isMetadataExpanded && renderImageMetadata(props.image.metadata)}
                </div>
            )}
        </div>
    );
};

export default PopupImageFooter;
