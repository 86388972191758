import React from 'react';
import { useSelector } from 'react-redux';
import { getFormattedImageTypes } from '../../store/slices/configurationsSlice';
import { svgIcons } from '../common/entities/enums';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import FileAndFolderUpload from '../common/fileAndFolderUpload';
import { useTranslation } from 'react-i18next';

interface CompareApiUploadProps {
    onUpload: (file: File[]) => void;
    disabled: boolean;
    maxPhotos: number;
}

const CompareApiUpload: React.FC<CompareApiUploadProps> = (props) => {
    const { t } = useTranslation('compareApi');

    const formattedImageTypes = useSelector(getFormattedImageTypes);

    const renderUploadLimitations = () => {
        return (
            <ul>
                <li>{t('compare_api_upload.limitations.one_face')}</li>
                <li>
                    {t('compare_api_upload.limitations.supported_graphic', {
                        formattedImageTypes: formattedImageTypes
                    })}
                </li>
                <li>{t('compare_api_upload.limitations.max_photos', { maxPhotos: props.maxPhotos })}</li>
            </ul>
        );
    };

    return (
        <div className='zip-upload-container'>
            <FileAndFolderUpload
                multiple
                withVideo={false}
                files={null}
                maxSizeInMegaBytes={1000}
                title={
                    <div className='flex-center'>
                        {t('compare_api_upload.upload_files')}
                        {
                            <CustomSVGIcon
                                customStyle={{ marginLeft: '6px' }}
                                type={svgIcons.info}
                                size={22}
                                tooltip={renderUploadLimitations()}
                            />
                        }
                    </div>
                }
                onChange={props.onUpload}
                onRemove={null}
                disabled={props.disabled}
            />
        </div>
    );
};

export default CompareApiUpload;
