import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateOption } from '../../components/common/dateRangeSelect';
import { PhotoSourceNetwork } from '../../components/topologyview/VisionSynapse';
import { topologyExit } from './sharedActions';
import _ from 'lodash';
import { RootState } from '..';
export interface Filters {
    clientFilters: ClientFilters;
    serverFilters: ServerFilters;
}

export interface ServerFilters {
    networks: PhotoSourceNetwork[];
    objects: string[];
    uploadDate: DateOption;
    creationDate: DateOption;
    ages: Age[];
    gender: Gender;
    flagged: boolean;
}

export interface ClientFilters {
    strength: number;
    isLabeledOnly: boolean;
}

export enum Gender {
    Male = 'MALE',
    Female = 'FEMALE',
    Unknown = 'UNKNOWN'
}

export enum Age {
    A_18_MINUS = 'A_18_MINUS',
    A_18_TO_24 = 'A_18_TO_24',
    A_25_TO_34 = 'A_25_TO_34',
    A_35_TO_44 = 'A_35_TO_44',
    A_45_TO_54 = 'A_45_TO_54',
    A_54_PLUS = 'A_54_PLUS',
    Unknown = 'UNKNOWN'
}

export const getDefaultFilters = (): Filters => {
    return {
        serverFilters: getInitialServerSideFiltersValues(),
        clientFilters: getClientSideFiltersValues()
    };
};

export const getClientSideFiltersValues = (): ClientFilters => {
    return {
        strength: 1,
        isLabeledOnly: false
    };
};

export const getInitialServerSideFiltersValues = (): ServerFilters => {
    return {
        ages: [],
        gender: null,
        objects: [],
        uploadDate: null,
        creationDate: null,
        networks: [],
        flagged: false
    };
};

const initialState: Filters = getDefaultFilters();

const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        updateServerFilters(state, action: PayloadAction<ServerFilters>) {
            state.serverFilters = { ...state.serverFilters, ...action.payload };
        },
        updateClientFilters(state, action: PayloadAction<ClientFilters>) {
            state.clientFilters = { ...state.clientFilters, ...action.payload };
        },
        updateServerFilter(
            state,
            action: PayloadAction<{ key: keyof ServerFilters; value: ServerFilters[keyof ServerFilters] }>
        ) {
            state.serverFilters[action.payload.key as string] = action.payload.value;
        },
        updateClientFilter(
            state,
            action: PayloadAction<{ key: keyof ClientFilters; value: ClientFilters[keyof ClientFilters] }>
        ) {
            state.clientFilters[action.payload.key as string] = action.payload.value;
        },
        resetClientFilters(state) {
            state.clientFilters = getClientSideFiltersValues();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(topologyExit.type, (state, action) => initialState);
    }
});

export const activeServerFiltersCount = createSelector(
    (state: RootState) => state.filters.serverFilters,
    (serverFilters: ServerFilters) =>
        Object.entries(getInitialServerSideFiltersValues()).filter(
            ({ '0': key, '1': value }) => !_.isEqual(serverFilters[key], value)
        ).length
);

export const {
    updateServerFilters,
    updateClientFilters,
    updateClientFilter,
    updateServerFilter,
    resetClientFilters
} = filterSlice.actions;

export default filterSlice.reducer;
