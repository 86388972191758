import { Button, IconButton } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { PersonDescriptor } from '../topologyview/VisionSynapse';
import BottomDrawer from './bottomDrawer';
import './bottomSelectIndeviduals.less';
import { svgIcons } from './entities/enums';
import CustomSVGIcon from './misc/CustomSvgIcon';
import VvAvatar from './vvAvatar';
import { useTranslation } from 'react-i18next';

interface BottomSelectIndividualsProps {
    persons: PersonDescriptor[];
    onPersonClick?: (person: PersonDescriptor) => void;
    buttonText: React.ReactNode;
    buttonDisabled: boolean;
    onButtonClick: () => void;
    onClose: () => void;
    selectIndividualsTooltip?: string;
}

const BottomSelectIndeviduals: React.FC<BottomSelectIndividualsProps> = (props) => {
    const { t } = useTranslation('common');
    const maxPersonInFirstRow = 15;
    const [seeAll, setSeeAll] = useState(false);

    const personsToRender = useMemo(
        () => (seeAll ? [...props.persons].reverse() : [...props.persons].reverse().slice(0, maxPersonInFirstRow)),
        [props.persons, maxPersonInFirstRow]
    );

    const renderDrawerContent = () => (
        <div className={`panel ${seeAll ? 'open' : 'close'}`}>
            <div className='selected-count flex-align-center'>
                {props.persons.length + ' ' + t('bottom_select_individual.selected_individuals')}
                {props.selectIndividualsTooltip && (
                    <CustomSVGIcon
                        customStyle={{ marginLeft: '6px' }}
                        type={svgIcons.info}
                        size={24}
                        tooltip={props.selectIndividualsTooltip}
                    />
                )}
            </div>

            <div className='persons'>
                {personsToRender.map((person) => (
                    <VvAvatar key={person.personUID} person={person} onClick={props.onPersonClick} />
                ))}

                {seeAll && (
                    <b onClick={() => setSeeAll(false)} className='see-less-button'>
                        {t('bottom_select_individual.close')}
                    </b>
                )}

                {!seeAll && props.persons.length > maxPersonInFirstRow && (
                    <IconButton onClick={() => setSeeAll(true)} className='see-all-button'>
                        +{props.persons.length - maxPersonInFirstRow}
                    </IconButton>
                )}
            </div>

            <Button
                disabled={props.buttonDisabled}
                color='primary'
                onClick={props.onButtonClick}
                size='small'
                variant='contained'>
                {props.buttonText}
            </Button>

            <CustomSVGIcon customClass='close-icon' onClick={props.onClose} type={svgIcons.close} size={20} />
        </div>
    );

    return <BottomDrawer onClose={props.onClose}>{renderDrawerContent()}</BottomDrawer>;
};

export default BottomSelectIndeviduals;
