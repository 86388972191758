import React from 'react';
import { Map, GoogleApiWrapper, Marker, IMapProps } from 'google-maps-react';
import { StyleVariables as St, typographySubtitle, flexSpaceBetween } from '../styleVariables';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../colors';
import { Landmark } from '../topologyview/VisionSynapse';
import CustomTooltip from '../common/misc/CustomTooltip';

interface CustomMapProps extends IMapProps {
    landmark: Landmark;
    index: number;
    googleApiKey: string;
}

const useStyles = makeStyles({
    root: {
        width: St.landmarks.boxWidth,
        marginBottom: St.gap * 2,
        paddingBottom: St.gap * 3,
        borderBottom: '1px solid ' + Colors.darkGray
    },
    title: {
        ...typographySubtitle,
        ...flexSpaceBetween,
        marginBottom: St.gap
    },
    name: {
        width: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    score: {
        color: Colors.accent
    }
});

const mapStyles = {
    position: 'static',
    width: St.landmarks.boxWidth,
    height: St.landmarks.boxHeight,
    borderRadius: 4
};

export const CustomMap: React.FC<CustomMapProps> = ({ google, landmark, index, googleApiKey }) => {
    const classes = useStyles();
    const { latitude, longitude } = landmark.location;

    return (
        <div className={`custom-map ${classes.root}`}>
            <div className={`custom-map-title ${classes.title}`}>
                <CustomTooltip title={landmark.description}>
                    <span className={`custom-map-title-name ${classes.name}`}>{landmark.description}</span>
                </CustomTooltip>
                <span className={`custom-map-title-score ${index === 0 && classes.score}`}>
                    {Math.round(landmark.score * 100)}%
                </span>
            </div>
            <Map
                google={google}
                containerStyle={mapStyles}
                style={mapStyles}
                initialCenter={{
                    lat: latitude,
                    lng: longitude
                }}
                // @ts-ignore
                zoom={14}>
                <Marker
                    // @ts-ignore
                    title={landmark.description}
                    position={{ lat: latitude, lng: longitude }}
                />
            </Map>
        </div>
    );
};

export default GoogleApiWrapper((props) => ({
    apiKey: props.googleApiKey
}))(CustomMap);
