import React from 'react';
import CreateTopologyService from './createTopologyService';

interface UploadMediaTooltipProps {}

const UploadMediaTooltip: React.FC<UploadMediaTooltipProps> = () => (
    <ul>
        {CreateTopologyService.getInstructions().map((instruction) => (
            <li key={instruction}>{instruction}</li>
        ))}
    </ul>
);

export default UploadMediaTooltip;
