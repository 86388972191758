import {
    Checkbox,
    Chip,
    FormControl,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ServerFilters } from '../../store/slices/filtersSlice';
import DateRangeSelect, { isDateOptionsDisabled } from '../common/dateRangeSelect';
import { Network, PhotoSourceNetwork } from '../topologyview/VisionSynapse';
import { useTranslation } from 'react-i18next';
interface AggregationMediaFiltersProps {
    filters: ServerFilters;
    setFilters: (filters: ServerFilters) => void;
    aggregatedPhotosCount: number;
    aggregationFilteredPhotosCount: number;
    disabled: boolean;
}

const AggregationMediaFilters: React.FC<AggregationMediaFiltersProps> = (props) => {
    const { t } = useTranslation(['filters', 'networks']);
    const currentTopologyPhotos = useSelector((state: RootState) => state.topology.data.topologyPhotos);
    const isOptionDisabled = useMemo(
        () =>
            isDateOptionsDisabled(
                currentTopologyPhotos.map((photo) => photo.timestamp),
                true
            ),
        currentTopologyPhotos
    );

    return (
        <>
            <Typography variant='subtitle2' color='textPrimary' style={{ marginTop: '50px' }}>
                {t('aggregation_media_filters.falcon_media')} (
                {`${props.aggregationFilteredPhotosCount}/${props.aggregatedPhotosCount} images`})
            </Typography>

            <DateRangeSelect
                isOptionDisabled={isOptionDisabled}
                disabled={props.disabled || !props.aggregatedPhotosCount}
                value={props.filters.uploadDate}
                onChange={(date) => props.setFilters({ ...props.filters, uploadDate: date })}
                label={t('aggregation_media_filters.upload_date')}
            />
            <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor='network'>{t('aggregation_media_filters.network')}</InputLabel>
                <Select
                    disabled={props.disabled || !props.aggregatedPhotosCount}
                    multiple
                    value={props.filters.networks}
                    onChange={(e) =>
                        props.setFilters({ ...props.filters, networks: e.target.value as PhotoSourceNetwork[] })
                    }
                    input={<Input id='network' />}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        getContentAnchorEl: null
                    }}
                    renderValue={(networks: Network[]) =>
                        networks.map((value) => (
                            <Chip
                                style={{ marginLeft: '2px' }}
                                key={value}
                                label={t(value, { ns: 'networks' })}
                                size='small'
                            />
                        ))
                    }>
                    {Object.values(PhotoSourceNetwork).map((network) => (
                        <MenuItem
                            disabled={currentTopologyPhotos.filter((photo) => photo.photoType === network).length === 0}
                            key={network}
                            value={network}>
                            <Checkbox color='primary' checked={props.filters.networks.indexOf(network) > -1} />
                            <ListItemText primary={t(network, { ns: 'networks' })} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default AggregationMediaFilters;
