import axios, { AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import jwt, { SignCallback } from 'jsonwebtoken';
import { LoginFormData } from '../components/login/LoginPage';
import { Permissions } from '../shared/model/Permissions';
import history from '../store/history';
import AjaxClient from './ajaxClient';

export interface Tokens {
    accessToken: string;
    refreshToken: string;
}

declare module 'axios' {
    interface AxiosRequestConfig {
        skipAuthRefresh?: boolean;
        signal?: SignCallback;
    }
}

const refreshToken = 'refreshToken';
const accessToken = 'accessToken';

const authService = {
    login(data: LoginFormData, config?: AxiosRequestConfig) {
        return AjaxClient.post(`/user/login/`, data, config);
    },

    getUserByName(userName: string) {
        return AjaxClient.get(`/user/byusername/${userName}`);
    },

    getUser() {
        return AjaxClient.get(`/sso/getUser`);
    },

    refreshToken(refreshToken: string) {
        return AjaxClient.post<Tokens>('/user/refreshToken', { refreshToken }, { skipAuthRefresh: true });
    },

    storeTokens(tokens: Tokens) {
        localStorage.setItem(accessToken, tokens.accessToken);
        localStorage.setItem(refreshToken, tokens.refreshToken);
    },

    getAccessToken() {
        return localStorage.getItem(accessToken);
    },

    getRefreshToken() {
        return localStorage.getItem(refreshToken);
    },

    isAuthorized() {
        return !!authService.getAccessToken();
    },

    async logout(manualLogout: boolean, ssonEnabled?: boolean) {
        if (manualLogout) {
            await AjaxClient.post(`/user/logout`, null);
        }
        localStorage.removeItem(accessToken);
        localStorage.removeItem(refreshToken);
        if (ssonEnabled) {
            await AjaxClient.post(`/sso/logout`, null);
        }
    },

    getAccessTokenData(): AccessToken {
        try {
            return jwt.decode(authService.getAccessToken()) as AccessToken;
        } catch {
            return null;
        }
    },

    getTokens(): Tokens {
        return {
            accessToken: authService.getAccessToken(),
            refreshToken: authService.getRefreshToken()
        };
    },

    hasPermissions(...permissions: Permissions[]): boolean {
        const data = authService.getAccessTokenData();

        return data && data.permissions && permissions.every((permission) => data.permissions.includes(permission));
    },

    hasAtLeastOnePermission(...permissions: Permissions[]): boolean {
        const data = authService.getAccessTokenData();

        return data && data.permissions && permissions.some((permission) => data.permissions.includes(permission));
    }
};

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    config.headers['x-auth-token'] = authService.getAccessToken();
    return config;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
    if (failedRequest.response.data.msg === 'err_invalid_token_sso') {
        return authService.logout(false, true).finally(() => {
            history.push('/login');
            return Promise.reject();
        });
    } else {
        return authService
            .refreshToken(authService.getRefreshToken())
            .then((res) => {
                authService.storeTokens(res.data);
                return Promise.resolve();
            })
            .catch(() => {
                authService.logout(false);
                history.push('/login');
                return Promise.reject();
            });
    }
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic, { skipWhileRefreshing: false, statusCodes: [403] });

export interface AccessToken {
    id: string;
    userName: string;
    permissions: string[];
    groups: string[];
    lastUsedUserGroup: string;
    userId?: number;
}

export default authService;
