import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
const BASE_URL = '/api';

axiosRetry(axios, { retries: 0 });
const AjaxClient = {
    post<T = any>(url: string, payload: Record<string, any>, headers?: AxiosRequestConfig) {
        return axios.post<T>(`${BASE_URL}${url}`, payload, headers);
    },
    get<T = any>(url: string, config?: AxiosRequestConfig) {
        return axios.get<T>(`${BASE_URL}${url}`, config);
    },
    postFormData<T = any>(url: string, payload: Record<string, any>, requestConfig: AxiosRequestConfig = null) {
        const formData = new FormData();
        for (const key in payload) {
            const data = payload[key];
            if (data) {
                if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        formData.append(`${key}`, data[i]);
                    }
                } else {
                    formData.append(key, data);
                }
            }
        }
        return AjaxClient.post<T>(url, formData, requestConfig);
    },
    delete<T = any>(url: string, config?: AxiosRequestConfig) {
        return axios.delete<T>(`${BASE_URL}${url}`, config);
    },
    put<T = any>(url: string, payload?: Record<string, any>, headers?: AxiosRequestConfig) {
        return axios.put<T>(`${BASE_URL}${url}`, payload, headers);
    },
    patch<T = any>(url: string, payload: Record<string, any>, headers?: AxiosRequestConfig) {
        return axios.patch<T>(`${BASE_URL}${url}`, payload, headers);
    }
};

export const AjaxUtils = {
    createCancelToken() {
        return axios.CancelToken;
    },
    isCancelError(error: AxiosError) {
        return axios.isCancel(error);
    }
};

export default AjaxClient;
