import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StyleVariables } from '../styleVariables';

const useStyles = makeStyles({
    wrapper: {
        width: '600px',
        padding: StyleVariables.gap * 2,
        paddingRight: StyleVariables.gap * 2,
        paddingTop: StyleVariables.gap,
        paddingBottom: StyleVariables.gap,
        background: '#202424',
        border: '1px solid #979797',
        marginTop: StyleVariables.gap / 2
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid white',
        height: '92px',
        alignItems: 'center'
    },
    notesRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        lineHeight: '34px',
        paddingLeft: '21px'
    },
    operand: {
        width: '20%',
        fontWeight: 700,
        fontSize: '24px'
    },
    valueWrapper: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column'
    },
    sentenceWrapper: {
        textAlign: 'left',
        lineHeight: '34px',
        fontSize: '14px'
    }
});

const AdvanceSearchTips = () => {
    const { t } = useTranslation('advancedSearch');
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.row}>
                <div className={classes.operand}>{t('advance_search_tips.and')}</div>
                <div className={classes.valueWrapper}>
                    <div className={classes.sentenceWrapper}>
                        <span>{t('advance_search_tips.a_man_near_car')}</span>
                        <strong> {t('advance_search_tips.and')} </strong>
                        <span>{t('advance_search_tips.a_woman_near_a_motorcycle')}</span>
                    </div>
                    <div>
                        <span>{t('advance_search_tips.Both_described_images')}</span>
                    </div>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.operand}>{t('advance_search_tips.or')}</div>
                <div className={classes.valueWrapper}>
                    <div className={classes.sentenceWrapper}>
                        <span>{t('advance_search_tips.a_man_near_car')}</span>
                        <strong> {t('advance_search_tips.or')} </strong>
                        <span>{t('advance_search_tips.a_woman_near_a_motorcycle')}</span>
                    </div>
                    <div>
                        <span>{t('advance_search_tips.Both_described_images')}</span>
                    </div>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.operand}>{t('advance_search_tips.not')}</div>
                <div className={classes.valueWrapper}>
                    <div className={classes.sentenceWrapper}>
                        <span>{t('advance_search_tips.a_man_near_car')}</span>
                        <strong> {t('advance_search_tips.and')} </strong>
                        <span>{t('advance_search_tips.a_woman_near_a_motorcycle')}</span>
                        <strong> {t('advance_search_tips.not')} </strong>
                        <span>{t('advance_search_tips.a_man_near_a_bus')}</span>
                    </div>
                    <div>
                        <span>{t('advance_search_tips.image_description_will_not')}</span>
                    </div>
                </div>
            </div>
            <ul className={classes.notesRow}>
                <li>{t('advance_search_tips.not_search_field')}</li>
                <li>{t('advance_search_tips.search_field_can_include')}</li>
            </ul>
        </div>
    );
};

export default AdvanceSearchTips;
