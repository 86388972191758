import { Drawer, DrawerProps, withStyles } from '@material-ui/core';
import React from 'react';

const StyledDrawer = withStyles({
    paper: {
        width: '70vw',
        margin: '0 auto',
        backgroundColor: '#171919'
    }
})(Drawer);

interface BottomDrawerProps extends DrawerProps {}

const BottomDrawer: React.FC<BottomDrawerProps> = (props) => {
    return (
        <StyledDrawer
            className='drawer-container'
            variant='persistent'
            open={true}
            BackdropProps={{ invisible: true }}
            anchor='bottom'
            {...props}>
            {props.children}
        </StyledDrawer>
    );
};

export default BottomDrawer;
