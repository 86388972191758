import React from 'react';
import authService from '../../../services/authService';
import { Permissions } from '../../../shared/model/Permissions';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import { svgIcons } from '../../common/entities/enums';
import { BatchDescriptor, ClassifyMode } from '../createTopologyService';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../colors';

interface FrModeFormikSectionProps {}

const FrModeFormikSection: React.FC<FrModeFormikSectionProps> = () => {
    const { t } = useTranslation(['createTopology', 'common']);

    const { values, isSubmitting } = useFormikContext<BatchDescriptor>();

    const isDisabled = isSubmitting || !values.batchData.advancedSettings.entityMatching;
    return authService.hasPermissions(Permissions.fc_customization) ? (
        <div className='fr-mode'>
            <div className='flex-align-center'>
                <span>{t('fr_model_formik_section.entity_matching_mode')}</span>
                <CustomSVGIcon
                    size={20}
                    fillColor={Colors.lightGray}
                    customStyle={{ marginLeft: '8px' }}
                    type={svgIcons.info}
                    tooltip={
                        <>
                            <span>{t('fr_model_formik_section.tooltip')}</span>
                            <ul>
                                <li>
                                    {' '}
                                    "{t('classifyMode.' + ClassifyMode.MODERATE_FOR_VIDEOS, { ns: 'common' })}" -{' '}
                                    {t('fr_model_formik_section.classify_mode.' + ClassifyMode.MODERATE_FOR_VIDEOS)}
                                </li>
                                <li>
                                    "{t('classifyMode.' + ClassifyMode.STRICT, { ns: 'common' })}" -{' '}
                                    {t('fr_model_formik_section.classify_mode.' + ClassifyMode.STRICT)}
                                </li>
                                <li>
                                    "{t('classifyMode.' + ClassifyMode.MODERATE, { ns: 'common' })}" -{' '}
                                    {t('fr_model_formik_section.classify_mode.' + ClassifyMode.MODERATE)}
                                </li>
                            </ul>
                        </>
                    }
                />
            </div>

            <div style={{ marginBottom: '15px' }}>
                <Field row component={RadioGroup} name='batchData.classifyMode'>
                    <FormControlLabel
                        value={ClassifyMode.MODERATE_FOR_VIDEOS}
                        control={<Radio disabled={isDisabled} />}
                        label={t('classifyMode.' + ClassifyMode.MODERATE_FOR_VIDEOS, { ns: 'common' })}
                        disabled={isDisabled}
                    />
                    <FormControlLabel
                        value={ClassifyMode.STRICT}
                        control={<Radio disabled={isDisabled} />}
                        label={t('classifyMode.' + ClassifyMode.STRICT, { ns: 'common' })}
                        disabled={isDisabled}
                    />
                    <FormControlLabel
                        value={ClassifyMode.MODERATE}
                        control={<Radio disabled={isDisabled} />}
                        label={t('classifyMode.' + ClassifyMode.MODERATE, { ns: 'common' })}
                        disabled={isDisabled}
                    />
                </Field>
            </div>
        </div>
    ) : null;
};

export default FrModeFormikSection;
